import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Button,
    Card,
    Radio
} from 'antd'
import _ from 'lodash'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'
import {API_URL} from '../../../request'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {connect} from 'react-redux'
import {createMatchSelector, goBack} from 'connected-react-router'


@Form.create()
class AddUser extends PureComponent {


    handleSubmit = e => {
        const {dispatch, form} = this.props
        let {countryList} = this.state
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)

                values.state = values.countryType === 'Outside India' ? [] : values.state
                if (values.totalFollowupInHour > values.totalFollowupInDay) {
                    return notification.error({
                        message: 'Day Followup must be greater than Slot Followup'
                    })

                }
                dispatch(showLoader())

                let x = null
                if (this.state.id) {
                    values._id = this.state.id

                    x = await Request.updateUserDetails(values)
                } else {
                    x = await Request.addUser(values)
                }

                dispatch(hideLoader())


                if (!x.error) {

                    notification.success({
                        message: this.state.id
                            ? x.message
                            : x.message
                    })

                    if (!this.state.id) {
                        this.props.form.resetFields()
                        this.props.form.setFieldsValue({countryType: 'India'})

                    }

                    if (this.state.id) {
                        dispatch(goBack())

                    }


                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }


    constructor(props) {
        super(props)
        this.state = {stateList: [], countryList: [], loader: true}

    }

    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search)
        //  let {data: countryList} = await Request.loadCountry({})
        let {data: userList} = await Request.getAllROList()
        this.setState({roList: userList})

        // 5fca1f319d6c311f44e80d06
        let {data: stateList} = await Request.loadState({countryId: '5fca34a100c10219d4c564de'})
        this.setState({stateList: stateList})

        let userId = searchParams.get('id')
        if (userId) {
            Request.getUserById({_id: userId}).then(({data, error, message}) => {
                if (!error) {
                    this.setState({
                        id: userId,
                        loader: false


                    })
                    this.props.form.setFieldsValue({
                        name: data && data.name,
                        email: data && data.email,
                        mobile: data && data.mobile,
                        password: data && data.password,
                        userType: data && data.userType,
                        countryType: data && data.countryType
                    

                    })
                    this.props.form.setFieldsValue({
                        recordingPath: data && data.recordingPath,
                        state: data && data.state,
                        asyncLimit: data && data.asyncLimit,
                        totalFollowupInDay: data && data.totalFollowupInDay,
                        totalFollowupInHour: data && data.totalFollowupInHour,
                        totalLeadsInDay: data && data.totalLeadsInDay,
                        totalCallerLeadsInDay: data && data.totalCallerLeadsInDay,
                        roList: data && data.roList,
                        dontTransferLeads: data.dontTransferLeads,
                        accessToken: data && data.accessToken,
                        instanceId: data && data.instanceId,
                        checkLeadCall: data && data.checkLeadCall,
                        leadAlertTime: data && data.leadAlertTime


                    })

                } else {
                    this.setState({loader: false})

                    notification.error({
                        message: 'Error Getting Data'
                    })
                }
            })
        } else {
            this.setState({loader: false})
            this.props.form.setFieldsValue({countryType: 'India'})

        }


    }

    render() {
        let {countryList, stateList, loader, roList} = this.state

        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props


        const inputTypes = {
            fields: [
                {
                    label: 'Name',
                    key: 'name',
                    required: true
                },
                {
                    label: 'Mobile',
                    key: 'mobile',
                    required: true
                },
                {
                    label: 'User Type',
                    key: 'userType',
                    required: true,
                    type: 'select',
                    options: ['Admin', 'RO', 'Dispatch', 'Technician', 'Lead Executive', 'Caller', 'Manager'],
                    onChange: v => {
                        this.props.form.setFieldsValue({userType: v})

                    }

                },
                {
                    label: 'Relationship Officer',
                    key: 'roList',
                    type: 'select',
                    hidden: getFieldValue('userType') !== 'Manager',
                    showSearch: true,
                    keyAccessor: x => x._id,
                    valueAccessor: x => `${x.name} (${x.mobile})`,
                    options: roList || [],
                    mode: 'multiple',
                    onChange: (roList) => {
                        this.props.form.setFieldsValue({
                            roList: roList
                        })
                    },
                    required: true
                },
                {
                    label: 'Recording Path',
                    key: 'recordingPath',
                    placeholder: 'Start with folder name',
                    hidden: getFieldValue('userType') !== 'RO'
                    // required: true
                },
                {
                    label: 'Async Limit',
                    key: 'asyncLimit',
                    hidden: getFieldValue('userType') !== 'RO',
                    type: 'number'
                    // required: true
                },
                {
                    label: 'Dont Transfer Leads',
                    key: 'dontTransferLeads',
                    type: 'switch',
                    //   required: true,
                    onChange: (dontTransferLeads) => {
                        this.props.form.setFieldsValue({
                            dontTransferLeads: dontTransferLeads
                        })
                    }
                }


            ]
        }

        const inputTypes1 = {
            fields: [
                {
                    label: 'State',
                    type: 'select',
                    key: 'state',
                    // required: true,
                    showSearch: true,
                    hidden: (getFieldValue('countryType') !== 'India') || (getFieldValue('userType') !== 'RO'),
                    keyAccessor: x => _.startCase(x.stateName),
                    valueAccessor: x => x.stateName && _.startCase(x.stateName),
                    options: stateList || [],
                    mode: 'multiple',
                    onChange: (state) => {
                        this.props.form.setFieldsValue({
                            state: state
                        })
                    }


                },

                {
                    label: 'Email',
                    key: 'email',
                    required: true
                },
                {
                    label: 'Password',
                    key: 'password',
                    type: 'password',
                    required: true,
                    hidden: this.state.id ? true : false
                },
                {
                    label: 'Total Followup (Day)',
                    key: 'totalFollowupInDay',
                    required: true,
                    type: 'number',
                    // hidden: (getFieldValue('userType') !== 'RO')
                },

                {
                    label: 'Total Followup (Slot)',
                    key: 'totalFollowupInHour',
                    required: true,
                    type: 'number',
                    // hidden: (getFieldValue('userType') !== 'RO')
                },

                {
                    label: 'Total Leads(Day)',
                    key: 'totalLeadsInDay',
                    required: true,
                    type: 'number',
                    // hidden: (getFieldValue('userType') !== 'RO')
                },
                {
                    label: 'Total Caller Leads(Day)',
                    key: 'totalCallerLeadsInDay',
                    required: true,
                    type: 'number',
                    // hidden: (getFieldValue('userType') !== 'Caller')
                },
                {
                    label: 'Inactive Alert Time',
                    key: 'leadAlertTime',
                    required: true,
                    type: 'number',
                    rightComp:<span style={{padding:'5px 5px'}}>(minutes)</span>
                    // hidden: (getFieldValue('userType') !== 'Caller')
                },
                {
                    label: 'Access Token',
                    key: 'accessToken',
                    // hidden: (getFieldValue('userType') !== 'Caller')
                }, {
                    label: 'Instance Id',
                    key: 'instanceId',
                    // hidden: (getFieldValue('userType') !== 'Caller')
                },
                {
                    label: 'Check Lead Call',
                    key: 'checkLeadCall',
                    type: 'switch',
                    //   required: true,
                    onChange: (checkLeadCall) => {
                        this.props.form.setFieldsValue({
                            checkLeadCall: checkLeadCall
                        })
                    }
                }

            ]
        }

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }


        return (
            <PageHeaderWrapper
                title={this.state.id ? 'Update Relationship Officer' : 'Add Relationship Officer'}
            >

                <Card bordered={true} loading={loader}>
                    <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{marginTop: 8}}>

                        <GetAllFormFields apiurl={API_URL}
                                          inputSchema={inputTypes} formItemLayout={formItemLayout}
                                          getFieldDecorator={getFieldDecorator}/>

                        <Form.Item label={'Country Type'} {...formItemLayout} >
                            {getFieldDecorator('countryType', {
                                rules: [{required: true}]

                            })(
                                <Radio.Group name="countryType" disabled={getFieldValue('userType') !== 'RO'}>
                                    <Radio value={'India'}>India</Radio>
                                    <Radio value={'Outside India'}>Outside India</Radio>

                                </Radio.Group>)}
                        </Form.Item>

                        <GetAllFormFields apiurl={API_URL}
                                          inputSchema={inputTypes1} formItemLayout={formItemLayout}
                                          getFieldDecorator={getFieldDecorator}/>

                        <Form.Item {...submitFormLayout} style={{marginTop: 32}}>
                            <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                SAVE
                            </Button>
                        </Form.Item>

                    </Form>
                </Card>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddUser)
