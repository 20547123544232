import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils'
import moment from 'moment'
import {
    Modal,
    Card,
    Button, Tooltip,
    Select,
    Row,
    Col

} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
//import styles from './styles.less'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'
import {apiUrl} from "../../../settings";

const {Option} = Select

class AllStates extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {visible: false, key: Date.now()}

    }


    apiRequest = (params) => {

        return new Promise(async (resolve) => {

            if (this.state.countryId) {
                params.countryId = this.state.countryId
            }

            let data = await Request.getAllStates({
                ...params, regExFilters: ['stateName']
            })

            this.setState({count: data.total})
            resolve(data);
        })


    }
    showModal = () => {
        this.setState({
            visible: true
        })
    }

    async componentDidMount() {

        let {data: countryList} = await Request.loadCountry()

        this.setState({countryList: countryList})

    }

    render() {
        const {submitting, dispatch} = this.props
        const {visible, singleMail, countryList} = this.state;


        const columns = [
            {
                title: 'Country Name',
                key: 'countryName',
                width: 150,
                render: (val, record) => {
                    return <div>{record && record.countryId && _.startCase(record.countryId.countryName)}</div>
                }


            },
            {
                title: 'State Name',
                key: 'stateName',
                width: 150,
                dataIndex: 'stateName',
                searchTextName: 'stateName',
                render: (val) => {
                    return val && _.startCase(val)
                }


            }

        ]


        return (
            <PageHeaderWrapper
                title={this.state.count ? `All State: ${this.state.count}` : `All State: 0`}>


                <Card>
                    <Row>
                        <Col span={8}>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                style={{
                                    width: 225

                                }}
                                placeholder="Select Country"
                                onChange={id => {
                                    this.setState({countryId: id}, () => {
                                        this.table.current.reload()

                                    })
                                }}
                            >
                                {countryList &&
                                countryList.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val._id}>
                                            {val && _.startCase(val.countryName)}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Col>

                    </Row>
                </Card>


                <Card bordered={true}>

                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>


            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllStates)
