import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Input,
  DatePicker,
  Tooltip,
  Select,
  Button,
  Card,
  Row,
  Col,
  Icon,
  Drawer,
  Spin,
  AutoComplete,
} from 'antd'
import { ChromePicker } from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils'
import { API_URL } from '../../../request'
import ReactQuill from 'react-quill'
import { getUrlPushWrapper } from '../../../routes'
import { LinkOutlined } from '@ant-design/icons'

import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import { connect } from 'react-redux'
import { createMatchSelector } from 'connected-react-router'
import StateData from '../../../states'
import AddContact from '../../contacts/add'

const FormItem = Form.Item
const { Option } = Select
const { TextArea } = Input

const State = [
  'Andaman and Nicobar Islands',
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chandigarh',
  'Chhattisgarh',
  'Dadra and Nagar Haveli',
  'Daman and Diu',
  'Delhi',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu',
  'Jharkhand',
  'Karnataka',
  'Kashmir',
  'Kerala',
  'Ladakh',
  'Lakshadweep',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Puducherry',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttarakhand',
  'Uttar Pradesh',
  'West Bengal',
]

const styles = {
  mainDiv: {
    position: 'relative',
  },
  loadingBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
    backgroundColor: 'rgba(255, 255,255, 0.5)',
    textAlign: 'center',
    paddingTop: '10%',
  },
}

@Form.create()
class EditLead extends PureComponent {
  handleSubmit = (e) => {
    const { dispatch, form, handleUpdateLead } = this.props
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let values = _.clone(valData)

        values.contact = this.state.selectedContactId

        //set autocomplete contact
        if (this.state.fromRoPage) {
          values.contact = this.state.selectedContactId
        }
    

        dispatch(showLoader())
        values._id = this.state.id
        let x = await Request.updateLead(values)
        dispatch(hideLoader())
        if (!x.error) {
          notification.success({
            message: x.message,
          })
          if (handleUpdateLead) {
            handleUpdateLead()
          }
          form.resetFields()
          this.setState({ cardId: '', leadData: null })
        } else {
          notification.error({
            message: 'Error Saving',
            description: x.message,
          })
        }
      }
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      //source: ['Ads', 'Referrals', 'Website', 'Word of mouth'],
      source: ['Purchased', 'Call', 'Direct'],
      stage: [
        'New (Untouched)',
        'Contacted',
      ],
      priority: ['High', 'Low', 'Medium'],
      status: ['Open', 'Lost', 'won'],
      visibleContact: false,
      allContacts: [],
      loader: true,
      showSpin: false,
      fromRoPage: this.props.fromRoPage,
    }
  }

  async componentDidMount() {
    const { leadOwner, cardId } = this.props
    let { data: allContacts } = await Request.getAllContacts({ results: 10 })
    this.setState({ allContacts: allContacts })

    if (cardId) {
      Request.getLeadById({ _id: cardId }).then(({ data, error, message }) => {
        if (!error) {
          this.setState({
            id: cardId,
            leadData: data,
            loader: false,
            selectedContactId: data && data.contact && data.contact._id,
          })

          this.props.form.setFieldsValue({
            company: data && data.company ? data.company : '',
            source: data && data.source,
            stage: data && data.stage,
            priority: data && data.priority,
            status: (data && data.status) || 'Open',
            description: data && data.description,
            leadType: data && data.leadType,
            title: data && data.title,

            leadOwner: data && data.leadOwner,
            contact:
              data &&
              data.contact &&
              `${data.contact.firstName} (${data.contact.mobile})`,
            selectedContactId: data && data.contact && data.contact._id,
            closeDate:
              data && data.closeDate
                ? moment(data.closeDate)
                : moment().endOf('month'),
          })
        } else {
          notification.error({
            message: 'Error Getting Data',
          })
        }
      })
    }
    this.setState({ ownerData: [leadOwner] })
  }

  handleContactSearch = async (test) => {
    // this.setState({​​​​​​​​showSpin: true}​​​​​​​​)
    if (test == '') {
      this.setState({ allContacts: this.state.allContacts })
      //   , () => {
      //   this.setState({
      //     showSpin: false,
      //   })
      // })
      return false
    }
    let { data, error, message } = await Request.contactSearch({ text: test })
    if (!error) {
      this.setState({ allContacts: data }, () => {
        this.setState({ showSpin: false })
      })
    }
  }

  onContactSelect = (val) => {
    let selectedContact = this.state.allContacts.find((obj) => obj._id === val)
    if (selectedContact) {
      this.setState({ selectedContactId: selectedContact._id })
      this.props.form.setFieldsValue({
        contact: `${selectedContact.firstName} (${selectedContact.mobile})`,
      })
    }
  }

  render() {
    const { submitting, dispatch } = this.props
    let allContacts = this.state.allContacts
      ? this.state.allContacts
      : this.props.allContacts
    const {
      form: { getFieldDecorator, getFieldValue },
    } = this.props

    const { stage, source, priority, status, ownerData, leadData } = this.state

    const leftInputTypes = {
      fields: [
        // {
        //   label: 'Contact',
        //   key: 'contact',
        //   showSearch: true,
        //   keyAccessor: x => x._id,
        //   valueAccessor: x => `${x.firstName} (${x.mobile})`,
        //   type: 'select',
        //   options: allContacts || [],
        //   onChange: v => {
        //     this.props.form.setFieldsValue({ contact: v })
        //   }
        // },

        {
          label: 'Estimated Close Date',
          key: 'closeDate',
          type: 'date',
        },
        {
          label: 'Lead Type',
          key: 'leadType',
          
          required: true,
          type: 'select',
          options: ['Inbound', 'Facebook Ads','IndiaMART Api','aajjo'],
          onChange: (v) => {
            
            this.props.form.setFieldsValue({ leadType: v })
          },
          
          
        },
        {
          label: 'Stage',
          key: 'stage',
          required: true,
          type: 'select',
          options: stage || [],
          onChange: (v) => {
            this.props.form.setFieldsValue({ stage: v })
          },
        },
        {
          label: 'Priority',
          key: 'priority',
          type: 'select',
          options: priority || [],
          onChange: (v) => {
            this.props.form.setFieldsValue({ priority: v })
          },
        },
        
      ],
    }

    const rightInputTypes = {
      fields: [
        {
          label: 'Company',
          key: 'company',
        },
        /*    {
              label: 'Lead Owner',
              key: 'leadOwner',
              required: true,
              keyAccessor: x => x._id,
              valueAccessor: x => x.name,
              type: 'select',
              options: ownerData || [],
              onChange: v => {
                this.props.form.setFieldsValue({ leadOwner: v })
              }
            },*/

      
        {
          label: 'Source',
          key: 'source',
          type: 'select',
          options: source || [],
          onChange: (v) => {
            this.props.form.setFieldsValue({ source: v })
          },
        },

        {
          label: 'Status',
          key: 'status',
          type: 'select',
          options: status || [],
          onChange: (v) => {
            this.props.form.setFieldsValue({ status: v })
          },
        },
       
      ],
    }

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 12 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 24 },
      },
    }
    const formItemLayout1 = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 4 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 24 },
      },
    }

    return this.state.loader ? (
      <div style={styles.loadingBox}>
        <Spin size="large" />
      </div>
    ) : (
      <React.Fragment>
        <div style={{ borderBottom: '1px solid #eee' }}>
          <div style={{ margin: '15px 30px' }}>
            <span>
              <a
                onClick={() => {
                  dispatch(
                    getUrlPushWrapper('lead.viewLead', {
                      id: this.state.id,
                    })
                  )
                }}>
                <Icon style={{ fontSize: '18px' }} type="link" />{' '}
                <strong style={{ marginLeft: '5px', fontSize: '18px' }}>
                  {leadData && leadData.title}
                </strong>
              </a>
            </span>
            <p>{leadData && leadData.company}</p>
          </div>
          <div style={{ margin: '10px 30px' }}>
            <Tooltip title={'Go to contact'}>
              <span>
                <Icon
                  style={{ fontSize: '17px', marginRight: '10px' }}
                  type="mobile"
                />{' '}
                <a
                  onClick={() => {
                    this.setState({ visibleContact: true })
                  }}>
                  {this.state.leadData && this.state.leadData.contact
                    ? this.state.leadData.contact.firstName
                    : ''}
                </a>
              </span>
            </Tooltip>
          </div>
        </div>
        <Form
          onSubmit={this.handleSubmit}
          hideRequiredMark
          style={{ margin: '8px' }}
          layout={'vertical'}>
          <Card bordered={true}>
            <div>
              <Row>
                <Col span={24}>
                  <Form.Item {...formItemLayout1} label={'Title'}>
                    {getFieldDecorator('title')(<Input />)}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={11}>
                  <GetAllFormFields
                    apiurl={API_URL}
                    inputSchema={leftInputTypes}
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                  />
                </Col>
                <Col span={2}></Col>
                <Col span={11}>
                  <GetAllFormFields
                    apiurl={API_URL}
                    inputSchema={rightInputTypes}
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                  />
                </Col>
              </Row>
              <Row>
                <Col span={12}>
                  <Form.Item label="Contact" {...formItemLayout}>
                    {getFieldDecorator('contact')(
                      <AutoComplete
                        notFoundContent={this.state.showSpin && <Spin />}
                        autoComplete={'none'}
                        onSelect={this.onContactSelect}
                        onSearch={this.handleContactSearch}
                        placeholder={'Contact'}>
                        {allContacts.map((val, item) => (
                          <Option value={val._id}>
                            <>
                              <div>
                                <b>{`${val.firstName} (${val.mobile})`}</b>
                              </div>
                            </>
                          </Option>
                        ))}
                      </AutoComplete>
                    )}
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item {...formItemLayout1} label={'Description'}>
                    {getFieldDecorator('description', {
                      rules: [
                        {
                          required: true,
                          message: `Please input description!`,
                        },
                      ],
                    })(<TextArea rows={5} />)}
                  </Form.Item>
                </Col>
              </Row>
            </div>
            <Form.Item style={{ marginTop: 20 }}>
              <Button
                type="primary"
                htmlType="submit"
                loading={this.props.loading}>
                Update
              </Button>
            </Form.Item>
          </Card>
        </Form>
        <Drawer
          width={780}
          onCancel={() => this.setState({ visibleContact: false })}
          onClose={() => this.setState({ visibleContact: false })}
          visible={this.state.visibleContact}
          bodyStyle={{ paddingBottom: 80 }}>
          <div
            style={{ marginTop: '15px', paddingLeft: '15px' }}
            className="contactFormWrapper">
            <AddContact
              className="asd"
              style={{ marginTop: '40px' }}
              contactId={
                this.state.leadData && this.state.leadData.contact
                  ? this.state.leadData.contact._id
                  : ''
              }
              handleEditContact={async () => {
                this.setState({ visibleContact: false })
                await Request.getLeadById({
                  _id: this.props.cardId ? this.props.cardId : '',
                }).then(({ data, error, message }) => {
                  if (!error) {
                    this.setState({
                      id: this.props.cardId ? this.props.cardId : '',
                      leadData: data,
                    })
                  }
                })
                let { data: allContacts } = await Request.loadContacts()
                this.setState({ allContacts: allContacts })
                this.props.form.setFieldsValue({
                  contact:
                    this.state.leadData &&
                    this.state.leadData.contact &&
                    this.state.leadData.contact._id,
                })
              }}
            />
          </div>
        </Drawer>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditLead)
