import React, { Component } from 'react'
import Board from 'react-trello'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { LinkOutlined, FileImageOutlined } from '@ant-design/icons'
import _ from 'lodash'

import moment from 'moment'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  Popconfirm,
  notification,
  Modal,
  Switch,
  Avatar,
  Row,
  Col,
  Popover,
  Input,
  Button,
  Icon,
  Select,
  Drawer,
  Form,
  AutoComplete,
  Spin,
} from 'antd'
import Request from '../../../request'
import styles from './styles.less'
import { connect } from 'react-redux'
import UpdateLead from '../add/editLead'
import { Typography } from 'antd'
import { stateFilter } from '../../../states'
import { addLeadActivity } from './helpers'

import LeadsProduct from '../../product/add/addProductByRO'

const { Paragraph } = Typography

const { Option } = Select

class PreQualifiedLeads extends Component {
  constructor(props) {
    super(props)
    this.table = React.createRef()
    const { dispatch } = props
    this.state = {
      allContacts: [],
      leadStage: [
        'New (Untouched)',
        'Contacted',
        'Today Followup',

      ],
      boardData: { lanes: [] },
      key: Math.random(),
      RoList: [],
      selectedRowKeys: [],
      selectedRO: undefined,
      currentPage: 1,
      leadStatuseArr: ['Open', 'won', 'Unqualified', 'Lost'],
    }
    this.searchContact = _.debounce(this.handleContactSearch, 1000, true)
  }

  apiRequest = (params) => {
    let { contactNameId } = this.state
    let stageArr = []
    if (
      this.state.selectedLeadStage == undefined ||
      this.state.selectedLeadStage == ''
    ) {
      stageArr = [
        'New (Untouched)',
        'Contacted',
        'Today Followup',

      ]
    } else {
      stageArr = this.state.selectedLeadStage
    }
    if (params.page) {
      this.state.currentPage = params.page
    }
    if (!params.sortOrder) {
      params.sortField = 'createdAt'
      params.sortOrder = 'desc'
    }
    let user = { ...JSON.parse(localStorage.getItem('user')) }
    if (user && user.userType == 'Caller') {
      params.callerId = user && user._id
    }

    return new Promise(async (resolve) => {
      params.isChild = false
      let data = await Request.getAllLeads({
        ...params,
        customQuery: {
          status: 'preQualified',
          stage: { $in: stageArr },
          //  officer: user._id,
          contact: contactNameId ? contactNameId : undefined,
        },
        regExFilters: ['title', 'company', 'contact'],
      })
      resolve(data)
      this.setState({ leadData: data, count: data.total })
    })
  }

  getStageColor = (stage) => {
    let returnColor = ''

    switch (stage) {
      case 'New (Untouched)':
        returnColor = '#FF3E4D'
        break
      case 'Contacted':
        returnColor = '#ff8c00'
        break
      case 'Today Followup':
        returnColor = '#26ae60'
        break
      default:
        returnColor = 'green'
    }

    return returnColor
  }

  async componentDidMount() {
    let { data: allContacts } = await Request.getAllContacts({ results: 10 })
    this.setState({ allContacts, sale: 'Sales' })
    //   let {data: userList} = await Request.getAllROList({customQuery: {userType: 'RO'}});
    let { data: callerList } = await Request.loadUser({ userType: 'Caller' })
    this.setState({ callerList: callerList, RoList: [] })
  }

  handleContactSearch = async (test) => {
    // this.setState({​​​​​​​​showSpin: true}​​​​​​​​)
    this.setState({ selectedContact: test })
    if (test == '') {
      this.setState(
        {
          allContacts: this.state.allContacts,
          selectedContact: test,
          contactNameId: null,
        },
        () => this.table.current.reload()
      )
      return false
    }
    let { data, error, message } = await Request.contactSearch({
      text: test,
      type: 'list',
    })
    if (!error) {
      this.setState({ allContacts: data }, () => {
        this.setState({ showSpin: false })
      })
    }
  }

  onContactSelect = (val) => {
    let selectedContact = this.state.allContacts.find((obj) => obj._id === val)
    if (selectedContact) {
      this.setState(
        {
          contactNameId: selectedContact._id,
          selectedContact: `${selectedContact.firstName} (${selectedContact.mobile})`,
        },
        () => {
          this.table.current.reload()
        }
      )
    }
  }
  changeLeadStatus = async (status) => {
    this.setState({ statusLoader: true })
    let { data, error, message } = await Request.updateMultipleLeadStatus(
      [this.state.leadId],
      { status: status }
    )
    this.setState({ statusLoader: false })

    if (!error) {
      notification.success({ message: message })
      this.table.current.reload()
      this.setState({ statusChangeDrawer: false })
    } else {
      return notification.error({ message: message })
    }
  }

  render() {
    // const RofilterArray = this.state.RoList && this.state.RoList.map((user) => {
    //     return {
    //         text: user.name,
    //         value: user._id
    //     }
    // });
    const callerList =
      this.state.callerList &&
      this.state.callerList.map((user) => {
        return {
          text: user.name,
          value: user._id,
        }
      })
    const { submitting, currentUser, dispatch } = this.props
    const {
      currentPage,
      allContacts,
      selectedRowKeys,
      selectedStatus,
      statusLoader,
      leadStatuseArr,
      leadData,
    } = this.state
    const columns = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        width: 50,
        key: 'sno',
        fixed: 'left',
        render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
      },

      {
        key: 'actions',
        title: 'Action',
        fixed: 'left',
        width: 120,
        render: (text, record) => {
          return (
            <React.Fragment>
              <Tooltip title="Products">
                <Button
                  onClick={async () => {
                    await Request.getLeadById({
                      _id: record && record._id,
                    }).then(async ({ data, error, message }) => {
                      if (!error) {
                        this.setState({
                          visible: true,
                          leadData: data,
                          drawerKey: Date.now(),
                        })
                      } else {
                        this.setState({
                          loader: false,
                        })
                        notification.error({
                          message: 'Error Getting Data',
                        })
                      }
                    })
                  }}
                  style={{ marginLeft: '5px' }}
                  size="small"
                  icon={'eye'}
                  shape={'circle'}
                />
              </Tooltip>

              <Tooltip title={'Qualified'}>
                <Popconfirm
                  title="Are you sure to Qualified this Lead?"
                  onConfirm={() => {
                    this.setState({ leadId: record && record._id }, () => {
                      this.changeLeadStatus('Open')
                    })
                  }}
                  onCancel={() => {
                    console.log()
                  }}
                  okText="Yes"
                  cancelText="No">
                  <Button
                    type="primary"
                    size={'small'}
                    loading={this.state.statusLoader}
                    style={{
                      marginLeft: '10px',
                    }}
                    icon={'check-circle'}
                  />
                </Popconfirm>
              </Tooltip>
              <Tooltip title={'Unqualified'}>
                <Popconfirm
                  title="Are you sure to Unqualified this Lead?"
                  onConfirm={() => {
                    this.setState({ leadId: record && record._id }, () => {
                      this.changeLeadStatus('Unqualified')
                    })
                  }}
                  onCancel={() => {
                    console.log()
                  }}
                  okText="Yes"
                  cancelText="No">
                  <Button
                    type="primary"
                    size={'small'}
                    loading={this.state.statusLoader}
                    style={{
                      backgroundColor: 'red',
                      borderColor: 'red',
                      marginLeft: '10px',
                    }}
                    icon={'close-circle'}
                  />
                </Popconfirm>
              </Tooltip>
            </React.Fragment>
          )
        },
      },
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        searchTextName: 'id',
        fixed: 'left',
      },
      {
        title: 'Lead',
        dataIndex: 'title',
        key: 'title',
        searchTextName: 'title',
        render: (text, record) => (
          <span>
            <a
              onClick={() => {
                // this.setState({...this.state, cardId: record._id, updatelead: true})
              }}>
              {record.title}
            </a>
          </span>
        ),
      },
      {
        title: 'Contact',
        dataIndex: 'contact.firstName',
        key: 'contact.firstName',
        render: (text, record) => (
          <span>
            <span>{record.contact ? record.contact.firstName : ''}</span>
            <br />
            <span>{record.contact ? record.contact.mobile : ''}</span>
          </span>
        ),
      },
      {
        title: 'Relationship Officer',
        dataIndex: 'officer',
        key: 'officer',
        // filters: RofilterArray,
        render: (val, record) => {
          return (
            <div>
              <div>{val && val.name}</div>
              <div>{val && val.email}</div>
              <div>{val && val.mobile}</div>
            </div>
          )
        },
      },
      {
        title: 'Caller',
        dataIndex: 'callerId',
        key: 'callerId',
        filters: callerList,
        render: (val, record) => {
          return (
            <div>
              <div>{val && val.name}</div>
              <div>{val && val.email}</div>
              <div>{val && val.mobile}</div>
            </div>
          )
        },
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        searchTextName: 'company',
        // width: 100
      },

      {
        title: 'Stage',
        dataIndex: 'stage',
        key: 'stage',
        filters: [
          { text: 'New (Untouched)', value: 'New (Untouched)' },
          { text: 'Contacted', value: 'Contacted' },
          { text: 'Today Followup', value: 'Today Followup' },

        ],
        render: (text, record) => {
          return (
            <div>
              {record && record.stage ? (
                <div
                  className={styles.stageColor}
                  style={{
                    backgroundColor: this.getStageColor(record.stage),
                  }}>
                  {record.stage}
                </div>
              ) : (
                ''
              )}
            </div>
          )
        },
      },
      {
        title: 'Lead Type',
        dataIndex: 'leadType',
        key: 'leadType',
        filters: [
          { text: 'IndiaMART Api', value: 'IndiaMART Api' },
          { text: 'Inbound', value: 'Inbound' },
          { text: 'Aajjo', value: 'aajjo' },
          { text: 'Facebook Ads', value: 'Facebook Ads' },
        ],
        render: (val, record) => {
          return (
            <div>
              {record.leadType ? (
                <div style={getLeadTypeColor(record.leadType)}>
                  {record.leadType}
                </div>
              ) : (
                ''
              )}
            </div>
          )
        },
      },

      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        filters: stateFilter,
      },

      {
        title: 'Status',
        key: 'status',
        render: (text, record) => {
          return (
            <div
              style={{
                backgroundColor: '#43BE31',
                color: 'white',
                textAlign: 'center',
                padding: '2px 2px',
                borderRadius: '2px',
                minWidth: '30px',
              }}>
              {record && record.status}
            </div>
          )
        },
      },
      {
        title: 'Priority',
        key: 'priority',
        // width: 120,
        render: (text, record) => {
          return <div>{record.priority ? record.priority : ''}</div>
        },
        filters: [
          { text: 'Low', value: 'Low' },
          { text: 'High', value: 'High' },
          { text: 'Medium', value: 'Medium' },
        ],
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        searchDateName: 'createdAt',
        render: (val, record) => {
          return (
            <div>
              <div>{moment(record.createdAt).format('lll')}</div>
            </div>
          )
        },
        // width:100
      },
      {
        title: 'Lead Received Date',
        dataIndex: 'leadReceivedDate',
        key: 'leadReceivedDate',
        searchDateName: 'leadReceivedDate',
        sorter: true,
        fixed: 'right',
        render: (val, record) => {
          return (
            <div>
              <div>
                {' '}
                {record && record.leadReceivedDate
                  ? moment(record.leadReceivedDate).format('lll')
                  : ''}{' '}
              </div>
            </div>
          )
        },
        width: 150,
      },
    ]
    const getLeadTypeColor = (leadType) => {
      switch (leadType) {
        case 'aajjo':
          return {
            backgroundColor: '#43BE31',
            textAlign: 'center',
            color: 'white',
            padding: '2px 2px',
            borderRadius: '2px',
            minWidth: '30px',
          }
          break
        case 'IndiaMART Api':
          return {
            backgroundColor: '#1287A5',
            textAlign: 'center',
            color: 'white',
            padding: '2px 2px',
            borderRadius: '2px',
            minWidth: '30px',
          }
          break
        default:
          return {
            backgroundColor: '#F3B431',
            color: 'white',
            textAlign: 'center',
            padding: '2px 2px',
            borderRadius: '2px',
            minWidth: '30px',
          }
      }
    }
    const rowSelection = {
      selectedRowKeys: selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys })
      },
      getCheckboxProps: (record) => ({
        _id: record._id,
      }),
    }

    if (currentUser && currentUser.userType == 'Caller') {
      columns.splice(6, 1)
    }

    return (
      <PageHeaderWrapper
        title={
          this.state.count
            ? `All PreQualified Leads: ${this.state.count}`
            : `All PreQualified Leads: 0`
        }>
        <div style={{ margin: '-24px', padding: '0px' }}>
          <Card>
            <Row>
              <Col span={8}>
                <AutoComplete
                  value={this.state.selectedContact}
                  notFoundContent={this.state.showSpin && <Spin />}
                  autoComplete={'none'}
                  onSelect={this.onContactSelect}
                  onSearch={this.searchContact}
                  onChange={(val) => {
                    this.setState({ selectedContact: val })
                  }}
                  placeholder={'Search Contact'}
                  style={{ width: 300 }}>
                  {allContacts &&
                    allContacts.map((val, item) => (
                      <Option value={val._id}>
                        <>
                          <div>
                            <b>{`${val.firstName} (${val.mobile})`}</b>
                          </div>
                        </>
                      </Option>
                    ))}
                </AutoComplete>
              </Col>
            </Row>
          </Card>

          <Card className={styles.greyCard}>
            <TableComp
              ref={this.table}
              columns={columns}
              apiRequest={this.apiRequest}
              extraProps={{
                scroll: { x: 1700 },
                // rowSelection: {...rowSelection}
              }}
              id="leadList"
              pagination={{
                ...this.state.pagination,
                defaultPageSize: 10,
                pageSizeOptions: ['10', '25', '50', '100'],
                showSizeChanger: true,
                ...this.props.pagination,
              }}
            />
          </Card>
        </div>

        <Modal
          title="Change Leads Status"
          onCancel={async () => {
            this.setState({
              statusChangeDrawer: false,
              selectedStatus: undefined,
            })
          }}
          visible={this.state.statusChangeDrawer}
          okText="Change"
          onOk={this.changeLeadStatus}
          okButtonProps={{
            loading: statusLoader,
          }}>
          <div style={{ textAlign: 'center' }}>
            <Form layout="inline">
              <Form.Item label="Status">
                <Select
                  showSearch
                  // allowClear
                  optionFilterProp="children"
                  style={{ width: 200 }}
                  value={selectedStatus}
                  onChange={(selectedStatus) => {
                    this.setState({ selectedStatus })
                  }}
                  placeholder="Select Status">
                  {leadStatuseArr &&
                    leadStatuseArr.map((val, id) => {
                      return (
                        <Option key={val} value={val}>
                          {val}
                        </Option>
                      )
                    })}
                </Select>
              </Form.Item>
            </Form>
          </div>
        </Modal>

        <Drawer
          width={1000}
          onCancel={async () => {
            this.setState({
              visible: false,
            })
          }}
          onClose={() => this.setState({ visible: false })}
          visible={this.state.visible}
          title={'Products'}>
          <div style={{ padding: '30px' }}>
            <LeadsProduct
              key={this.state.drawerKey}
              leadId={leadData && leadData._id}
              otherProduct={leadData && leadData.otherProduct}
              selectedProducts={leadData && leadData.productList}
              formResponse={async () => {
                this.setState({
                  visible: false,
                })
              }}
            />
          </div>
        </Drawer>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  categories: global.categories,
  currentUser: global.currentUser,
  search: router.location.search,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreQualifiedLeads)
