import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils'
import moment from 'moment'
import {
  Form,
  Table,
  Badge,
  Card,
  Tooltip,
  Popconfirm,
  notification,
  Row,
  Col,
  Select,
  Drawer,
  InputNumber,
  Button,
  Icon,
  Tag,
  Typography,
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
//import styles from './styles.less'
import { connect } from 'react-redux'
import { getUrlPushWrapper } from '../../../routes'
const { Text } = Typography

const { Option } = Select

@Form.create()
class AllVoucher extends Component {
  setDrawerData(record) {
    this.setState({
      productList: record.products,
      drawerVisible: true,
      bomList: (record && record.bom) || [],
    })
  }

  setStatusDrawerData() {
    let newProductList
    if (!this.state.record.bomMapping.length != 0) {
      if (this.state.record && this.state.record.bom) {
        newProductList = _.times(this.state.productCompleted, (i) => {
          let bomCol = {}
          this.state.record.bom.forEach((i, idx) => {
            bomCol[this.state.record.bom[idx].productId._id] = {
              name: this.state.record.bom[idx].productId.name,
              serialNumbers: [],
              generateSerialNumber:
                this.state.record.bom[idx].productId.generateSerialNumber == undefined
                  ? true
                  : this.state.record.bom[idx].productId.generateSerialNumber,
            }
          })
          return {
            pSNO: '',
            ...bomCol,
          }
        })
      }
    } else {
      newProductList = _.times(this.state.record.bomMapping.length, (i) => {
        let bomCol = {}
        this.state.record.bomMapping[i].bomSerialNumbers.forEach(
          (item, idx) => {
            bomCol[
              this.state.record.bomMapping[i].bomSerialNumbers[
                idx
              ].productId._id
            ] = {
              name: this.state.record.bomMapping[i].bomSerialNumbers[idx]
                .productId.name,
              serialNumbers:
                this.state.record.bomMapping[i].bomSerialNumbers[idx]
                  .serialNumbers,
            }
          }
        )
        return {
          pSNO: this.state.record.bomMapping[i].serialNumber,
          ...bomCol,
        }
      })
    }

    this.setState({
      productList: this.state.record.products,
      // statusDrawerVisible: true,
      //it's not productId it's voucher id
      productId: this.state.record && this.state.record._id,
      bomList: (this.state.record && this.state.record.bom) || [],
      newProductList,
      productCompleted:
        this.state.record.bomMapping.length == 0
          ? this.state.productCompleted
          : this.state.record.bomMapping.length,
    })
  }

  deleteVoucher = async (data) => {
    this.setState({ loading: true })
    let x = await Request.deleteVoucher(data)
    this.setState({ loading: false })

    this.table.current.reload()
    if (!x.error) {
      notification.success({
        message: x.message,
        duration: 20,
        key: `${data._id}-close`,
      })
    } else {
      notification.error({
        message: x.message || 'Try Again',
        duration: 20,
        key: `${data._id}-close`,
      })
    }
  }

  apiRequest = (params) => {
    if (params.page) {
      this.state.currentPage = params.page
    }
    return new Promise(async (resolve) => {
      if (!params.sortOrder) {
        params.sortField = 'voucherNumber'
        params.sortOrder = 'desc'
      }
      let user = { ...JSON.parse(localStorage.getItem('user')) }
      if (user.userType === 'RO') {
        params.createdByUserId = user._id
      }
      let tt = await Request.AllVoucher({
        ...params,
        type: 'Manufacture',
        regExFilters: [],
      })
      this.setState({ count: tt.total })
      resolve(tt)
    })
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
    this.state = {
      visible: false,
      currentPage: 1,
      key: Date.now(),
      totalData: [],
      productList: [],
      bomList: [],
      newProductList: [],
      statusDrawerVisible: false,
      drawerVisible: false,
      statusDrawer: false,
      productCompleted: 0,
    }
  }

  updateStatus = async (data) => {
    this.setState({ loading: true })
    let bomMapping = [],found = false

    this.state.productList.forEach((products) => {
      this.state.newProductList.forEach((i) => {
        let tmp = {}
        let bomSerialNumbers = []

        if(found) return

        Object.keys(i).forEach((key, idx) => {
          if (idx == 0) return
          let tmpObj = {}
          tmpObj.productId = key
          //check if serialno. is not entered ,if not that product is not saved in bommapping array
          if ((!i[key].serialNumbers || i[key].serialNumbers.length == 0) && i[key].generateSerialNumber) {
            found = true
            return
          }

          tmpObj.serialNumbers = i[key].serialNumbers
          bomSerialNumbers.push(tmpObj)
        })

        tmp.bomSerialNumbers = bomSerialNumbers
        tmp.productId = products.productId._id
        tmp.serialNumber = i.pSNO
        bomMapping.push(tmp)
      })
    })

    if (found) {
      return notification.error({
        message: 'Fields missing',
      })
    }

    let x = await Request.updateStatus({
      _id: this.state.productId,
      bomMapping,
    })
    this.setState({ loading: false })

    this.table.current.reload()
    if (!x.error) {
      notification.success({
        message: x.message,
        duration: 20,
      })
      this.setState({ statusDrawerVisible: false })
    } else {
      notification.error({
        message: x.message || 'Try Again',
        duration: 20,
      })
    }
  }

  async componentDidMount() {
    let { data: userList } = await Request.loadUser({
      customQuery: { userType: ['Technician'] },
      results: 1000000000,
    })
    this.setState({ userList: userList })
  }

  render() {
    const {
      form,
      form: { getFieldDecorator },
    } = this.props

    const { submitting, dispatch, currentUser } = this.props
    const { currentPage } = this.state
    const userListArray =
      this.state.userList &&
      this.state.userList.map((user) => {
        return {
          text: user.name,
          value: user._id,
        }
      })

    const columns = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        width: 80,
        key: 'sno',
        render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
      },
      {
        title: 'Voucher No',
        dataIndex: 'voucherId',
        width: 100,
        key: 'voucherId',
        searchTextName: 'voucherId',
      },
      {
        title: 'Created By',
        width: 150,
        dataIndex: 'createdByUserId',
        key: 'createdByUserId',
        filters: userListArray,
        render: (val, record) => {
          return (
            <div>
              <div>
                {record &&
                  record.createdByUserId &&
                  record.createdByUserId.name}
              </div>
              <div>
                {record &&
                  record.createdByUserId &&
                  record.createdByUserId.mobile}
              </div>
              <div>
                {record &&
                  record.createdByUserId &&
                  record.createdByUserId.email}
              </div>
            </div>
          )
        },
      },
      {
        title: 'Responsible',
        width: 150,
        dataIndex: 'createdForUserId',
        key: 'createdForUserId',
        filters: userListArray,
        render: (val, record) => {
          return (
            <div>
              <div>
                {record &&
                  record.createdForUserId &&
                  record.createdForUserId.name}
              </div>
              <div>
                {record &&
                  record.createdForUserId &&
                  record.createdForUserId.mobile}
              </div>
              <div>
                {record &&
                  record.createdForUserId &&
                  record.createdForUserId.email}
              </div>
            </div>
          )
        },
      },
      {
        title: 'Created At',
        key: 'voucherDate',
        width: 150,
        dataIndex: 'voucherDate',
        searchDateName: 'voucherDate',

        render: (text, record) => {
          return <Tag>{text ? moment(text).format('lll') : ''}</Tag>
        },
        sorter: true,
      },
      {
        title: 'Status',
        dataIndex: 'status',
        width: 100,
        key: 'status',
        searchTextName: 'status',
      },
      {
        key: 'actions',
        title: 'Actions',
        width: 120,
        render: (text, record, key) => {
          return (
            <React.Fragment>
              <Tooltip title="Edit Voucher">
                <Button
                  shape="circle"
                  style={{ marginRight: 6 }}
                  size="small"
                  onClick={() => {
                    dispatch(
                      getUrlPushWrapper('voucher.editManufacture', {
                        id: record._id,
                      })
                    )
                  }}
                  icon="edit"
                />
              </Tooltip>
              <Tooltip title="Delete Voucher">
                <Popconfirm
                  title="Are you sure delete this Voucher?"
                  onConfirm={() => {
                    this.deleteVoucher({ _id: record._id })
                  }}
                  onCancel={() => {}}
                  okText="Yes"
                  cancelText="No">
                  <Button
                    shape="circle"
                    size="small"
                    style={{ marginRight: 6 }}
                    icon="delete"
                  />
                </Popconfirm>
              </Tooltip>

              <Tooltip title="View Products">
                <Button
                  type="warning"
                  size="small"
                  shape="circle"
                  style={{ marginTop: '6px' }}
                  onClick={() => {
                    this.setDrawerData(record)
                  }}
                  icon="eye"
                />
              </Tooltip>
              <Tooltip title="Edit status">
                <Button
                  type="warning"
                  size="small"
                  shape="circle"
                  style={{ marginTop: '6px' }}
                  onClick={() => {
                    this.setState({ record, statusDrawerVisible: true }, () => {
                      this.setStatusDrawerData()
                    })
                    this.props.form.setFieldsValue({ qtyCompleted: undefined })
                  }}
                  icon="swap"
                />
              </Tooltip>
            </React.Fragment>
          )
        },
      },
    ]

    const drawerCol = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        key: 'sno',
        width: 80,
        render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
      },
      {
        title: 'Product',
        width: 150,
        dataIndex: 'productId.name',
        key: 'name',
      },

      {
        title: 'Quantity',
        dataIndex: 'qty',
        key: 'qty',
      },
      {
        key: 'actions',
        title: 'Actions',
        width: 120,
        render: (text, record, key) => {
          return (
            <React.Fragment>
              <Tooltip title="View BOM">
                <Button
                  type="warning"
                  size="small"
                  shape="circle"
                  style={{ marginTop: '6px' }}
                  onClick={() => {
                    this.setState({
                      bomVisible: true,
                    })
                  }}
                  icon="eye"
                />
              </Tooltip>
            </React.Fragment>
          )
        },
      },
    ]
    const drawerCol1 = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        key: 'sno',
        width: 80,
        render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
      },
      {
        title: 'Product',
        width: 150,
        dataIndex: 'productId.name',
        key: 'name',
      },

      {
        title: 'Quantity',
        dataIndex: 'qty',
        key: 'qty',
      },
      {
        title: 'Serial No.',
        dataIndex: 'serialNumbers',
        key: 'serialNumbers',
        width: 150,
        render: (text) => text.join(', '),
      },
    ]

    let statusDrawer = Object.keys(
      this.state.newProductList[0] ? this.state.newProductList[0] : {}
    ).map((key, value) => {
      if (key == 'pSNO') {
        return {
          title: 'Product SNO',
          dataIndex: 'pSNO',
          key: 'pSNO',
          render: (y, item, index) => {
            return (
              <div>
                <Text
                  editable={{
                    onChange: async (val) => {
                      let tt = this.state.newProductList

                      tt[index].pSNO = val
                      this.setState({
                        newProductList: tt,
                        tableKey: Date.now(),
                      })
                    },
                  }}>
                  {y}
                </Text>
              </div>
            )
          },
        }
      } else {
        return {
          title: this.state.newProductList[0][key].name,
          dataIndex: key,
          key: key,
          render: (x, item, index) => {
            return (
              <React.Fragment>
                <Select
                  defaultValue={x.serialNumbers}
                  disabled={!x.generateSerialNumber}
                  style={{ width: 200, maxHeight: '100px', overflow: 'auto' }}
                  placeholder={'Enter Product Id'}
                  mode={'tags'}
                  onChange={(e) => {
                    let array = []
                    _.each(e, (i) => {
                      array.push(i)
                    })

                    let tt = this.state.newProductList

                    tt[index][key].serialNumbers = array
                    this.setState({
                      newProductList: tt,
                      tableKey: Date.now(),
                    })
                  }}
                />
              </React.Fragment>
            )
          },
        }
      }
    })

    return (
      <PageHeaderWrapper
        title={
          this.state.count
            ? `All Manufacture Vouchers: ${this.state.count}`
            : `All Manufacture Voucher: 0`
        }>
        <Card bordered={true}>
          <Row>
            <Col>
              <TableComp
                bordered={true}
                ref={this.table}
                columns={columns}
                apiRequest={this.apiRequest}
                rowKey={(x) => x.voucherNumber}
                pagination={{
                  ...this.state.pagination,
                  defaultPageSize: 10,
                  pageSizeOptions: ['10', '25', '50', '100'],
                  showSizeChanger: true,
                  ...this.props.pagination,
                }}
              />
            </Col>
          </Row>
        </Card>
        <Drawer
          title="Product list"
          placement="right"
          width={'50%'}
          closable={false}
          onClose={() => {
            this.setState({
              drawerVisible: false,
              bomList: [],
              productList: [],
            })
          }}
          visible={this.state.drawerVisible}>
          <Card bordered={true}>
            <Table
              bordered={true}
              rowKey={(x) => x.voucherNumber}
              columns={drawerCol}
              dataSource={this.state.productList || []}
              pagination={false}
            />
          </Card>
        </Drawer>

        <Drawer
          title="BOM list"
          placement="right"
          width={'40%'}
          closable={false}
          onClose={() => {
            this.setState({ bomVisible: false, bomList: [] })
          }}
          visible={this.state.bomVisible}>
          <Card bordered={true}>
            <Table
              bordered={true}
              rowKey={(x) => x._id}
              columns={drawerCol1}
              dataSource={this.state.bomList || []}
              pagination={false}
            />
          </Card>
        </Drawer>

        <Drawer
          title="Edit Voucher Status"
          placement="right"
          width={'70%'}
          closable={false}
          onClose={() => {
            this.setState({
              statusDrawerVisible: false,
              bomList: [],
              productList: [],
              newProductList: [],
              productCompleted: 0,
            })
          }}
          visible={this.state.statusDrawerVisible}>
          <Card bordered={true}>
            <div style={{ textAlign: 'left' }}>
              <>
                <p>
                  Product Completed{' '}
                  <InputNumber
                    type="number"
                    defaultValue={this.state.productCompleted}
                    min={0}
                    max={
                      this.state.productList[0]
                        ? this.state.productList[0].qty
                        : 0
                    }
                    onChange={(e) => {
                      this.setState({ productCompleted: e }, () => {
                        this.setStatusDrawerData()
                      })
                    }}
                  />
                </p>
              </>
            </div>
            <div style={{ textAlign: 'right' }}>
              <Button
                type="primary"
                style={{ marginBottom: '10px' }}
                onClick={() => {
                  this.updateStatus()
                }}>
                Save
              </Button>
            </div>

            <Table
              bordered={true}
              rowKey={(x) => x.voucherNumber}
              columns={statusDrawer}
              dataSource={this.state.newProductList || []}
              pagination={false}
            />
          </Card>
        </Drawer>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  currentUser: global.currentUser,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllVoucher)
