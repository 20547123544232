import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'

import {
    Card,
    Avatar,
    Skeleton,
    Select,
    Modal,
    Icon,
    Spin,
    Row,
    Col,
    List,
    Divider,
    Button,
    Form,
    Tag,
    Descriptions,
    notification,
    Input, InputNumber,
} from 'antd'
import 'react-quill/dist/quill.snow.css'

import Request, {API_URL, getStatusColor} from '../../../request'
import {connect} from 'react-redux'
import moment from 'moment'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'
import _ from 'lodash'

import {apiUrl} from "../../../settings";

const {Option} = Select


@Form.create()
class OrderDetails extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            loader: false,
            videos: [],
            photos: [],
            tabKey: '0',
            totalData: [],
            array: [],

        }
    }


    componentDidMount() {

        let searchParams = new URLSearchParams(this.props.search)
        let orderId = searchParams.get('id')
        Request.loadUser({userType: 'Dispatch'}, {results: 1000000000000}).then(({data: userList}) => {
            this.setState({userList: userList})
        })

        if (orderId) {
            this.setState({loader: true})
            Request.orderDispatchList({_id: orderId}).then(({data, error, message}) => {
                if (!error) {
                    this.setState({
                        id: orderId,
                        loader: false,
                        orderData: data && data[0],
                        products: data && data[0] && data[0].products,
                        array: data && data[0] && data[0].stickers

                    })
                } else {
                    this.setState({loader: false})

                    notification.error({
                        message: 'Error Getting Data'
                    })
                }
            })
        } else {
            this.setState({loader: false})

        }
    }

    remove = k => {
        const {form} = this.props;
        const keys = form.getFieldValue('keys');
        if (keys.length === 1) {
            return;
        }

        form.setFieldsValue({
            keys: keys.filter(key => key !== k),
        });
    };

    add = () => {
        const {form} = this.props;
        let keys = form.getFieldValue('keys');
        const nextKeys = keys.concat({
            productName: '',
            qty: null,
            checkedByName: '',
            productId: '',
            checkedById: ''
        });
        form.setFieldsValue({
            keys: nextKeys,
        });


    };
    handleSubmit = e => {
        e.preventDefault();
        let {array} = this.state
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const {keys, names} = values;
                let findIn = _.find(keys, v => {
                    return !v.qty || !v.productId || !v.checkedById
                })
                if (findIn) {
                    notification.error({
                        message: 'Required',
                        description: `Required Fields`
                    })
                    return
                }
                // if (this.state.setIndex || this.state.setIndex == 0) {
                //     _.each(array, (item, key) => {
                //         if (key === this.state.setIndex) {
                //             item.items = keys
                //         }
                //     })
                // } else {
                //
                //
                // }

                array.push({boxNumber: array.length + 1, items: keys})



                this.props.form.setFieldsValue({
                    keys: [{
                        productName: '',
                        qty: null,
                        checkedByName: '',
                        productId: '',
                        checkedById: ''
                    }]
                })

                this.setState({visible: false, modalKey: Date.now()})

            }
        });
    };
    save = async () => {
        let value = {}
        let {array} = this.state

        value.stickers = array
        value._id = this.state.id

        this.setState({loading: true})
        let x = await Request.updateOrderAction({...value})
        this.setState({loading: false})


        if (!x.error) {
            notification.success({
                message: x.message,
            })

            if (x.url) {
                window.open(`${apiUrl}/${x.url}`, '_blank')
            }

        } else {
            notification.error({
                message: x.message,
            })
        }


    }

    render() {
        const {getTodos, currentUser, form} = this.props
        const {orderData, userList, products, array} = this.state
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        getFieldDecorator('keys', {
            initialValue: [{
                productName: '',
                qty: null,
                checkedByName: '',
                productId: '',
                checkedById: ''
            }]
        });

        const keys = getFieldValue('keys');

        let formItems = (keys) => {
            return <>
                {keys.map((k, index) => {
                    return <Row key={index}>
                        <Col span={6}>
                            <Form.Item
                                required={false}
                            >
                                <Select
                                    value={k.productName}
                                    showSearch
                                    style={{width: '200px', marginRight: 8}}
                                    allowClear
                                    optionFilterProp="children"
                                    placeholder="Select Product"
                                    onChange={(id) => {
                                        let found = _.find(products, (item) => {
                                            return item.productId == id
                                        })
                                        const keyss = _.clone(getFieldValue('keys'))
                                        keyss[index].qty = (found && found.quantity) || 0
                                        keyss[index].productName = (found && found.productName) || ''
                                        keyss[index].productId = (found && found._id) || ''
                                        this.props.form.setFieldsValue({keys: keyss})

                                    }}
                                >
                                    {products &&
                                        products.map((val, idn) => {
                                            return (
                                                <Option key={idn} value={val.productId}>
                                                    {val.productName}
                                                </Option>
                                            )
                                        })}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={6}>
                            <Form.Item
                                required={false}
                            >
                                <InputNumber placeholder="Quantity" value={k.qty} onChange={(val) => {
                                    const keyss = _.clone(getFieldValue('keys'))
                                    keyss[index].qty = val
                                    this.props.form.setFieldsValue({keys: keyss})

                                }} style={{width: '100%', marginRight: 8}}/>
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item
                                required={false}
                            >
                                <Select
                                    showSearch
                                    value={k.checkedByName}
                                    style={{width: '200px', marginRight: 8}}
                                    allowClear
                                    optionFilterProp="children"
                                    placeholder="Select Dispatch USer"
                                    onChange={(id) => {

                                        let found = _.find(userList, (item) => {
                                            return item._id == id
                                        })
                                        const keyss = _.clone(getFieldValue('keys'))
                                        keyss[index].checkedByName = (found && found.name) || ''
                                        keyss[index].checkedById = (found && found._id) || ''
                                        this.props.form.setFieldsValue({keys: keyss})

                                    }}
                                >
                                    {userList &&
                                        userList.map((val, idn) => {
                                            return (
                                                <Option key={idn} value={val._id}>
                                                    {val.name} ({val.mobile})
                                                </Option>
                                            )
                                        })}
                                </Select>

                            </Form.Item>
                            {keys.length > 1 ? (
                                <Icon
                                    style={{marginRight: '5px'}}
                                    className="dynamic-delete-button"
                                    type="minus-circle-o"
                                    onClick={() => this.remove(k)}
                                />
                            ) : null}

                            {keys.length != (products && products.length) && <Icon
                                className="dynamic-delete-button"
                                type="plus-circle"
                                onClick={() => this.add()}
                            />}

                        </Col>
                    </Row>
                })}
            </>
        };

        return (<Spin size="large" spinning={this.state.loader}>
                <PageHeaderWrapper title={'Order Details'}>

                    <Card>
                        <Row>
                            <Col>
                                <Descriptions column={4}>
                                    <Descriptions.Item
                                        label={<b>Order Id</b>}>{orderData && orderData.id}</Descriptions.Item>
                                    <Descriptions.Item
                                        label={<b>Order
                                            Date</b>}>{orderData && moment(orderData.orderDate).format('ll')}</Descriptions.Item>
                                    <Descriptions.Item
                                        label={<b>Status</b>}><Tag
                                        color={getStatusColor(orderData && orderData.status)}>{orderData && orderData.status}</Tag></Descriptions.Item>
                                    <Descriptions.Item
                                        label={<b>Lead Name</b>}>{orderData && orderData.leadName}</Descriptions.Item>
                                    <Descriptions.Item
                                        label={<b>Invoice
                                            Number</b>}>{orderData && orderData.invoiceNumber}</Descriptions.Item>
                                    <Descriptions.Item
                                        label={<b>GST</b>}>{orderData && orderData.gstNumber}</Descriptions.Item>
                                    <Descriptions.Item
                                        label={<b>Carrier</b>}>{orderData && orderData.carrier}</Descriptions.Item>

                                    {orderData && orderData.estimatedDate ?
                                        <Descriptions.Item
                                            label={<b>Estimated
                                                Date</b>}>{moment(orderData.estimatedDate).format('ll')}</Descriptions.Item>
                                        : ''}
                                    {orderData && orderData.dispatchNumber ?
                                        <Descriptions.Item
                                            label={<b>Dispatch
                                                Number</b>}>{orderData.dispatchNumber}</Descriptions.Item>
                                        : ''}
                                </Descriptions>


                            </Col>
                        </Row>
                        <Divider/>

                        <Row>

                            <h2 style={{backgroundColor: '#8080800f'}}>Contact Details</h2>
                            <Col>
                                <Descriptions>
                                    <Descriptions.Item
                                        label={<b>Contact
                                            Name</b>}>{orderData && orderData.contactName}</Descriptions.Item>
                                    <Descriptions.Item
                                        label={<b>Mobile</b>}>{orderData && orderData.mobile}</Descriptions.Item>
                                    <Descriptions.Item
                                        label={<b>Email</b>}>{orderData && orderData.email}</Descriptions.Item>
                                    <Descriptions.Item
                                        label={<b>State</b>}>{orderData && orderData.state}</Descriptions.Item>
                                    <Descriptions.Item
                                        label={<b>Pincode</b>}>{orderData && orderData.pincode}</Descriptions.Item>
                                </Descriptions>


                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <Card bordered={false}
                                      extra={<Button type="primary" onClick={() => {
                                          this.setState({visible: true, setIndex: undefined})
                                      }} style={{marginTop: 20}}>
                                          Add New Box <Icon type={'plus'}/>
                                      </Button>}>
                                    {array && array.length > 0 ? < List
                                        className="demo-loadmore-list"
                                        itemLayout="vertical"
                                        dataSource={array}
                                        renderItem={(item, key) => (
                                            <>
                                                <div style={{
                                                    marginTop: '10px',
                                                    display: 'flex',
                                                    justifyContent: 'space-between'
                                                }}>
                                                    <b>Box No : {key + 1}</b>
                                                    <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                                        {/*<Icon size={48} type="edit" onClick={() => {*/}
                                                        {/*    this.setState({visible: true, setIndex: key})*/}
                                                        {/*    this.props.form.setFieldsValue({keys: item.items})*/}
                                                        {/*}}/>*/}

                                                        <Icon size={48} style={{marginLeft: '15px'}} type="delete"
                                                              onClick={() => {
                                                                  let keyss = _.clone(array)
                                                                  keyss = _.reject(keyss, (t, i) => {
                                                                      return i == key
                                                                  })
                                                                  let arr = []
                                                                  _.each(keyss, (ii, key) => {
                                                                      arr.push({...ii, boxNumber: key + 1})
                                                                  })

                                                                  this.setState({array: arr})

                                                              }}/>
                                                        {item && item._id &&
                                                            <>
                                                                {this.state.spinLoader ? <Icon type="loading"
                                                                                               style={{marginLeft: '15px'}}/> :
                                                                    <Icon size={48} style={{marginLeft: '15px'}}
                                                                          type="printer"
                                                                          onClick={async () => {
                                                                              this.setState({spinLoader: true})
                                                                              let x = await Request.generateSingleBox({
                                                                                  _id: this.state.id,
                                                                                  boxId: item._id
                                                                              })
                                                                              this.setState({spinLoader: false})


                                                                              if (!x.error) {

                                                                                  if (x.url) {
                                                                                      window.open(`${apiUrl}/${x.url}`, '_blank')
                                                                                  }

                                                                              } else {
                                                                                  notification.error({
                                                                                      message: x.message,
                                                                                  })
                                                                              }

                                                                          }}/>}


                                                            </>

                                                        }


                                                    </div>

                                                </div>

                                                <List.Item key={key}>
                                                    {_.map(item.items, (ii, key) => {
                                                        return <>
                                                            <div key={key} style={{marginBottom: '10px'}}>
                                                                {ii.productName},{ii.qty}, {ii.checkedByName}
                                                            </div>

                                                        </>
                                                    })}


                                                </List.Item>

                                            </>
                                        )}
                                    /> : ''}

                                    <div>
                                        <Button loading={this.state.loading} hidden={!array.length}
                                                type="primary"
                                                onClick={() => {
                                                    this.save()
                                                }}>
                                            Save & Generate
                                        </Button>
                                    </div>

                                </Card>
                            </Col>
                        </Row>
                        <Modal
                            title={`Box (${array.length + 1}) Details`}
                            visible={this.state.visible}
                            footer={false}
                            key={this.state.modalKey}
                            onCancel={() => {
                                this.setState({visible: false})
                                this.props.form.setFieldsValue({
                                    keys: [{
                                        productName: '',
                                        qty: null,
                                        checkedByName: '',
                                        productId: '',
                                        checkedById: ''
                                    }]
                                })
                            }}
                            okButtonProps={{disabled: false}}
                            cancelButtonProps={{disabled: false}}
                            width={1000}
                        >
                            <Row>
                                <Col>

                                    <Row>
                                        <Col span={6}>
                                            Product Name
                                        </Col>
                                        <Col span={6}>
                                            Quantity
                                        </Col>
                                        <Col span={12}>
                                            Checked By Dispatched User
                                        </Col>
                                    </Row>
                                    <Form layout={'inline'} onSubmit={this.handleSubmit}>
                                        {formItems(keys)}
                                        <div>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit">
                                                    Save
                                                </Button>
                                            </Form.Item>
                                        </div>
                                    </Form>
                                </Col>

                            </Row>


                        </Modal>


                    </Card>


                </PageHeaderWrapper>

            </Spin>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    currentUser: global.currentUser

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderDetails)
