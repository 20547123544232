import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Button,
    Card,
    Input,
    Tooltip,
    Popconfirm,
    Tag,
    Switch
} from 'antd'
import _ from 'lodash'
import {FormUtils as GetAllFormFields, TableComp} from 'sz-react-utils'
import {API_URL} from '../../../request'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {connect} from 'react-redux'
import {createMatchSelector} from 'connected-react-router'
import moment from "moment/moment";


@Form.create()
class AddIP extends PureComponent {


    handleSubmit = e => {
        const {dispatch, form} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)


                dispatch(showLoader())

                let x = null
                if (this.state.id) {
                    values._id = this.state.id

                    x = await Request.updateIP(values)
                } else {
                    x = await Request.addIP(values)
                }

                dispatch(hideLoader())


                if (!x.error) {

                    notification.success({
                        message: this.state.id
                            ? x.message
                            : x.message
                    })

                    if (!this.state.id) {
                        this.props.form.resetFields()
                        this.table.current.reload()

                    }


                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }


    constructor(props) {
        super(props)
        this.table = React.createRef()

        this.state = {}

    }

    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search);
        let userId = searchParams.get('id')
        Request.getValidateSetting().then(({data: validateData, error, message}) => {

            if (!error) {

                this.setState({validateValue: validateData && validateData.validateIp})


            } else {
                notification.error({
                    message: 'Error Getting Data'
                })
            }
        })


    }


    apiRequest = (params) => {

        return new Promise(async (resolve) => {


            let data = await Request.getAllIps({
                ...params, regExFilters: []
            })

            this.setState({count: data.total})
            resolve(data);
        })
    }
    deleteIP = async data => {
        this.setState({loading: true})
        let x = await Request.deleteIP(data)
        this.setState({loading: false})

        this.table.current.reload()
        if (!x.error) {
            notification.success({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })

        }
        else {
            notification.error({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })
        }


    }
    saveSetting = async value => {
        let x = await Request.saveSetting({validateIp: value})

        if (!x.error) {
            notification.success({
                message: x.message,
            })

        }
        else {
            notification.error({
                message: x.message,
            })
        }


    }

    render() {


        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props;


        const columns = [
            {
                title: 'IP Address',
                key: 'ipAddress',
                dataIndex: 'ipAddress',
                searchTextName: 'ipAddress'


            },
            {
                title: 'Created At',
                key: 'createdAt',
                dataIndex: 'createdAt',
                sorter: true,
                render: (text, record) => {
                    return (
                        <Tag> {text ? moment(text).format('lll') : ''}</Tag>
                    )
                }
            },
            {
                key: 'actions',
                title: 'Actions',
                render: (text, record) => {
                    return <React.Fragment>

                        <Tooltip title="Delete IP">
                            <Popconfirm
                                title="Are you sure to delete this IP?"
                                onConfirm={() => {
                                    this.deleteIP({_id: record._id})
                                }}
                                onCancel={() => {
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button
                                    shape="circle"
                                    size="small"

                                    icon="delete"
                                />
                            </Popconfirm>

                        </Tooltip>
                    </React.Fragment>
                }
            }


        ]


        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }

        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }


        return (
            <PageHeaderWrapper
                title={this.state.id ? 'Update IP Address' : 'Add IP Address'}
            >

                <Card bordered={true}>
                    <Form layout={'inline'} onSubmit={this.handleSubmit} hideRequiredMark
                          style={{marginTop: 8, display: 'flex', justifyContent: 'space-between'}}>

                        <div>
                            <Form.Item label={'IP Address'}>
                                {getFieldDecorator('ipAddress', {
                                    rules: [
                                        {
                                            required: true,
                                            message: `Please input ip`
                                        }
                                    ]
                                })(<Input>

                                </Input>)}
                            </Form.Item>

                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                    ADD
                                </Button>
                            </Form.Item>
                        </div>

                        <Form.Item label={'Validate Ip'}>
                            <Switch checked={this.state.validateValue} style={{marginLeft: '1em'}} onClick={(val) => {
                                this.setState({validateValue: val})
                                this.saveSetting(val)
                            }}/>
                        </Form.Item>


                    </Form>


                </Card>
                <Card bordered={true}>
                    <h3>{this.state.count ? `All IPs: ${this.state.count}` : `All IPs: 0`}</h3>

                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddIP)
