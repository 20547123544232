import React, { Component } from 'react'

class Redirect extends Component {
  constructor (props) {
    super(props);
    // window.close();

  }

  render () {
    return (
      <div className={'asdf'} style={{alignItems:'center', textAlign:'center'}}>
        <h1>Your contacts have been uploaded successfully. </h1>
        <p><a href="javascript:history.back()">Go Back</a></p>
      </div>
    )
  }

}

export default Redirect

