import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp, FormUtils as GetAllFormFields } from 'sz-react-utils'
import moment from 'moment'
import {
  Checkbox,
  Table,
  Badge,
  Card,
  Tooltip,
  Popconfirm,
  notification,
  Switch,
  Input,
  Button,
  Icon,
  Form,
  Drawer,
  Row,
  Col,
  Tag,
  Select,
} from 'antd'
import Request, { API_URL } from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import { getUrlPushWrapper } from '../../../routes'
import { hideLoader, showLoader } from '../../../modules/actions'
import { ThemeProvider } from 'styled-components'

const Option = { Select }

@Form.create()
class AllInwardList extends Component {
  delete = async (data) => {
    this.setState({ loading: true })
    let x = await Request.deleteInwardOutward(data)
    this.setState({ loading: false })

    this.table.current.reload()
    if (!x.error) {
      notification.success({
        message: x.message,
        duration: 20,
        key: `${data._id}-close`,
      })
    } else {
      notification.error({
        message: x.message,
        duration: 20,
        key: `${data._id}-close`,
      })
    }
  }

  apiRequest = (params) => {
    if (params.page) {
      this.state.currentPage = params.page
    }
    let currentUser = JSON.parse(window.localStorage.getItem('user'))

    if (currentUser && currentUser.userType === 'Dispatch') {
      params.filledBy = currentUser._id
    }
    if (!params.sortOrder) {
      params.sortField = 'name'
      params.sortOrder = 'ascend'
    }

    this.setState({ filters: { ...params } })

    return new Promise(async (resolve) => {
      let data = await Request.getAllStockProductList({
        ...params,
        regExFilters: ['name'],
      })

      this.setState({ count: data.total })

      resolve(data)
    })
  }
  showDrawer = () => {
    this.setState({
      visible: true,
      key: Date.now(),
    })
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
    this.state = {
      currentPage: 1,
      visible: false,
      userType: ['Inward', 'Outward'],
      productType: [
        'Finish Goods',
        'Packing Material',
        'Electronic Components',
        'Raw Material',
      ],
      purpose: ['Sale', 'Repair', 'Purchase', 'Replacement', 'Receiving'],
      carriersData: ['Fedex', 'DHL', 'UPS', 'USPS', 'BlueDart', 'DTDC'],
      productShow: [],
      fileDownloadLoader: false,
    }
  }

  exportData = async () => {

    this.setState({ startLoading: true })
    let data = await Request.inwardOutwardList({
      ...this.state.filters,
      export: true,
      regExFilters: [
        'mobile',
        'name',
        'email',
        'challanNumber',
        'carrierDetails',
        'packingDoneBy',
        'consignee',
        'invoiceNumber',
      ],
    })
    this.setState({ startLoading: false })
    if (!data.error) {
      window.open(data.url)
    } else {
      return notification.error({ message: 'Error in Export File' })
    }
  }

  downloadDispatch = async () => {
    this.setState({ fileDownloadLoader: true })
    let date = moment().format('lll')
    await Request.downloadStock(date)
    this.setState({ fileDownloadLoader: false })
  }

  componentDidMount() {
    Request.loadUser({
      userType: ['RO', 'Admin', 'Dispatch', 'Technician'],
      results: 200,
    }).then(({ data: userList }) => {
      this.setState({ userList: userList || [] })
    })


    Request.inwardOutwardProducts().then(({ data, productIds }) => {
   
      this.setState({ productsData: data || [], productIds: productIds || [] })
    })
  }
  async componentDidMount() {
    let {data: categoryList} = await Request.loadCategories();

    this.setState({categoryList: categoryList})


}
  render() {
    const { dispatch } = this.props

    let {
      singleRecord,
      userType,
      currentPage,
      userList,
      productsData,
      purpose,
      carriersData,
      productShow,
      productIds,
    } = this.state
    const RofilterArray =
      userList &&
      userList.map((user) => {
        return {
          text: user.name,
          value: user._id,
        }
      })
      const categoriesArray = this.state.categoryList && this.state.categoryList.map((item) => {
        return {
            text: item.category,
            value: item._id
        }
    
      }


      );

    const columns = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        width: 50,
        key: 'sno',
        fixed: 'left',
        render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
      },

      {
        title: 'Products',
        dataIndex: 'name',
        key: 'name',
        searchTextName: 'name',
        width: 200,
      },
      {
        title: 'Category',
        width: 100,
        key: 'category',
        filters: categoriesArray,

        render: (val, record) => {
            return <div>
                {record && record.category && record.category.category}
            </div>
        }

    },
    {
      title: 'Stock',
      dataIndex: 'stockQty',
      key: 'stockQty',
      width: 200,
      render:(value,record) => {
       const {stockQty,criticalValue,criticalValueTwo} = record
       if(stockQty>criticalValue && stockQty>criticalValueTwo){
        return <Tag color='#26ae60'>{stockQty}</Tag>
       }
       else if(stockQty<=criticalValue && stockQty>criticalValueTwo){
        return <Tag color='#ff8c00'>{stockQty}</Tag>
       }        
       else {
        return <Tag color='#FF3E4D'>{stockQty}</Tag>
       }
      },
      filters: [
        { text: 'Green', value: 'green' },
        { text: 'Orange', value: 'orange' },
        { text: 'Red', value: 'red' },
      ],
      onFilter: (value, record) => {
        const { stockQty, criticalValue, criticalValueTwo } = record;
        if (value === 'green' && stockQty > criticalValue && stockQty > criticalValueTwo) {
          return true;
        } else if (value === 'orange' && stockQty <= criticalValue && stockQty > criticalValueTwo) {
          return true;
        } else if (value === 'red' && (stockQty <= criticalValueTwo || stockQty <= criticalValue)) {
          return true;
        } else {
          return false;
        }
      }, 
    },

    
      
      {
        title: 'Latest Inward Qty',
        dataIndex: 'inwardQty',
        key: 'inwardQty',
        render:(val,record)=>{
          if (record.inwardAddedDate !== null && record.inwardQty!==0){
            // record.inwardDate
            return <p>{record.inwardQty} ({moment(record.inwardAddedDate).format('lll')})</p>
          }
          else {
            return <p>{record.inwardQty}</p>
          }
        }
      },
      {
        title: 'Latest  Outward Qty',
        dataIndex: 'outwardQty',
        key: 'outwardQty',
        render:(val,record)=>{
          if (record.outwardAddedDate !== null  && record.outwardQty!==0){
            // record.outwardDate
            return <p>{record.outwardQty} ({moment(record.outwardAddedDate).format('lll')})</p>
          }
          else {
            return <p>{record.outwardQty}</p>
          }
        }
      },
      {
        title: 'Net  Qty',
        dataIndex: 'stock',
        key: 'stock',
      },
     
    ]
    return (
      <PageHeaderWrapper
        title={
          this.state.count ? `All Stock : ${this.state.count}` : `All Stock : 0`
        }>
        <Card bordered={true}>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Tooltip title="Download">
              <Button
                type="warning"
                style={{ marginTop: '6px' }}
                loading={this.state.fileDownloadLoader}
                onClick={() => {
                  this.downloadDispatch()
                }}
                icon="download">
                Download
              </Button>{' '}
            </Tooltip>
          </div>
          <TableComp
            ref={this.table}
            columns={columns}
            apiRequest={this.apiRequest}
            pagination={{
              ...this.state.pagination,
              defaultPageSize: 20,
              pageSizeOptions: ['10', '20', '50', '100'],
              showSizeChanger: true,
              ...this.props.pagination,
            }}
          />
        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  loading: global.buttonLoading,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllInwardList)
