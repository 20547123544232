import React, { Component } from 'react'
import { Route, Link, Redirect } from 'react-router-dom'
import IdleTimer from 'react-idle-timer'
import socketio from 'socket.io-client'
import { notification, Modal } from 'antd'
import memoizeOne from 'memoize-one'

import menuData, { getUrlPath } from '../../routes'
import Exception from '../../components/Exception'
import AuthLayout from '../../layouts/AuthLayout'
import BasicLayout from '../../layouts/BasicLayout'
import Login from '../../containers/login'
import Recording from '../recordngs/recodingInTab'
import RedirectPage from '../contacts/all/redirect'
import Emitter from '../../emitter'

import '../../index.css'
import _ from 'lodash'
import { pageTitle, apiUrl } from '../../settings'
import moment from 'moment'
import Request from '../../request'

const Exp = () => (
  <Exception
    type="404"
    desc={'You Seems lost !!'}
    linkElement={Link}
    redirect={'/dashboard'}
    backText={'Go To Homepage?'}
  />
)
const Exp403 = () => (
  <Exception
    type="403"
    desc={"Sorry You Don't have access to this area !!"}
    linkElement={Link}
    redirect={'/dashboard'}
    backText={'Go To Homepage?'}
  />
)

class BasicLayoutWrapper extends Component {
  render() {
    const { menuData, component, path } = this.props

    let user =
      localStorage.getItem('user') != 'undefined'
        ? JSON.parse(localStorage.getItem('user'))
        : null

    if (!user) {
      return <Redirect to="/login" />
    }

    let menuItem = _(menuData)
      .thru(function (coll) {
        return _.union(coll, _.map(coll, 'children'))
      })
      .flatten()
      .find({ path: path })

    if (
      menuItem.authority !== undefined &&
      menuItem.authority.indexOf(user.userType) === -1
    ) {
      return <Exp403 />
    }

    if (!menuItem.title) {
      menuItem.title = 'Untitled'
    }

    return (
      <BasicLayout
        location={window.location}
        title={pageTitle}
        pageTitle={`${menuItem.title} - Scizers Portfolio App`}
        menuData={menuData}>
        {!!component ? <this.props.component /> : <Exp />}
      </BasicLayout>
    )
  }
}

class App extends Component {

  loadApisRequest = () => { 
    Request.getReportToAdmin().then((data) => {
      this.setState({ reportToAdminData: data });
    })


    Request.getAllCallSkip().then(({data, error, message}) => {

      if (!error) {
          this.setState({count: data && data[0] && data[0].count})

      } else {
          notification.error({
              message: 'Error Getting Data'
          })
      }
    })

    
  }




  constructor(props) {
    super(props)
    this.state = {
      reportToAdminData:[],
      currentAttempt:0,
      maxAttempt:0,
      isVisible:true,
      notremoveOk:true,
      showModal: false,
      lastCalled: null,
      token: localStorage.getItem('token'),
      user:
        localStorage.getItem('user') != 'undefined'
          ? JSON.parse(localStorage.getItem('user'))
          : null,
    }
    this.idleTimer = null

    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)

    this.socket = socketio.connect(apiUrl)
    this.socket.on('logout_user_force', () => {
      console.log(
        ' logout_user_force logout_user_force logout_user_force logout_user_force '
      )
      this.logout()
    })

    this.socket.on('connect', async () => {
      console.log('conneciton succssful')
      this.reAuthenticate(this.socket)
    })
    this.socket.on('notifyRoForCAll', (obj) => {
      this.setState({isVisible : obj.isVisible});

      this.setState({maxAttempt : obj.maxAttempt});
    
      this.setState({currentAttempt : obj.currentAttempt});




      this.setState({ showModal: true, lastCalled: obj.lastCalled });
    })
    this.socket.on('notifyROForQualityCheck', (obj) => {
      notification.success({
        title: 'Please call',
        message: `You have a new Quality Check`,
      })
    })

    this.socket.on('reloadUsers', () => {
      Emitter.emit('reloadUsers')
    })
  }

  componentDidMount() {
    this.loadApisRequest()
    Emitter.on('reAuth', () => {
      this.reAuthenticate(this.socket)
    })
  }

  componentWillUnmount() {
    Emitter.off('reAuth', () => {})
  }
  onOk = () => {

    
    this.loadApisRequest();



    // console.log(this.state.reportToAdminData,"Here is report data on this page");

    // const today = moment().format('YYYY-MM-DD');
    // const dataArray = this.state.reportToAdminData;

    // let checkPoint = false;

    // dataArray.forEach(obj => {
    //   const objDate = moment(obj.date).format('YYYY-MM-DD'); // Convert object's date to the desired format
    //   if (objDate === today) {
    //     checkPoint = true;
    //     console.log('Match found:', obj);
    //   }
    // });

    

    let isVisible = this.state.isVisible;

    if(isVisible){
    this.setState({ showModal: false });
    let val={
      name:''
    }

    Request.roCallConfirm(val).then(({data,error})=>{
      if(!error){
        console.log("this the data required data",data);

      }

    });

    }

  }
  onCancel = () => {

    Request.addReportToAdmin({}).then(({data,error})=>{
      if(!error){
        console.log("this the data required data",data);

      }

    });




    this.setState({ showModal: false })
  }
  reAuthenticate = async (socket) => {
    let userToken = localStorage.getItem('token')
    if (userToken) {
      console.log('reAuthenticate with token at ' + new Date())
      socket.emit('authentication', { userToken })
    }
  }

  logout = async () => {
    let user = localStorage.getItem('user')
    user = JSON.parse(user)
    if (user) {
      user.isLoggedIn = false
      user.logoutTime = moment()
      let x = await Request.updateUserDetails(user)
      await Request.addLoginHistory({
        logoutTime: user.logoutTime,
        type: 'Logout',
        email: user && user.email,
        name: user && user.name,
        userType: user && user.userType,
        mobile: x && x.data && x.data.mobile,
      })

      localStorage.removeItem('token')
      localStorage.removeItem('user')
      window.location.reload()
    }
  }

  handleOnAction(event) {
    this.socket.emit('userIsActive')
  }

  handleOnActive(event) {
    this.socket.emit('userIsActive')
  }

  handleOnIdle(event) {
    this.socket.emit('userIsIdle')
  }

  render() {
    const { user } = this.state

    return (
      <>
        {localStorage.getItem('token') && (
          <IdleTimer
            ref={(ref) => {
              this.idleTimer = ref
            }}
            timeout={30 * 60 * 1000}
            eventsThrottle={30 * 1000}
            onActive={this.handleOnActive}
            onIdle={this.handleOnIdle}
            onAction={this.handleOnAction}
            debounce={250}
          />
        )}
        <div>
          <Route
            exact
            path="/form/basic-formsdf"
            render={(route) => {
              return (
                <div>
                  {menuData.map((item, key) => {
                    if (item.children) {
                      return item.children.map((child, k) => {
                        return <div key={item.children}>{child.path}</div>
                      })
                    }
                  })}
                </div>
              )
            }}
          />

          {menuData.map((item, key) => {
            if (!item.children) {
              return (
                <Route
                  exact
                  path={item.path}
                  key={item.path}
                  render={(route) => {
                    return (
                      <BasicLayoutWrapper
                        component={item.component}
                        path={item.path}
                        user={user}
                        menuData={menuData}
                      />
                    )
                  }}
                />
              )
            }
          })}

          {menuData.map((item, key) => {
            if (item.children) {
              return item.children.map((child, k) => {
                return (
                  <Route
                    exact
                    path={child.path}
                    key={child.path}
                    render={(route) => {
                      return (
                        <BasicLayoutWrapper
                          component={child.component}
                          path={child.path}
                          user={user}
                          menuData={menuData}
                        />
                      )
                    }}
                  />
                )
              })
            }
          })}

          <Route
            exact
            path="/login"
            render={(route) => {
              return (
                <AuthLayout location={window.location} menuData={menuData}>
                  <Login />
                </AuthLayout>
              )
            }}
          />

          <Route
            exact
            path="/"
            render={(route) => {
              let user = { ...JSON.parse(localStorage.getItem('user')) }
              if (user && user.userType == 'RO') {
                return <Redirect to="/roLeads/allList" />
              } else if (user && user.userType == 'Dispatch') {
                return <Redirect to="/dispatch/inwardOutwardList" />
              } else if (user && user.userType == 'Lead Executive') {
                return <Redirect to="/lead/add/executive" />
              } else if (user && user.userType == 'Caller') {
                return <Redirect to="/caller/leads" />
              } else if (user && user.userType == 'Manager') {
                return <Redirect to="/graph" />
              } else if (user && user.userType == 'Technician') {
                return <Redirect to="/complaint/add" />
              } else return <Redirect to="/dashboard" />
            }}
          />
          <Route
            exact
            path="/singleRecording"
            render={(route) => {
              return <Recording />
            }}
          />
          <Route
            exact
            path="/contactsRedirect"
            render={(route) => {
              return <RedirectPage />
            }}
          />
          <Modal
            visible={this.state.showModal}
            // visible={true}
            onOk={this.onOk}
            style={{ width: '600px' }}
            title={
              <span style={{ color: 'red', fontSize: '20px' }}>
                Caller Alert
              </span>
            }
            onCancel={this.onCancel}
            okButtonProps={{ disabled: !this.state.isVisible, style: { display: this.state.isVisible ? 'inline-block' : 'none' } }}
            // okButtonProps={{ disabled: !this.state.notremoveOk, style: { display: this.state.notremoveOk ? 'inline-block' : 'none' } }}
            okText="I am Calling"
            cancelText="Report to Admin"
            closable={this.state.isVisible}
            maskClosable={false}>
            You hadn't call from the last {this.state.lastCalled} hours{' '} 
          </Modal>
        </div>
      </>
    )
  }
}

export default App
