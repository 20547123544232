import React, { Component } from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper/index'
import { TableComp } from 'sz-react-utils'
import moment from 'moment'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  Popconfirm,
  notification,
  Row,
  Col,
  Select,
  Drawer,
  Switch,
  Input,
  Button,
  Icon,
  Tag,
} from 'antd'
import Request, { getLeadStageColor } from '../../request'
import { stateFilter } from '../../states'
import _ from 'lodash'
import { connect } from 'react-redux'
import { getUrlParams, getUrlPushWrapper } from '../../routes'
import { hideLoader, showLoader } from '../../modules/actions'
import { DatePicker, Space } from 'antd'
import UpdateLead from '../lead/add/editLead'

const { Option } = Select

class AllFollowUpsDashboardNew extends Component {
  apiRequest = (params) => {
    let user = { ...JSON.parse(localStorage.getItem('user')) }

    if (params.page) {
      this.state.currentPage = params.page
    }
    if (!params.sortOrder) {
      params.sortField = 'createdAt'
      params.sortOrder = 'desc'
    }
    if (this.state.selectedLeadStage) {
      params.stage = this.state.selectedLeadStage
    }
    if (params.done && params.done.length) {
      this.paramsDone = true
    }
    if (!(params.officer && params.officer.length)) {
      delete params.officer
    }

    return new Promise(async (resolve) => {
      if (this.state.addedBy) {
        params.officer = this.state.addedBy
      }

      let data = await Request.getAllLeadsFollowUpPage({
        ...params,
        followUpDateQuery: this.state.customQuery,
        followUpStatus: this.status,
        regExFilters: ['title', 'company', 'contact'],
        customQuery: {
          ...(this.paramsDone === true
            ? {
                $or: [
                  { stage: { $ne: 'Contacted' } },
                  { $and: [
                    { stage: 'Contacted' },
                    { tags: { $ne: 'No Future FollowUp'} }
                  ]},
                ],
                ...(params.officer && params.officer.length
                  ? {}
                  : { officer: { $exists: true, $ne: null } }),
              }
            : {}),
        },
      })

      this.paramsDone = false
      this.setState({ count: data.total })
      resolve(data)
    })
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
    this.state = {
      currentPage: 1,
      RoList: [],
      allContacts: [],
      startRange: moment().startOf('day').format('YYYY-MM-DDTHH:MM:ss'),
      lastRange: moment().endOf('day').format('YYYY-MM-DDTHH:MM:ss'),
      leadStage: ['New (Untouched)', 'Contacted', 'Today Followup'],
      priceValue: [
        'Below 10K',
        'From 10K to 50K',
        'From 50K to 1 Lakh',
        'Above 1 Lakh',
      ],
    }

    this.status = null
    this.paramsDone = false

    let followUpsUrl = getUrlParams('followUpsUrl', window.location.pathname)
    if (followUpsUrl) {
      let { gte, lte, value } = followUpsUrl
      this.state.startRange = gte
      this.state.lastRange = lte
      this.onChange(value)
      this.state.filterValue = value
    } else {
      this.onChange('Total')
    }
  }

  async componentDidMount() {
    let user = { ...JSON.parse(localStorage.getItem('user')) }

    if (user && user.userType == 'Manager') {
      Request.loadUser(
        { _id: { $in: user.roList } },
        { results: 1000000000000 }
      ).then(({ data: userList }) => {
        this.setState({ userList: userList })
      })
    } else {
      let { data: userList } = await Request.getAllROList({
        customQuery: { userType: { $eq: 'RO' } },
      })
      this.setState({ RoList: userList })
    }

    let { data: allContacts } = await Request.getAllContacts()
    this.setState({ allContacts })
  }

  onChange = (e) => {
    this.setState({ filterValue: e })
    let filter = {}

    switch (e) {
      case 'Completed':
        filter.customQuery = {
          $and: [
            {
              followUpDate: {
                $gte: this.state.startRange,
                $lte: this.state.lastRange,
              },
            },
          ],
        }
        this.status = 'Completed'
        break
      case 'Remaining':
        filter.customQuery = {
          $and: [
            {
              followUpDate: {
                $gte: this.state.startRange,
                $lte: this.state.lastRange,
              },
            },
          ],
        }
        this.status = 'Remaining'
        break
      default:
        filter.customQuery = {
          $and: [
            {
              followUpDate: {
                $gte: this.state.startRange,
                $lte: this.state.lastRange,
              },
            },
          ],
        }
        this.status = null
    }

    this.state.customQuery = filter.customQuery
  }

  updateTheLead = async (values) => {
    let x = await Request.updateLead(values)
    if (!x.error) {
      notification.success({
        message: x.message,
      })
    } else {
      console.log('err is', x.error)
      notification.error({
        message: 'Error Saving',
        description: x.message,
      })
    }
  }

  render() {
    const RofilterArray =
      this.state.RoList &&
      this.state.RoList.map((user) => {
        return {
          text: user.name,
          value: user._id,
        }
      })

    const { currentUser } = this.props
    let { currentPage, allContacts } = this.state

    const columns = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        width: 50,
        key: 'sno',
        fixed: 'left',
        render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
      },
      {
        key: 'actions',
        title: 'View',
        fixed: 'left',
        width: 50,
        render: (text, record) => {
          return (
            <React.Fragment>
              <Tooltip title="View">
                <a
                  href={`/lead/detail?id=${record && record._id}`}
                  target={'_blank'}>
                  <Button
                    style={{ marginLeft: '5px' }}
                    size="small"
                    icon={'eye'}
                    shape={'circle'}
                  />
                </a>
              </Tooltip>
            </React.Fragment>
          )
        },
      },
      {
        title: 'Lead Id',
        dataIndex: 'id',
        key: 'id',
        fixed: 'left',
        searchTextName: 'id',
        width: 100,
      },
      {
        title: 'Lead',
        dataIndex: 'title',
        key: 'title',
        searchTextName: 'title',
        render: (text, record) => (
          <span>
            <a
              onClick={() => {
                this.setState({
                  ...this.state,
                  cardId: record._id,
                  updatelead: true,
                })
              }}>
              {record.title}
            </a>
          </span>
        ),
      },
      {
        title: 'Product',
        dataIndex: 'productName',
        key: 'productName',
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        filters: stateFilter,
        width: 150,
      },
      {
        title: 'Contact',
        dataIndex: 'contact.firstName',
        key: 'contact.firstName',
        render: (text, record) => (
          <span>
            <span>{record.contact ? record.contact.firstName : ''}</span>
            <br />
            <span>{record.contact ? record.contact.mobile : ''}</span>
          </span>
        ),
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        searchTextName: 'company',
      },
      {
        title: 'Relationship Officer',
        dataIndex: 'officer',
        key: 'officer',
        filters: RofilterArray,
        render: (val, record) => {
          return (
            <div>
              <div>{val && val.name}</div>
            </div>
          )
        },
      },
      {
        title: 'Stage',
        key: 'stage',
        render: (text, record) => {
          return (
            <div>
              {record && record.stage ? (
                <div
                  style={{
                    color: 'white',
                    backgroundColor: getLeadStageColor(record.stage),
                    padding: '2px',
                    textAlign: 'center',
                    borderRadius: '2px',
                  }}>
                  {record.stage}
                </div>
              ) : (
                ''
              )}
            </div>
          )
        },
        filters: _.map(this.state.leadStage, (ii) => ({
          text: ii,
          value: ii,
        })),
      },
      {
        title: 'Total Call Logs',
        dataIndex: 'callLogs',
        key: 'callLogs',
        width: 150,
        render: (val, record) => {
          return (
            <div>
              <small>
                Outgoing:{' '}
                {val &&
                  (val.outgoingCalls || 0) +
                    (val.outgoingRejectCalls || 0)}{' '}
                (
                {val &&
                  (val.todayOutgoingCalls || 0) +
                    (val.todayOutgoingRejectCalls || 0)}
                )
              </small>
              <div>
                <small>
                  Incoming: {(val && val.incomingCalls) || 0} (
                  {(val && val.todayIncomingCalls) || 0})
                </small>
              </div>
              <div>
                <small>
                  Missed: {(val && val.missedCalls) || 0} (
                  {(val && val.todayMissedCalls) || 0})
                </small>
              </div>
              <div>
                <small>
                  Attended: {(val && val.totalAttended) || 0} (
                  {(val && val.todayTotalAttended) || 0})
                </small>
              </div>
            </div>
          )
        },
      },
      {
        title: 'Done',
        key: 'done',
        width: 80,
        render: (text, record) => {
          if (
            record &&
            !record.isVault &&
            record.status === 'Open' &&
            record.officer &&
            record.officer.name &&
            (record.stage !== 'Contacted' ||
              (record.stage === 'Contacted' &&
                !(record.tags && record.tags.includes('No Future FollowUp')) &&
                (!record.followUpDate ||
                  (record.followUpDate &&
                    moment(record.followUpDate).isSame(moment(), 'day')))))
          ) {
            if (record.done === true) {
              return (
                <Tag color="green" style={{ marginBottom: '2px' }}>
                  Yes
                </Tag>
              )
            } else {
              return (
                <Tag color="red" style={{ marginBottom: '2px' }}>
                  No
                </Tag>
              )
            }
          } else {
            return <Tag style={{ marginBottom: '2px' }}>N/A</Tag>
          }

        },
        filters: [
          { text: 'Yes', value: 'true' },
          { text: 'No', value: 'false' },
        ],
      },
      {
        title: 'Tags',
        key: 'tags',
        width: 140,
        render: (text, record) => {
          if (record.tags && record.tags.length) {
            return _.map(record.tags, (ii) => {
              return <Tag style={{ marginBottom: '2px' }}>{ii}</Tag>
            })
          }
        },
        filters: [{ text: 'No Future FollowUp', value: 'No Future FollowUp' }],
      },
      {
        title: 'Value',
        dataIndex: 'value',
        key: 'value',
        width: 100,
        render: (text, record) => {
          return (
            record.value && (
              <span>
                <span>
                  {record.currency && record.currency.slice(-1)} {record.value}
                </span>
              </span>
            )
          )
        },
        filters: _.map(this.state.priceValue, (ii) => ({
          text: ii,
          value: ii,
        })),
      },
      {
        title: 'Last Note',
        key: 'lastNote',
        width: 140,
        render: (text, record) => {
          if (record.notes && record.notes.length) {
            return (
              record.notes[record.notes.length - 1] && (
                <span>
                  <span>
                    {record.notes[record.notes.length - 1].message &&
                      record.notes[record.notes.length - 1].message.replace(
                        /<[^>]+>/g,
                        ' '
                      )}
                  </span>
                  <br />
                  <small>
                    {moment(record.notes[record.notes.length - 1].date).format(
                      'lll'
                    )}
                  </small>
                </span>
              )
            )
          }
        },
      },
      {
        title: 'Followup On',
        dataIndex: 'latestFollowup',
        key: 'latestFollowup',
        render: (val, record) => {
          return (
            <div>
              <div>
                {record && record.description ? record.description : ''}
              </div>
              <div>
                {record && record.followUpDate
                  ? moment(record.followUpDate).format('ll')
                  : ''}
              </div>
            </div>
          )
        },
      },
      {
        title: 'Lead Received Date',
        dataIndex: 'leadReceivedDate',
        key: 'leadReceivedDate',
        searchDateName: 'leadReceivedDate',
        sorter: true,
        fixed: 'right',
        render: (val, record) => {
          return (
            <div>
              <div>
                {' '}
                {record && record.leadReceivedDate
                  ? moment(record.leadReceivedDate).format('lll')
                  : ''}{' '}
              </div>
            </div>
          )
        },
        width: 150,
      },
    ]

    const handleChangeDebut = (range) => {
      if (!range.length) {
        this.state = {
          ...this.state,
          startRange: moment().subtract(2, 'months').format('YYYY-MM-DD'),
          lastRange: moment().add(1, 'months').format('YYYY-MM-DD'),
        }
        if (this.state.overDueRange) {
          this.setState({
            startRange: null,
            lastRange: this.state.overDueRange,
          })
        }
      } else {
        const valueOfInput1 = moment(range[0]).startOf('day').format()
        const valueOfInput2 = moment(range[1]).endOf('day').format()
        this.setState({
          ...this.state,
          startRange: valueOfInput1,
          lastRange: valueOfInput2,
        })
      }
      setTimeout(() => {
        this.onChange(this.state.filterValue)
        this.table.current.reload()
      }, 500)
    }

    return (
      <PageHeaderWrapper
        title={
          this.state.count
            ? `All FollowUp : ${this.state.count}`
            : `All FollowUp: 0`
        }>
        <Card bordered={true}>
          <Row>
            <Col span={8}>
              <DatePicker.RangePicker
                style={{ width: '100%' }}
                getPopupContainer={(trigger) => trigger.parentNode}
                value={[
                  moment(this.state.startRange),
                  moment(this.state.lastRange),
                ]}
                onChange={(value, dateString) => handleChangeDebut(value)}
              />
            </Col>

            <Col span={8}>
              <Select
                showSearch
                allowClear
                optionFilterProp="children"
                placeholder={'Select Filter'}
                style={{ width: 200, marginLeft: '10px' }}
                value={this.state.filterValue}
                onChange={(id) => {
                  this.setState({ filterValue: id }, () => {
                    this.onChange(this.state.filterValue)
                    this.table.current.reload()
                  })
                }}>
                <Option key={'All'} value={'All'}>
                  All
                </Option>
                <Option key={'Completed'} value={'Completed'}>
                  Completed
                </Option>
                <Option key={'Remaining'} value={'Remaining'}>
                  Remaining
                </Option>
              </Select>
            </Col>
          </Row>

          <TableComp
            ref={this.table}
            columns={columns}
            apiRequest={this.apiRequest}
            extraProps={{
              scroll: { x: 1950 },
            }}
            pagination={{
              ...this.state.pagination,
              defaultPageSize: 10,
              pageSizeOptions: ['10', '25', '50', '100'],
              showSizeChanger: true,
              ...this.props.pagination,
            }}
          />
        </Card>

        <Drawer
          title=""
          placement="right"
          closable={true}
          onClose={async () => {
            this.setState({
              ...this.state,
              updatelead: false,
              cardId: '',
            })
          }}
          visible={this.state.updatelead}
          width={800}>
          <UpdateLead
            cardId={this.state.cardId}
            allContacts={allContacts}
            leadOwner={currentUser}
            key={this.state.drawerKey ? this.state.drawerKey : Date.now()}
            handleUpdateLead={() => {
              this.table.current.reload()
              this.setState({ updatelead: false, cardId: '' })
            }}
          />
        </Drawer>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  categories: global.categories,
  currentUser: global.currentUser,
  search: router.location.search,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllFollowUpsDashboardNew)
