import React, { Component } from 'react';
import moment from 'moment';
// import TableComp from './TableComp';
import Request from '../../request';
import { TableComp, FormUtils as GetAllFormFields } from 'sz-react-utils'

 // Assuming Request is the module for making API requests

class ReportTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentPage: 1,
      pagination: {},
    };

    this.table = React.createRef();
  }

  componentDidMount() {
    // this.apiRequest2({ page: this.state.currentPage });
  }

  apiRequest2 = (params) => {
    if (params.page) {
      this.setState({ currentPage: params.page });
    }

    return new Promise(async (resolve) => {
      let data = await Request.getReportToAdmin({
        ...params,
        // regExFilters: ['RoName', 'RoId', 'howManyTimeReported', 'lastReportTime'],
      });
      resolve(data);
    });
  };

  render() {
    const { currentPage } = this.state;

    const ReportColumns = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        width: 50,
        key: 'sno',
        render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
      },
      {
        title: 'Name',
        dataIndex: 'roName',
        key: 'roName',
        
      },
      {
        title: 'How Many Times Reported',
        dataIndex: 'howManyTimeReported',
        key: 'howManyTimeReported',
       
      },
      {
        title: 'Last Reported Time',
        dataIndex: 'lastReportTime',
        key: 'lastReportTime',
        render: (value) => moment(value).fromNow(),
      },
    ];

    return (
      <div>
        <TableComp
          ref={this.table}
          columns={ReportColumns}
          apiRequest={this.apiRequest2}
          pagination={{
            ...this.state.pagination,
            defaultPageSize: 10,
            pageSizeOptions: ['10', '25', '50', '100'],
            showSizeChanger: true,
            ...this.props.pagination,
          }}
        />
      </div>
    );
  }
}

export default ReportTable;
