import React, { PureComponent } from 'react'
import {
  Divider,
  Button, List,
  Card, Drawer, Timeline, Avatar
} from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { connect } from 'react-redux'
import { API_URL } from '../../../request'

class ViewImages extends PureComponent {

  constructor(props) {
    super(props)
    this.state = {}
  }

  async componentDidMount() {
  }

  render() {

    const { imageArray } = this.props
    return (
      <React.Fragment>

        <Card>

          <List
            dataSource={imageArray}
            renderItem={(item, key) => (
              <List.Item key={key}>
                <List.Item.Meta
                  avatar={
                    <Avatar src={`${API_URL}/${item}`} />
                  }
                  title={<a
                    onClick={() => {
                      window.open(`${API_URL}/${item}`, 'download')

                    }}

                    target={'_blank'}>Download</a>}
                  description={''}
                />
              </List.Item>
            )}
          >
          </List>

        </Card>

      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewImages)
