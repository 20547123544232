import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import moment from 'moment'
import { TableComp } from 'sz-react-utils'

import {

  Card,
  DatePicker

} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
import styles from './styles.less'
import { connect } from 'react-redux'

const { RangePicker } = DatePicker


class AllCalls extends Component {
  apiRequest = (params) => {
    if(params.page) {this.state.currentPage = params.page}

    return new Promise(async (resolve) => {
      if (!params.sortField) {
        params.sortField = 'id'
        params.sortOrder = 'descend'
      }
      // if (this.state.time && this.state.time.from) {
      //   params.dateFilter = this.state.time
      // }
      let data = await Request.getAllCalls({
        ...params, regExFilters: []
      })
      this.setState({ count: data.total })
      resolve(data)
    })
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
    this.state = {
      currentPage: 1,
      count: 0, departmentlist: ['Technical Support', 'Sales']
      // time: {
      //   key: 'date',
      //   from: moment().startOf('month').format('YYYY-MM-DD'),
      //   to: moment().endOf('month').format('YYYY-MM-DD')
      // }
    }

  }

  componentDidMount() {
  }

  // chooseRangerPicker = (date, dateString) => {
  //   this.setState({
  //     time: {
  //       key: 'date',
  //       from: date[0],
  //       to: date[1]
  //     }
  //   })
  //   if (date[0] == undefined) {
  //     setTimeout(() => {
  //       this.reload()
  //     }, 500)
  //   }
  // }
  // onOk = value => {
  //   this.reload()
  // }
  // reload = () => {
  //   this.table.current.reload()
  // }

  render() {
    const { dispatch } = this.props

    let { count, departmentlist ,currentPage} = this.state
    const columns = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        width: 50,
        key: 'sno',
        render: (value, item, index) => (currentPage - 1) * 10 + index + 1
    },
      {
        title: 'Id',
        dataIndex: 'id',
        sorter: true,
        key: 'id',
        searchTextName: 'id'
      },
      {
        title: 'Caller Number',
        dataIndex: 'callerNumber',
        key: 'callerNumber',
        searchTextName: 'caller Number'
      },
      {
        title: 'Department',
        dataIndex: 'departmentName',
        key: 'departmentName',
        filters: departmentlist && departmentlist.map(x => ({ value: x, text: x }))
      },
      {
        title: 'Country',
        dataIndex: 'country',
        key: 'country'
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state'
      },
      {
        title: 'Duration',
        dataIndex: 'duration',
        key: 'duration'

      },
      {
        title: 'Start Time',
        dataIndex: 'startTime',
        key: 'startTime',
        searchDateName: 'startTime',
        render: (item, record) => {
          return (<div>{record && record.startTime ? moment(record.startTime).format('lll') : ''}</div>)
        }
      },
      {
        title: 'End Time',
        dataIndex: 'endTime',
        key: 'endTime',
        searchDateName: 'endTime',
        render: (item, record) => {
          return (<div>{record && record.endTime ? moment(record.endTime).format('lll') : ''}</div>)
        }
      },
      {
        title: 'Receiver Details',
        dataIndex: 'receiverDetails',
        key: 'receiverDetails',
        render: (item, record) => {
          return (<div>
            <div>{record && record.receiverDetails ? record.receiverDetails.receivedByName : ''}</div>
            <div><b>duration:</b> {record && record.receiverDetails ? record.receiverDetails.duration : ''}</div>
            <div><b>start
              time:</b> {record && record.receiverDetails ? moment(record.receiverDetails.startTime).format('lll') : ''}
            </div>
            <div><b>end
              time:</b> {record && record.receiverDetails ? moment(record.receiverDetails.endTime).format('lll') : ''}
            </div>

          </div>)
        }
      }
    ]
    return (
      <PageHeaderWrapper
        title={count ? `All Calls: ${count}` : `All Calls:0`}>
        {/*  <Card>
          <h4 style={{ display: 'inline-block', marginRight: 10 }}>Filter By Date</h4>
          <RangePicker
            showTime={{ format: 'HH:mm' }}
            format="YYYY-MM-DD"
            style={{ width: '270px' }}
            defaultValue={[moment().startOf('month'), moment().endOf('month')]}
            placeholder={['Start Time', 'End Time']}
            onChange={this.chooseRangerPicker}
            onOk={this.onOk}
          />
        </Card>*/}

        <Card bordered={true}>


          <TableComp ref={this.table}
                     columns={columns}
                     apiRequest={this.apiRequest}
                     pagination={{
                       ...this.state.pagination,
                       defaultPageSize: 10,
                       pageSizeOptions: ['10', '25', '50', '100'],
                       showSizeChanger: true,
                       ...this.props.pagination
                     }}/>
        </Card>

      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  loading: global.buttonLoading

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllCalls)
