import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils'
import moment from 'moment'
import {
    Modal,
    Card,
    Button, Tooltip,
    Select, Tag,

} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
//import styles from './styles.less'
import {connect} from 'react-redux'
import {apiUrl} from "../../../settings";

const {Option} = Select

class StockLedger extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            visible: false, key: Date.now(), currentPage: 1, userType: ['Inward', 'Outward'],
        }

    }


    apiRequest = (params) => {
        if (params.page) {
            this.state.currentPage = params.page
        }

        return new Promise(async (resolve) => {

            if (!params.sortField) {
                params.sortField = 'createdAt'
                params.sortOrder = 'descend'
            }
            let data = await Request.getAllStockLedger({
                ...params, regExFilters: ['productName']
            })

            this.setState({count: data.total})
            resolve(data);
        })


    }

    async componentDidMount() {

    }

    render() {
        const {} = this.props
        const {currentPage,userType} = this.state;

        const columns = [
            {
                title: 'S.No',
                dataIndex: 'sno',
                width: 50,
                key: 'sno',
                render: (value, item, index) => (currentPage - 1) * 10 + index + 1
            },
            {
                title: 'Id',
                width: 80,
                key: 'id',
                dataIndex: 'id',
                searchTextName: 'id'

            },
            {
                title: 'Product Name',
                key: 'productName',
                width: 150,
                dataIndex: 'productName',
                searchTextName: 'productName'


            }, {
                title: 'Type',
                dataIndex: 'type',
                width:150,
                key: 'type',
                filters: userType && userType.map(x => ({value: x, text: x})),
                render: (val) => {
                    return <Tag color={val === 'Inward' ? 'blue' : 'red'}>{val}</Tag>
                }

            },


            {
                title: 'Quantity',
                dataIndex: 'quantity',
                width: 80,
                searchTextName: 'quantity',

            },
            {
                title: 'Date',
                width: 180,
                key: 'date',
                dataIndex: 'date',
                sorter: true,
                render: (text, record) => {
                    return (
                        <div>
                            {text ? moment(text).format('lll') : ''}
                        </div>
                    )
                }
            }

        ]


        return (
            <PageHeaderWrapper
                title={this.state.count ? `Stock Ledger: ${this.state.count}` : `Stock Ledger: 0`}>

                <Card bordered={true}>

                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(StockLedger)
