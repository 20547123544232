import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    notification,
    Anchor,
    Spin,
    Select,
    Tag,
    Button,
    Table,
    Card,
    Drawer,
    Modal,
    Popover,
    Radio,
    Typography,
    Row,
    Col,
    InputNumber,
    Descriptions,
    Icon, Tooltip, Popconfirm, Avatar, Checkbox, AutoComplete
} from 'antd'
import _ from 'lodash'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'
import moment from 'moment'


import {API_URL} from '../../../request'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import styles from '../all/styles.less'
import {connect} from 'react-redux'
import {createMatchSelector, goBack} from 'connected-react-router'
import {getUrlPushWrapper} from "../../../routes";

const FormItem = Form.Item
const {Option} = Select
const {Text, Paragraph} = Typography;
let nextId = 0

@Form.create()
class AddDispatch extends PureComponent {

    handleSubmit = e => {
        const {dispatch, form} = this.props
        let {sendNotification, notifications} = this.state

        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)


                values.shippingAddress = {
                    addressLine1: values.addressLine1,
                    addressLine2: values.addressLine2,
                    state: this.state.stateName,
                    city: this.state.cityName,
                    zipcode: values.zipcode
                }
                values.trackingData = this.state.trackingData

                values.contact = this.state.selectedContactId;
                if (!values.contactName) {
                    return notification.error({
                        message: 'Please Choose contact'
                    })
                }
                if (!notifications.mail && !notifications.whatsapp && !notifications.message && sendNotification) {
                    return notification.error({
                        message: 'Atleast select one notification'
                    })
                }
                if (sendNotification)
                    values.notifications = {...notifications}
                dispatch(showLoader())
                this.setState({sendLoading: true})

                let x = null
                if (this.state.id) {
                    values._id = this.state.id

                    x = await Request.updateDispatchForm(values)
                } else {
                    x = await Request.addDispatch(values)
                }

                dispatch(hideLoader())
                this.setState({sendLoading: false})


                if (!x.error) {

                    notification.success({
                        message: this.state.id
                            ? x.message
                            : x.message
                    })

                    if (!this.state._id) {
                        this.props.form.resetFields()
                        this.props.form.setFieldsValue({dispatchDate: moment()})


                    }

                    if (this.state.id) {
                        dispatch(goBack())

                    }


                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }

    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            loader: true,
            allContacts: [],
            totalData: [],
            carriersData: ['Fedex', 'DHL', 'UPS', 'USPS', 'BlueDart', 'DTDC'],
            trackingNumberExists: false,
            notifications: {'mail': false, 'text': false, 'whatsapp': false},
            trackingData: [{trackingNumber: '', courierName: ''}]
        }


    }


    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search);

        let id = searchParams.get('id')

        Request.getAllContacts().then(({data: allContacts}) => {
            this.setState({allContacts: allContacts || []})
        })
        Request.loadUser({userType: ['RO', 'Dispatch']}).then(({data: userList}) => {
            this.setState({userList: userList || []})
        })

        let {data: stateList} = await Request.loadState({countryId: '5fca34a100c10219d4c564de'})
        this.setState({stateList: stateList})

        if (id) {

            Request.getDispatchById({_id: id}).then(({data: dispatchData, error, message}) => {

                if (!error) {

                    this.setState({
                        id: dispatchData && dispatchData._id,
                        loader: false,
                        selectedContactId: dispatchData && dispatchData.contact && dispatchData.contact._id,
                        stateName: dispatchData && dispatchData.shippingAddress && dispatchData.shippingAddress.state,
                        cityName: dispatchData && dispatchData.shippingAddress && dispatchData.shippingAddress.city,
                        trackingNumberExists: dispatchData && dispatchData.trackingNumber ? true : false,
                        trackingData: dispatchData && dispatchData.trackingData
                    }, () => {
                        this.checkNotification()
                    })

                    this.props.form.setFieldsValue({

                        noOfBoxes: dispatchData && dispatchData.noOfBoxes,
                        trackingNumber: dispatchData && dispatchData.trackingNumber,
                        checkedBy: dispatchData && dispatchData.checkedBy,
                        dispatchDate: dispatchData && moment(dispatchData.dispatchDate),
                        contactName: dispatchData && dispatchData.contact && `${dispatchData.contact.firstName} (${dispatchData.contact.mobile})`,
                        addressLine1: dispatchData && dispatchData.shippingAddress && dispatchData.shippingAddress.addressLine1,
                        addressLine2: dispatchData && dispatchData.shippingAddress && dispatchData.shippingAddress.addressLine2,
                        state: dispatchData && dispatchData.shippingAddress && dispatchData.shippingAddress.state,
                        city: dispatchData && dispatchData.shippingAddress && dispatchData.shippingAddress.city,
                        zipcode: dispatchData && dispatchData.shippingAddress && dispatchData.shippingAddress.zipcode,
                        carrier: dispatchData && dispatchData.carrier,
                        packedBy: dispatchData && dispatchData.packedBy,


                    });
                } else {
                    notification.error({
                        message: 'Error Getting Data'
                    })
                }
            })
        }
        else {

            this.setState({

                loader: false

            })
            this.props.form.setFieldsValue({dispatchDate: moment()})

        }


    }

    setSocial = (field, val) => {
        let newData = _.clone(this.state.notifications)
        newData[field] = val
        this.setState({notifications: newData})
    }
    handleContactSearch = async (test) => {
        this.setState({contactSearchText: test, selectedContactId: null})
        if (test == '') {
            this.setState({allContacts: this.state.allContacts})

            return false
        }
        let {data, error, message} = await Request.contactSearch({text: test})
        if (!error) {
            this.setState({allContacts: data}, () => {
                this.setState({showSpin: false})
            })
        }
    }

    onContactSelect = (val) => {
        let selectedContact = this.state.allContacts.find((obj) => obj._id === val)
        if (selectedContact) {
            this.setState({
                selectedContactId: selectedContact._id,
                stateName: selectedContact.shippingAddress && selectedContact.shippingAddress.state,
                cityName: selectedContact.shippingAddress && selectedContact.shippingAddress.city
            })

            this.props.form.setFieldsValue({
                contactName: `${selectedContact.firstName} (${selectedContact.mobile})`,

                state: selectedContact.shippingAddress && selectedContact.shippingAddress.state,
                city: selectedContact.shippingAddress && selectedContact.shippingAddress.city,
                addressLine1: selectedContact.shippingAddress && selectedContact.shippingAddress.addressLine1,
                addressLine2: selectedContact.shippingAddress && selectedContact.shippingAddress.addressLine2,
                zipcode: selectedContact.shippingAddress && selectedContact.shippingAddress.zipcode
            })
        }
    }

    remove = k => {
        let tt = _.clone(this.state.trackingData)
        tt = _.reject(tt, (t, i) => {
            return i == k
        })
        this.setState({trackingData: tt}, () => {
            this.checkNotification()
        })
    };

    checkNotification = () => {
        let showError = false

        _.each(this.state.trackingData, (i) => {
            if (i.trackingNumber == '' || i.courierName == '') {
                if (!showError) {
                    showError = true
                }
            }


        })
        if (showError) {
            this.setState({trackingNumberExists: false})
        } else {
            this.setState({trackingNumberExists: true})
        }

    }

    render() {
        const {
            form,
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        const {productList, _id, allContacts, stateList, carriersData, userList} = this.state

        const inputTypes = {
            fields: [

                {
                    label: 'Dispatch Date',
                    key: 'dispatchDate',
                    type: 'date',
                    required: true

                },
                {
                    label: 'No of Boxes',
                    key: 'noOfBoxes',
                    required: true,
                    type: 'number'


                },

                {
                    label: 'Checked By',
                    key: 'checkedBy',
                    showSearch: true,
                    required: true,
                    keyAccessor: x => x._id,
                    valueAccessor: x => `${x.name} ${x.mobile}`,
                    type: 'select',
                    options: userList || [],
                    onChange: checkedBy => {
                        this.props.form.setFieldsValue({
                            checkedBy: checkedBy
                        })

                    }
                },
                {
                    label: 'Packed By',
                    key: 'packedBy',
                    required: true,
                    showSearch: true,
                    keyAccessor: x => x._id,
                    valueAccessor: x => `${x.name} ${x.mobile}`,
                    type: 'select',
                    options: userList || [],
                    onChange: packedBy => {
                        this.props.form.setFieldsValue({
                            packedBy: packedBy
                        })

                    }


                },

                //
                // {
                //     label: 'Tracking Number',
                //     key: 'trackingNumber',
                //     customProps: {
                //         onChange: (trackingNumber) => {
                //             if (trackingNumber.target.value === '') {
                //                 this.setState({trackingNumberExists: false})
                //             } else {
                //                 this.setState({trackingNumberExists: true})
                //
                //             }
                //             this.props.form.setFieldsValue({
                //                 trackingNumber: trackingNumber
                //             })
                //         }
                //     }
                //
                //
                // },


            ]

        }
        const addressDetails1 = {
            fields: [

                {
                    label: 'Address Line1',
                    key: 'addressLine1',
                    required: true,
                    customProps: {disabled: true}
                },
                {
                    label: 'Address Line2',
                    key: 'addressLine2',
                    required: true,
                    customProps: {disabled: true}
                },
                {
                    label: 'State',
                    type: 'select',
                    key: 'state',
                    showSearch: true,
                    required: true,
                    disabled: true,
                    //  disabled: this.state.stateList && this.state.stateList.length ? false : true,
                    keyAccessor: x => x._id,
                    valueAccessor: x => x.stateName ? _.startCase(x.stateName) : '',
                    options: stateList || [],
                    onChange: async (shippingState) => {
                        let {data: cityList} = await Request.loadCity({stateId: shippingState})
                        let {data: stateDetails} = await Request.getStateById({_id: shippingState})

                        this.setState({
                            cityList: cityList,
                            stateName: stateDetails && stateDetails.stateName,
                            cityName: ''
                        })
                        this.props.form.setFieldsValue({
                            state: shippingState,
                            city: ''
                        })
                    }

                },
                {
                    label: 'City',
                    type: 'select',
                    key: 'city',
                    showSearch: true,
                    required: true,
                    disabled: true,
                    // disabled: this.state.cityList && this.state.cityList.length ? false : true,
                    keyAccessor: x => x._id,
                    valueAccessor: x => x.cityName ? _.startCase(x.cityName) : '',
                    options: this.state.cityList || [],
                    onChange: async (shippingCity) => {

                        let {data: cityDetails} = await Request.getCityById({_id: shippingCity})
                        this.setState({
                            cityName: cityDetails && cityDetails.cityName
                        })
                        this.props.form.setFieldsValue({
                            city: shippingCity,
                        })

                    }
                },
                {
                    label: 'Zipcode',
                    key: 'zipcode',
                    required: true,
                    customProps: {disabled: true}

                },

            ]
        }

        const formItemLayoutWithOutLabel = {
            wrapperCol: {
                xs: {span: 24, offset: 4},
                sm: {span: 20, offset: 8},
            },
        };
        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }


        return (
            <PageHeaderWrapper title={this.state.id ? 'Update Dispatch Form' : 'Add Dispatch Form'}
            >

                <Spin spinning={false}>
                    <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{marginTop: 8}}>

                        <Card bordered={true}>

                            <Row>


                                <Col span={12}>
                                    <Form.Item
                                        label='Contact'
                                        {...formItemLayout}
                                    ><Col span={18}>{getFieldDecorator('contactName', {
                                        rules: [{
                                            required: true,
                                            message: 'This is a Mandatory Field'
                                        }]
                                    })(
                                        <AutoComplete
                                            notFoundContent={this.state.showSpin && <Spin/>}
                                            autoComplete={'none'}
                                            onSelect={this.onContactSelect}
                                            onSearch={this.handleContactSearch}
                                            placeholder={'Contact Name'}
                                        >{allContacts.map((val, item) => (
                                            <Option value={val._id}>
                                                <>
                                                    <div><b>{`${val.firstName} (${val.mobile})`}</b></div>
                                                </>
                                            </Option>
                                        ))}

                                        </AutoComplete>
                                    )}</Col>

                                    </Form.Item>
                                    <GetAllFormFields apiurl={API_URL}
                                                      inputSchema={inputTypes}
                                                      formItemLayout={formItemLayout}
                                                      getFieldDecorator={getFieldDecorator}/>
                                    <Form.Item
                                        label='Send Through'
                                        {...formItemLayout}
                                    >{getFieldDecorator('carrier', {
                                        rules: [{
                                            required: false,
                                            message: 'This is a Mandatory Field',
                                        }]
                                    })(
                                        <AutoComplete
                                            autoComplete={'none'}
                                            onSelect={this.onSelect}
                                            placeholder={'Delivery Carrier'}

                                        >
                                            {carriersData.map((val, item) => (
                                                <Option value={val}>
                                                    {val}
                                                </Option>
                                            ))}
                                        </AutoComplete>
                                    )}
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <GetAllFormFields apiurl={API_URL}
                                                      inputSchema={addressDetails1}
                                                      formItemLayout={formItemLayout}
                                                      getFieldDecorator={getFieldDecorator}/>


                                </Col>
                            </Row>


                            <Row>
                                <Col span={12}>

                                    {_.map(this.state.trackingData, (k, index) => {
                                        return <>
                                            <Form.Item
                                                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                                label={index === 0 ? 'Courier Name' : ''}
                                                required={false}
                                                key={index}
                                            >
                                                <Input placeholder="Courier Name" value={k.courierName}
                                                       style={{width: '250px'}}
                                                       onChange={(val) => {
                                                           let tt = _.clone(this.state.trackingData)
                                                           tt[index].courierName = val.target.value

                                                           this.setState({trackingData: tt}, () => {
                                                               this.checkNotification()

                                                           })
                                                       }}/>
                                            </Form.Item>

                                            <Form.Item
                                                {...(index === 0 ? formItemLayout : formItemLayoutWithOutLabel)}
                                                label={index === 0 ? 'Tracking Number' : ''}
                                                required={false}
                                                key={index + 1}
                                            >
                                                <Input value={k.trackingNumber} placeholder="Tracking Number"
                                                       onChange={(val) => {

                                                           let tt = _.clone(this.state.trackingData)
                                                           tt[index].trackingNumber = val.target.value
                                                           this.setState({trackingData: tt}, () => {
                                                               this.checkNotification()
                                                           })
                                                       }}
                                                       style={{width: '230px', marginRight: '4px'}}/>
                                                {this.state.trackingData.length > 1 ? (
                                                    <Icon
                                                        className="dynamic-delete-button"
                                                        type="minus-circle-o"
                                                        onClick={() => {
                                                            this.remove(index)
                                                        }}
                                                    />
                                                ) : null}

                                            </Form.Item>

                                        </>
                                    })}

                                    <Button type="dashed" onClick={() => {
                                        let tt = _.clone(this.state.trackingData)
                                        tt.push({trackingNumber: '', courierName: ''})
                                        this.setState({trackingData: tt}, () => {
                                            this.checkNotification()
                                        })

                                    }}>
                                        <Icon type="plus"/> Add Tracking Details
                                    </Button>
                                </Col>
                            </Row>


                            <Row>
                                <Col>
                                    <div className={styles.notification} style={{textAlign: 'center'}}>
                                        <Checkbox
                                            disabled={!this.state.trackingNumberExists}
                                            defaultChecked={false} onChange={(e) => {
                                            this.setSocial('mail', e.target.checked)
                                        }}><Icon style={{color: "#E8290B"}} type='mail'/> Mail</Checkbox>
                                        <Checkbox
                                            disabled={!this.state.trackingNumberExists}
                                            defaultChecked={false} onChange={(e) => {
                                            this.setSocial('whatsapp', e.target.checked)
                                        }}><Icon style={{color: "#45CE30"}} type='message'/> Whatsapp</Checkbox>
                                        <Checkbox
                                            disabled={!this.state.trackingNumberExists}
                                            defaultChecked={false} onChange={(e) => {
                                            this.setSocial('message', e.target.checked)
                                        }}><Icon style={{color: "#0A79DF"}} type='message'/> Text</Checkbox>
                                    </div>
                                </Col>
                            </Row>

                            <Form.Item style={{marginTop: 32, textAlign: 'center'}}>

                                <Button size="large" type='primary'
                                        loading={this.props.loading}
                                        htmlType='submit'
                                        onClick={() => {
                                            this.setState({
                                                sendNotification: false
                                            });
                                        }}
                                        style={{width: '15%', marginLeft: '1em'}}>{this.state.id ? `Update` : `Save`}
                                </Button>
                                <Button size="large" type='dashed'
                                        loading={this.state.sendLoading}
                                        htmlType='submit'
                                        disabled={!this.state.trackingNumberExists}
                                        onClick={() => {
                                            this.setState({
                                                sendNotification: true
                                            });
                                        }}
                                        style={{width: '15%', marginLeft: '1em'}}>
                                    Save and Send
                                </Button>

                            </Form.Item>


                        </Card>


                    </Form>
                </Spin>


            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    currentUser: global.currentUser

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddDispatch)
