import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'

import {
    Card,
    Table,
    Select,
    DatePicker,
    Modal,
    Icon,
    Spin,
    Row,
    Col,
    Tabs,
    Collapse,
    Avatar,
    Tooltip,
    Divider,
    Popover,
    Button,
    Form,
    Tag,
    Alert,
    Descriptions,
    notification,
    Drawer, AutoComplete, Popconfirm
} from 'antd'
import 'react-quill/dist/quill.snow.css'

import Request, {API_URL, getStatusColor} from '../../../request'
import {connect} from 'react-redux'
import moment from 'moment'
import ViewComments from '../../complaint/all/viewComments'
import {FilesLogs, ProductsLogs, DateLogs} from './viewLogs'

const {TabPane} = Tabs

@Form.create()
class OrderDetails extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            loader: false,
            videos: [],
            photos: [],
            tabKey: '0'

        }
    }


    componentDidMount() {

        let searchParams = new URLSearchParams(this.props.search)
        let orderId = searchParams.get('id')
        if (orderId) {
            this.setState({loader: true})
            Request.orderDispatchList({_id: orderId}).then(({data, error, message}) => {
                if (!error) {
                    this.setState({
                        id: orderId,
                        loader: false,
                        orderData: data && data[0],
                        photos: data && data[0].photos,
                        videos: data && data[0].videos,
                        statusLogsData: data && data[0].statusLogs,
                        products: data && data[0].products,
                        dateObject: {
                            orderAcceptedDate: data && data[0].orderAcceptedDate,
                            orderPreparingDate: data && data[0].orderPreparingDate,
                            orderPreparedDate: data && data[0].orderPreparedDate,
                            qualityCheckDate: data && data[0].qualityCheckDate,
                            qualityApprovedDate: data && data[0].qualityApprovedDate,
                            readyToDispatchedDate: data && data[0].readyToDispatchedDate,
                            dispatched: data && data[0].dispatched
                        }

                    })


                } else {
                    this.setState({loader: false})

                    notification.error({
                        message: 'Error Getting Data'
                    })
                }
            })
        } else {
            this.setState({loader: false})

        }
    }


    render() {
        const {getTodos, currentUser} = this.props
        const {orderData, photos, videos, statusLogsData, drawerVisible, products, dateObject} = this.state

        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props


        return (<Spin size="large" spinning={this.state.loader}>
                <PageHeaderWrapper title={'Order Details'}>

                    <Card>
                        <Row>
                            <Col>
                                <Descriptions column={4}>
                                    <Descriptions.Item
                                        label={<b>Order Id</b>}>{orderData && orderData.id}</Descriptions.Item>
                                    <Descriptions.Item
                                        label={<b>Order
                                            Date</b>}>{orderData && moment(orderData.orderDate).format('ll')}</Descriptions.Item>
                                    <Descriptions.Item
                                        label={<b>Status</b>}><Tag
                                        color={getStatusColor(orderData && orderData.status)}>{orderData && orderData.status}</Tag>

                                        <a hidden={orderData && orderData.status != 'Ready To Dispatched'}
                                           href={`/orderDispatch/dispatchItem?id=${this.state.id}`} target={'_blank'}>
                                            <Icon type="printer"/>
                                        </a>
                                    </Descriptions.Item>
                                    <Descriptions.Item
                                        label={<b>Lead Name</b>}>{orderData && orderData.leadName}</Descriptions.Item>
                                    <Descriptions.Item
                                        label={<b>Invoice
                                            Number</b>}>{orderData && orderData.invoiceNumber}</Descriptions.Item>
                                    <Descriptions.Item
                                        label={<b>GST</b>}>{orderData && orderData.gstNumber}</Descriptions.Item>
                                    <Descriptions.Item
                                        label={<b>Carrier</b>}>{orderData && orderData.carrier}</Descriptions.Item>

                                    {orderData && orderData.estimatedDate ?
                                        <Descriptions.Item
                                            label={<b>Estimated
                                                Date</b>}>{moment(orderData.estimatedDate).format('ll')}</Descriptions.Item>
                                        : ''}
                                    {orderData && orderData.dispatchNumber ?
                                        <Descriptions.Item
                                            label={<b>Dispatch Number</b>}>{orderData.dispatchNumber}</Descriptions.Item>
                                        : ''}
                                </Descriptions>


                            </Col>
                        </Row>
                        <Divider/>

                        <Row>

                            <h2 style={{backgroundColor: '#8080800f'}}>Contact Details</h2>
                            <Col>
                                <Descriptions>
                                    <Descriptions.Item
                                        label={<b>Contact
                                            Name</b>}>{orderData && orderData.contactName}</Descriptions.Item>
                                    <Descriptions.Item
                                        label={<b>Mobile</b>}>{orderData && orderData.mobile}</Descriptions.Item>
                                    <Descriptions.Item
                                        label={<b>Email</b>}>{orderData && orderData.email}</Descriptions.Item>
                                    <Descriptions.Item
                                        label={<b>State</b>}>{orderData && orderData.state}</Descriptions.Item>
                                    <Descriptions.Item
                                        label={<b>Pincode</b>}>{orderData && orderData.pincode}</Descriptions.Item>
                                </Descriptions>


                            </Col>
                        </Row>

                        <Divider/>

                        <Row>
                            <Col>
                                <Card style={{marginTop: '30px'}}>
                                    <Tabs
                                        type="line"
                                        activeKey={this.state.tabKey}
                                        defaultActiveKey={'0'}
                                        onTabClick={(key) => {
                                            this.setState({tabKey: key})
                                        }}
                                    >
                                        <TabPane tab={'Products'} key={0}>
                                            <ProductsLogs productsData={products}/>

                                        </TabPane>
                                        <TabPane tab={'Status Logs'} key={1}>
                                            <ViewComments statusLogsData={statusLogsData} isCommentDrawer={false}/>

                                        </TabPane>
                                        <TabPane tab={'Video'} key={2}>
                                            <FilesLogs videos={videos}/>
                                        </TabPane>
                                        <TabPane tab={'Photos'} key={3}>
                                            <FilesLogs photos={photos}/>
                                        </TabPane>
                                        <TabPane tab={'Date Logs'} key={4}>
                                            <DateLogs orderDate={dateObject}/>
                                        </TabPane>
                                    </Tabs>
                                </Card>
                            </Col>
                        </Row>


                    </Card>


                </PageHeaderWrapper>

            </Spin>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    currentUser: global.currentUser

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderDetails)
