import React, { PureComponent } from 'react'
// import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import PageHeaderWrapper from '../PageHeaderWrapper'
import {
  Form,
  Input,
  Spin,
  Button,
  Card,
  List,
  Row,
  Col,
  Checkbox,
  Upload,
  Icon,
  Select,
  AutoComplete,
  Tag,
  Radio,
  Descriptions,
} from 'antd'
import _ from 'lodash'
import moment from 'moment'

import { getUrlPushWrapper } from '../../routes'
import { API_URL } from '../../request'

import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
// import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../request'
import { connect } from 'react-redux'
import { createMatchSelector, goBack } from 'connected-react-router'

import styles from './styles.less'

const { Option } = Select

class OrderStats extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      total: null,
      newData: ['one'],
      prevDisableDate: props.disableDate,
    }
  }
  apiFunction = async () => {
    this.setState({ total: null, newData: ['one'] })
    let params = {
      startRange: this.props.startRange,
      lastRange: this.props.lastRange,
      disableDate: this.props.disableDate,
    }

    const { data, error } = await Request.getOrderDispatchCount({ ...params })
    if (!error) {
      if (data.length > 0) {
        this.setState({ total: data[0], newData: data })
      }
    }
  }
  async componentDidMount() {
    this.apiFunction()
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevProps.disableDate !== this.props.disableDate) {
      this.apiFunction()
      this.setState({ prevDisableDate: this.props.disableDate })
    }
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.key !== this.props.key) {
      this.componentDidMount()
    }
  }

  render() {
    const { dispatch, startRange, lastRange } = this.props
    const { total, data } = this.state

    return (
      <>
        <Row style={{ marginBottom: '15px' }} gutter={16}>
          <Col>
            <Card
              title={
                <span style={{ fontSize: '16px' }}>
                  <img
                    src="/test/complaint-count.svg"
                    alt="Icon"
                    style={{
                      height: '15px',
                      marginRight: '5px',
                      marginBottom: '4px',
                    }}
                  />
                  Order Counts
                </span>
              }
              bordered={false}
              headStyle={{
                // backgroundColor: '#03a9f4',
                // textAlign: 'center',
                // color: '',
                fontSize: '15px',
                fontWeight: '600',
                borderBottom: 'none',
                marginBottom: '-10px',
                marginLeft: '-3px',
              }}
              bodyStyle={{
                padding: '7px 20px',
                // maxHeight: '236px',
                // overflowY: 'scroll',
              }}
              style={{
                // marginRight: '10px',
                // height: '365x',
                padding: '10px !important',
                marginTop: '15px',
              }}>
              <List
                className={styles.scroll}
                size="small"
                bordered={false}
                dataSource={this.state.newData || []}
                renderItem={(val) => (
                  <>
                    <List.Item>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          width: '100%',
                        }}>
                        <div
                          style={{
                            width: '60%',
                            textAlign: 'start',
                            color: '#686B75',
                            fontWeight: '400',
                          }}>
                          Total Orders
                        </div>
                        <div
                          style={{
                            width: '40%',
                            textAlign: 'end',
                            marginRight: '10px',
                          }}>
                          <a
                            target={'_blank'}
                            href={`/orderDispatch/list`}
                            //             onClick={() => {
                            //   dispatch(
                            //     getUrlPushWrapper('orderDispatch.list', {
                            //       type: 'Order Accepted'
                            //     })
                            //   )
                            // }}
                            style={{
                              width: '40%',
                              textAlign: 'end',
                              color: '#2B2539',
                              fontWeight: '600',
                            }}>
                            {total !== null ? total.totalCount : 0}
                          </a>
                        </div>
                      </div>
                    </List.Item>
                    <List.Item>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          width: '100%',
                        }}>
                        <div
                          style={{
                            width: '60%',
                            textAlign: 'start',
                            color: '#686B75',
                            fontWeight: '400',
                          }}>
                          Order Received
                        </div>
                        <div
                          style={{
                            width: '40%',
                            textAlign: 'end',
                            marginRight: '10px',
                          }}>
                          <a
                            target={'_blank'}
                            href={`/orderDispatch/list?type=Order Received`}
                            //             onClick={() => {
                            //   dispatch(
                            //     getUrlPushWrapper('orderDispatch.list', {
                            //       type: 'Order Received'
                            //     })
                            //   )
                            // }}
                            style={{
                              width: '40%',
                              textAlign: 'end',
                              color: '#2B2539',
                              fontWeight: '600',
                            }}>
                            {total !== null ? total.orderReceived : 0}
                          </a>
                        </div>
                      </div>
                    </List.Item>
                    <List.Item>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          width: '100%',
                        }}>
                        <div
                          style={{
                            width: '60%',
                            textAlign: 'start',
                            color: '#686B75',
                            fontWeight: '400',
                          }}>
                          Order Accepted
                        </div>
                        <div
                          style={{
                            width: '40%',
                            textAlign: 'end',
                            marginRight: '10px',
                          }}>
                          <a
                            target={'_blank'}
                            href={`/orderDispatch/list?type=Order Accepted`}
                            //             onClick={() => {
                            //   dispatch(
                            //     getUrlPushWrapper('orderDispatch.list', {
                            //       type: 'Order Accepted'
                            //     })
                            //   )
                            // }}
                            style={{
                              width: '40%',
                              textAlign: 'end',
                              color: '#2B2539',
                              fontWeight: '600',
                            }}>
                            {total !== null ? total.orderAccepted : 0}
                          </a>
                        </div>
                      </div>
                    </List.Item>
                    <List.Item>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          width: '100%',
                        }}>
                        <div
                          style={{
                            width: '60%',
                            textAlign: 'start',
                            color: '#686B75',
                            fontWeight: '400',
                          }}>
                          Order Preparing
                        </div>
                        <div
                          style={{
                            width: '40%',
                            textAlign: 'end',
                            marginRight: '10px',

                            marginRight: '10px',
                          }}>
                          <a
                            target={'_blank'}
                            href={`/orderDispatch/list?type=Order Preparing`}
                            //             onClick={() => {
                            //   dispatch(
                            //     getUrlPushWrapper('orderDispatch.list', {
                            //       type: 'Order Preparing'
                            //     })
                            //   )
                            // }}
                            style={{
                              width: '40%',
                              textAlign: 'end',
                              color: '#2B2539',
                              fontWeight: '600',
                            }}>
                            {total !== null ? total.orderPreparing : 0}
                          </a>
                        </div>
                      </div>
                    </List.Item>
                    <List.Item>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          width: '100%',
                        }}>
                        <div
                          style={{
                            width: '60%',
                            textAlign: 'start',
                            color: '#686B75',
                            fontWeight: '400',
                          }}>
                          Order Prepared
                        </div>
                        <div
                          style={{
                            width: '40%',
                            textAlign: 'end',
                            marginRight: '10px',
                          }}>
                          <a
                            target={'_blank'}
                            href={`/orderDispatch/list?type=Order Prepared`}
                            //             onClick={() => {
                            //   dispatch(
                            //     getUrlPushWrapper('orderDispatch.list', {
                            //       type: 'Order Prepared'
                            //     })
                            //   )
                            // }}
                            style={{
                              width: '40%',
                              textAlign: 'end',
                              color: '#2B2539',
                              fontWeight: '600',
                            }}>
                            {total !== null ? total.orderPrepared : 0}
                          </a>
                        </div>
                      </div>
                    </List.Item>
                    <List.Item>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          width: '100%',
                        }}>
                        <div
                          style={{
                            width: '60%',
                            textAlign: 'start',
                            color: '#686B75',
                            fontWeight: '400',
                          }}>
                          Quality Check
                        </div>
                        <div
                          style={{
                            width: '40%',
                            textAlign: 'end',
                            marginRight: '10px',
                          }}>
                          <a
                            target={'_blank'}
                            href={`/orderDispatch/list?type=Quality Check`}
                            //             onClick={() => {
                            //   dispatch(
                            //     getUrlPushWrapper('orderDispatch.list', {
                            //       type: 'Quality Check'
                            //     })
                            //   )
                            // }}
                            style={{
                              width: '40%',
                              textAlign: 'end',
                              color: '#2B2539',
                              fontWeight: '600',
                            }}>
                            {total !== null ? total.qualityCheck : 0}
                          </a>
                        </div>
                      </div>
                    </List.Item>
                    <List.Item>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          width: '100%',
                        }}>
                        <div
                          style={{
                            width: '60%',
                            textAlign: 'start',
                            color: '#686B75',
                            fontWeight: '400',
                          }}>
                          Quality Approved
                        </div>
                        <div
                          style={{
                            width: '40%',
                            textAlign: 'end',
                            marginRight: '10px',
                          }}>
                          <a
                            target={'_blank'}
                            href={`/orderDispatch/list?type=Quality Approved`}
                            //             onClick={() => {
                            //   dispatch(
                            //     getUrlPushWrapper('orderDispatch.list', {
                            //       type: 'Quality Approved'
                            //     })
                            //   )
                            // }}
                            style={{
                              width: '40%',
                              textAlign: 'end',
                              color: '#2B2539',
                              fontWeight: '600',
                            }}>
                            {total !== null ? total.qualityApproved : 0}
                          </a>
                        </div>
                      </div>
                    </List.Item>
                    <List.Item>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          width: '100%',
                        }}>
                        <div
                          style={{
                            width: '60%',
                            textAlign: 'start',
                            color: '#686B75',
                            fontWeight: '400',
                          }}>
                          Ready To Dispatched
                        </div>
                        <div
                          style={{
                            width: '40%',
                            textAlign: 'end',
                            marginRight: '10px',
                          }}>
                          <a
                            target={'_blank'}
                            href={`/orderDispatch/list?type=Ready To Dispatched`}
                            //             onClick={() => {
                            //   dispatch(
                            //     getUrlPushWrapper('orderDispatch.list', {
                            //       type: 'Ready To Dispatched'
                            //     })
                            //   )
                            // }}
                            style={{
                              width: '40%',
                              textAlign: 'end',
                              color: '#2B2539',
                              fontWeight: '600',
                            }}>
                            {total !== null ? total.readyToDispatched : 0}
                          </a>
                        </div>
                      </div>
                    </List.Item>
                    <List.Item>
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'space-around',
                          width: '100%',
                        }}>
                        <div
                          style={{
                            width: '60%',
                            textAlign: 'start',
                            color: '#686B75',
                            fontWeight: '400',
                          }}>
                          Dispatched
                        </div>
                        <div
                          style={{
                            width: '40%',
                            textAlign: 'end',
                            marginRight: '10px',
                          }}>
                          <a
                            target={'_blank'}
                            href={`/orderDispatch/list?type=Dispatched`}
                            //             onClick={() => {
                            //   dispatch(
                            //     getUrlPushWrapper('orderDispatch.list', {
                            //       type: 'Dispatched'
                            //     })
                            //   )
                            // }}
                            style={{
                              width: '40%',
                              textAlign: 'end',
                              color: '#2B2539',
                              fontWeight: '600',
                            }}>
                            {total !== null ? total.dispatched : 0}
                          </a>
                        </div>
                      </div>
                    </List.Item>
                  </>
                )}></List>
            </Card>
          </Col>
        </Row>
      </>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderStats)
