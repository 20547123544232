import React, {Component} from 'react'
import {
    Card,
    Col,
    Row,
    Icon,
    Table,
    List,
    Radio,
    Descriptions,
    Tooltip,
    Select,
    Spin,
    DatePicker,
    Badge,
    Input,
    Button
} from 'antd'
import styles from './styles.less'
import moment from 'moment'
import _ from 'lodash'

import Highlighter from 'react-highlight-words'

import Request, {API_URL} from '../../../request'
import {getUrlPushWrapper} from '../../../routes'
import {connect} from "react-redux";
import FormatNumber from "indian-number-format";

const {Option} = Select

class StateWiseLeads extends Component {
    loadApisRequest = () => {
        this.setState({startLoading: true})
        Request.stateWiseLeads({
            owner: this.state.owner, leadDate: {$gte: this.state.startRange, $lte: this.state.lastRange},
        }).then(({data}) => {

            data = _.reject(data, (ii) => {
                return ii._id === null
            })
            this.setState({
                activitiesCountDataByRO: data,
                startLoading: false
            })
        })

    }

    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({setSelectedKeys, selectedKeys, confirm, clearFilters}) => (
            <div style={{padding: 8}}>
                <Input
                    ref={node => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{width: 188, marginBottom: 8, display: 'block'}}
                />
                <Button
                    type="primary"
                    onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    icon="search"
                    size="small"
                    style={{width: 90, marginRight: 8}}
                >
                    Search
                </Button>
                <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{width: 90}}>
                    Reset
                </Button>
            </div>
        ),
        filterIcon: filtered => (
            <Icon type="search" style={{color: filtered ? '#1890ff' : undefined}}/>
        ),
        onFilter: (value, record) =>
            record[dataIndex]
                .toString()
                .toLowerCase()
                .includes(value.toLowerCase()),
        onFilterDropdownVisibleChange: visible => {
            if (visible) {
                setTimeout(() => this.searchInput.select());
            }
        },
        render: text =>
            this.state.searchedColumn === dataIndex ? (
                <Highlighter
                    highlightStyle={{backgroundColor: '#ffc069', padding: 0}}
                    searchWords={[this.state.searchText]}
                    autoEscape
                    textToHighlight={text.toString()}
                />
            ) : (
                text
            ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
    };

    handleReset = clearFilters => {
        clearFilters();
        this.setState({searchText: ''});
    };


    handleCancel = e => {
        this.setState({
            visible: false,
        });
    };

    constructor(props) {
        super(props)
        this.state = {
            startRange: moment().startOf('day').toDate(),
            lastRange: moment().endOf('day').toDate(),
        }
    }

    async componentDidMount() {
        this.loadApisRequest()
        let {data: userList} = await Request.getAllROList({customQuery: {userType: {$eq: "RO"}}});
        this.setState({userList: userList})
    }


    render() {

        let {userList, startLoading} = this.state
        const {dispatch} = this.props
        const handleChangeDebut = (range) => {
            if (!range.length) {
                this.setState({
                    startRange: moment().startOf('month').toDate(),
                    lastRange: moment().endOf('month').toDate(),
                })
            }
            else {
                const valueOfInput1 = moment(range[0]).startOf('day').format();
                const valueOfInput2 = moment(range[1]).endOf('day').format();
                this.setState({...this.state, startRange: valueOfInput1, lastRange: valueOfInput2});
            }
            setTimeout(() => {
                this.loadApisRequest()

            }, 500);

        }

        const columns = [
            {
                title: 'State',
                dataIndex: 'stateName',
                key: 'stateName',
                sortDirections: ['descend', 'ascend'],
                sorter: (a, b) => a.stateName.length - b.stateName.length,
                render: (val) => {
                    return val
                },
                ...this.getColumnSearchProps('stateName'),

            },
            {
                title: 'Open',
                dataIndex: 'openLeads',
                key: 'openLeads',
                sortDirections: ['descend', 'ascend'],
                sorter: (a, b) => a.openLeads - b.openLeads,
                render: (val, record) => {
                    if (val > 0) {
                        let href1 = `/states/${record.stateName}/${moment(this.state.lastRange).format("YYYY-MM-DD HH:mm")}/${moment(this.state.startRange).format("YYYY-MM-DD HH:mm")}/Open`
                        let href = `/officerStates/${this.state.owner}/${record.stateName}/${moment(this.state.lastRange).format("YYYY-MM-DD HH:mm")}/${moment(this.state.startRange).format("YYYY-MM-DD HH:mm")}/Open`
                        return <a target={'_blank'}
                                  href={this.state.owner ? href : href1}
                        > <Badge style={{backgroundColor: '#1287A5'}} count={val}/></a>
                    } else {
                        return <Badge style={{backgroundColor: '#D3D3D3'}} count={val} showZero/>

                    }
                }
            },
            {
                title: 'Won',
                dataIndex: 'wonLeads',
                key: 'wonLeads',
                sortDirections: ['descend', 'ascend'],
                sorter: (a, b) => a.wonLeads - b.wonLeads,
                render: (val, record) => {
                    if (val > 0) {
                        let href1 = `/states/${record.stateName}/${moment(this.state.lastRange).format("YYYY-MM-DD HH:mm")}/${moment(this.state.startRange).format("YYYY-MM-DD HH:mm")}/won`
                        let href = `/officerStates/${this.state.owner}/${record.stateName}/${moment(this.state.lastRange).format("YYYY-MM-DD HH:mm")}/${moment(this.state.startRange).format("YYYY-MM-DD HH:mm")}/won`

                        return <a target={'_blank'}
                                  href={this.state.owner ? href : href1}
                        > <Badge style={{backgroundColor: '#43BE31'}} count={val}/></a>
                    } else {
                        return <Badge style={{backgroundColor: '#D3D3D3'}} count={val} showZero/>
                    }
                }
            },
            {
                title: 'Lost',
                dataIndex: 'lostLeads',
                key: 'lostLeads',
                sortDirections: ['descend', 'ascend'],
                sorter: (a, b) => a.lostLeads - b.lostLeads,
                render: (val, record) => {
                    let href1 = `/states/${record.stateName}/${moment(this.state.lastRange).format("YYYY-MM-DD HH:mm")}/${moment(this.state.startRange).format("YYYY-MM-DD HH:mm")}/Lost`
                    let href = `/officerStates/${this.state.owner}/${record.stateName}/${moment(this.state.lastRange).format("YYYY-MM-DD HH:mm")}/${moment(this.state.startRange).format("YYYY-MM-DD HH:mm")}/Lost`

                    if (val > 0) {
                        return <a target={'_blank'}
                                  href={this.state.owner ? href : href1}
                        > <Badge style={{backgroundColor: 'red'}} count={val}/></a>
                    } else {
                        return <Badge style={{backgroundColor: '#D3D3D3'}} count={val} showZero/>
                    }
                }


            }, {
                title: 'All',
                key: 'all',
                sortDirections: ['descend', 'ascend'],
                sorter: (a, b) => (a.openLeads + a.wonLeads + a.lostLeads) - (b.openLeads + b.wonLeads + b.lostLeads),
                render: (val, record) => {
                    let href1 = `/states/${record.stateName}/${moment(this.state.lastRange).format("YYYY-MM-DD HH:mm")}/${moment(this.state.startRange).format("YYYY-MM-DD HH:mm")}/all`
                    let href = `/officerStates/${this.state.owner}/${record.stateName}/${moment(this.state.lastRange).format("YYYY-MM-DD HH:mm")}/${moment(this.state.startRange).format("YYYY-MM-DD HH:mm")}/all`
                    let totalCount = record.openLeads + record.wonLeads + record.lostLeads


                    if (totalCount > 0) {
                        return <a target={'_blank'}
                                //  href={this.state.owner ? href : href1}
                        > <Badge showZero style={{backgroundColor: 'black'}}
                                 count={totalCount}/></a>
                    } else {
                        return <Badge style={{backgroundColor: '#D3D3D3'}} count={totalCount} showZero/>
                    }
                }

            },
        ];

        return (
            <div>
                <Card>
                    <Row>
                        <Col span={8}>
                            <h4
                                style={{display: 'inline-block', marginRight: '10px'}}>Select Relationship Officer</h4>
                            <Select
                                showSearch
                                allowClear
                                // mode={'multiple'}
                                optionFilterProp="children"
                                style={{
                                    width: 225

                                }}
                                placeholder="Select Relationship Officer"
                                onChange={ids => {
                                    this.setState({owner: ids}, () => {
                                        this.loadApisRequest()
                                    })
                                }}
                            >
                                {userList &&
                                userList.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val._id}>
                                            {val.name} ({val.mobile})
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Col>


                        <Col span={8}>
                            <h4 style={{display: 'inline-block', marginRight: '10px'}}>Select Date Range</h4>
                            <DatePicker.RangePicker
                                style={{width: "100%"}}
                                format="DD-MM-YYYY"
                                value={[moment(this.state.startRange), moment(this.state.lastRange)]}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={(value, dateString) => handleChangeDebut(value)}
                            />
                        </Col>
                    </Row>
                </Card>

                <Spin spinning={startLoading}>

                    <div className="site-card-wrapper">

                        <Card> <Table size={'small'} dataSource={this.state.activitiesCountDataByRO} columns={columns}
                                      bordered={true}
                                      pagination={false}/></Card>


                    </div>
                </Spin>
            </div>
        )
    }

}

const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default connect(
    mapDispatchToProps
)(StateWiseLeads)

