import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp, FormUtils as GetAllFormFields} from 'sz-react-utils'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Switch,
    Input, Button, Icon,
    Popover, Checkbox,
    Form,
    Drawer, Row, Col,
    Tag, DatePicker, Select
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import {connect} from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import {getUrlPushWrapper} from '../../../routes'
import {hideLoader, showLoader} from '../../../modules/actions'
import {apiUrl} from "../../../settings";
import {
    DealActivityModal, EmailActivityModal, FollowUpActivityModal, LostModal, NoteActivityModal, TextActivityModal,
    WhatsAppActivityModal,
    WinModal
} from "../../lead/all/helpers";
import ReactPlayer from 'react-player/lazy'
import S from "string";

const {Option} = Select


@Form.create()
class SingleROList extends Component {

    apiRequest = (params) => {
        if (params.page) {
            this.state.currentPage = params.page
        }

        return new Promise(async (resolve) => {
            this.setState({startLoading: true})
            if (this.state.ownerIds) {
                params.officer = this.state.ownerIds
            }
            if (this.state.showAdminActivity) {
                params.showAdminActivity = true
            }
            else params.showAdminActivity = false;

            if (this.state.showWelcomeMsg) {
                params.showWelcomeMsg = true
            }
            else params.showWelcomeMsg = false;

            if (!params.sortField) {
                params.sortOrder = 'desc'
            }
            params.sortField = 'activities.createdAt'

            let data = await Request.getSingleROActivity({
                ...params,
                dateFilter: {$gte: this.state.startRange, $lte: this.state.lastRange},

            })
            resolve(data)
        })
    }
    deleteActivity = async data => {
        this.setState({loading: true})
        let x = await Request.deleteActivity(data)
        this.setState({loading: false})

        this.table.current.reload()
        if (!x.error) {
            notification.success({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })

        }
        else {
            notification.error({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })
        }


    }


    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            visible: false, userType: ['Admin', 'RO'], showPlayer: [],
            startRange: moment().startOf('day').toDate(),
            lastRange: moment().endOf('day').toDate(),
            noteModal: false,
            emailModal: false,
            currentPage: 1,
            followUpModal: false,
            dealActivityModal: false,
            whatsAppModal: false,
            textActivityModal: false,
            showWelcomeMsg: false,
            showAdminActivity: false,
        }

    }

    handleVisibleChange = (key, value) => {
        let newData = _.clone(this.state.showPlayer)
        newData[key] = value
        this.setState({showPlayer: newData})
    }

    async componentDidMount() {
        let {data: userList} = await Request.getAllROList({customQuery: {userType: {$eq: "RO"}}});
        this.setState({userList: userList})
    }

    render() {
        const {dispatch} = this.props

        let {userType, userList, showPlayer, currentPage} = this.state;
        const onWelcomeFilterChange = (e) => {
            if (e.target.checked) {
                this.state.showWelcomeMsg = true;
            }
            else this.state.showWelcomeMsg = false;
            this.table.current.reload();
        }
        const onAdminFilterChange = (e) => {
            if (e.target.checked) {
                this.state.showAdminActivity = true;
            }
            else this.state.showAdminActivity = false;
            this.table.current.reload();
        }
        const columns = [
            {
                title: 'S.No',
                dataIndex: 'sno',
                width: 50,
                key: 'sno',
                fixed: 'left',
                render: (value, item, index) => (currentPage - 1) * 10 + index + 1
            },

            {

                title: 'Title',
                key: 'title',
                dataIndex: 'title',
                searchTextName: 'title',
                render: (val, record) => {
                    return <a href={`/lead/detail?id=${record && record.leadId}`}
                              target={'_blank'}>{record && record.title}</a>

                }

            }
            , {
                title: 'Message',
                dataIndex: 'message',
                searchTextName: 'message',
                key: 'message',

                render: (val, activity, key) => {
                    let newKey = `${activity._id}`
                    if (activity.type === "value" || activity.type === "status" || activity.type === "stage" || activity.type === "closeDate")
                        return <a>{activity.message}</a>
                    else if (activity.type === "notes")
                        return <a>"{S(activity.noteMessage).stripTags().s}"</a>
                    else if (activity.type === "email")
                        return <a onClick={() => {
                            this.setState({emailModal: true, emailActivity: activity});
                        }}>{activity.message}</a>
                    else if (activity.type === "whatsApp")
                        return <a onClick={() => {
                            this.setState({whatsAppModal: true, whatsAppActivity: activity});
                        }}>{activity.message}</a>
                    else if (activity.type === "file")
                        return <div>{activity.message} by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format("lll")}</b><a
                            style={{marginLeft: "10px"}} href={apiUrl + `${activity.fileUrl}`} target="_blank">
                            <mark>{activity.fileName}</mark>
                        </a></div>
                    else if (activity.type === "followUp")
                        return <a onClick={() => {
                            this.setState({followUpModal: true, followupActivity: activity});
                        }}>{activity.message}</a>

                    else if (activity.type === "message")
                        return <a onClick={() => {
                            this.setState({textActivityModal: true, textActivity: activity});
                        }}>{activity.message}</a>

                    else if (activity.type === "activity")
                        return <a onClick={() => {
                            this.setState({dealActivityModal: true, dealActivity: activity});
                        }}>{activity.message}</a>
                    else if (activity.type === "recording")
                        return <div>
                            <span>{activity.message}</span> by <b>{activity.user}</b> on <b>{moment(activity.createdAt).format("lll")}</b>
                            <Popover
                                content={<ReactPlayer
                                    url={`${apiUrl}/recordingStream?path=${activity.filePath}&isPath=true`}
                                    controls
                                    height={50}
                                    className={styles.player}
                                    width={400}
                                />}
                                placement={'bottomRight'}
                                trigger="click"
                                destroyTooltipOnHide
                                visible={showPlayer[key]}
                                onVisibleChange={(val) => this.handleVisibleChange(key, val)}
                            >
                                <Icon className={styles.playBtn} size='small' type='play-circle'/>
                            </Popover>
                            <Icon className={styles.playBtn} size='small' type='download' onClick={() => {
                                Request.downloadWithPath(activity.filePath)
                            }}/></div>
                    else if (activity.type === "info")
                        return <a>{activity.message}</a>

                    else if (activity.type === "callLog") {
                        let recordId = activity && activity.callLogId && activity.callLogId.recording
                        return <div><span>{activity && activity.callLogId ? <Tag
                            style={getCallColor(activity.callLogId.callType, activity.callDuration)}>{_.startCase(activity.callLogId.callType)}</Tag> : ''}</span> Call
                            of duration <span>{moment.utc(activity.callDuration * 1000).format('HH:mm:ss')} </span>
                            of <b>{activity.user}</b> on <b>{moment(activity.followUpDate).format("lll")}</b>
                            {recordId && <>
                                <Popover
                                    content={<ReactPlayer
                                        url={`${apiUrl}/recordingStream?id=${recordId}`}
                                        controls
                                        height={50}
                                        className={styles.player}
                                        width={400}
                                    />}
                                    placement={'bottomRight'}
                                    trigger="click"
                                    destroyTooltipOnHide
                                    visible={showPlayer[key]}
                                    onVisibleChange={(val) => this.handleVisibleChange(key, val)}
                                >
                                    <Icon className={styles.playBtn} size='small' type='play-circle'/>
                                </Popover>
                                <Icon className={styles.playBtn} size='small' type='download' onClick={() => {
                                    Request.downloadWithPath(`recordings/${recordId}.mp3`)
                                }}/>

                            </>}</div>
                    }

                }
            },
            {
                title: 'RO',
                dataIndex: 'user',
                key: 'user',
            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                //  filters: userType && userType.map(x => ({ value: x, text: x })),

                filters: [
                    {
                        text: 'Notes',
                        value: 'notes',
                    },
                    {
                        text: 'Email',
                        value: 'email',
                    },
                    {
                        text: 'Recording',
                        value: 'recording',
                    }, {
                        text: 'WhatsApp',
                        value: 'whatsApp',
                    }, {
                        text: 'Call Type',
                        value: 'callLog',
                    }
                ],
                render: (val, record) => {
                    return _.startCase(val)
                }

            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                width: 120,
                fixed: 'right',
                sorter: true,
                render: (item) => {
                    return (<Tooltip title={moment(item).format('lll')}>
                        {moment(item).fromNow()}
                    </Tooltip>)
                }
            }, {
                key: 'actions',
                title: 'Actions', fixed: 'right',
                render: (text, record) => {
                    return <React.Fragment>
                        <Tooltip title="Delete Activity">
                            <Popconfirm
                                title="Are you sure to delete this activity?"
                                onConfirm={() => {
                                    this.deleteActivity({activityId: record._id, _id: record.leadId})
                                }}
                                onCancel={() => {
                                    console.log()
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button
                                    style={{marginRight: '6px'}}

                                    shape="circle"
                                    size="small"
                                    icon="delete"
                                />
                            </Popconfirm>

                        </Tooltip>

                    </React.Fragment>

                }
            }

        ];
        const onCancel = () => {
            this.setState({
                ...this.state, lost: false, win: false
            })
        }
        const getCallColor = (callType, duration) => {
            if (callType === 'outgoing' && duration == 0) {
                return {

                    color: "#FBB917",
                    fontWeight: 'bold'

                }
            }
            else if (callType === 'outgoing' && duration > 0) {
                return {
                    color: "#43BE31",
                    fontWeight: 'bold'

                }
            }
            else if (callType === 'incoming') {
                return {

                    color: "#1287A5",
                    fontWeight: 'bold'

                }
            }
            else {
                return {
                    color: "red",
                    fontWeight: 'bold'

                }
            }
        }
        const cancelActivityModal = () => {
            this.setState({
                noteModal: false,
                emailModal: false,
                followUpModal: false,
                dealActivityModal: false,
                whatsAppModal: false,
                textActivityModal: false,
            })
        }
        const handleChangeDebut = (range) => {
            if (!range.length) {
                this.setState({
                    startRange: moment().startOf('day').toDate(),
                    lastRange: moment().endOf('day').toDate(),
                })
            }
            else {
                const valueOfInput1 = moment(range[0]).startOf('day').format();
                const valueOfInput2 = moment(range[1]).endOf('day').format();
                this.setState({...this.state, startRange: valueOfInput1, lastRange: valueOfInput2});
            }
            setTimeout(() => {
                this.table.current.reload()

            }, 500);

        }

        return (
            <PageHeaderWrapper
                title={'RO Activities'}>

                <Card bordered={true}>

                    <Row>
                        <Col span={8}>
                            <h4
                                style={{display: 'inline-block', marginRight: '10px'}}>Select Relationship Officer</h4>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                style={{
                                    width: 225

                                }}
                                placeholder="Select Relationship Officer"
                                onChange={ids => {
                                    this.setState({ownerIds: ids}, () => {
                                        this.table.current.reload()
                                    })
                                }}
                            >
                                {userList &&
                                userList.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val._id}>
                                            {val.name} ({val.mobile})
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Col>


                        <Col span={8}>
                            <h4 style={{display: 'inline-block', marginRight: '10px'}}>Select Date Range</h4>
                            <DatePicker.RangePicker
                                style={{width: "100%"}}
                                format="DD-MM-YYYY"
                                value={[moment(this.state.startRange), moment(this.state.lastRange)]}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={(value, dateString) => handleChangeDebut(value)}
                            />
                        </Col>
                        <Col span={8} style={{marginTop: '32px', textAlign: 'center'}}>
                            <Checkbox onChange={onAdminFilterChange}>Admin Activities</Checkbox>
                            <Checkbox onChange={onWelcomeFilterChange}>Welcome mail</Checkbox>
                        </Col>
                    </Row>


                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                        //    pagination={{
                        //        ...this.state.pagination,
                        //        defaultPageSize: 10,
                        //        pageSizeOptions: ['10', '25', '50', '100'],
                        //        showSizeChanger: true,
                        //        ...this.props.pagination
                        //    }}
                    />

                </Card>
                {this.state.win && WinModal(
                    {onCancel, state: {...this.state}, form: {...this.props.form}})}
                {this.state.lost && LostModal(
                    {onCancel, state: {...this.state}, form: {...this.props.form}})}
                {this.state.noteModal && NoteActivityModal(
                    {cancelActivityModal, state: {...this.state},})}
                {this.state.emailModal && EmailActivityModal(
                    {cancelActivityModal, state: {...this.state}})}
                {this.state.followUpModal && FollowUpActivityModal(
                    {cancelActivityModal, state: {...this.state},})}
                {this.state.dealActivityModal && DealActivityModal(
                    {cancelActivityModal, state: {...this.state},})}
                {this.state.whatsAppModal && WhatsAppActivityModal(
                    {cancelActivityModal, state: {...this.state},})}
                {this.state.textActivityModal && TextActivityModal(
                    {cancelActivityModal, state: {...this.state},})}

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    loading: global.buttonLoading,

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SingleROList)
