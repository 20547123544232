import React, { Component } from 'react'
import Board from 'react-trello'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils'


import _ from 'lodash'

import moment from 'moment'
import {
  Card,
  Tooltip,
  Row,
  Col,
  Input,
  Button,
  Icon,
  Select,
  Drawer,
  Form,
  AutoComplete,
  Spin,
  Tag,
  notification,
} from 'antd'
import Request, {
  getLeadStageColor,
  getLeadTypeColor,
  getStatusStyle,
} from '../../../request'
import styles from './styles.less'
import { connect } from 'react-redux'
import UpdateLead from '../add/editLead'
import { Typography } from 'antd'
import { stateFilter } from '../../../states'
import { getUrlPushWrapper } from '../../../routes'

const { Paragraph } = Typography

const { Option } = Select

class WonLeads extends Component {
  constructor(props) {
    super(props)
    this.table = React.createRef()
    const { dispatch } = props
    this.state = {
      allContacts: [],
      showBoard: true,
      selectedValues: [],
      showTable: false,
      leadStage: [
        'New (Untouched)',
        'Contacted',
        'Today Followup',
        
      ],
      boardData: { lanes: [] },
      key: Math.random(),
      RoList: [],
      selectedLeadValue: '',
      selectedRowKeys: [],
      selectedRO: undefined,
      currentPage: 1,
      leadsData: [],
      parentLeadId: null,
    }
    this.searchContact = _.debounce(this.handleContactSearch, 1000, true)
    this.searchLead = _.debounce(this.handleDealsSearch, 100, true)
  }

  // apiRequest = (params) => {
  //     let {contactNameId} = this.state

  //     if (params.page) {
  //         this.state.currentPage = params.page
  //     }
  //     if (!params.sortOrder) {
  //         params.sortField = 'createdAt';
  //         params.sortOrder = 'desc';
  //     }
  //     let user = {...JSON.parse(localStorage.getItem('user'))};
  //     return new Promise(async (resolve) => {
  //         let data = await Request.getAllLeads({
  //             ...params,
  //             customQuery: {
  //                 contact: contactNameId ? contactNameId : undefined
  //             },
  //             regExFilters: ['title', 'company', 'contact'],
  //         })
  //         resolve(data)
  //         this.setState({leadData: data, count: data.total})
  //     })
  // }

  mergeLeadsToParent = async () => {
    let childLeadsArray = []
    _.map(this.state.selectedValues, (item) =>
      childLeadsArray.push({ childLeadId: item._id })
    )

    const params = {
      parentLeadId: this.state.parentLeadId,
      childLeadsArray: childLeadsArray,
    }
    const {error} = await Request.mergeLeads(params)
    if(!error) {
      notification.success({message:"Lead Merged Successfully"})
      
      this.setState({
        selectedValues: [
        ],
        selectedLeadValue: '',
        visible:false
      })  
      this.table.current.reload()
      this.getLeadsForAutoComplete()
    }
    else{
      notification.error({message:"Error Merging"})
    }
  }
  apiRequest = (params) => {
    if (params.page) {
      this.state.currentPage = params.page
    }
    let { contactNameId } = this.state

    if (!params.stage || !params.stage.length) {
      params.stage = this.state.leadStage
    }

    if (this.state.selectedLeadStage) {
      params.stage = this.state.selectedLeadStage
    }

    if (!params.sortOrder) {
      params.sortField = 'createdAt'
      params.sortOrder = 'desc'
    }

    let user = { ...JSON.parse(localStorage.getItem('user')) }
    let { status, statusKey, stageKey, stage, stateName } = this.state
    return new Promise(async (resolve) => {
      if (status && statusKey && !stateName) {
        params.status = status
        params[statusKey] = {
          $gte: this.state.gte,
          $lte: this.state.lte,
        }
      }
      if (stage && stageKey) {
        params.stage = stage
        params[stageKey] = {
          $gte: this.state.gte,
          $lte: this.state.lte,
        }
      }

      if (!params.status || !params.status.length) {
        params.status = ['Open', 'won', 'Lost', 'Unqualified']
      }

      if (stateName) {
        params.state = {
          $in: [stateName.toLowerCase(), _.startCase(stateName)],
        }

        params.state =
          stateName && stateName == 'Jammu Kashmir'
            ? 'Jammu & Kashmir'
            : stateName

        params[statusKey] = {
          $gte: this.state.gte,
          $lte: this.state.lte,
        }
        params.status = status
      }

      if (this.state.unassignedLeads) {
        params.officer = { $in: [null] }
      }
      if (params.results && params.page) {
        this.setState({ lockUnlockLead: [] })
      }

      if (user && user.userType == 'Manager' && !this.state.officerId) {
        if (!params.officer || !params.officer.length) {
          params.officer = user && user.roList
        }
      }

      if (this.state.transferLeads) {
        params.isTransferred = true
      }
      params.isChild = false
      this.setState({ filters: { ...params } })
      let data = await Request.getAllLeads({
        ...params,
        customQuery: {
          contact: contactNameId ? contactNameId : undefined,
          officer: this.state.officerId ? this.state.officerId : undefined,
          $or: [
            { leadApprovedByAdmin: { $in: [true] } },
            { leadApprovedByAdmin: { $exists: false } },
          ],
        },
        regExFilters: ['title', 'company', 'contact'],
      })
      _.each(data.data, (item, i) => {
        if (item.isLocked) {
          this.setLockUnlockLead(i, item.isLocked)
        }
      })
      resolve(data)
   
      this.setState({ leadData: data, count: data.total })
    })
  }

  showModal = () => {
    this.setState({
      visible: true,
      btnLoader: false,
    })
  }
  getLeadsForAutoComplete = () => {
    Request.getAllLeads({
      sortField: 'createdAt',
      sortOrder: 'desc',
      results: 10,
      isChild:false
     
    }).then(({ data: leadNames }) => {
      this.setState({ leadsData: leadNames })
    })
  }
  async componentDidMount() {
    let { data: allContacts } = await Request.getAllContacts({ results: 10 })
    this.setState({ allContacts, sale: 'Sales' })
    let { data: userList } = await Request.getAllROList({
      customQuery: { userType: 'RO' },
    })
   this.getLeadsForAutoComplete()
    this.setState({ RoList: userList })
  }

  handleContactSearch = async (test) => {

    // this.setState({​​​​​​​​showSpin: true}​​​​​​​​)
    this.setState({ selectedContact: test })
    if (test == '') {
      this.setState(
        {
          allContacts: this.state.allContacts,
          selectedContact: test,
          contactNameId: null,
        },
        () => this.table.current.reload()
      )
      return false
    }
    let { data, error, message } = await Request.contactSearch({
      text: test,
      type: 'list',
    })
    if (!error) {
      this.setState({ allContacts: data }, () => {
        this.setState({ showSpin: false })
      })
    }
  }

  onContactSelect = (val) => {
    let selectedContact = this.state.allContacts.find((obj) => obj._id === val)
    if (selectedContact) {
      this.setState(
        {
          contactNameId: selectedContact._id,
          selectedContact: `${selectedContact.firstName} (${selectedContact.mobile})`,
        },
        () => {
          this.table.current.reload()
        }
      )
    }
  }
  handleDealsSearch = async (test) => {
    let obj = {}
    this.setState({ showSpin: true })

    if (test === '') {
      return false
    }

    obj.text = test
    obj.isChild = true
    let { data, error, message } = await Request.leadsSearch(obj)
    if (!error) {
      this.setState({ leadsData: data }, () => {
        this.setState({ showSpin: false })
      })
    }
  }

  handleRemove = (value) => {
    const { selectedValues } = this.state
    const newValues = _.filter(
      this.state.selectedValues,
      (val) => val._id !== value
    )
    this.setState({ selectedValues: newValues })
  }

  render() {
    const { submitting, currentUser, dispatch } = this.props
    const { currentPage, leadStatuseArr, allContacts, showSpin, leadsData } =
      this.state
    const columns = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        width: 50,
        key: 'sno',
        fixed: 'left',
        render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
      },

      {
        key: 'actions',
        title: 'View',
        fixed: 'left',
        width: 80,
        render: (text, record) => {
          return (
            <React.Fragment>
              <Tooltip title="View">
                <a
                  href={`/lead/detail?id=${record && record._id}`}
                  target={'_blank'}>
                  <Button
                    style={{ marginLeft: '5px' }}
                    size="small"
                    icon={'eye'}
                    shape={'circle'}
                  />
                </a>
              </Tooltip>
            </React.Fragment>
          )
        },
      },
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        searchTextName: 'id',
        fixed: 'left',
      },
      {
        title: 'Lead',
        dataIndex: 'title',
        key: 'title',
        searchTextName: 'title',
        render: (text, record) => (
          <span>
            <a
              onClick={() => {
                // this.setState({...this.state, cardId: record._id, updatelead: true})
              }}>
              {record.title}
            </a>
          </span>
        ),
      },
      {
        title: 'Merged Leads',
        // dataIndex: 'leadType',
        key: 'mergeLead',
       
        render: (val, record) => {
          
          if(record.childLeadsArray && record.childLeadsArray.length>0 ){
            return(
              <div>{record.childLeadsArray.length}</div>
            )
          }
          else{
            return(
              <div>0</div>
            )
          }
        },
      },
      {
        title: 'Contact',
        dataIndex: 'contact.firstName',
        key: 'contact.firstName',
        render: (text, record) => (
          <span>
            <span>{record.contact ? record.contact.firstName : ''}</span>
            <br />
            <span>{record.contact ? record.contact.mobile : ''}</span>
          </span>
        ),
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        searchTextName: 'company',
        // width: 100
      },

      {
        title: 'Stage',
        dataIndex: 'stage',
        key: 'stage',
        filters: _.map(this.state.leadStage, (ii) => ({ text: ii, value: ii })),
        render: (text, record) => {
          return (
            <div>
              {record && record.stage ? (
                <div
                  className={styles.stageColor}
                  style={{
                    backgroundColor: getLeadStageColor(record.stage),
                  }}>
                  {record.stage}
                </div>
              ) : (
                ''
              )}
            </div>
          )
        },
      },
      
      {
        title: 'Lead Type',
        dataIndex: 'leadType',
        key: 'leadType',
        filters: [
          { text: 'IndiaMART Api', value: 'IndiaMART Api' },
          { text: 'Inbound', value: 'Inbound' },
          { text: 'Aajjo', value: 'aajjo' },
          { text: 'Facebook Ads', value: 'Facebook Ads' },
        ],
        render: (val, record) => {
          return (
            <div>
              {record.leadType ? (
                <div style={getLeadTypeColor(record.leadType)}>
                  {record.leadType}
                </div>
              ) : (
                ''
              )}
            </div>
          )
        },
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        filters: stateFilter,
      },

      {
        title: 'Status',
        key: 'status',
        filters:
          leadStatuseArr && leadStatuseArr.map((x) => ({ value: x, text: x })),
        render: (text, record) => {
          return (
            <div>
              {record.status ? (
                <div style={getStatusStyle(record.status)}>{record.status}</div>
              ) : (
                ''
              )}
            </div>
          )
        },
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        searchDateName: 'createdAt',
        render: (val, record) => {
          return (
            <div>
              <div>{moment(record.createdAt).format('lll')}</div>
            </div>
          )
        },
        // width:100
      },
      {
        title: 'Lead Received Date',
        dataIndex: 'leadReceivedDate',
        key: 'leadReceivedDate',
        searchDateName: 'leadReceivedDate',
        sorter: true,
        fixed: 'right',
        render: (val, record) => {
          return (
            <div>
              <div>
                {' '}
                {record && record.leadReceivedDate
                  ? moment(record.leadReceivedDate).format('lll')
                  : ''}{' '}
              </div>
            </div>
          )
        },
        width: 150,
      },
      {
        key: 'actions',
        title: 'Actions',
        fixed: 'right',
        width: 100,
        render: (val, record, key) => {
          return (
            <React.Fragment>
              <Tooltip title="Merge Lead">
                <Button
                  style={{ marginRight: '6px', marginTop: '6px' }}
                  size="small"
                  shape="circle"
                  onClick={() => {
                
                    this.setState(
                      {
                        selectedValues: [],
                        selectedLeadValue: null,
                        parentLeadId: record._id,
                        singleRecord: record,
                      },
                      () => {
                        this.showModal()
                      }
                    )
                  }}
                  icon="diff"
                />
              </Tooltip>
            </React.Fragment>
          )
        },
      },
    ]
    if (currentUser && currentUser.userType == 'Admin') {
      let dataItem = {
        title: 'Total Call Logs',
        dataIndex: 'callLogs',
        key: 'callLogs',
        render: (val, record) => {
          return (
            <div>
              <small>
                Outgoing:{' '}
                {val &&
                  (val.outgoingCalls || 0) + (val.outgoingRejectCalls || 0)}
              </small>
              <div>
                <small>Incoming: {(val && val.incomingCalls) || 0}</small>
              </div>
              <div>
                <small>Missed: {(val && val.missedCalls) || 0}</small>
              </div>
              <div>
                <small>Attended: {(val && val.totalAttended) || 0}</small>
              </div>
            </div>
          )
        },
        // width: 100
      }
      columns.splice(9, 0, dataItem)
    }

    return (
      <PageHeaderWrapper
        title={
          this.state.count
            ? `All Merge Leads: ${this.state.count}`
            : `All Merge Leads: 0`
        }>
        <div style={{ margin: '-24px', padding: '0px' }}>
          <Card>
            <Row>
              <Col span={8}>
                <AutoComplete
                  value={this.state.selectedContact}
                  notFoundContent={this.state.showSpin && <Spin />}
                  autoComplete={'none'}
                  onSelect={this.onContactSelect}
                  onSearch={this.searchContact}
                  onChange={(val) => {
                    this.setState({ selectedContact: val })
                  }}
                  placeholder={'Search Contact'}
                  style={{ width: 300 }}>
                  {allContacts &&
                    allContacts.map((val, item) => (
                      <Option value={val._id}>
                        <>
                          <div>
                            <b>{`${val.firstName} (${val.mobile})`}</b>
                          </div>
                        </>
                      </Option>
                    ))}
                </AutoComplete>
              </Col>
            </Row>
          </Card>

          <Card className={styles.greyCard}>
            <TableComp
              ref={this.table}
              columns={columns}
              apiRequest={this.apiRequest}
              extraProps={{
                scroll: { x: 1700 },
              }}
              id="leadList"
              pagination={{
                ...this.state.pagination,
                defaultPageSize: 10,
                pageSizeOptions: ['10', '25', '50', '100'],
                showSizeChanger: true,
                ...this.props.pagination,
              }}
            />
          </Card>
        </div>

        <Drawer
          title=""
          placement="right"
          // maskClosable={false}
          closable={true}
          onClose={async () => {
            this.setState({ visible: false,selectedValues: [],
                        selectedLeadValue: null, })
                       this.getLeadsForAutoComplete()
            this.setState({
                        selectedValues: [],
                        selectedLeadValue: null,
                      })
          }}
          visible={this.state.visible}
          width={800}>
          <Card>
            <Row>
              <Col span={8}>
                <AutoComplete
                  notFoundContent={showSpin && <Spin />}
                  value={this.state.selectedLeadValue}
                  autoComplete={'none'}
                  onSelect={(v) => {
                    if (
                      !_.find(
                        this.state.selectedValues,
                        (item) => item._id === v
                      ) &&
                        v !== this.state.parentLeadId
                    ) {
                      let selectedLead = this.state.leadsData.find(
                        (obj) => obj._id === v
                      )

                      this.setState({
                        selectedValues: [
                          ...this.state.selectedValues,
                          selectedLead,
                        ],
                        selectedLeadValue: selectedLead.title,
                      })    
                    } else {
                        this.setState({
                      
                        selectedLeadValue: 'Already Exist',
                      })    
                    }
                  }}
                  style={{ width: '300px' }}
                  onSearch={this.searchLead}
                  placeholder={'Lead Name'}
                  onChange={(v) => this.setState({ selectedLeadValue: v })}>
                  {leadsData.map((val, item) => (
                    <Option value={val._id} key={val._id}>
                      <>
                        <div>
                          <b>{`${val.title} (${val.id})`}</b>
                        </div>
                        <div
                          className={styles.autoCompleteList}
                          style={{ marginTop: '0px' }}>{`${
                          val.contact && val.contact.email
                            ? val.contact.email
                            : ''
                        }`}</div>
                        <div className={styles.autoCompleteList}>{`${
                          val.contact && val.contact.firstName
                        } (${val.contact && val.contact.mobile})`}</div>
                        <div className={styles.autoCompleteList}>{`Company: ${
                          val.company || ''
                        }`}</div>
                        <Tag>{`${moment(val.createdAt).format(
                          'DD-MM-YYYY HH:mm'
                        )}`}</Tag>
                      </>
                    </Option>
                  ))}
                </AutoComplete>
                <div>
                  <div
                    style={{
                      display: 'flex',
                      width: '100%',
                      flexWrap: 'wrap',
                      width: '800px',

                      // m   ax-width:"700px",
                    }}>
                    {this.state.selectedValues.map((value) => (
                      <Tag
                        key={value._id}
                        style={{
                          width: '230px',
                          height: '130px',
                          margin: '8px',
                        }}
                        //   onClose={() => this.handleRemove(value._id)}
                      >
                        <div>
                          <b>{`${value.title} (${value.id})`}</b>
                        </div>
                        <div
                          className={styles.autoCompleteList}
                          style={{ marginTop: '0px' }}>{`${
                          value.contact && value.contact.email
                            ? value.contact.email
                            : ''
                        }`}</div>
                        <div className={styles.autoCompleteList}>{`${
                          value.contact && value.contact.firstName
                        } (${value.contact && value.contact.mobile})`}</div>
                        <div className={styles.autoCompleteList}>{`Company: ${
                          value.company || ''
                        }`}</div>
                        <Tag>{`${moment(value.createdAt).format(
                          'DD-MM-YYYY HH:mm'
                        )}`}</Tag>
                        <Button
                          style={{ color: 'red' }}
                          icon={'cross'}
                          onClick={() => this.handleRemove(value._id)}
                          type="link"></Button>
                      </Tag>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
            <Button onClick={() => this.mergeLeadsToParent()} type="primary" style={{marginTop:'10px'}}>
              Merge All Leads
            </Button>
          </Card>
        </Drawer>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  categories: global.categories,
  currentUser: global.currentUser,
  search: router.location.search,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(WonLeads)
