import React, { Component } from 'react'
import Board from 'react-trello'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { LinkOutlined, FileImageOutlined } from '@ant-design/icons'
import _ from 'lodash'

import moment from 'moment'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  Popconfirm,
  notification,
  Modal,
  Switch,
  Avatar,
  Row,
  Col,
  Popover,
  Input,
  Button,
  Icon,
  Select,
  Drawer,
  Form,
  AutoComplete,
  Spin,
} from 'antd'
import Request from '../../../request'
import styles from './styles.less'
import { connect } from 'react-redux'
import UpdateLead from '../add/editLead'
import { Typography } from 'antd'
import { stateFilter } from '../../../states'
import { addLeadActivity } from './helpers'

const { Paragraph } = Typography

const { Option } = Select

class AdminApprovalLeads extends Component {
  constructor(props) {
    super(props)
    this.table = React.createRef()
    const { dispatch } = props
    this.state = {
      allContacts: [],
      showBoard: true,
      showTable: false,
      leadStage: ['New (Untouched)', 'Contacted', 'Today Followup'],
      boardData: { lanes: [] },
      key: Math.random(),
      RoList: [],
      selectedRowKeys: [],
      selectedRO: undefined,
      currentPage: 1,
      leadStatuseArr: ['Open', 'won', 'Unqualified', 'Lost'],
    }
    this.searchContact = _.debounce(this.handleContactSearch, 1000, true)
  }

  apiRequest = (params) => {
    let { contactNameId } = this.state
    let stageArr = []
    

    if (this.state.selectedLeadStage) {
      params.stage = this.state.selectedLeadStage
    }
    if (params.page) {
      this.state.currentPage = params.page
    }
    if (!params.sortOrder) {
      params.sortField = 'createdAt'
      params.sortOrder = 'desc'
    }

    params.leadApprovedByAdmin = false
    return new Promise(async (resolve) => {
      let data = await Request.getAllLeads({
        ...params,
        customQuery: {
          contact: contactNameId ? contactNameId : undefined,
        },
        regExFilters: ['title', 'company', 'contact'],
      })
      resolve(data)
      this.setState({ leadData: data, count: data.total })
    })
  }

  showModal = () => {
    this.setState({
      visible: true,
      btnLoader: false,
    })
  }
  getStageColor = (stage) => {
    let returnColor = ''

    switch (stage) {
      case 'New (Untouched)':
        returnColor = '#FF3E4D'
        break
      case 'Contacted':
        returnColor = '#ff8c00'
        break
      case 'Today Followup':
        returnColor = '#26ae60'
        break
      
      default:
        returnColor = 'green'
    }

    return returnColor
  }

  assignLeads = async () => {
    const { selectedRowKeys, selectedRO } = this.state
    if (selectedRowKeys.length === 0 || !selectedRO) {
      return
    }
    this.setState({ assignLoader: true })
    let { data, error, message } = await Request.assignLeads(selectedRowKeys, {
      officer: selectedRO,
      leadApprovedByAdmin: true,
      newOfficer: null,
    })
    this.setState({ assignLoader: false })

    if (!error) {
      let foundRO = _.find(this.state.RoList, (item) => {
        return item._id === selectedRO
      })
      if (foundRO) {
        _.each(selectedRowKeys, async (item) => {
          await addLeadActivity({
            values: {
              message: `Lead assign to ${foundRO.name}`,
              leadId: item,
              user: this.props.currentUser.name,
              type: 'info',
            },
          })
        })
      }

      notification.success({ message: message })
      this.table.current.reload()
      this.setState({
        selectedRO: undefined,
        assignLeadDrawer: false,
        selectedRowKeys: [],
      })
    } else {
      notification.error({ message: message })
    }
  }

  async componentDidMount() {
    let { data: allContacts } = await Request.getAllContacts({ results: 10 })
    this.setState({ allContacts, sale: 'Sales' })
    let { data: userList } = await Request.getAllROList({
      customQuery: { userType: 'RO' },
    })
    this.setState({ RoList: userList })
  }

  handleContactSearch = async (test) => {
    // this.setState({​​​​​​​​showSpin: true}​​​​​​​​)
    this.setState({ selectedContact: test })
    if (test == '') {
      this.setState(
        {
          allContacts: this.state.allContacts,
          selectedContact: test,
          contactNameId: null,
        },
        () => this.table.current.reload()
      )
      return false
    }
    let { data, error, message } = await Request.contactSearch({
      text: test,
      type: 'list',
    })
    if (!error) {
      this.setState({ allContacts: data }, () => {
        this.setState({ showSpin: false })
      })
    }
  }

  onContactSelect = (val) => {
    let selectedContact = this.state.allContacts.find((obj) => obj._id === val)
    if (selectedContact) {
      this.setState(
        {
          contactNameId: selectedContact._id,
          selectedContact: `${selectedContact.firstName} (${selectedContact.mobile})`,
        },
        () => {
          this.table.current.reload()
        }
      )
    }
  }

  render() {
    const { submitting, currentUser, dispatch } = this.props
    const {
      currentPage,
      allContacts,
      leadStatuseArr,
      selectedRowKeys,
      assignLoader,
      assignLeadDrawer,
      RoList,
      selectedRO,
    } = this.state
    const columns = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        width: 50,
        key: 'sno',
        fixed: 'left',
        render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
      },

      {
        key: 'actions',
        title: 'View',
        fixed: 'left',
        width: 80,
        render: (text, record) => {
          return (
            <React.Fragment>
              <Tooltip title="View">
                <a
                  href={`/lead/detail?id=${record && record._id}`}
                  target={'_blank'}>
                  <Button
                    style={{ marginLeft: '5px' }}
                    size="small"
                    icon={'eye'}
                    shape={'circle'}
                  />
                </a>
              </Tooltip>
            </React.Fragment>
          )
        },
      },
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        searchTextName: 'id',
        fixed: 'left',
      },
      {
        title: 'Lead',
        dataIndex: 'title',
        key: 'title',
        searchTextName: 'title',
        render: (text, record) => (
          <span>
            <a
              onClick={() => {
                // this.setState({...this.state, cardId: record._id, updatelead: true})
              }}>
              {record.title}
            </a>
          </span>
        ),
      },
      {
        title: 'Contact',
        dataIndex: 'contact.firstName',
        key: 'contact.firstName',
        render: (text, record) => (
          <span>
            <span>{record.contact ? record.contact.firstName : ''}</span>
            <br />
            <span>{record.contact ? record.contact.mobile : ''}</span>
          </span>
        ),
      },
      {
        title: 'Existing Relationship Officer',
        dataIndex: 'officer',
        key: 'officer',
        render: (val, record) => {
          return (
            <div>
              <div>{val && val.name}</div>
              <div>{val && val.email}</div>
              <div>{val && val.mobile}</div>
            </div>
          )
        },
      },
      {
        title: 'New Relationship Officer',
        dataIndex: 'newOfficer',
        key: 'newOfficer',
        render: (val, record) => {
          return (
            <div>
              <div>{val && val.name}</div>
              <div>{val && val.email}</div>
              <div>{val && val.mobile}</div>
            </div>
          )
        },
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        searchTextName: 'company',
        // width: 100
      },

      {
        title: 'Stage',
        dataIndex: 'stage',
        key: 'stage',
        filters: [
          { text: 'New (Untouched)', value: 'New (Untouched)' },
          { text: 'Contacted', value: 'Contacted' },
          { text: 'Today Followup', value: 'Today Followup' },
        ],
        render: (text, record) => {
          return (
            <div>
              {record && record.stage ? (
                <div
                  className={styles.stageColor}
                  style={{
                    backgroundColor: this.getStageColor(record.stage),
                  }}>
                  {record.stage}
                </div>
              ) : (
                ''
              )}
            </div>
          )
        },
      },
      {
        title: 'Lead Type',
        dataIndex: 'leadType',
        key: 'leadType',
        filters: [
          { text: 'IndiaMART Api', value: 'IndiaMART Api' },
          { text: 'Inbound', value: 'Inbound' },
          { text: 'Aajjo', value: 'aajjo' },
          { text: 'Facebook Ads', value: 'Facebook Ads' },
        ],
        render: (val, record) => {
          return (
            <div>
              {record.leadType ? (
                <div style={getLeadTypeColor(record.leadType)}>
                  {record.leadType}
                </div>
              ) : (
                ''
              )}
            </div>
          )
        },
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        filters: stateFilter,
      },

      {
        title: 'Status',
        key: 'status',
        filters:
          leadStatuseArr && leadStatuseArr.map((x) => ({ value: x, text: x })),
        // width: 130,
        render: (text, record) => {
          return (
            <div>
              {record.status ? (
                <div style={getStatusStyle(record.status)}>{record.status}</div>
              ) : (
                ''
              )}
            </div>
          )
        },
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        sorter: true,
        searchDateName: 'createdAt',
        render: (val, record) => {
          return (
            <div>
              <div>{moment(record.createdAt).format('lll')}</div>
            </div>
          )
        },
        // width:100
      },
      {
        title: 'Lead Received Date',
        dataIndex: 'leadReceivedDate',
        key: 'leadReceivedDate',
        searchDateName: 'leadReceivedDate',
        sorter: true,
        fixed: 'right',
        render: (val, record) => {
          return (
            <div>
              <div>
                {' '}
                {record && record.leadReceivedDate
                  ? moment(record.leadReceivedDate).format('lll')
                  : ''}{' '}
              </div>
            </div>
          )
        },
        width: 150,
      },
    ]
    const getStatusStyle = (status) => {
      switch (status) {
        case 'Open':
          return {
            backgroundColor: '#1287A5',
            textAlign: 'center',
            color: 'white',
            padding: '2px 2px',
            borderRadius: '2px',
            minWidth: '30px',
          }
          break
        case 'won':
          return {
            backgroundColor: '#43BE31',
            textAlign: 'center',
            color: 'white',
            padding: '2px 2px',
            borderRadius: '2px',
            minWidth: '30px',
          }
          break
        case 'Lost':
          return {
            backgroundColor: 'red',
            textAlign: 'center',
            color: 'white',
            padding: '2px 2px',
            borderRadius: '2px',
            minWidth: '30px',
          }
          break
        default:
          return {
            backgroundColor: '#F3B431',
            color: 'white',
            textAlign: 'center',
            padding: '2px 2px',
            borderRadius: '2px',
            minWidth: '30px',
          }
      }
    }
    const getLeadTypeColor = (leadType) => {
      switch (leadType) {
        case 'aajjo':
          return {
            backgroundColor: '#43BE31',
            textAlign: 'center',
            color: 'white',
            padding: '2px 2px',
            borderRadius: '2px',
            minWidth: '30px',
          }
          break
        case 'IndiaMART Api':
          return {
            backgroundColor: '#1287A5',
            textAlign: 'center',
            color: 'white',
            padding: '2px 2px',
            borderRadius: '2px',
            minWidth: '30px',
          }
          break
        default:
          return {
            backgroundColor: '#F3B431',
            color: 'white',
            textAlign: 'center',
            padding: '2px 2px',
            borderRadius: '2px',
            minWidth: '30px',
          }
      }
    }

    const rowSelection = {
      selectedRowKeys: selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        this.setState({ selectedRowKeys })
        this.setState({ selectedRows })
      },
      getCheckboxProps: (record) => ({
        _id: record._id,
      }),
    }

    return (
      <PageHeaderWrapper
        title={
          this.state.count
            ? `All Unapproval Leads: ${this.state.count}`
            : `All Unapproval Leads: 0`
        }>
        <div style={{ margin: '-24px', padding: '0px' }}>
          <Card>
            <Row>
              <Col span={8}>
                <AutoComplete
                  value={this.state.selectedContact}
                  notFoundContent={this.state.showSpin && <Spin />}
                  autoComplete={'none'}
                  onSelect={this.onContactSelect}
                  onSearch={this.searchContact}
                  onChange={(val) => {
                    this.setState({ selectedContact: val })
                  }}
                  placeholder={'Search Contact'}
                  style={{ width: 300 }}>
                  {allContacts &&
                    allContacts.map((val, item) => (
                      <Option value={val._id}>
                        <>
                          <div>
                            <b>{`${val.firstName} (${val.mobile})`}</b>
                          </div>
                        </>
                      </Option>
                    ))}
                </AutoComplete>
              </Col>
              <Col span={8}>
                {' '}
                <Button
                  disabled={selectedRowKeys.length === 0}
                  onClick={() => this.setState({ assignLeadDrawer: true })}>
                  Assign To <Icon type="right" />
                </Button>
              </Col>
            </Row>
          </Card>

          <Card className={styles.greyCard}>
            <TableComp
              ref={this.table}
              columns={columns}
              apiRequest={this.apiRequest}
              extraProps={{
                scroll: { x: 1700 },
                rowSelection: { ...rowSelection },
              }}
              id="leadList"
              pagination={{
                ...this.state.pagination,
                defaultPageSize: 10,
                pageSizeOptions: ['10', '25', '50', '100'],
                showSizeChanger: true,
                ...this.props.pagination,
              }}
            />
          </Card>
        </div>

        <Drawer
          title=""
          placement="right"
          closable={true}
          onClose={async () => {
            this.setState({
              ...this.state,
              updatelead: false,
              cardId: '',
            })
          }}
          visible={this.state.updatelead}
          width={800}>
          {/* cardId={cardId} */}
          <UpdateLead
            cardId={this.state.cardId}
            allContacts={allContacts}
            leadOwner={currentUser}
            key={this.state.drawerKey ? this.state.drawerKey : Date.now()}
            handleUpdateLead={() => {
              this.setState({ updatelead: false, cardId: '' })
              this.table.current.reload()
            }}
          />
        </Drawer>

        <Modal
          title="Assign Leads"
          onCancel={async () => {
            this.setState({
              assignLeadDrawer: false,
              selectedRO: undefined,
            })
          }}
          visible={assignLeadDrawer}
          okText="Assign"
          onOk={this.assignLeads}
          okButtonProps={{
            loading: assignLoader,
          }}>
          <div style={{ textAlign: 'center' }}>
            <Form layout="inline">
              <Form.Item label="RO Officer">
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  style={{ width: 200 }}
                  value={selectedRO}
                  onChange={(selectedRO) => {
                    this.setState({ selectedRO })
                  }}
                  placeholder="Select RO Officer">
                  {RoList &&
                    RoList.map((val, id) => {
                      return (
                        <Option
                          key={val._id}
                          value={
                            val._id
                          }>{`${val.name} (${val.email})`}</Option>
                      )
                    })}
                </Select>
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  categories: global.categories,
  currentUser: global.currentUser,
  search: router.location.search,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminApprovalLeads)
