import React, {Component} from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Row, Col,
    Select,
    Drawer,
    Switch,
    Input, Button, Icon,
    Tag
} from 'antd'
import Request from '../../request'
import _ from 'lodash'
//import styles from './styles.less'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from '../../routes'
import {hideLoader, showLoader} from '../../modules/actions';
import {DatePicker, Space} from 'antd';
import AddFollowup from './addDirect'
import ViewLead from '../lead/all/viewLead'

const {Option} = Select

class AllFollowUps extends Component {

    apiRequest = (params) => {

        if (params.page) {
            this.state.currentPage = params.page
        }
        let user = {...JSON.parse(localStorage.getItem('user'))};

        if (this.state.overDueRange) {
            params.followUpDate = {$lte: this.state.overDueRange}

        }

        if (this.state.startRange && this.state.lastRange) {
            params.followUpDate = {$gte: this.state.startRange, $lte: this.state.lastRange}
        }

        if (user.userType == 'RO') {
            params.populateOfficer = {officer: user._id}
            params.addedBy = user._id

            return new Promise(async (resolve) => {
                let data = await Request.getAllFollowupsByLeadId({
                    ...params,
                    customQuery: {
                        //   followUpDate: {$gte: this.state.startRange, $lte: this.state.lastRange},
                        active: true
                    },
                    regExFilters: ['description']
                });
                this.setState({count: data.total});

                resolve(data)
            })
        }
        else {
            return new Promise(async (resolve) => {
                if (this.state.addedBy) {
                    params.addedBy = this.state.addedBy
                }

                let data = await Request.getAllFollowupsByLeadId({
                    ...params,
                    customQuery: {
                        //  followUpDate: {$gte: this.state.startRange, $lte: this.state.lastRange},
                        active: true
                    },
                    regExFilters: ['description']
                });
                this.setState({count: data.total})
                resolve(data)
            })
        }
    }


    constructor(props) {

        super(props)
        let searchParams = new URLSearchParams(this.props.search)


        this.table = React.createRef()
        this.state = {
            visible: false, key: Date.now(),
            startRange: null,
            lastRange: null,
            currentPage: 1,
            // startRange: moment('2020-01-01').format('YYYY-MM-DDTHH:MM:ss'),
            // lastRange: moment().add(7, 'days').endOf('day').format('YYYY-MM-DDTHH:MM:ss'),
            leadId: {},
            leadDataVisible: false
        }
        let overdue = searchParams.get('overDue')
        if (overdue) {
            this.state.overDueRange = moment().subtract(1, 'days').endOf('day').format('YYYY-MM-DDTHH:MM:ss')
        }
    }

    async componentDidMount() {
        let {data: userList} = await Request.getAllROList({customQuery: {userType: {$eq: "RO"}}});
        this.setState({userList: userList})
    }


    render() {
        const {submitting, dispatch, currentUser} = this.props;
        let {userList, currentPage} = this.state
        const showAddDrawer = (leadId) => {
            this.setState({...this.state, visible: true, leadId: leadId})
        }
        const statusFilterArr = [{text: 'Started', value: 'Started'}, {
            text: 'Not Started',
            value: 'Not Started'
        }, {text: 'In Progress', value: 'In Progress'},];
        const columns = [
            {
                title: 'S.No',
                dataIndex: 'sno',
                width: 50,
                key: 'sno',
                render: (value, item, index) => (currentPage - 1) * 10 + index + 1
            },
            {
                title: 'Date',
                dataIndex: 'followUpDate',
                //  width: 100,
                key: 'followUpDate',
                sorter: true,
                render: (text, record) => {
                    let date = moment(text).isBefore(moment(), 'day')
                    return (
                        <div>
                            <Tag color={date ? 'red' : ''}>
                                {text ? moment(text).format('ll') : ''} ({record.followUpTime ? record.followUpTime : ''})

                            </Tag>
                        </div>
                    )

                }

            },

            {
                title: 'Description',
                key: 'description',
                // width: 150,
                dataIndex: 'description',
                searchTextName: 'description'
            },
            {
                title: 'Overdue',
                key: 'overdueCount',
                // width: 150,
                // dataIndex: 'overdueCount',
                render: (val) => {
                    return (val ? <Badge
                        count={val}
                        style={{backgroundColor: 'red'}}
                    /> : '')
                }
            },

            {
                title: 'Lead',
                key: 'leadId',
//                width: 150,
                render: (val, record) => {
                    return (<div>
                        <a style={{color: 'blue', textDecoration: 'underline'}}
                           href={`/lead/detail?id=${record && record.leadId && record.leadId._id}`} target={'_blank'}>
                            {record.leadId ? record.leadId.title : ''}</a>


                    </div>)
                }

            },
            {
                title: 'Contact',
                key: 'contact',
                render: (text, record) => {
                    return (<div>
                        <div>{record && record.contactName}</div>
                        <div>{record && record.contactEmail}</div>
                        <div>{record && record.contactMobile}</div>

                    </div>)
                }
            },
            {
                key: 'actions',
                title: 'Actions',
                fixed: 'right',
                width: 80,
                render: (text, record) => {
                    return <React.Fragment>
                        <Tooltip title="Add Followup">
                            <Button
                                shape="circle"
                                style={{marginRight: 6}}
                                size="small"
                                onClick={() => {
                                    showAddDrawer(record.leadId)
                                }}
                                icon="plus"
                            />
                        </Tooltip>

                    </React.Fragment>

                }
            }

        ]


        if (currentUser && currentUser.userType === 'Admin') {

            let dataItem = {
                title: 'RO Details',
                key: 'addedBy',
                // width: 180,
                render: (text, record) => {

                    return (<div>
                        <div>{record && record.addedBy && record.addedBy.name}</div>
                        <small>{record && record.addedBy && record.addedBy.email}</small>
                        <small>{record && record.addedBy && record.addedBy.mobile}</small>

                    </div>)
                }
            }
            columns.splice(2, 0, dataItem)
        }


        const handleChangeDebut = (range) => {
            // const valueOfInput1 = moment(range.startDate).format();
            if (!range.length) {
                this.state = {
                    ...this.state,
                    startRange: moment().subtract(2, 'months').format('YYYY-MM-DD'),
                    lastRange: moment().add(1, 'months').format('YYYY-MM-DD'),

                }
                if (this.state.overDueRange) {
                    this.setState({
                        startRange: null,
                        lastRange: this.state.overDueRange
                    })


                }
            }
            else {
                const valueOfInput1 = moment(range[0]).startOf('day').format();
                const valueOfInput2 = moment(range[1]).endOf('day').format();
                this.setState({...this.state, startRange: valueOfInput1, lastRange: valueOfInput2});
            }
            setTimeout(() => {
                this.table.current.reload();
            }, 500);

        }


        return (
            <PageHeaderWrapper
                title={this.state.count ? `All FollowUp : ${this.state.count}` : `All FollowUp: 0`}>

                <Card bordered={true}>

                    <Row>
                        <Col span={8}>
                            <h4 style={{display: 'inline-block', marginRight: '10px'}}>Select Date Range</h4>
                            <DatePicker.RangePicker
                                style={{width: "100%"}}
                                //format="YYYY-MM-DD HH:mm"
                                //defaultValue={[moment(this.state.startRange), moment(this.state.lastRange)]}
                                getPopupContainer={trigger => trigger.parentNode}
                                onChange={(value, dateString) => handleChangeDebut(value)}
                            />
                        </Col>

                        {currentUser && currentUser.userType !== 'RO' && <Col span={8}>
                            <h4
                                style={{display: 'inline-block', marginLeft: '10px'}}>Select Relationship Officer</h4>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                style={{
                                    width: 225,
                                    marginLeft: '15px'

                                }}
                                placeholder="Select Relationship Officer"
                                onChange={id => {
                                    this.setState({addedBy: id}, () => {
                                        this.table.current.reload()
                                    })
                                }}
                            >
                                {userList &&
                                userList.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val._id}>
                                            {val.name} ({val.mobile})
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Col>}


                    </Row>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                        //   extraProps={{scroll: {x: 1800}}}
                        //  customQuery={}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>
                <Drawer
                    width={780}
                    onCancel={() => this.setState({visible: false})}
                    onClose={() => this.setState({visible: false})}
                    visible={this.state.visible}
                    title='Add Followup'
                    bodyStyle={{paddingBottom: 80}}>
                    <AddFollowup
                        leadid={this.state.leadId}
                        handleAddFollowup={() => {
                            this.setState({visible: false});
                            this.table.current.reload();
                        }}
                    />
                </Drawer>

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global, router}) => ({
    categories: global.categories,
    currentUser: global.currentUser,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllFollowUps)
