import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Row, Col,
    Select,
    Drawer,
    Switch,
    Input, Button, Icon
} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
import ReactQuill from 'react-quill'

import styles from '../styles.less'
import {connect} from 'react-redux'
// import { getUrlPushWrapper } from '../../routes'

const atValues = [
    {id: 1, value: 'contactName'},
    {id: 2, value: 'ROOfficer'}
];

class AllTemplates extends Component {

    apiRequest = (params) => {
        return new Promise(async (resolve) => {
            let data = await Request.getAllEmailTemplates({
                ...params,
                regExFilters: ['']
            })
            this.setState({count: data.total})
            resolve(data)
        })
    }


    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            visible: false, key: Date.now(),
        }
    }

    async componentDidMount() {
        //get data of template
        let data = await Request.getTemplateById({_id: this.props.templateId});
    }

    mentionModule = {
        allowedChars: /^[A-Za-z\s]*$/,
        mentionDenotationChars: ["@"],
        source: function (searchTerm, renderList, mentionChar) {
            let values;
            if (mentionChar === "@") {
                values = atValues;
            }
            if (searchTerm.length === 0) {
                renderList(values, searchTerm);

            } else {
                const matches = [];
                for (let i = 0; i < values.length; i++)
                    if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) matches.push(values[i]);
                renderList(matches, searchTerm);
            }
        },
        mentionContainerClass: styles.mentionContainer,
        onSelect: (item, insertItem) => {
            insertItem({...item, value: `{%=${item.value}%}`})
        },
        showDenotationChar: false
    }

    render() {

        const handleUpdate = async () => {
            if ((!this.state.templateContent) || (!this.state.templateTitle)) {
                return notification.error({
                    message: 'Please Fill All Required Fields'
                })
            }

            let body = {
                _id: this.state.template._id,
                content: this.state.templateContent,
                title: this.state.templateTitle
            }
            let x = await Request.updateTemplate(body);
            if (!x.error) {
                console.log("updated templates are", x);
                notification.success({
                    message: 'Template updated',
                    description: x.message
                })
                this.table.current.reload();
                this.setState({visible: false})
            } else {
                notification.error({
                    message: 'Error Saving',
                    description: x.message
                })
            }
        }

        const handleDelete = async (id) => {
            let x = await Request.deleteTemplate({_id: id});
            if (!x.error) {
                console.log("deleted templates are", x);
                notification.success({
                    message: 'Template deleted',
                    description: x.message
                })
                this.table.current.reload();
            } else {
                notification.error({
                    message: 'Error deleting',
                    description: x.message
                })
            }

        }
        const {submitting, dispatch, currentUser, templateId} = this.props;
        const showEditDrawer = async (templateId) => {
            let {data} = await Request.getTemplateById({_id: templateId});
            this.setState({
                ...this.state,
                visible: true,
                template: data,
                templateContent: data.content,
                templateTitle: data.title
            });
        }
        const columns = [

            {
                title: 'Title',
                key: 'title',
                // width: 200,
                dataIndex: 'title',
                searchTextName: 'title'
            },

            //   {
            //     title: 'Content',
            //     dataIndex: 'content',
            //     key: 'content',
            //   },
            {
                key: 'actions',
                title: 'Actions',
                fixed: 'right',
                width: 80,
                render: (text, record) => {
                    return <React.Fragment>
                        <Tooltip title="Edit">
                            <Button
                                shape="circle"
                                style={{marginRight: 6}}
                                size="small"
                                onClick={() => {
                                    console.log("record is", record)
                                    showEditDrawer(record._id)
                                }}
                                icon="edit"
                            />
                        </Tooltip>
                        <Tooltip title="Delete">

                            <Popconfirm
                                title="Are you sure delete this Template?"
                                onConfirm={() => {
                                    handleDelete(record._id)
                                }}

                                onCancel={() => {
                                    console.log()
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button
                                    shape="circle"
                                    style={{marginRight: 6}}
                                    size="small"

                                    icon="delete"
                                />
                            </Popconfirm>


                        </Tooltip>

                    </React.Fragment>

                }
            }

        ]


        return (
            <PageHeaderWrapper
                title={this.state.count ? `All Templates : ${this.state.count}` : `All Templates: 0`}>

                <Card bordered={true}>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                        //  extraProps={{ scroll: { x: 1800 } }}
                        //  customQuery={}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>
                <Drawer
                    width={780}
                    onCancel={() => this.setState({visible: false})}
                    onClose={() => this.setState({visible: false})}
                    visible={this.state.visible}
                    title='Edit Template'
                    bodyStyle={{paddingBottom: 80, padding: 80}}>

                    <Input style={{marginBottom: '10px', margin: '30px', width: '80%'}} value={this.state.templateTitle}
                           placeholder={'Please input the title of Template!'} onChange={(v) => {
                        if (v.target.value === '') {
                            this.setState({templateTitle: undefined})
                        }
                        this.setState({templateTitle: v.target.value})
                    }}/>
                    {/* value={(this.state.template)?this.state.template.content:''} */}
                    <ReactQuill style={{margin: '30px', marginTop: '0px', width: '80%'}}
                                value={this.state.templateContent}
                                modules={{mention: this.mentionModule}}
                                placeholder={'Type Content...'} onChange={(text) => {
                        this.setState({templateContent: text});
                        if (text === '<p><br></p>') {
                            this.setState({templateContent: undefined});
                        }

                    }
                    }/>

                    <Button onClick={handleUpdate} type="primary" style={{marginLeft: '40%'}}>Update</Button>

                </Drawer>


            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllTemplates)
