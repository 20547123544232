import React, { Component } from 'react'
import {
  Card,
  Col,
  Row,
  Icon,
  Table,
  List,
  Radio,
  DatePicker,
  Spin,
} from 'antd'
import styles from './style.less'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'

import moment from 'moment'
import _ from 'lodash'
import {
  RadialBarChart,
  RadialBar,
  Legend,
  Tooltip,
  CartesianGrid,
  XAxis,
  YAxis,
  ComposedChart,
  Line,
  Area,
  BarChart,
  Bar,
} from 'recharts'

import Request from '../../request'
import { getUrlPushWrapper } from '../../routes'

class RODashboard extends Component {
  loadApisRequest = () => {
    this.setState({ startLoading: true })
    Request.ROPerformance({
      leadDate: { $gte: this.state.startRange, $lte: this.state.lastRange },
    }).then(({ data }) => {
      this.setState({
        performanceData: data,
        startLoading: false,
      })
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      startRange: moment().startOf('day').toDate(),
      lastRange: moment().endOf('day').toDate(),
      startLoading: false,
    }
  }

  componentDidMount() {
    this.loadApisRequest()
  }

  render() {
    const { performanceData } = this.state
    const { currentUser } = this.props
    const handleChangeDebut = (range) => {
      if (!range.length) {
        this.setState({
          startRange: moment().startOf('month').toDate(),
          lastRange: moment().endOf('month').toDate(),
        })
      } else {
        const valueOfInput1 = moment(range[0]).startOf('day').format()
        const valueOfInput2 = moment(range[1]).endOf('day').format()
        this.setState({
          ...this.state,
          startRange: valueOfInput1,
          lastRange: valueOfInput2,
        })
      }
      setTimeout(() => {
        this.loadApisRequest()
      }, 500)
    }

    return (
      <PageHeaderWrapper title={'Dashboard'}>
        {/* <Card>
          <Row>
            <Col span={8}>
              <h4 style={{ display: 'inline-block', marginRight: '10px' }}>
                Select Date Range
              </h4>
              <DatePicker.RangePicker
                style={{ width: '100%' }}
                format="DD-MM-YYYY"
                value={[
                  moment(this.state.startRange),
                  moment(this.state.lastRange),
                ]}
                getPopupContainer={(trigger) => trigger.parentNode}
                onChange={(value, dateString) => handleChangeDebut(value)}
              />
            </Col>
          </Row>
        </Card> */}

        <Card>
          <Spin spinning={this.state.startLoading}>
            <Row gutter={16}>
              <Col span={12}>
                <h1 className={styles.headingRO} style={{ marginTop: '0px' }}>
                  <img
                    src="/test/leads-stats.svg"
                    alt="Icon"
                    className={styles.headingROIcon}
                  />
                  Overall Stats
                </h1>
              </Col>
              <Col span={12}>
                <div style={{ position: 'absolute', right: '10px' }}>
                  <h4 style={{ display: 'inline-block', marginRight: '10px' }}>
                    Select Date Range
                  </h4>
                  <DatePicker.RangePicker
                    style={{ width: '25vw' }}
                    format="DD-MM-YYYY"
                    value={[
                      moment(this.state.startRange),
                      moment(this.state.lastRange),
                    ]}
                    getPopupContainer={(trigger) => trigger.parentNode}
                    onChange={(value, dateString) => handleChangeDebut(value)}
                  />
                </div>
              </Col>
            </Row>

            <Row gutter={[16, 16]}>
              <Col span={6}>
                <div className={styles.cardRO}>
                  <div>
                    <h6 className={styles.cardHeadingRO}>Won Leads</h6>

                    <div className={styles.cardNumberRO}>
                      {(performanceData &&
                        performanceData[0] &&
                        performanceData[0].won) ||
                        0}
                    </div>
                  </div>

                  <img
                    src="/test/RO/leads/won-leads.svg"
                    alt="Icon"
                    className={styles.iconLeads}
                  />
                </div>
              </Col>

              <Col span={6}>
                <div className={styles.cardRO}>
                  <div>
                    <h6 className={styles.cardHeadingRO}>Lost Leads</h6>

                    <div className={styles.cardNumberRO}>
                      {(performanceData &&
                        performanceData[0] &&
                        performanceData[0].lost) ||
                        0}
                    </div>
                  </div>

                  <img
                    src="/test/RO/leads/lost-leads.svg"
                    alt="Icon"
                    className={styles.iconLeads}
                  />
                </div>
              </Col>

              <Col span={6}>
                <div className={styles.cardRO}>
                  <div>
                    <h6 className={styles.cardHeadingRO}>Quotes</h6>

                    <div className={styles.cardNumberRO}>
                      {(performanceData &&
                        performanceData[0] &&
                        performanceData[0].totalQuotes) ||
                        0}
                    </div>
                  </div>

                  <img
                    src="/test/RO/leads/quotes.svg"
                    alt="Icon"
                    className={styles.iconLeads}
                  />
                </div>
              </Col>

              <Col span={6}>
                <div className={styles.cardRO}>
                  <div>
                    <h6 className={styles.cardHeadingRO}>Contacted Leads</h6>

                    <div className={styles.cardNumberRO}>
                      {(performanceData &&
                        performanceData[0] &&
                        performanceData[0].contacted) ||
                        0}
                    </div>
                  </div>

                  <img
                    src="/test/RO/leads/contacted-leads.svg"
                    alt="Icon"
                    className={styles.iconLeads}
                  />
                </div>
              </Col>

              {/* <Col span={6}>
                <div className={styles.cardRO}>
                  <div>
                    <h6 className={styles.cardHeadingRO}>
                      Proposal Presented Leads
                    </h6>

                    <div className={styles.cardNumberRO}>
                      {(performanceData &&
                        performanceData[0] &&
                        performanceData[0].proposalPresented) ||
                        0}
                    </div>
                  </div>

                  <img
                    src="/test/RO/leads/proposal-leads.svg"
                    alt="Icon"
                    className={styles.iconLeads}
                  />
                </div>
              </Col>

              <Col span={6}>
                <div className={styles.cardRO}>
                  <div>
                    <h6 className={styles.cardHeadingRO}>
                      In Negotiation Leads
                    </h6>

                    <div className={styles.cardNumberRO}>
                      {(performanceData &&
                        performanceData[0] &&
                        performanceData[0].inNegotiation) ||
                        0}
                    </div>
                  </div>

                  <img
                    src="/test/RO/leads/in-negotiations.svg"
                    alt="Icon"
                    className={styles.iconLeads}
                  />
                </div>
              </Col> */}
            </Row>

            <div>
              {/* <div>
                <a
                  style={{
                    backgroundColor: '#095d51',
                    color: 'white',
                    borderRadius: 10,
                    padding: 20,
                    display: 'block',
                    margin: 10,
                  }}>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 18,
                    }}>
                    Won Leads
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 20,
                    }}>
                    {(performanceData &&
                      performanceData[0] &&
                      performanceData[0].won) ||
                      0}
                  </div>
                </a>
              </div>
              <div>
                <a
                  style={{
                    backgroundColor: '#13c2c2',
                    color: 'white',
                    borderRadius: 10,
                    padding: 20,
                    display: 'block',
                    margin: 10,
                  }}>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 18,
                    }}>
                    Lost Leads
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 20,
                    }}>
                    {(performanceData &&
                      performanceData[0] &&
                      performanceData[0].lost) ||
                      0}
                  </div>
                </a>
              </div> */}

              {/*<div>*/}
              {/*<a style={{*/}
              {/*backgroundColor: '#2d922b',*/}
              {/*color: 'white',*/}
              {/*borderRadius: 10,*/}
              {/*padding: 20,*/}
              {/*display: 'block',*/}
              {/*margin: 10*/}
              {/*}}>*/}
              {/*<div style={{textAlign: 'center', fontWeight: 'bold', fontSize: 18}}>Closed Leads*/}
              {/*</div>*/}
              {/*<div style={{*/}
              {/*textAlign: 'center',*/}
              {/*fontWeight: 'bold',*/}
              {/*fontSize: 20*/}
              {/*}}>{performanceData && performanceData[0] && performanceData[0].closed || 0}</div>*/}
              {/*</a>*/}
              {/*</div>*/}

              {/* <div>
                <a
                  style={{
                    backgroundColor: '#095d51',
                    color: 'white',
                    borderRadius: 10,
                    padding: 20,
                    display: 'block',
                    margin: 10,
                  }}>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 18,
                    }}>
                    Quotes
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 20,
                    }}>
                    {(performanceData &&
                      performanceData[0] &&
                      performanceData[0].totalQuotes) ||
                      0}
                  </div>
                </a>
              </div>
              <div>
                <a
                  style={{
                    backgroundColor: '#13c2c2',
                    color: 'white',
                    borderRadius: 10,
                    padding: 20,
                    display: 'block',
                    margin: 10,
                  }}>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 18,
                    }}>
                    Calls in Minutes
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 20,
                    }}>
                    {(performanceData &&
                      performanceData[0] &&
                      performanceData[0].durationInMinutes) ||
                      0}
                  </div>
                </a>
              </div>
              <div>
                <a
                  style={{
                    backgroundColor: '#2d922b',
                    color: 'white',
                    borderRadius: 10,
                    padding: 20,
                    display: 'block',
                    margin: 10,
                  }}>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 18,
                    }}>
                    Attended Calls
                  </div>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 20,
                    }}>
                    {(performanceData &&
                      performanceData[0] &&
                      performanceData[0].totalAttended) ||
                      0}
                  </div>
                </a>
              </div>
              <div>
                <a
                  style={{
                    backgroundColor: '#095d51',
                    color: 'white',
                    borderRadius: 10,
                    padding: 20,
                    display: 'block',
                    margin: 10,
                  }}>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 18,
                    }}>
                    Contacted Leads
                  </div>
                  <br />
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 20,
                    }}>
                    {(performanceData &&
                      performanceData[0] &&
                      performanceData[0].contacted) ||
                      0}
                  </div>
                </a>
              </div>
              <div>
                <a
                  style={{
                    backgroundColor: '#095d51',
                    color: 'white',
                    borderRadius: 10,
                    padding: 20,
                    display: 'block',
                    margin: 10,
                  }}>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 18,
                    }}>
                    In Negotiation Leads
                  </div>
                  <br />
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 20,
                    }}>
                    {(performanceData &&
                      performanceData[0] &&
                      performanceData[0].inNegotiation) ||
                      0}
                  </div>
                </a>
              </div>
              <div>
                <a
                  style={{
                    backgroundColor: '#faad14',
                    color: 'white',
                    borderRadius: 10,
                    padding: 20,
                    display: 'block',
                    margin: 10,
                  }}>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 18,
                    }}>
                    Proposal Presented Leads
                  </div>
                  <br />
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 20,
                    }}>
                    {(performanceData &&
                      performanceData[0] &&
                      performanceData[0].proposalPresented) ||
                      0}
                  </div>
                </a>
              </div> */}

              {/*<div>*/}
              {/*<a style={{*/}
              {/*backgroundColor: '#faad14',*/}
              {/*color: 'white',*/}
              {/*borderRadius: 10,*/}
              {/*padding: 20,*/}
              {/*display: 'block',*/}
              {/*margin: 10*/}
              {/*}}>*/}
              {/*<div style={{textAlign: 'center', fontWeight: 'bold', fontSize: 18}}>Won Leads*/}
              {/*Average*/}
              {/*</div>*/}
              {/*<br/>*/}
              {/*<div style={{*/}
              {/*textAlign: 'center',*/}
              {/*fontWeight: 'bold',*/}
              {/*fontSize: 20*/}
              {/*}}>{performanceData && performanceData[0] && performanceData[0].wonAverageOfLeads || 0}</div>*/}
              {/*</a>*/}
              {/*</div>*/}
            </div>

            <h1 className={styles.headingRO}>
              <img
                src="/test/RO/call-stats.svg"
                alt="Icon"
                className={styles.headingROIcon}
                style={{ height: '18px' }}
              />
              Call Stats
            </h1>

            <Row gutter={[16, 16]} style={{ marginBottom: '10px' }}>
              <Col span={4}>
                <div className={styles.cardROCalls}>
                  <img
                    src="/test/RO/calls/incoming-calls.svg"
                    alt="Icon"
                    className={styles.iconCalls}
                  />
                  <div className={styles.cardNumberRO}>
                    {(performanceData &&
                      performanceData[0] &&
                      performanceData[0].incomingCalls) ||
                      0}
                  </div>
                  <h6 className={styles.cardHeadingRO}>Incoming Calls</h6>
                </div>
              </Col>

              <Col span={4}>
                <div className={styles.cardROCalls}>
                  <img
                    src="/test/RO/calls/missed-calls.svg"
                    alt="Icon"
                    className={styles.iconCalls}
                  />
                  <div className={styles.cardNumberRO}>
                    {(performanceData &&
                      performanceData[0] &&
                      performanceData[0].missedCalls) ||
                      0}
                  </div>
                  <h6 className={styles.cardHeadingRO}>Missed Calls</h6>
                </div>
              </Col>

              <Col span={4}>
                <div className={styles.cardROCalls}>
                  <img
                    src="/test/RO/calls/outgoing-calls.svg"
                    alt="Icon"
                    className={styles.iconCalls}
                  />
                  <div className={styles.cardNumberRO}>
                    {(performanceData &&
                      performanceData[0] &&
                      performanceData[0].outgoingCalls) ||
                      0}
                  </div>
                  <h6 className={styles.cardHeadingRO}>Outgoing Calls</h6>
                </div>
              </Col>

              <Col span={4}>
                <div className={styles.cardROCalls}>
                  <img
                    src="/test/RO/calls/rejected-calls.svg"
                    alt="Icon"
                    className={styles.iconCalls}
                  />
                  <div className={styles.cardNumberRO}>
                    {(performanceData &&
                      performanceData[0] &&
                      performanceData[0].rejectedCalls) ||
                      0}
                  </div>
                  <h6 className={styles.cardHeadingRO}>Rejected Calls</h6>
                </div>
              </Col>

              <Col span={4}>
                <div className={styles.cardROCalls}>
                  <img
                    src="/test/RO/calls/attended-calls.svg"
                    alt="Icon"
                    className={styles.iconCalls}
                  />
                  <div className={styles.cardNumberRO}>
                    {(performanceData &&
                      performanceData[0] &&
                      performanceData[0].totalAttended) ||
                      0}
                  </div>
                  <h6 className={styles.cardHeadingRO}>Attended Calls</h6>
                </div>
              </Col>

              <Col span={4}>
                <div className={styles.cardROCalls}>
                  <img
                    src="/test/RO/calls/calls-in-minutes.svg"
                    alt="Icon"
                    className={styles.iconCalls}
                  />
                  <div className={styles.cardNumberRO}>
                    {(performanceData &&
                      performanceData[0] &&
                      performanceData[0].durationInMinutes) ||
                      0}
                  </div>
                  <h6 className={styles.cardHeadingRO}>Calls in Minutes</h6>
                </div>
              </Col>
            </Row>

            {/* <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              <div>
                <a
                  style={{
                    backgroundColor: '#095d51',
                    color: 'white',
                    borderRadius: 10,
                    padding: 20,
                    display: 'block',
                    margin: 10,
                  }}>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 18,
                    }}>
                    Incoming Calls
                  </div>
                  <br />
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 20,
                    }}>
                    {(performanceData &&
                      performanceData[0] &&
                      performanceData[0].incomingCalls) ||
                      0}
                  </div>
                </a>
              </div>
              <div>
                <a
                  style={{
                    backgroundColor: '#095d51',
                    color: 'white',
                    borderRadius: 10,
                    padding: 20,
                    display: 'block',
                    margin: 10,
                  }}>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 18,
                    }}>
                    Missed Calls
                  </div>
                  <br />
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 20,
                    }}>
                    {(performanceData &&
                      performanceData[0] &&
                      performanceData[0].missedCalls) ||
                      0}
                  </div>
                </a>
              </div>
              <div>
                <a
                  style={{
                    backgroundColor: '#faad14',
                    color: 'white',
                    borderRadius: 10,
                    padding: 20,
                    display: 'block',
                    margin: 10,
                  }}>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 18,
                    }}>
                    Outgoing Calls
                  </div>
                  <br />
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 20,
                    }}>
                    {(performanceData &&
                      performanceData[0] &&
                      performanceData[0].outgoingCalls) ||
                      0}
                  </div>
                </a>
              </div>
              <div>
                <a
                  style={{
                    backgroundColor: '#faad14',
                    color: 'white',
                    borderRadius: 10,
                    padding: 20,
                    display: 'block',
                    margin: 10,
                  }}>
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 18,
                    }}>
                    Rejected Calls
                  </div>
                  <br />
                  <div
                    style={{
                      textAlign: 'center',
                      fontWeight: 'bold',
                      fontSize: 20,
                    }}>
                    {(performanceData &&
                      performanceData[0] &&
                      performanceData[0].rejectedCalls) ||
                      0}
                  </div>
                </a>
              </div>
            </div> */}
          </Spin>
        </Card>
      </PageHeaderWrapper>
    )
  }
}

export default RODashboard
