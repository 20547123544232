import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Button,
    Card,

} from 'antd'
import _ from 'lodash'
import {FormUtils as GetAllFormFields, TableComp} from 'sz-react-utils'
import {API_URL} from '../../../request'
import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {connect} from 'react-redux'


@Form.create()
class AddCarrier extends PureComponent {


    handleSubmit = e => {
        const {dispatch, form} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)


                dispatch(showLoader())

                let x = null

                x = await Request.addCarriers(values)


                dispatch(hideLoader())


                if (!x.error) {

                    notification.success({
                        message: this.state.id
                            ? x.message
                            : x.message
                    })

                    this.getCarriers()


                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }


    constructor(props) {
        super(props)
        this.table = React.createRef()

        this.state = {}

    }

    getCarriers = () => {
        Request.getAllCarriers().then(({data, error, message}) => {

            if (!error) {
                this.setState({carriers: data && data[0] && data[0].carriers})
                this.props.form.setFieldsValue({carriers: data && data[0] && data[0].carriers})


            } else {
                notification.error({
                    message: 'Error Getting Data'
                })
            }
        })
    }

    async componentDidMount() {

        this.getCarriers()
    }


    render() {

        let {carriers} = this.state
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props;


        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }

        const inputType = {
            fields: [
                {
                    label: 'Carriers',
                    key: 'carriers',
                    type: 'select',
                    mode: 'tags',
                    showSearch: true,
                    required: true,
                    keyAccessor: x => x,
                    valueAccessor: x => x,
                    options: carriers || [],
                    onChange: async (carriers) => {

                        this.props.form.setFieldsValue({
                            carriers: carriers
                        })

                    }


                },
            ]
        }
        return (
            <PageHeaderWrapper
                title={this.state.id ? 'Update IP Address' : 'Add Carriers'}
            >

                <Card bordered={true}>
                    <Form onSubmit={this.handleSubmit} hideRequiredMark>

                        <div>
                            <GetAllFormFields apiurl={API_URL}
                                              inputSchema={inputType}
                                              formItemLayout={formItemLayout}
                                              getFieldDecorator={getFieldDecorator}/>

                            <div style={{textAlign: 'center'}}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                        ADD
                                    </Button>
                                </Form.Item>
                            </div>
                        </div>


                    </Form>


                </Card>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddCarrier)
