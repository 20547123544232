import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    notification,
    Anchor,
    Spin,
    Select,
    Tag,
    Button,
    Table,
    Card,
    Drawer,
    Modal,
    Popover,
    Radio,
    Typography,
    Row,
    Col,
    InputNumber,
    Descriptions,
    Icon, Tooltip, Popconfirm, Avatar, Checkbox, AutoComplete
} from 'antd'
import _ from 'lodash'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'
import moment from 'moment'
import numberToText from 'number2text'
import Format from 'format-number'
import FormatNumber from 'indian-number-format'

import {API_URL} from '../../../request'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import styles from './styles.less'
import {connect} from 'react-redux'
import {createMatchSelector, goBack} from 'connected-react-router'
import StateData from '../../../states'
import AddContact from '../../../containers/contacts/add'
import {apiUrl, pdfUrl} from '../../../settings'
import {addLeadActivity} from '../../lead/all/helpers'
import {getUrlPushWrapper} from '../../../routes'
import AddProduct from '../../product/add'

const FormItem = Form.Item
const {Option} = Select
const {TextArea} = Input
const {Text, Paragraph} = Typography
const {Link} = Anchor

@Form.create()
class AddQuote extends PureComponent {

    handleSubmit = e => {
        const {dispatch, form, currentUser} = this.props
        let {sendNotification, notifications, totalData} = this.state
        e.preventDefault()

        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)
                values.contactName = this.state.selectedContactId
                if (!values.contactName) {
                    return notification.error({
                        message: 'Please Choose contact'
                    })
                }

                dispatch(showLoader())
                let x = null
                let array = []
                let showStatus = false
                this.setState({sendLoading: true})
                let productsTotalDiscount = 0


                if (totalData && totalData.length == 1) {
                    dispatch(hideLoader())
                    this.setState({sendLoading: false})
                    return notification.error({
                        message: 'Atleast add one product'
                    })
                }
                if (this.state.showError || this.state.grandTotal == 0) {
                    dispatch(hideLoader())
                    this.setState({sendLoading: false})
                    return notification.error({
                        message: 'Amount cannot be zero'
                    })
                }


                totalData = _.reject(this.state.totalData, (x) => x.isLast == true)
                _.each(totalData, (ii) => {
                    productsTotalDiscount = productsTotalDiscount + ii.discountAmount
                    ii.discountAmount = parseFloat((ii.discountAmount).toFixed(2))
                    ii.totalAmount = parseFloat((ii.totalAmount).toFixed(2))
                    ii.amount = parseFloat((ii.amount).toFixed(2))
                    ii.rate = parseFloat((ii.rate).toFixed(2))
                    ii.mrpAmount = parseFloat((ii.mrpAmount).toFixed(2))


                })

                values.productList = totalData
                values.approved = this.state.approved
                if (this.state.approved) {
                    values.status = 'Approved'
                } else {
                    values.status = 'Unapproved'
                }

                values.leadId = this.state.leadId
                values.tradeTerms = this.state.tradeTerms
                values.grandTotal = parseFloat((this.state.grandTotal).toFixed(2))
                values.user = this.props.currentUser.name
                values.totalDiscount = parseFloat((this.state.totalDiscount).toFixed(2))
                values.subTotal = parseFloat((this.state.subTotal || 0).toFixed(2))
                values.totalGST = parseFloat((this.state.totalGST || 0).toFixed(2))
                values.roundOff = this.state.roundValue
                if (!notifications.mail && !notifications.whatsapp && !notifications.message && sendNotification) {
                    dispatch(hideLoader())
                    this.setState({sendLoading: false})
                    return notification.error({
                        message: 'Atleast select one notification'
                    })
                }
                if (sendNotification)
                    values.notifications = {...notifications}

                if (this.state.id) {
                    values._id = this.state.id
                    values.approved = this.state.approved
                    if (this.state.approved) {
                        values.status = 'Approved'
                    } else {
                        values.status = 'Unapproved'
                    }
                    if (this.state.adminApproved) {
                        values.notifications = {'mail': false, 'text': false, 'whatsapp': false}
                        values.status = 'Approved'
                        values.approved = true
                    }
                 
                   if (currentUser && currentUser.userType === 'RO'){
                    values.adminApproved = false
                    values.approved = false
                    values.status = 'Unapproved'
                   }
                    x = await Request.updateQuote(values)
                    if (this.state.notifications.whatsapp) {
                        // let msg = `Please Find Attached your quote from Hindustan Thermostatics. ${API_URL}${x.pdfName}`
                        let pdfId = x && x.data && x.data._id
                        let mobileNumber = x && x.mobile ? x.mobile : this.state.contact.mobile
                        let msg = `Please Find Attached your quote from Hindustan Thermostatics. ${pdfUrl}${pdfId}.pdf \n\n\n
                                                 Connect with us on Whatsapp: https://signupforservices.com/whatsapp/optin/?bId=a73133cc-6fc1-4fba-b696-28a96e2e733f&bName=hindustanthermostatics&s=URL&lang=en_US"\`
`
                        setTimeout(() => {
                            addLeadActivity({
                                values: {
                                    message: `Whatsapp sent to ${mobileNumber}`,
                                    whatsAppMessage: msg,
                                    leadId: this.state.leadId,
                                    user: this.props.currentUser.name,
                                    type: 'whatsApp'
                                }
                            })
                            window.open(`http://api.whatsapp.com/send?phone=${mobileNumber}&text=${msg} `)
                        }, 1000)
                    }
                } else {
                    values.leadId = this.state.leadId
                    x = await Request.addQuote(values)
                    let pdfId = x && x.data && x.data._id
                    let mobileNumber = x && x.mobile ? x.mobile : this.state.contact.mobile

                    // let msg = `Please Find Attached your quote from Hindustan Thermostatics. ${API_URL}${x.pdfName} `
                    let msg = `Please Find Attached your quote from Hindustan Thermostatics. ${pdfUrl}${pdfId}.pdf \n\n\n
                              Connect with us on Whatsapp: https://signupforservices.com/whatsapp/optin/?bId=a73133cc-6fc1-4fba-b696-28a96e2e733f&bName=hindustanthermostatics&s=URL&lang=en_US"\`
`
                    if (!x.error) {
                        if (this.state.notifications.whatsapp) {
                            setTimeout(() => {
                                addLeadActivity({
                                    values: {
                                        message: `Whatsapp sent to ${mobileNumber}`,
                                        whatsAppMessage: msg,
                                        leadId: this.state.leadId,
                                        user: this.props.currentUser.name,
                                        type: 'whatsApp'
                                    }
                                })
                                window.open(`http://api.whatsapp.com/send?phone=${mobileNumber}&text=${msg}`)

                            }, 1000)
                        }
                    }
                }

                this.setState({sendLoading: false})
                dispatch(hideLoader())

                if (!x.error) {
                    if (!this.state.id) {
                        this.props.form.resetFields()
                        this.props.form.setFieldsValue({validDate: '14', carrier: 'DTDC', title: 'Mr.'})
                        this.setState({
                            totalData: [{isLast: true}],
                            subTotal: 0,
                            subtractAmount: 0,
                            billingData: '',
                            shippingData: '',
                            editContactId: '',
                            showEditButton: false,
                            leadNamesFiltered: this.state.leadNames,
                            isEdited: false,
                            totalGST: 0,
                            totalDiscount: 0,
                            grandTotal: 0,
                            approved: true


                        })

                        if (currentUser && currentUser.userType === 'RO') {
                            this.props.form.setFieldsValue({
                                quoteOwner: currentUser._id
                            })
                            this.setState({isRO: true})
                        }


                        await Request.updateLead({
                            _id: this.state.leadId || this.state.urlLeadId,
                            new: false,
                            stage: 'Contacted'
                        })
                        await addLeadActivity({
                            values: {
                                message: `Lead staged as "Contacted"`,
                                leadId: this.state.leadId || this.state.urlLeadId,
                                user: this.props.currentUser.name,
                                type: 'stage'
                            }
                        })
                        if (this.state.urlLeadId) {
                            dispatch(
                                getUrlPushWrapper('quote.addQuote')
                            )

                        }


                    }
                    notification.success({
                        message: this.state.id
                            ? x.message
                            : x.message
                    })
                    if (this.state.id) {
                        dispatch(goBack())
                    }

                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }

    constructor(props) {
        super(props)
        this.state = {
            modalVisible: false,
            visible: false,
            loader: false,
            userList: [],
            allContacts: [],
            productList: [],
            totalData: [],
            popVisible: false,
            time: Date.now(),
            disableSend: false,
            notifications: {'mail': true, 'text': false, 'whatsapp': false},
            leadNames: [],
            contact: {},
            leadId: '',
            // leadId: '',
            leadNamesFiltered: [],
            approved: true,
            showEditButton: false,
            carriersData: ['Fedex', 'DHL', 'UPS', 'USPS', 'BlueDart', 'DTDC'],
            contactSearchText: ''
        }
        this.AddData = _.debounce(this.addData, 1000, true)
        this.searchLead = _.debounce(this.handleDealsSearch, 100, true)


    }

    showDrawer = () => {
        this.setState({
            visible: true,
            showProductForm: false,
            key: Date.now()
        })
    }

    showModal = () => {
        this.setState({
            modalVisible: true
        })
    }


    handleOk = async e => {
        let {
            quantity, productName
        } = this.state
        if (!productName) {
            return notification.error({
                message: 'Select Product'
            })
        }
        if (quantity < 0 || quantity == 0) {
            return notification.error({
                message: 'Incorrect Quantity'
            })
        }


        if (productName && quantity) {
            this.calculateCurrency()
            this.setState({
                modalVisible: false
            })
        } else {
            this.setState({
                modalVisible: true
            })
        }


    }
    handleCancel = e => {

        this.props.form.setFieldsValue({
            quantity: null,
            productName: undefined
        })
        this.setState({
            modalVisible: false,
            productName: '',
            quantity: ''


        })
    }


    addData = () => {


        let tt = _.cloneDeep(this.state.totalData)
        let found = _.find(tt, (i) => {
            if (i && i.isLast != true) {
                return (i.productName == this.state.productName) || (i.productName._id == this.state.productName)

            }
        })

        if (found) {
            this.setState({showText: false})
            this.props.form.setFieldsValue({

                productName: undefined,
                quantity: null

            })

            return notification.error({
                message: 'Product Already Exists.'
            })
        }
        let comp = tt.pop()
        tt.push({
            rate: this.state.rate,
            discount: this.state.discount || 0,
            discountAmount: this.state.discount || 0,
            quantity: this.state.quantity,
            gst: this.state.gst,
            amount: this.state.amount,
            productName: this.state.productName,
            totalAmount: this.state.totalAmount,
            description: this.state.description,
            isLast: false,
            mrpAmount: this.state.mrpAmount,
            photos: this.state.photos,
            originalMRP: this.state.originalMRP,
            newProductAdded: this.state.newProductAdded ? this.state.newProductAdded : undefined
        })
        tt.push(comp)
        this.props.form.setFieldsValue({
            rate: null,
            discount: null,
            totalData: tt,
            productName: undefined,
            quantity: null,
            gst: null,
            amount: null,
            totalAmount: null,
            mrpAmount: null
        })

        this.setState({
            rate: null,
            discount: null,
            totalData: tt,
            productName: null,
            quantity: null,
            gst: null,
            amount: null,
            totalAmount: null,
            description: null,
            photos: null,
            originalMRP: null,
            newProductAdded: null


        }, () => {

            this.totalCalculate()
        })

    }
    handleContactSearch = async (test) => {
        this.setState({contactSearchText: test, selectedContactId: null})
        if (test == '') {
            this.setState({allContacts: this.state.allContacts})

            return false
        }
        let {data, error, message} = await Request.contactSearch({text: test})
        if (!error) {
            this.setState({allContacts: data}, () => {
                this.setState({showSpin: false})
            })
        }
    }

    onContactSelect = (val) => {
        let selectedContact = this.state.allContacts.find((obj) => obj._id === val)
        if (selectedContact) {
            this.setState({selectedContactId: selectedContact._id})
            this.setContactInfo(selectedContact._id)
            this.props.form.setFieldsValue({
                contactName: `${selectedContact.firstName} (${selectedContact.mobile})`
            })
        }
    }


    totalCalculate = async () => {
        let currentUser = JSON.parse(window.localStorage.getItem('user'))

        let label1 = 0
        let totalDiscount = 0
        let totalGST = 0
        let grandTotal = 0
        let showStatus = false
        let showError = false
        _.each(this.state.totalData, (i) => {
            if (i && i.isLast != true) {
                label1 = i.amount + label1
                totalDiscount = totalDiscount + i.discountAmount
                totalGST = totalGST + (i.amount * i.gst) / 100
                grandTotal = grandTotal + i.totalAmount
                if (i.totalAmount == 0) {
                    if (!showError) {
                        showError = true
                    }


                }

                if (currentUser && currentUser.userType == 'RO') {
                    if (i.discountAmount > 0 || i.newProductAdded) {
                        if (!showStatus) {
                            showStatus = true
                        }


                    }


                }


            }


        })

        if (currentUser && currentUser.userType === 'RO') {
            if (!this.state.approved) {
                if (showStatus) {
                    this.setState({approved: false})
                } else {
                    this.setState({approved: true})
                }
            }

        }
        let isEdited = _.sumBy(this.state.totalData, (e) => e.isEdited ? 1 : 0)
        if (isEdited) {
            this.setState({approved: false})
        }
        if (showError) {
            this.setState({showError: true})
        } else {
            this.setState({showError: false})
        }

        this.setState({
            subTotal: label1,
            totalDiscount: totalDiscount,
            totalGST: totalGST,
            grandTotal: grandTotal
        }, () => {
        })


    }


    calculateCurrency = async (data = {}) => {
        let values = this.props.form.getFieldsValue()
        let totalAmount = (data.quantity || values.quantity || this.state.quantity) * (data.rate || this.state.rate)
        let gstValue = ((totalAmount * this.state.gst) / 100)

        this.setState({
            amount: totalAmount,
            gst: this.state.gst,
            totalAmount: totalAmount + gstValue,
            rate: this.state.rate
        }, () => {
            this.addData()
        })


    }

    setAddress = async (contactId) => {

        // let values = _.find(this.state.allContacts, (ii) => {
        //     return ii._id == contactId
        // })
        let {data} = await Request.getContactById({_id: contactId})
        let values = data
        if (values) {

            this.props.form.setFieldsValue({
                // contactName: values && values._id,
                contactName: values && `${values.firstName} (${values.mobile})`,
                companyName: values && values.companyName

            })
            this.setState({
                billingData: values && values.billingAddress,
                shippingData: values && values.shippingAddress,
                selectedContactId: values._id

            })

        }

    }

    unApproveQuote = () => {
        // this.state.approved = false;
        this.setState({approved: false})
    }

    calculateDiscount = async () => {

        let tt = this.state.totalData

        _.each(tt, (item) => {
            if (item.isLast !== true) {
                let value = (item.mrpAmount || 0) - (item.rate || 0)
                let checkValue = Math.sign(value)
                item.discountAmount = (value / item.mrpAmount) * 100
                if (checkValue < 0 || checkValue == 0) {
                    item.discountAmount = 0
                    item.rate = item.mrpAmount
                }
                item.amount = item.rate * item.quantity
                let gstValue = ((item.amount * item.gst) / 100)

                item.totalAmount = item.amount + gstValue

            }
        })

        this.setState({
            totalData: tt
        }, () => {
            this.totalCalculate()
        })


    }


    getOptionsData = async (lead) => {
        // const { currentUser } = this.props
        let currentUser = JSON.parse(window.localStorage.getItem('user'))
        let filter = {userType: 'RO', results: 10000000000000000}
        if (currentUser && currentUser.userType === 'RO') {
            filter._id = currentUser._id
            this.props.form.setFieldsValue({
                quoteOwner: currentUser._id
            })
            this.setState({isRO: true})
        }

        Request.loadUser(filter).then(({data: userList}) => {
            this.setState({userList: userList || []})
        })
        Request.getAllContacts().then(({data: allContacts}) => {
            this.setState({allContacts: allContacts || []})
        })
        Request.getAllProducts({results: 1000}).then(({data: productList}) => {
            this.setState({productList: productList || []}, () => {

            })
        })
        Request.getAllLeads(currentUser && currentUser.userType === 'RO' ? {
            officer: currentUser._id,
            sortField: 'createdAt',
            sortOrder: 'desc',
            _id: lead
        } : {
            sortField: 'createdAt',
            sortOrder: 'desc',
            _id: lead
        }).then(({data: leadNames}) => {
            this.setState({leadNames: leadNames || [], leadNamesFiltered: leadNames || []}, () => {
                if (lead) {
                    this.onDealSelect(lead)
                }
            })
        })

    }

    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search)
        let currentUser = JSON.parse(window.localStorage.getItem('user'))
        let quoteId = searchParams.get('id')
        let leadId = searchParams.get('leadId')
        if (leadId) {
            this.setState({urlLeadId: leadId})
        }
        this.getOptionsData(leadId)
        if (quoteId) {
            this.setState({loader: true})

            Request.getQuoteById({_id: quoteId}).then(({data: quoteData, error, message}) => {
                this.setContactInfo(quoteData && quoteData.contactName._id)
                // this.setContactInfo(quoteData && quoteData.contactName)
                this.state.leadId = quoteData && quoteData.leadId ? quoteData.leadId : ''

                if (quoteData && quoteData.approved == undefined) {
                    this.setState({approved: true})
                } else {
                    if (currentUser && currentUser.userType == 'RO') {
                        this.setState({approved: quoteData && quoteData.approved})
                    } else {
                        this.setState({approved: quoteData && quoteData.approved, disableSend: true})
                    }
                }

                if (!error) {

                    this.setState({
                        id: quoteId,
                        loader: false,
                        // approved: quoteData.approved,
                        totalData: [...quoteData.productList, {isLast: true}]
                    }, () => {

                        this.calculateDiscount()

                    })
                    this.props.form.setFieldsValue({

                        quoteOwner: quoteData && quoteData.quoteOwner,
                        subject: quoteData && quoteData.subject,
                        stage: quoteData && quoteData.stage,
                        team: quoteData && quoteData.team,
                        carrier: quoteData && quoteData.carrier,
                        dealName: quoteData && quoteData.dealName,
                        validDate: quoteData && quoteData.validDate,
                        contactName: quoteData && quoteData.contactName && `${quoteData.contactName.firstName} (${quoteData.contactName.mobile})`,
                        // contactName: quoteData && quoteData.contactName,
                        companyName: quoteData && quoteData.companyName,
                        termsCondition: quoteData && quoteData.termsCondition,
                        note: quoteData && quoteData.note,
                        tradeTerms: quoteData && quoteData.tradeTerms,
                        title: quoteData && quoteData.title,
                        quoteComment: quoteData && quoteData.quoteComment,
                        quoteTitle: quoteData && quoteData.quoteTitle
                    })
                    this.setState({selectedContactId: quoteData && quoteData.contactName && quoteData.contactName._id})
                } else {
                    notification.error({
                        message: 'Error Getting Data'
                    })
                }
            })
        } else {
            this.props.form.setFieldsValue({validDate: '14', carrier: 'DTDC', title: 'Mr.'})
            this.setState({

                loader: false

            })
            this.state.totalData.push({isLast: true})

        }


    }


    setSocial = (field, val) => {
        let newData = _.clone(this.state.notifications)
        newData[field] = val
        this.setState({notifications: newData})
    }

    setContactInfo = async (contactId) => {
        let contact = await Request.getContactById({_id: contactId})
        this.state.contact = contact.data
        this.setState({
            billingData: contact && contact.data && contact.data.billingAddress,
            shippingData: contact && contact.data && contact.data.shippingAddress,
            showEditButton: true, editContactId: contactId
        })

    }

    handleDealsSearch = async (test) => {
        let obj = {}
        this.setState({showSpin: true})

        let currentUser = JSON.parse(window.localStorage.getItem('user'))

        if (currentUser && currentUser.userType === 'RO') {
            obj.officer = currentUser._id
        }
        if (test === '') {
            this.setState({leadNamesFiltered: this.state.leadNames}, () => {
                this.setState({
                    showSpin: false,
                    isEdited: false,
                    showEditButton: false,
                    billingData: null,
                    shippingData: null
                })
                this.props.form.setFieldsValue({
                    contactName: undefined,
                    companyName: undefined
                })

            })
            return false

        }
        obj.text = test
        let {data, error, message} = await Request.leadsSearch(obj)
        if (!error) {

            this.setState({leadNamesFiltered: data}, () => {
                this.setState({showSpin: false})

            })
        }
    }

    onDealSelect = async (val) => {
        let selectedQuote = this.state.leadNamesFiltered.find((obj) => obj._id === val)
        if (selectedQuote) {
            this.props.form.setFieldsValue({
                dealName: selectedQuote.title,
                // contactName: selectedQuote.contact._id,
                contactName: `${selectedQuote.contact.firstName} (${selectedQuote.contact.mobile})`,
                companyName: selectedQuote.company
            })
            this.setState({selectedContactId: selectedQuote.contact._id})
            this.state.leadId = selectedQuote._id
            await this.setContactInfo(selectedQuote.contact._id)
            this.setState({isEdited: true})
        }
    }

    onSelect = (data) => {
        console.log('onSelect', data)
    }

    onProductSelect = (id) => {
        if (id) {
            this.props.form.setFieldsValue({
                productName: id,
                quantity: 1
            })
            this.state.productName = id
            this.state.quantity = 1
            let found = _.find(this.state.productList, (ii) => {
                return ii._id == id
            })
            if (found) {

                this.state.rate = found.priceInformation && found.priceInformation.unitPrice
                this.state.amount = found.priceInformation && found.priceInformation.unitPrice
                this.state.gst = found.priceInformation && found.priceInformation.gstTax
                this.state.description = found.notes
                this.state.mrpAmount = found.priceInformation && found.priceInformation.unitPrice
                this.state.photos = found.productImage && found.productImage.length ? found.productImage[0].url : ''
                this.state.originalMRP = found.priceInformation && found.priceInformation.unitPrice


            }


        } else {
            this.setState({quantity: null})
            this.props.form.setFieldsValue({
                amount: '',
                gst: '',
                quantity: ''
            })
        }

    }
    onChangeDescription = (data, index) => {
        return <TextArea style={{
            width: '130px',
            height: '80px'
        }} onChange={(val) => {

            let tt = this.state.totalData
            tt[index].description = val.target.value

            this.setState({totalData: tt}, () => {
                this.calculateDiscount()
            })

        }}>{data}</TextArea>

    }
    calculateRoundOff = (value) => {
        value = parseFloat(value)
        let splitValue = value.toFixed(2).split('.')
        if (splitValue[1] && parseInt(splitValue[1])) {

            let newValue = FormatNumber.formatFixed(value - parseInt(splitValue[0]), 2)
            this.setState({roundValue: newValue})
            return <div>- {`₹${newValue}`}</div>

            //  return <div> {`₹${FormatNumber.formatFixed((Math.round((value || 0))), 0)}`}</div>

        } else {
            this.setState({roundValue: 0})

            return '-'
        }
    }

    render() {
        const {
            form,
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        const {
            userList,
            allContacts,
            productList,
            billingData,
            shippingData,
            isRO,
            id,
            sendLoading,
            leadNamesFiltered,
            isEdited,
            showSpin,
            showEditButton,
            editContactId,
            carriersData
        } = this.state

        const contactDetails = {
            fields: [

                {
                    label: 'Quote Owner',
                    key: 'quoteOwner',
                    showSearch: true,
                    disabled: isRO,
                    required: true,
                    keyAccessor: x => x._id,
                    valueAccessor: x => `${x.name} ${x.mobile}`,
                    type: 'select',
                    options: userList || [],
                    onChange: quoteOwner => {
                        this.props.form.setFieldsValue({
                            quoteOwner: quoteOwner
                        })

                    }
                },

                {
                    label: 'Validity (in Days)',
                    key: 'validDate',
                    type: 'select',
                    required: true,
                    options: ['3', '7', '10', '14'],
                    onChange: validDate => {
                        this.props.form.setFieldsValue({
                            validDate: parseInt(validDate)
                        })

                    }


                }


            ]
        }

        const contactDetails1 = {
            fields: [

                {
                    label: 'Company Name',
                    key: 'companyName',
                    disabled: isEdited
                },

                {
                    label: 'Title',
                    key: 'title',
                    type: 'select',
                    options: ['Mr.', 'Mrs.'],
                    onChange: (title) => {
                        this.props.form.setFieldsValue({
                            title: title
                        })

                    }

                },
                {
                    label: 'Quote Title',
                    key: 'quoteTitle',
                    type: 'select',
                    required: true,
                    options: ['Quote', 'Proforma Invoice'],
                    onChange: (quoteTitle) => {
                        this.props.form.setFieldsValue({
                            quoteTitle: quoteTitle
                        })

                    }

                }
            ]
        }


        const refColumns = [


            {
                title: 'Product',
                dataIndex: 'productName',
                key: 'productName',
                fixed: 'left',
                width: 130,
                render: (x, item, index) => {
                    if (item && item.isLast == true) {

                        return (<div>

                            {getFieldDecorator('productName', {})(
                                <Select
                                    showSearch
                                    dropdownMatchSelectWidth={false}
                                    optionFilterProp="children"
                                    style={{
                                        width: 130
                                    }}
                                    placeholder="Select Product"
                                    onSelect={id => {
                                        this.onProductSelect(id)
                                        this.handleOk()

                                    }}
                                >
                                    {productList &&
                                        productList.map((val, idn) => {
                                            return (
                                                <Option key={idn} value={val._id}>
                                                    {val.name} {val && val.quoteDisplayedName ? `(${val.quoteDisplayedName})` : ''}
                                                </Option>
                                            )
                                        })}
                                </Select>)}

                        </div>)

                    } else {
                        if (x && x._id) {
                            return <div>
                                <div style={{marginBottom: '5px'}}> {x.quoteDisplayedName || ''}</div>
                                <div>{this.onChangeDescription(item && item.description, index)}</div>
                            </div>
                        }
                        let found = _.find(productList, (ii) => {
                            return ii._id === x
                        })
                        if (found) {
                            return <div>
                                <div style={{marginBottom: '5px'}}>{found.quoteDisplayedName || ''}</div>
                                <div>{this.onChangeDescription(item && item.description, index)}</div>
                            </div>
                        }
                    }


                }


            },
            {
                title: 'Photo',
                dataIndex: 'photos',
                width: 120,
                key: 'photos',
                //                fixed: 'left',
                render: (val, item) => {
                    if (item && item.isLast !== true) {
                        return <div>
                            {val && <img src={`${API_URL}${val}`} style={{width: '100%'}}/>}
                        </div>
                    }
                }
            },

            {
                title: 'Quantity',
                key: 'quantity',
                dataIndex: 'quantity',
                render: (y, item, index) => {
                    if (item && item.isLast != true) {
                        return <div><Text editable={{
                            onChange: (val) => {
                                if (!isNaN(parseInt(val))) {
                                    let tt = this.state.totalData
                                    item.quantity = val ? Math.abs(val) : tt[index].quantity
                                    this.setState({totalData: tt}, () => {
                                        this.calculateDiscount()
                                    })

                                }


                            }
                        }}> {y}</Text>


                        </div>
                    }


                }

            },

            {
                title: 'MRP',
                dataIndex: 'mrpAmount',
                key: 'mrpAmount',
                render: (val, item, index) => {
                    if (item && item.isLast != true) {

                        return <div><InputNumber
                            value={item && item.mrpAmount ? `₹${FormatNumber.formatFixed(parseFloat(item.mrpAmount), 2)}` : 0}
                            onBlur={(e) => {
                                let currentUser = JSON.parse(window.localStorage.getItem('user'))

                                let vall = e.target.value
                                if (vall === '') {
                                    return false
                                }
                                let tt = this.state.totalData
                                tt[index].mrpAmount = parseFloat(vall) || 0

                                tt[index].rate = parseFloat(vall) || 0
                                if (currentUser && currentUser.userType === 'RO') {
                                    if (tt[index].originalMRP === tt[index].mrpAmount) {
                                        tt[index].isEdited = false
                                    } else {
                                        tt[index].isEdited = true
                                    }


                                }
                                this.setState({totalData: tt}, () => {
                                    this.calculateDiscount()
                                })
                            }}>
                        </InputNumber>
                        </div>

                    }

                }


            },
            {
                title: 'Discount',
                dataIndex: 'discountAmount',
                key: 'discountAmount',
                render: (y, item, index) => {
                    if (item && item.isLast !== true) {
                        return <div>{item && parseFloat(y && y).toFixed(2)}%</div>


                    }

                }


            },

            {
                title: 'Rate',
                dataIndex: 'rate',
                key: 'rate',
                render: (val, item, index) => {
                    if (item && item.isLast != true) {
                        return (<div><InputNumber
                            value={item && item.rate ? `₹${FormatNumber.formatFixed(parseFloat(item.rate), 2)}` : 0}
                            onBlur={(e) => {
                                let currentUser = JSON.parse(window.localStorage.getItem('user'))

                                let vall = e.target.value
                                if (vall === '') {
                                    return false
                                }
                                let tt = this.state.totalData
                                tt[index].rate = parseFloat(vall) || 0

                                if (currentUser && currentUser.userType === 'RO') {
                                    this.state.approved = false
                                }
                                this.setState({totalData: tt}, () => {
                                    this.calculateDiscount()
                                })
                            }}>

                        </InputNumber></div>)
                    }

                }


            },

            {
                title: 'Amount',
                dataIndex: 'amount',
                key: 'amount',
                render: (val, item) => {
                    if (item && item.isLast != true) {
                        return `₹${FormatNumber.formatFixed(val, 2)}`


                    } else {
                        return `₹${FormatNumber.formatFixed(this.state.subTotal || 0, 2)}`
                    }

                }


            },
            {
                title: 'GST',
                dataIndex: 'gst',
                key: 'gst',
                render: (val, item) => {
                    if (item && item.isLast != true) {
                        return <div>{val}%</div>

                    } else {
                        return `₹${FormatNumber.formatFixed(this.state.totalGST || 0, 2)}`
                    }
                }


            },


            {
                title: 'Total Amount',
                dataIndex: 'totalAmount',
                key: 'totalAmount',
                render: (y, item) => {
                    if (item && item.isLast != true) {
                        return `₹${FormatNumber.formatFixed(y, 2)}`


                    } else {
                        return `₹${FormatNumber.formatFixed(this.state.grandTotal || 0, 2)}`
                    }
                }


            },
            {
                title: 'Action',
                key: 'operation',
                width: 50,
                fixed: 'right',
                render: (item, record) => {
                    if (record && record.isLast != true) {
                        return <React.Fragment>

                            <Tooltip title="Delete Product">
                                <Popconfirm
                                    title="Are you sure delete this Product?"
                                    onConfirm={() => {
                                        let tt = this.state.totalData
                                        tt = _.reject(tt, item)
                                        if (tt.length == 1) {
                                            this.setState({approved: true})
                                        }
                                        this.setState({totalData: tt}, () => {
                                            this.totalCalculate()
                                        })


                                    }}
                                    onCancel={() => {
                                        console.log()
                                    }}
                                    okText="Yes"
                                    cancelText="No">
                                    <Button
                                        shape="circle"
                                        size="small"
                                        type={'danger'}
                                        icon="delete"
                                    />
                                </Popconfirm>

                            </Tooltip>

                        </React.Fragment>

                    }
                }
            }
        ]


        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }


        return (
            <PageHeaderWrapper
                title={this.state.id ? 'Update Quote' : 'Add Quote'}
            >

                <Spin spinning={this.state.loader}>
                    <div>
                        <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{marginTop: 8}}>

                            <Card bordered={true} style={{marginBottom: '15px'}} title='Quote Information'>

                                <Row>
                                    <Col span={12}>
                                        <GetAllFormFields apiurl={API_URL}
                                                          inputSchema={contactDetails}
                                                          formItemLayout={formItemLayout}
                                                          getFieldDecorator={getFieldDecorator}/>
                                        <Form.Item
                                            label='Contact'
                                            {...formItemLayout}
                                        ><Col span={18}>{getFieldDecorator('contactName', {
                                            rules: [{
                                                required: true,
                                                message: 'This is a Mandatory Field'
                                            }]
                                        })(
                                            <AutoComplete
                                                disabled={isEdited}
                                                notFoundContent={this.state.showSpin && <Spin/>}
                                                autoComplete={'none'}
                                                onSelect={this.onContactSelect}
                                                onSearch={this.handleContactSearch}
                                                placeholder={'Contact Name'}
                                            >{allContacts.map((val, item) => (
                                                <Option value={val._id}>
                                                    <>
                                                        <div><b>{`${val.firstName} (${val.mobile})`}</b></div>
                                                    </>
                                                </Option>
                                            ))}

                                            </AutoComplete>
                                        )}</Col>
                                            <Col span={6}>
                                                {!showEditButton ?
                                                    <Button style={{marginLeft: '3px'}} type="primary" onClick={() => {
                                                        this.showDrawer()

                                                    }}>{<Icon type="plus"/>}</Button> :
                                                    <Button style={{marginLeft: '3px'}} type="primary" onClick={() => {
                                                        this.showDrawer()

                                                    }}>{<Icon type="edit"/>}</Button>}
                                            </Col>
                                        </Form.Item>
                                        <Form.Item label={'Terms of Trade'} {...formItemLayout}>
                                            {getFieldDecorator('tradeTerms', {
                                                rules: [{
                                                    required: true,
                                                    message: 'This is a Mandatory Field'
                                                }]
                                            })(
                                                <Checkbox.Group
                                                    checked={true}
                                                    className={styles.checkbox}
                                                    options={['Freight additional as actual',
                                                        'Payment 100% advance',
                                                        'Local duty & taxes as applicable',
                                                        'warranty as per the product',
                                                        'Delivery as per requirement',
                                                        'Installation and training extra']}
                                                    onChange={(checkedValues) => {
                                                        this.setState({tradeTerms: checkedValues})

                                                    }}
                                                />)}
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item
                                            label='Lead Name'
                                            {...formItemLayout}
                                        >{getFieldDecorator('dealName', {
                                            rules: [{
                                                required: true,
                                                message: 'This is a Mandatory Field'
                                            }]
                                        })(
                                            <AutoComplete
                                                notFoundContent={showSpin && <Spin/>}

                                                autoComplete={'none'}
                                                onSelect={this.onDealSelect}
                                                onSearch={this.searchLead}
                                                placeholder={'Lead Name'}

                                            >
                                                {leadNamesFiltered.map((val, item) => (
                                                    <Option value={val._id}>
                                                        <>
                                                            <div><b>{`${val.title} (${val.id})`}</b></div>
                                                            <div className={styles.autoCompleteList}
                                                                 style={{marginTop: '0px'}}>{`${val.contact && val.contact.email ? val.contact.email : ''}`}</div>
                                                            <div
                                                                className={styles.autoCompleteList}>{`${val.contact && val.contact.firstName} (${val.contact && val.contact.mobile})`}</div>
                                                            <div
                                                                className={styles.autoCompleteList}>{`Company: ${val.company || ''}`}</div>
                                                            <Tag>{`${moment(val.createdAt).format('DD-MM-YYYY HH:mm')}`}</Tag>
                                                        </>
                                                    </Option>
                                                ))}
                                            </AutoComplete>
                                        )}
                                        </Form.Item>
                                        <GetAllFormFields apiurl={API_URL}
                                                          inputSchema={contactDetails1}
                                                          formItemLayout={formItemLayout}
                                                          getFieldDecorator={getFieldDecorator}/>


                                        <Form.Item
                                            label='Delivery Carrier'
                                            {...formItemLayout}
                                        >{getFieldDecorator('carrier', {
                                            rules: [{
                                                required: true,
                                                message: 'This is a Mandatory Field'
                                            }]
                                        })(
                                            <AutoComplete
                                                notFoundContent={showSpin && <Spin/>}
                                                autoComplete={'none'}
                                                onSelect={this.onSelect}
                                                placeholder={'Delivery Carrier'}

                                            >
                                                {carriersData.map((val, item) => (
                                                    <Option value={val}>
                                                        {val}
                                                    </Option>
                                                ))}
                                            </AutoComplete>
                                        )}
                                        </Form.Item>

                                    </Col>
                                </Row>
                            </Card>


                            {(shippingData || billingData) && <Card bordered={true} style={{marginBottom: '15px'}}>


                                <Row>


                                    <Col span={11}>

                                        <h2>Billing Details</h2>

                                        <table className={styles.dataTable}>
                                            <tbody>
                                            <tr>
                                                <td>Address Line 1</td>
                                                <td>{billingData && billingData.addressLine1}</td>
                                            </tr>
                                            <tr>
                                                <td>Address Line 2</td>
                                                <td>{billingData && billingData.addressLine2}</td>
                                            </tr>
                                            <tr>
                                                <td>City</td>
                                                <td>{billingData && billingData.city}</td>
                                            </tr>
                                            <tr>
                                                <td>State</td>
                                                <td>{billingData && billingData.state}</td>
                                            </tr>
                                            <tr>
                                                <td>Zipcode</td>
                                                <td> {billingData && billingData.zipcode}</td>
                                            </tr>
                                            <tr>
                                                <td>Country</td>
                                                <td>{billingData && billingData.country}</td>
                                            </tr>

                                            </tbody>

                                        </table>

                                    </Col>

                                    <Col span={2}></Col>
                                    <Col span={11}>
                                        <h2>Shipping Details</h2>
                                        <table className={styles.dataTable}>
                                            <tbody>
                                            <tr>
                                                <td>Address Line 1</td>
                                                <td>{shippingData && shippingData.addressLine1}</td>
                                            </tr>
                                            <tr>
                                                <td>Address Line 2</td>
                                                <td>{shippingData && shippingData.addressLine2}</td>
                                            </tr>
                                            <tr>
                                                <td>City</td>
                                                <td>{shippingData && shippingData.city}</td>
                                            </tr>
                                            <tr>
                                                <td>State</td>
                                                <td>{shippingData && shippingData.state}</td>
                                            </tr>
                                            <tr>
                                                <td>Zipcode</td>
                                                <td> {shippingData && shippingData.zipcode}</td>
                                            </tr>
                                            <tr>
                                                <td>Country</td>
                                                <td>{shippingData && shippingData.country}</td>
                                            </tr>

                                            </tbody>

                                        </table>
                                    </Col>

                                </Row>


                            </Card>}


                            <Card bordered={true} style={{marginBottom: '15px'}}
                                  title={<div style={{
                                      display: 'flex', justifyContent: 'space-between'
                                  }}><h4>Product Details</h4>

                                      <div>
                                          <Button onClick={() => {
                                              this.setState({
                                                  showProductForm: true,
                                                  visible: true,
                                                  modalKey: Date.now()
                                              })
                                          }}>Add Product</Button>
                                          <Button onClick={() => {
                                              this.setState({totalData: []}, () => {
                                                  this.setState({totalData: [{isLast: true}]})
                                              })
                                              let currentUser = JSON.parse(window.localStorage.getItem('user'))

                                              if (currentUser && currentUser.userType === 'RO') {
                                                  this.setState({approved: true})
                                              }

                                          }}>Reset</Button>
                                      </div>
                                  </div>}>

                                <div>
                                    <Row> <Col span={24}>
                                        {<Table
                                            bordered={true}
                                            rowKey={x => x.productName}
                                            columns={refColumns}
                                            dataSource={this.state.totalData || []}
                                            pagination={false}
                                            scroll={{x: true}}
                                        />}

                                    </Col>
                                    </Row>
                                </div>

                                <div className={styles.addItem}>

                                    {this.state.totalData && this.state.totalData.length > 1 &&
                                        <div style={{
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            marginTop: '10px'
                                        }}>
                                            <div className={styles.subTotal}>
                                                <Descriptions column={1} size={'small'}
                                                              bordered={false}>
                                                    <Descriptions.Item
                                                        label={<b>Sub
                                                            Total</b>}> {`₹${FormatNumber.formatFixed((this.state.grandTotal || 0), 2)}`}

                                                    </Descriptions.Item>

                                                    <Descriptions.Item
                                                        label={
                                                            <b>Round
                                                                Off</b>}>  {this.calculateRoundOff(this.state.grandTotal || 0)}

                                                    </Descriptions.Item>

                                                    <Descriptions.Item
                                                        label={<b>Grand
                                                            Total</b>}>{`₹${FormatNumber.formatFixed(((this.state.grandTotal || 0) - this.state.roundValue), 2)}`}
                                                    </Descriptions.Item>
                                                </Descriptions>
                                                <div style={{whiteSpace: 'break-spaces'}}><b><span
                                                    style={{color: 'black'}}>In
                                                Words: </span>
                                                </b>{numberToText((this.state.grandTotal || 0).toFixed(0), 'Indian')}
                                                </div>
                                            </div>
                                        </div>}

                                </div>
                            </Card>
                            <Card bordered={true} style={{marginBottom: '15px'}}>

                                <div className={styles.notification}>
                                    <Form.Item label={'Quote Note'} style={{flex: 1}}  {...formItemLayout}>
                                        {getFieldDecorator('quoteComment')(<TextArea rows={4}
                                                                                     onChange={ei => {
                                                                                     }}/>)}
                                    </Form.Item>

                                    <Form.Item label={'Internal Note'} style={{flex: 1}}  {...formItemLayout}>
                                        {getFieldDecorator('note')(<TextArea rows={4}
                                                                             onChange={ei => {
                                                                                 this.setState({note: ei.target.value})
                                                                             }}/>)}
                                    </Form.Item>
                                </div>

                                <div className={styles.notification}>
                                    <Checkbox
                                        disabled={(this.props.currentUser.userType == 'RO') ? (!this.state.approved) : false}
                                        defaultChecked={true} onChange={(e) => {
                                        this.setSocial('mail', e.target.checked)
                                    }}><Icon style={{color: '#E8290B'}} type='mail'/> Mail</Checkbox>
                                    <Checkbox
                                        disabled={(this.props.currentUser.userType == 'RO') ? (!this.state.approved) : false}
                                        defaultChecked={false} onChange={(e) => {
                                        this.setSocial('whatsapp', e.target.checked)
                                    }}><Icon style={{color: '#45CE30'}} type='message'/> Whatsapp</Checkbox>
                                    <Checkbox
                                        disabled={(this.props.currentUser.userType == 'RO') ? (!this.state.approved) : false}
                                        defaultChecked={false} onChange={(e) => {
                                        this.setSocial('message', e.target.checked)
                                    }}><Icon style={{color: '#0A79DF'}} type='message'/> Text</Checkbox>
                                </div>
                            </Card>

                            <Form.Item style={{marginTop: 32, textAlign: 'center'}}>
                                <Button size="large" type="primary" htmlType="submit" onClick={() => {
                                    this.setState({
                                        sendNotification: false
                                    })
                                   
                                }}
                              
                                
                                        loading={this.props.loading}
                                        style={{width: '15%'}}>
                                    {id ? `Update` : `Save`}

                                </Button>
                                <Button size="large" type='dashed'
                                        loading={sendLoading}
                                        htmlType='submit'
                                        disabled={this.props.currentUser.userType == 'RO' ? !this.state.approved : false}
                                        onClick={() => {
                                            this.setState({
                                                sendNotification: true,
                                                approved: true,
                                                adminApproved: false
                                            })
                                        }}
                                        style={{width: '15%', marginLeft: '1em'}}>
                                    {(this.state.approved == false) ? 'Approve and Send' : 'Save and Send'}
                                </Button>


                                <Button size="large" type='dashed'
                                        hidden={((this.props.currentUser.userType == 'Admin' || this.props.currentUser.userType == 'Manager') && !this.state.approved) ? false : true}
                                        loading={sendLoading}
                                        htmlType='submit'
                                        onClick={async () => {
                                            this.setState({adminApproved: true})

                                        }}
                                        style={{width: '15%', marginLeft: '1em'}}>Approve
                                </Button>


                            </Form.Item>


                        </Form>
                    </div>

                    <Drawer
                        title=""
                        placement="right"
                        closable={true}
                        onClose={async () => {
                            this.setState({
                                visible: false,
                                showProductForm: false
                            })
                        }}
                        visible={this.state.visible}
                        key={this.state.key}
                        width={1000}>
                        <div>

                            {this.state.showProductForm ? <AddProduct handleProductSubmit={async values => {
                                    let {data} = await Request.getAllProducts({results: 200})
                                    this.setState({
                                        visible: false,
                                        productList: data,
                                        newProductAdded: true
                                    }, () => {
                                        let currentUser = JSON.parse(window.localStorage.getItem('user'))
                                        if (currentUser && currentUser.userType === 'RO') {
                                            this.state.approved = false
                                        }
                                        this.onProductSelect(values && values._id)
                                        this.handleOk()
                                    })


                                }}/> :
                                <AddContact editContactId={showEditButton ? editContactId : ''}
                                            handleSubmit={async values => {

                                                let {data} = await Request.getAllContacts()
                                                this.setContactInfo(editContactId)

                                                this.setState({
                                                    allContacts: data,
                                                    visible: false
                                                })

                                                this.setAddress(values && values._id)


                                            }}/>}


                        </div>
                    </Drawer>


                </Spin>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    currentUser: global.currentUser

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddQuote)
