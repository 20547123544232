import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils'
import moment from 'moment'
import {AiOutlineWhatsApp} from 'react-icons/ai'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import {
    Table,
    Badge, Row, Col,
    Form,
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Select,
    Switch,
    Input, Button, Icon, Drawer
} from 'antd'
import {API_URL} from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import {connect} from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import {getUrlPushWrapper} from '../../../routes'
import Request from '../../../request'
import {goBack} from 'connected-react-router'
import {hideLoader, showLoader} from '../../../modules/actions'

const Categories = ['Milk Testing Equipment', 'Glassware', 'IOT']


const {Option} = Select
const {TextArea} = Input


@Form.create()

class AllProducts extends Component {
    handleSubmit = e => {
        const {dispatch, form} = this.props
        const {mode} = this.state
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)
                let user = JSON.parse(localStorage.getItem('user'))

                dispatch(showLoader())

                let x = null
                if (mode == 'sms') {
                    values.userName = user && user.name
                    x = await Request.sendPdfBySms(values)
                }
                if (mode == 'mail') {
                    values.html = values.template
                    delete values.template;
                    let user = JSON.parse(localStorage.getItem('user'));
                    // values.senderMail = user.email;
                    values.replyToMail = user.email;

                    x = await Request.sendPdfByMail(values)
                }
                dispatch(hideLoader())
                if (!x.error) {
                    notification.success({
                        message: x.msg ? x.msg : `${mode} sent Successfully`
                    })
                    this.setState({visible: false, mode: ''})
                    form.resetFields()
                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }
            }
        })
    }

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            currentPage: 1,
            count: 0,
            visible: false,
            editorState:
            '<div \n' +
            '                        \n' +
            '                        \n' +
            '                        <h1>Bulb fox</h1>\n' +
            '                        <h5>The client</h5>\n' +
            '                        <p>We focus on creating highly intuitive, usable and impactful digital products and services. Ideas is seamlessly integrated into our design process to produce the most effective, elegant and engaging work.</p>\n' +
            '                        <h5>The objective</h5>\n' +
            '                        <p>Ideas is seamlessly integrated into our design process to produce the most effective, elegant and engaging work.</p>\n' +
            '                        <h5>The solution</h5>\n' +
            '                        <p>We reimagined deas is seamlessly integrated into our design process to produce the most effective, elegant and engaging work.By using a combination of sketching, 3-D modeling, rapid prototyping, user testing and analytics analysis, we’re able to continuously grow and improve upon a given product. We focus most effective, elegant and engaging work on creating highly intuitive, usable and impactful digital products and services.</p>\n' +
            '                        \n' +
            '                        \n' +
            '                        \n' +
            '                        \n' +
            '                        \n' +
            '                        \n' +
            '                        \n' +
            '                    </div>',
            category: ['Milk Testing Equipment', 'Glassware', 'IOT']
        }

    }

    deleteProduct = async data => {
        this.setState({loading: true})
        let x = await Request.deleteProduct(data)
        this.setState({loading: false})

        this.table.current.reload()
        if (!x.error) {
            notification.success({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })

        }
        else {
            notification.error({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })
        }


    }
    apiRequest = (params) => {
        if (params.page) {
            this.state.currentPage = params.page
        }
        return new Promise(async (resolve) => {
            let data = await Request.getAllProducts({
                ...params, regExFilters: ['name']
            })
            this.setState({count: data.total})
            resolve(data)
        })
    }
    showDrawer = () => {
        this.setState({
            visible: true
        })
    }

    componentDidMount() {
    }

    render() {
        const {submitting, dispatch} = this.props
        const {visible, mode, productPdfUrl, category, currentPage} = this.state
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props


        const columns = [
            {
                title: 'S.No',
                dataIndex: 'sno',
                width: 50,
                key: 'sno',
                fixed: 'left',
                render: (value, item, index) => (currentPage - 1) * 10 + index + 1
            },

            {
                title: 'Id',
                dataIndex: 'id',
                key: 'id',
                searchTextName: 'id'


            },
            {
                title: 'Product Code',
                dataIndex: 'productCode',
                key: 'productCode',
                searchTextName: 'productCode'

            },

            {
                title: 'Product Name',
                dataIndex: 'name',
                key: 'name',
                searchTextName: 'name'

            },
            {
                title: 'Stock',
                dataIndex: 'inStock',
                key: 'inStock'

            },
            {
                title: 'Product Category',
                dataIndex: 'productCategory',
                key: 'productCategory',
                filters: category && category.map(x => ({value: x, text: x})),

            },

            {
                title: 'Manufacturer',
                dataIndex: 'manufacturer',
                key: 'manufacturer'

            },
            {
                title: 'Price Information',
                key: 'priceInformation',
                render: (val, record) => {
                    return <div>
                        <div>Unit Price: {record && record.priceInformation && record.priceInformation.unitPrice}</div>
                        <div>GST: {record && record.priceInformation && record.priceInformation.gstTax}</div>
                    </div>
                }
            },


            {
                title: 'Warranty',
                dataIndex: 'warrantyValue',
                key: 'warrantyValue',
                render: (x, item) => {
                    return <div>{item && item.warrantyValue} {item && item.warrantyType}</div>
                }
            },

            {
                title: 'YouTube Link',
                key: 'youtubeLink',
                dataIndex: 'youtubeLink',
                render: (val, record) => {
                    return <div>
                        <a href={val}
                           target={'_blank'}> {record && record.youtubeLink ? record.youtubeLink : ''}</a>
                    </div>


                }
            },
            {
                title: 'Facebook Link',
                key: 'facebookLink',
                dataIndex: 'facebookLink',
                render: (val, record) => {
                    return <div>
                        <a href={val}
                           target={'_blank'}> {record && record.facebookLink ? record.facebookLink : ''}</a>
                    </div>
                }
            },
            {
                title: 'Product Pdf',
                key: 'productPdf',
                dataIndex: 'productPdf',
                render: (val, record) => {
                    if (record && record.productPdf && record.productPdf.length > 0) {
                        const url = record && record.productPdf ? API_URL + `${record.productPdf[0].url}` : ''
                        return <div>{url && <a href={url}
                                               target={'_blank'}> Pdf Link</a>}
                        </div>
                    }

                }
            },
            {
                title: 'Notes',
                dataIndex: 'notes',
                key: 'notes'
            },
            {
                title: 'Updated At',
                // width: 120,
                key: 'updatedAt',
                dataIndex: 'updatedAt',
                sorter: true,
                render: (text, record) => {
                    return (
                        <div>
                            {text ? moment(text).format('lll') : ''}
                        </div>
                    )
                }
            },
            {
                title: 'Created At',
                //  width: 120,
                dataIndex: 'createdAt',
                key: 'createdAt',
                sorter: true,
                render: (text, record) => {
                    return (<div>  {text ? moment(text).format('lll') : ''}</div>)

                }
            },
            {
                key: 'actions',
                title: 'Actions',
                fixed: 'right',
                width: 150,
                render: (text, record) => {
                    return <React.Fragment>
                        <Tooltip title="Edit Product">
                            <Button
                                shape="circle"
                                style={{marginRight: 6}}
                                size="small"
                                onClick={() => {
                                    dispatch(
                                        getUrlPushWrapper('product.editProduct', {
                                            id: record._id
                                        })
                                    )
                                }}
                                icon="edit"
                            />
                        </Tooltip>
                        <Tooltip title="Delete Product">
                            <Popconfirm
                                title="Are you sure to delete this Product?"
                                onConfirm={() => {
                                    this.deleteProduct({_id: record._id})
                                }}
                                onCancel={() => {
                                    console.log()
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button
                                    shape="circle"
                                    size="small"

                                    icon="delete"
                                />
                            </Popconfirm>

                        </Tooltip>
                        {record && record.productPdf && record.productPdf.length > 0 && <Button
                            type="primary"
                            size="small"
                            style={{marginLeft: '5px'}}
                            onClick={() => {
                                let productPdfUrl = record && record.productPdf && API_URL + `${record.productPdf[0].url}`
                                this.setState({productDetails: record, productPdfUrl}, () => {
                                    this.showDrawer()
                                })
                            }
                            }>Share</Button>}
                    </React.Fragment>
                }
            }
        ]

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 5}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 18}
            }
        }

        return (
            <PageHeaderWrapper
                title={this.state.count ? `All Products: ${this.state.count}` : `All Product: 0`}>

                <Card bordered={true}>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               extraProps={{scroll: {x: 2200}}}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}

                    />
                </Card>
                <Drawer
                    title=""
                    placement="right"
                    closable={true}
                    onClose={async () => {
                        this.setState({
                            visible: false,
                            mode: ''
                        })
                    }}
                    visible={this.state.visible}
                    width={600}>
                    <Card bordered={true} style={{margin: '10px'}}>
                        <div style={{margin: '10px', display: 'inline-block'}}>
                            <label className={styles.label}>Share Mode :</label>

                            <Tooltip title="SMS">
                                <Button
                                    size={'medium'}
                                    className={styles.btnPrimary}
                                    onClick={() => {
                                        this.setState({mode: 'sms'}, () => {
                                            this.props.form.setFieldsValue({
                                                message: '<div \n' +
                                                '                        \n' +
                                                '                        \n' +
                                                '                        <h1>Hi</h1> <br>\n' +
                                                '                        <h5>attaching product details pdf. kindly go through it:</h5><br> \n' +
                                                `                        <a href=${productPdfUrl}>${productPdfUrl}</a>\n` +
                                                '                        \n' +
                                                '                        \n' +
                                                '                    </div>'
                                            })
                                        })
                                    }}
                                    icon="message"/>
                            </Tooltip>
                            <Tooltip title="Email">
                                <Button
                                    size={'medium'}
                                    className={styles.btnSuccess}
                                    onClick={() => {
                                        this.setState({mode: 'mail'}, () => {
                                            this.props.form.setFieldsValue({
                                                subject: 'Attaching pdf file',
                                                //template: `Hi, attaching product details pdf. kindly go through it to check details.-${productPdfUrl}`
                                                template: '<div \n' +
                                                '                        \n' +
                                                '                        \n' +
                                                '                        <h1>Hi</h1>\n' +
                                                '                        <h5>attaching product details pdf. kindly go through it to check details:</h5>\n' +
                                                `                        <a href=${productPdfUrl}>${productPdfUrl}</a>\n` +
                                                '                        \n' +
                                                '                        \n' +
                                                '                    </div>'
                                            })
                                        })
                                    }}
                                    icon="mail"/>
                            </Tooltip>
                            <Tooltip title="WhatsApp">
                                <Button
                                    size={'medium'}
                                    className={styles.watsappBtn}
                                    onClick={() => {
                                        this.setState({mode: 'whatsapp'})
                                    }}
                                > <AiOutlineWhatsApp className={styles.locIcon}/></Button>
                            </Tooltip>
                        </div>

                        <div style={{margin: '40px 0px'}}>
                            {mode == 'sms' || mode == 'mail' ?
                                <Form onSubmit={this.handleSubmit} hideRequiredMark={false}>

                                    <React.Fragment>
                                        {mode == 'sms' && <Row>
                                            <Col span={24}>

                                                <Form.Item label={'Mobile'} {...formItemLayout} >
                                                    {getFieldDecorator('mobile', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: `Please input mobile!`
                                                            }
                                                        ]
                                                    })(<Select
                                                        mode="tags"
                                                        placeholder="Tags Mode"
                                                        value={this.state.mobile}
                                                        onChange={ss => {
                                                            this.setState({mobile: ss})
                                                        }}
                                                    />)}
                                                </Form.Item>
                                                {/*  <Form.Item label={'Message'} {...formItemLayout}>
                        {getFieldDecorator('message', {
                          rules: [
                            {
                              required: true,
                              message: `Please input message!`
                            }
                          ]
                        })(<TextArea rows={5}/>)}
                      </Form.Item>*/}
                                                <Form.Item {...formItemLayout} label={'Message'}>
                                                    {getFieldDecorator('message', {
                                                        initialValue: this.state.editorState,
                                                        valuePropName: 'value',
                                                        getValueFromEvent: this.onChange
                                                    })(<ReactQuill/>)}
                                                </Form.Item>
                                            </Col>
                                        </Row>}
                                        {mode == 'mail' &&
                                        <Row>
                                            <Col span={24}>
                                                <Form.Item label={'Email'} {...formItemLayout}>
                                                    {getFieldDecorator('email', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: `Please input email!`
                                                            }
                                                        ]
                                                    })(<Select
                                                        mode="tags"
                                                        placeholder="Tags Mode"
                                                        value={this.state.email}
                                                        onChange={ss => {
                                                            this.setState({email: ss})
                                                        }}
                                                    />)}
                                                </Form.Item>
                                                <Form.Item label={'Subject'} {...formItemLayout}
                                                >
                                                    {getFieldDecorator('subject', {
                                                        rules: [
                                                            {
                                                                required: true,
                                                                message: `Please input subject!`
                                                            }
                                                        ]
                                                    })(<TextArea rows={2}/>)}
                                                </Form.Item>
                                                {/*   <Form.Item label={'Template'} {...formItemLayout} >
                        {getFieldDecorator('template', {
                          rules: [
                            {
                              required: true
                            }
                          ]
                        })(<TextArea rows={10}/>)}
                      </Form.Item>*/}
                                                <Form.Item {...formItemLayout} label={'Template'}>
                                                    {getFieldDecorator('template', {
                                                        initialValue: this.state.editorState,
                                                        valuePropName: 'value',
                                                        getValueFromEvent: this.onChange
                                                    })(<ReactQuill/>)}
                                                </Form.Item>
                                            </Col>
                                        </Row>}
                                        <div style={{textAlign: 'center', marginTop: '20px'}}><Button
                                            type={'primary'}
                                            htmlType="submit"
                                            loading={this.props.loading}
                                        >SEND</Button></div>
                                    </React.Fragment>


                                </Form> : <React.Fragment>
                                    {mode == 'whatsapp' && <Row>
                                        <Col span={24}>
                                            <label className={styles.label}>WhatsApp Number :</label>
                                            <Input style={{width: '200px'}}
                                                   onChange={(v) => {
                                                       this.setState({watsappNumber: v.target.value})
                                                   }
                                                   }/>

                                            {this.state.watsappNumber && this.state.watsappNumber.length == 10 &&
                                            <div style={{margin: '20px 0px 0px 100px'}}>
                                                <Tooltip title="Share on Whatsapp">
                                                    <a target={'_blank'}
                                                       style={{
                                                           textDecoration: 'underline',
                                                           fontSize: '15px',
                                                           fontWeight: 'bold'
                                                       }}
                                                       href={`http://api.whatsapp.com/send?phone=91${this.state.watsappNumber}&text=Hi attaching product details pdf. kindly go through it:-  ${productPdfUrl}&source=&data=`}
                                                       onClick={() => {
                                                           this.setState({visible: false, mode: '', watsappNumber: ''})
                                                       }}
                                                    >Share
                                                        on Whatsapp</a>
                                                </Tooltip>
                                            </div>}
                                        </Col>
                                    </Row>}
                                </React.Fragment>}


                        </div>
                    </Card>
                </Drawer>


            </PageHeaderWrapper>
        )

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllProducts)
