import React, { Component } from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import moment from 'moment'
import { TableComp } from 'sz-react-utils'
import {
  Card,
  Tooltip,
  Popover,
  Button,
  Tag,
  Modal,
  Form,
  Select,
  notification,
  AutoComplete,
  Spin,
} from 'antd'
import Request from '../../request'
import _ from 'lodash'
import styles from './styles.less'
import { connect } from 'react-redux'
import { push } from 'connected-react-router'
import ReactPlayer from 'react-player/lazy'
import { apiUrl } from '../../settings'
import { getUrlParams } from '../../routes'
import { FiExternalLink } from 'react-icons/fi'

const { Option } = Select
@Form.create()
class Recordings extends Component {
  apiRequest = (params) => {
    if (params.page) {
      this.state.currentPage = params.page
    }

    return new Promise(async (resolve) => {
      const { RONumber, urlRONumber } = this.state
      if (!params.sortField) {
        params.sortField = 'createdAt'
        params.sortOrder = 'descend'
      }
      if (RONumber) {
        params.roNumber = RONumber
      }
      if (urlRONumber) {
        params.roNumber = urlRONumber
        params.createdAt = {
          $gte: this.state.gte,
          $lte: this.state.lte,
        }
      }

      if (this.state.linkedStatus) {
        params.leadId =
          this.state.linkedStatus === 'linked' ? { $ne: null } : { $eq: null }
      }
      let data = await Request.getAllRecordings({
        ...params,
        regExFilters: ['title'],
      })
      this.setState({ count: data.total || 0 })
      resolve(data)
    })
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
    this.state = {
      count: 0,
      currentPage: 1,
      assignLead: false,
      leadList: [],
      roList: [],
      leadNamesFiltered: [],
      selectedLead: undefined,
      showPlayer: [],
    }
    this.searchLead = _.debounce(this.handleDealsSearch, 100, true)

    let searchParams = getUrlParams('recordingUrl', window.location.pathname)
    if (searchParams) {
      let { gte, lte, officerId } = searchParams
      if (officerId) {
        this.state.urlRONumber = officerId
        this.state.lte = lte
        this.state.gte = gte
      }
    }
  }

  getLeads = async () => {
    let { data: leadList } = await Request.getAllLeads({ results: 10 })
    this.setState({ leadList: leadList })
  }
  getAllRO = async () => {
    let { data: roList } = await Request.getAllROList({ results: 10 })
    this.setState({ roList: roList })
  }
  handleDealsSearch = async (test) => {
    let obj = {}
    this.setState({ showSpin: true })

    let currentUser = JSON.parse(window.localStorage.getItem('user'))

    if (currentUser && currentUser.userType === 'RO') {
      obj.officer = currentUser._id
    }
    if (test === '') {
      this.setState({ leadNamesFiltered: this.state.leadNames }, () => {
        this.setState({
          showSpin: false,
          isEdited: false,
          showEditButton: false,
          billingData: null,
          shippingData: null,
        })
      })
      return false
    }
    obj.text = test
    let { data, error, message } = await Request.leadsSearch(obj)

    if (!error) {
      this.setState({ leadNamesFiltered: data }, () => {
        this.setState({ showSpin: false })
      })
    }
  }
  onDealSelect = async (val) => {
    let selectedQuote = this.state.leadNamesFiltered.find(
      (obj) => obj._id === val
    )
    if (selectedQuote) {
      this.props.form.setFieldsValue({
        lead: selectedQuote.title,
        // contactName: selectedQuote.contact._id,
      })
      this.setState({ selectedLead: selectedQuote._id })
    }
  }

  assignRecordings = async () => {
    const { recording, selectedLead } = this.state

    const user = JSON.parse(window.localStorage.getItem('user'))
    if (!selectedLead) {
      return
    }
    this.setState({ assignLoader: true })
    let { data, error, message } = await Request.updateRecording(
      recording._id,
      { leadId: selectedLead, user }
    )
    this.setState({ assignLoader: false })
    if (!error) {
      notification.success({ message: message })
      this.table.current.reload()
      this.props.form.setFieldsValue({
        lead: '',
        // contactName: selectedQuote.contact._id,
      })
      this.setState({
        selectedLead: undefined,
        assignLead: false,
        recording: undefined,
      })
    } else {
      notification.error({ message: message })
    }
  }

  handleVisibleChange = (key, value) => {
    let newData = _.clone(this.state.showPlayer)
    newData[key] = value
    this.setState({ showPlayer: newData })
  }

  render() {
    const {
      form,
      form: { getFieldDecorator, getFieldValue },
    } = this.props

    let {
      count,
      RONumber,
      roList,
      assignLead,
      currentPage,
      selectedLead,
      leadList,
      showSpin,
      leadNamesFiltered,
      assignLoader,
      showPlayer,
    } = this.state
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 },
      },
    }
    const columns = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        width: 50,
        key: 'sno',
        fixed: 'left',
        render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
      },
      {
        title: 'Title',
        dataIndex: 'title',
        key: 'title',
        searchTextName: 'title',
        width: 180,
        fixed: 'left',
      },
      {
        title: 'RO Number',
        key: 'roNumber',
        //  width: 100,
        render: (val, record) => {
          return (
            <div>
              <div>{record && record.roName}</div>
              <div>{record && record.roNumber}</div>
              <div>{record && record.roEmail}</div>
            </div>
          )
        },
      },
      {
        title: 'Size',
        dataIndex: 'size',
        key: 'size',
        //  width: 100
      },
      {
        title: 'Mime Type',
        dataIndex: 'mimeType',
        key: 'mimeType',
        // width: 100
      },
      {
        title: 'Duration',
        dataIndex: 'duration',
        key: 'duration',
        //   width:100,
        render: (val) => {
          return val && `${moment.utc(val * 1000).format('HH:mm:ss')}`
        },
      },
      {
        title: 'Lead',
        dataIndex: 'leadId',
        key: 'leadId',
        width: 300,
        render: (val) => (
          <Tag
            color={val ? 'green' : 'red'}
            style={val && { cursor: 'pointer' }}
            // onClick={() => {
            //     if (val)
            //         this.props.dispatch(push(`/lead/detail?id=${val._id}`))
            // }}
          >
            {val ? (
              <a href={`/lead/detail?id=${val._id}`} target={'_blank'}>
                {val.title}
              </a>
            ) : (
              'Not Linked'
            )}
          </Tag>
        ),
      },

      {
        title: 'Recording Time',
        dataIndex: 'createdAt',
        key: 'createdAt',
        //     width: 100,
        sorter: true,
        render: (item) => {
          return (
            <Tooltip title={moment(item).format('lll')}>
              {moment(item).fromNow()}
            </Tooltip>
          )
        },
      },
      {
        title: 'Created At',
        dataIndex: 'timeStamp',
        key: 'timeStamp',
        sorter: true,
        // width: 100,
        render: (item) => {
          return (
            item && (
              <Tooltip title={moment(item).format('lll')}>
                {moment(item).fromNow()}
              </Tooltip>
            )
          )
        },
      },
      {
        title: 'Actions',
        dataIndex: 'action',
        key: 'action',
        width: 180,
        render: (item, record, key) => {
          return (
            <>
              <Popover
                content={
                  <ReactPlayer
                    url={`${apiUrl}/recordingStream?path=${record.path}&isPath=true`}
                    controls
                    height={50}
                    className={styles.player}
                    width={400}
                  />
                }
                placement={'bottomRight'}
                trigger="click"
                destroyTooltipOnHidek
                visible={showPlayer[key]}
                onVisibleChange={(val) => this.handleVisibleChange(key, val)}>
                <Button icon="play-circle" shape="circle" />
              </Popover>

              <Tooltip title={'Link to lead'}>
                <Button
                  icon="link"
                  style={{ marginLeft: '10px' }}
                  onClick={() => {
                    this.setState({ assignLead: true, recording: record })
                  }}
                />
              </Tooltip>

              <Tooltip title={'External Link'}>
                <a
                  href={`${apiUrl}/singleRecording?path=${record.path}`}
                  target={'_blank'}>
                  <Button
                    shape="square"
                    style={{ marginLeft: '10px', padding: '5px 8px' }}>
                    <FiExternalLink />
                  </Button>
                </a>
              </Tooltip>

            </>
          )
        },
      },
    ]
    return (
      <PageHeaderWrapper
        title={
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div>{`All Recordings: ${count}`}</div>
          </div>
        }>
        <Card bordered={true}>
          <Select
            showSearch
            allowClear
            optionFilterProp="children"
            style={{
              width: 225,
            }}
            mode={'multiple'}
            placeholder="Select RO"
            // value={RONumber}
            onChange={(RONumber) => {
              this.setState({ RONumber }, () => {
                this.table.current.reload()
              })
            }}>
            {roList &&
              roList.map((val, idn) => {
                return (
                  <Option key={idn} value={val.mobile}>
                    {val.name} ({val.mobile})
                  </Option>
                )
              })}
          </Select>
          <Select
            showSearch
            allowClear
            optionFilterProp="children"
            style={{
              width: 100,
              marginLeft: '10px',
            }}
            placeholder="Linked Filter"
            onChange={(linkedStatus) => {
              this.setState({ linkedStatus }, () => {
                this.table.current.reload()
              })
            }}>
            <Option value={'linked'}>Linked</Option>
            <Option value={'notLinked'}>Not Linked</Option>
          </Select>

          <TableComp
            ref={this.table}
            columns={columns}
            apiRequest={this.apiRequest}
            extraProps={{ scroll: { x: 1300 } }}
            pagination={{
              ...this.state.pagination,
              defaultPageSize: 10,
              pageSizeOptions: ['10', '25', '50', '100'],
              showSizeChanger: true,
              ...this.props.pagination,
            }}
          />
        </Card>
        <Modal
          title="Assign Leads"
          onCancel={async () => {
            this.props.form.setFieldsValue({
              lead: '',
              // contactName: selectedQuote.contact._id,
            })
            this.setState({
              assignLead: false,
              selectedLead: undefined,
            })
          }}
          visible={assignLead}
          okText="Assign"
          onOk={this.assignRecordings}
          okButtonProps={{
            loading: assignLoader,
          }}>
          <div style={{ textAlign: 'center' }}>
            <Form layout="inline">
              <Form.Item label="Lead ">
                {getFieldDecorator(
                  'lead',
                  {}
                )(
                  <AutoComplete
                    style={{
                      width: 300,
                    }}
                    notFoundContent={showSpin && <Spin />}
                    autoComplete={'none'}
                    onSelect={this.onDealSelect}
                    onSearch={this.searchLead}
                    placeholder={'Lead Name'}
                    size={30}>
                    {leadNamesFiltered.map((val, item) => (
                      <Option value={val._id}>
                        <>
                          <div>
                            <b>{`${val.title} (${val.id})`}</b>
                          </div>
                          <div
                            className={styles.autoCompleteList}
                            style={{ marginTop: '0px' }}>{`${
                            val.contact && val.contact.email
                              ? val.contact.email
                              : ''
                          }`}</div>
                          <div className={styles.autoCompleteList}>{`${
                            val.contact && val.contact.firstName
                          } (${val.contact && val.contact.mobile})`}</div>
                          <div className={styles.autoCompleteList}>{`Company: ${
                            val.company || ''
                          }`}</div>
                          <Tag>{`${moment(val.createdAt).format(
                            'DD-MM-YYYY HH:mm'
                          )}`}</Tag>
                        </>
                      </Option>
                    ))}
                  </AutoComplete>
                )}
              </Form.Item>
            </Form>
          </div>
        </Modal>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  categories: global.categories,
  loading: global.buttonLoading,
  search: router.location.search,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Recordings)
