import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    notification,
    Anchor,
    Spin,
    Select,
    Tag,
    Button,
    Table,
    Card,
    Typography,
    Row,
    Col,
    Icon, Tooltip, Popconfirm, Avatar, Checkbox, AutoComplete, Radio, Drawer, Upload
} from 'antd'
import _ from 'lodash'
import {FormUtils as GetAllFormFields, TableComp} from 'sz-react-utils'
import moment from 'moment'


import {API_URL, checkPincode} from '../../../request'

import 'react-quill/dist/quill.snow.css'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {connect} from 'react-redux'
import {createMatchSelector, goBack} from 'connected-react-router'
import styles from '../../quote/add/styles.less'


const FormItem = Form.Item
const {Option} = Select
const {Text, Paragraph} = Typography
const {TextArea} = Input

@Form.create()
class AddOrderDispatchForm extends PureComponent {

    handleSubmit = e => {
        const {dispatch, form, currentUser} = this.props
        let {totalData, userList} = this.state

        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)
                if (totalData && totalData.length == 1) {
                    return notification.error({
                        message: 'Atleast add one product'
                    })
                }

                totalData = _.reject(this.state.totalData, (x) => x.isLast == true)
                values.products = totalData
                let found = _.find(userList, (ii) => {
                    return ii._id === values.contactOwner
                })
                if (found) {
                    values.contactPerson = found && found.name
                    values.contactNumber = found && found.mobile

                }
                let x = null
                dispatch(showLoader())

                values.leadId = this.state.leadId
                if (this.state.id) {
                    values._id = this.state.id

                    x = await Request.updateOrderAction(values)
                } else {
                    x = await Request.addOrderDispatch(values)
                }

                dispatch(hideLoader())


                if (!x.error) {

                    notification.success({
                        message: this.state.id
                            ? x.message
                            : x.message
                    })

                    if (!this.state.id) {
                        this.props.form.resetFields()

                        this.props.form.setFieldsValue({orderDate: moment()})

                        this.setState({
                            totalData: [{isLast: true}], leadNamesFiltered: [], pincodeName: '', newOption: null
                        })


                    }

                    if (this.state.id) {
                        dispatch(goBack())

                    }


                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }

    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            productList: [],
            totalData: [],
            time: Date.now(),
            loader: true,
            outwardPendingList: [],
            selectedRowKeys: [],
            leadNamesFiltered: [], carriersData: []

        }
        this.AddData = _.debounce(this.addData, 1000, true)
        this.searchLead = _.debounce(this.handleDealsSearch, 1000, true)


    }

    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search)
        let id = searchParams.get('id')

        Request.getAllProducts({results: 2000}).then(({data: productList}) => {
            this.setState({productList: productList || []}, () => {

            })
        })
        Request.loadUser({userType: 'RO'}, {results: 1000000000000}).then(({data: userList}) => {
            this.setState({userList: userList})
        })

        Request.getAllCarriers().then(({data, error, message}) => {

            if (!error) {
                this.setState({carriersData: data && data[0] && data[0].carriers})


            }
        })

        this.setState({
            loader: true
        })
        if (id) {
            Request.orderDispatchList({_id: id}).then(({data, error, message}) => {

                if (!error) {
                    let orderDispatch = data[0]

                    this.setState({
                        id: id,
                        loader: false,
                    })
                    this.state.leadId = data.leadId


                    this.props.form.setFieldsValue({

                        contactName: orderDispatch && orderDispatch.contactName,
                        mobile: orderDispatch && orderDispatch.mobile,
                        email: orderDispatch && orderDispatch.email,
                        state: orderDispatch && orderDispatch.state,
                        address: orderDispatch && orderDispatch.address,
                        orderDate: orderDispatch && moment(orderDispatch.orderDate),
                        gstNumber: orderDispatch && orderDispatch.gstNumber,
                        invoiceNumber: orderDispatch && orderDispatch.invoiceNumber,
                        challanNumber: orderDispatch && orderDispatch.challanNumber,
                        remarks: orderDispatch && orderDispatch.remarks,
                        leadName: orderDispatch && orderDispatch.leadName,
                        pincode: orderDispatch && orderDispatch.pincode,
                        carrier: orderDispatch && orderDispatch.carrier,
                        shipTo: orderDispatch && orderDispatch.shipTo,
                        parcelType: orderDispatch && orderDispatch.parcelType,
                        goodsType: orderDispatch && orderDispatch.goodsType,
                        contactOwner: orderDispatch && orderDispatch.contactOwner


                    })

                    this.state.totalData = [...orderDispatch.products, {isLast: true}]
                } else {
                    console.log(error)
                    notification.error({
                        message: 'Error Getting Data'
                    })
                }
            })
        } else {
            this.setState({
                loader: false
            })
            this.state.totalData.push({isLast: true})
            this.props.form.setFieldsValue({orderDate: moment()})
        }


    }

    handleCancel = e => {

        this.props.form.setFieldsValue({
            quantity: null,
            productName: undefined
        })
        this.setState({
            modalVisible: false,
            productName: '',
            quantity: ''


        })
    }


    addData = () => {


        let tt = _.cloneDeep(this.state.totalData)
        let found = _.find(tt, (i) => {
            if (i && i.isLast != true) {
                return (i.productName == this.state.productName) || (i.productName._id == this.state.productName)

            }
        })

        if (found) {
            this.setState({showText: false})
            this.props.form.setFieldsValue({

                productName: undefined,
                quantity: null

            })

            return notification.error({
                message: 'Product Already Exists.'
            })
        }
        let comp = tt.pop()
        tt.push({

            quantity: this.state.quantity,
            productName: this.state.productName,
            isLast: false,
            productIds: [],
            subjectedToStock: this.state.subjectedToStock ? this.state.subjectedToStock : false,
            productId: this.state.productId,
            photos: this.state.photos,
            description: this.state.description

        })
        tt.push(comp)
        this.props.form.setFieldsValue({
            quantity: null,
            totalData: tt,
            productName: undefined

        })
        this.setState({
            quantity: null,
            totalData: tt,
            productName: undefined,
            subjectedToStock: undefined,
            productId: null,
            photos: null,
            description: null


        }, () => {

        })

    }
    handleProductSearch = async (test) => {
        if (test == '' || test == undefined) {
            this.setState({productList: this.state.productList})

            return false
        } else {
            let tt = _.cloneDeep(this.state.totalData)

            this.setState({
                totalData: tt,
                productName: test,
                quantity: 1


            }, () => {
                this.addData()
            })
        }


    }

    onProductSelect = (val) => {
        let selectedQuote = this.state.productList.find((obj) => obj.name === val)
        if (selectedQuote) {
            this.props.form.setFieldsValue({
                productName: selectedQuote.name

            })
            this.setState({
                productName: selectedQuote.name,
                productId: selectedQuote && selectedQuote._id,
                quantity: 1,
                photos: selectedQuote.productImage && selectedQuote.productImage.length ? selectedQuote.productImage[0].url : '',
                description: selectedQuote && selectedQuote.notes

            }, () => {
                this.addData()
            })
        } else {
            this.handleProductSearch(val)
        }

    }
    onSelect = (data) => {
        console.log('onSelect', data)
    }

    onDealSelect = async (val) => {
        let selectedQuote = this.state.leadNamesFiltered.find((obj) => obj._id === val)
        if (selectedQuote) {
            this.props.form.setFieldsValue({
                leadName: selectedQuote.title,
                contactName: selectedQuote && selectedQuote.contact && selectedQuote.contact.firstName,
                mobile: selectedQuote && selectedQuote.contact && selectedQuote.contact.mobile,
                email: selectedQuote && selectedQuote.contact && selectedQuote.contact.email,
                state: selectedQuote && selectedQuote.state

            })
            this.state.leadId = selectedQuote._id

        }
    }

    handleDealsSearch = async (test) => {
        let obj = {}
        this.setState({showSpin: true})

        if (test === '') {
            this.setState({leadNamesFiltered: this.state.leadNames}, () => {
                this.setState({
                    showSpin: false,
                    isEdited: false,
                    showEditButton: false,
                    billingData: null,
                    shippingData: null
                })
            })
            return false

        }
        obj.text = test
        obj.dontCheckStatus = true
        let {data, error, message} = await Request.leadsSearch(obj)
        if (!error) {

            this.setState({leadNamesFiltered: data}, () => {
                this.setState({showSpin: false})

            })
        }
    }
    onChangeDescription = (data, index) => {
        return <TextArea style={{
            width: '130px',
            height: '80px'
        }} onChange={(val) => {

            let tt = this.state.totalData
            tt[index].description = val.target.value

            this.setState({totalData: tt}, () => {
            })

        }}>{data}</TextArea>

    }
    handlePincodeCheck = async (rule, value, callback) => {
        this.setState({pincodeName: ''})

        if (!value) {
            return Promise.reject('This is a Mandatory Field')
        }
        if (value.length === 6) {
            let regex = /^\d{6}$/
            let findVal = regex.exec(value)
            if (findVal === null) {
                return Promise.reject('Invalid Pincode')
            }
            if (findVal.input) {
                let x = await Request.checkPin({pincode: value})
                if (!x.error) {
                    this.setState({pincodeName: x && x.data && x.data.districtName})
                    return Promise.resolve()
                } else {
                    return Promise.reject('Pincode not found')
                }
            }


        } else {
            return Promise.reject('Pincode number should be of 6 digit')
        }


    }
    handlePreview = async (file) => {
        let url
        if (file && file.response && file.response.path) {
            file.response.path = file.response.path.replace(/^public/, '')
            url = `${API_URL}/${file.response.path}`
        } else {
            url = `${API_URL}/${file.url}`
        }
        window.open(url, '_blank')
    }

    render() {
        const {
            form,
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        const {
            productList,
            carriersData,
            id,
            leadNamesFiltered,
            showSpin,
            userList
        } = this.state


        const inputTypes = {
            fields: [
                {
                    label: 'Contact Name',
                    key: 'contactName'
                },
                {
                    label: 'Mobile',
                    key: 'mobile'
                },
                {
                    label: 'Email',
                    key: 'email'
                },
                {
                    label: 'State',
                    key: 'state'
                },
                {
                    label: 'Address',
                    key: 'address',
                    type: 'textArea'

                },
                {
                    label: 'Ship To',
                    key: 'shipTo',
                    type: 'textArea',
                    required: true,
                    rows: 5

                },
                {
                    label: 'Contact Owner',
                    key: 'contactOwner',
                    required: true,
                    showSearch: true,
                    keyAccessor: x => x._id,
                    valueAccessor: x => `${x.name} (${x && x.id}) (${x.mobile}) `,
                    type: 'select',
                    options: userList || [],
                    onChange: contactOwner => {
                        this.props.form.setFieldsValue({
                            contactOwner: contactOwner
                        })


                    }

                }

            ]
        }
        const inputTypes1 = {
            fields: [
                {
                    label: 'Date',
                    key: 'orderDate',
                    type: 'date',
                    required: true

                },
                {
                    label: 'GST',
                    key: 'gstNumber'

                },
                {
                    label: 'Invoice Number',
                    key: 'invoiceNumber'

                },
                {
                    label: 'Challan Number',
                    key: 'challanNumber'

                },
                {
                    label: 'Remarks',
                    key: 'remarks',
                    type: 'textArea',
                    rows: 8

                },
                {
                    label: 'Parcel Type',
                    key: 'parcelType',
                    required: true,
                    type: 'select', options: ['Sale', 'Repaired'],
                    onChange: v => {
                        this.props.form.setFieldsValue({parcelType: v})
                    }

                },
                {
                    label: 'Goods Type',
                    key: 'goodsType',
                    required: true,
                    type: 'select', options: ['Finished  Goods', 'Repaired Goods'],
                    onChange: v => {
                        this.props.form.setFieldsValue({goodsType: v})
                    }

                },
            ]
        }


        const refColumns = [

            {
                title: 'S.No',
                dataIndex: 'sno',
                key: 'sno',
                width: 50,
                render: (value, item, index) => {
                    if (item && item.isLast != true) {
                        return index + 1
                    }
                }
            },
            {
                title: 'Product',
                dataIndex: 'productName',
                key: 'productName',
                width: 180,
                render: (x, item, index) => {
                    if (item && item.isLast == true) {

                        return (<div style={{width: '200px'}}>

                            {getFieldDecorator('productName', {})(
                                <AutoComplete
                                    notFoundContent={this.state.showSpin && <Spin/>}
                                    autoComplete={'none'}
                                    style={{width: 200}}
                                    onSelect={this.onProductSelect}
                                    placeholder={'Product Name'}
                                    onSearch={(val) => {
                                        this.setState({newOption: val})
                                    }}
                                    // onBlur={(val) => {
                                    //   this.handleProductSearch(val)
                                    // }}
                                >
                                    {this.state.newOption &&
                                        <Option value={this.state.newOption || ''}>{this.state.newOption}</Option>}


                                    {productList.map((val, item) => (
                                        <Option value={val.name}>
                                            <>
                                                <div><b>{val.name}</b></div>
                                            </>
                                        </Option>
                                    ))}

                                </AutoComplete>)}

                        </div>)

                    } else {
                        return <div>
                            <div style={{marginBottom: '5px'}}> {x}</div>
                            <div>{this.onChangeDescription(item && item.description, index)}</div>

                        </div>
                    }
                }


            },
            {
                title: 'Photo',
                dataIndex: 'photos',
                width: 120,
                key: 'photos',
                render: (val, item) => {
                    if (item && item.isLast !== true) {
                        return <div>
                            {val && <img src={`${API_URL}${val}`} style={{width: '100%'}}/>}
                        </div>
                    }
                }
            },
            {
                title: 'Upload Photo',
                dataIndex: 'photosFile',
                width: 120,
                key: 'photosFile',
                render: (val, item, index) => {
                    if (item && item.isLast !== true) {
                        return <div>
                            <Upload
                                action={`${API_URL}/filesUploader`}
                                onPreview={this.handlePreview}
                                defaultFileList={val}
                                onChange={(v) => {
                                    if (v.fileList) {
                                        this.setState({setFiles: v.fileList})
                                        let tt = this.state.totalData

                                        tt[index].photosFile = v.fileList
                                        this.setState({totalData: tt})
                                    }
                                }}
                            >

                                <Button>
                                    <Icon type="upload"/> Upload
                                </Button>


                            </Upload>
                        </div>
                    }
                }
            },

            {
                title: 'Quantity',
                key: 'quantity',
                dataIndex: 'quantity',
                width: 100,
                render: (y, item, index) => {
                    if (item && item.isLast != true) {
                        return <div><Text editable={{
                            onChange: (val) => {

                                if (!isNaN(parseInt(val))) {
                                    let tt = this.state.totalData
                                    item.quantity = val ? Math.abs(val) : tt[index].quantity
                                    this.setState({totalData: tt})
                                    this.props.form.setFieldsValue({
                                        quantity: val
                                    })
                                }


                            }
                        }}> {y}</Text>


                        </div>
                    }


                }

            },


            {
                title: 'Action',
                key: 'operation',
                width: 80,
                //  fixed: 'right',
                render: (item, record) => {
                    if (record && record.isLast != true) {
                        return <React.Fragment>

                            <Tooltip title="Delete Product">
                                <Popconfirm
                                    title="Are you sure delete this Product?"
                                    onConfirm={() => {
                                        let tt = this.state.totalData
                                        tt = _.reject(tt, item)
                                        this.setState({totalData: tt}, () => {
                                        })


                                    }}
                                    onCancel={() => {
                                    }}
                                    okText="Yes"
                                    cancelText="No">
                                    <Button
                                        shape="circle"
                                        size="small"
                                        type={'danger'}
                                        icon="delete"
                                    />
                                </Popconfirm>

                            </Tooltip>

                        </React.Fragment>

                    }
                }
            }
        ]


        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }


        return (
            <PageHeaderWrapper title={id ? 'Edit Order' : 'Create Order'}>

                <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{marginTop: 8}}>

                    <Spin spinning={this.state.loader}>
                        <Card bordered={true}>

                            <Row>
                                <Col span={12}>

                                    <Form.Item
                                        label='Lead Name'
                                        {...formItemLayout}
                                    >{getFieldDecorator('leadName', {
                                        rules: [{
                                            required: true,
                                            message: 'This is a Mandatory Field'
                                        }]
                                    })(
                                        <AutoComplete
                                            notFoundContent={showSpin && <Spin/>}

                                            autoComplete={'none'}
                                            onSelect={this.onDealSelect}
                                            onSearch={this.searchLead}
                                            placeholder={'Lead Name'}

                                        >
                                            {leadNamesFiltered && leadNamesFiltered.map((val, item) => (
                                                <Option value={val._id}>
                                                    <>
                                                        <div><b>{`${val.title} (${val.id})`}</b></div>
                                                        <div className={styles.autoCompleteList}
                                                             style={{marginTop: '0px'}}>{`${val.contact && val.contact.email ? val.contact.email : ''}`}</div>
                                                        <div
                                                            className={styles.autoCompleteList}>{`${val.contact && val.contact.firstName} (${val.contact && val.contact.mobile})`}</div>
                                                        <div
                                                            className={styles.autoCompleteList}>{`Company: ${val.company || ''}`}</div>
                                                        <Tag>{`${moment(val.createdAt).format('DD-MM-YYYY HH:mm')}`}</Tag>
                                                    </>
                                                </Option>
                                            ))}
                                        </AutoComplete>
                                    )}
                                    </Form.Item>


                                    <GetAllFormFields apiurl={API_URL}
                                                      inputSchema={inputTypes}
                                                      formItemLayout={formItemLayout}
                                                      getFieldDecorator={getFieldDecorator}/>
                                    <FormItem
                                        {...formItemLayout}
                                        label={'Pincode'}
                                    >
                                        {
                                            getFieldDecorator('pincode', {
                                                rules: [{
                                                    required: true,
                                                    message: ' '
                                                }, {
                                                    validator: this.handlePincodeCheck
                                                }]
                                            })(<Input/>)
                                        }
                                        {this.state.pincodeName}
                                    </FormItem>


                                </Col>
                                <Col span={12}>
                                    <GetAllFormFields apiurl={API_URL}
                                                      inputSchema={inputTypes1}
                                                      formItemLayout={formItemLayout}
                                                      getFieldDecorator={getFieldDecorator}/>
                                    <Form.Item
                                        label='Carrier'
                                        {...formItemLayout}
                                    >{getFieldDecorator('carrier', {
                                        rules: [{
                                            required: true,
                                            message: 'This is a Mandatory Field'
                                        }]
                                    })(
                                        <AutoComplete
                                            autoComplete={'none'}
                                            onSelect={this.onSelect}
                                            placeholder={'Delivery Carrier'}

                                        >
                                            {carriersData && carriersData.map((val, item) => (
                                                <Option value={val}>
                                                    {val}
                                                </Option>
                                            ))}
                                        </AutoComplete>
                                    )}
                                    </Form.Item>


                                </Col>
                            </Row>

                            <Row> <Col span={24}>
                                {<Table
                                    bordered={true}
                                    rowKey={x => x.productName}
                                    columns={refColumns}
                                    dataSource={this.state.totalData || []}
                                    pagination={false}
                                />}

                            </Col>
                            </Row>
                            <Form.Item style={{marginTop: 32, textAlign: 'center'}}>

                                <Button size="large" type='primary'
                                        loading={this.props.loading}
                                        htmlType='submit'
                                        style={{width: '15%', marginLeft: '1em'}}>{id ? `Update` : `Create`}
                                </Button>


                            </Form.Item>
                        </Card>
                    </Spin>


                </Form>

            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    currentUser: global.currentUser

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddOrderDispatchForm)
