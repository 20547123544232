import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp, FormUtils as GetAllFormFields } from 'sz-react-utils'
import moment from 'moment'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  Popconfirm,
  notification,
  Switch,
  Input,
  Button,
  Icon,
  Form,
  Drawer,
  Row,
  Col,
  Tag,
  Select,
} from 'antd'
import Request, { API_URL } from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import { connect } from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import { getUrlPushWrapper } from '../../../routes'
import { hideLoader, showLoader } from '../../../modules/actions'

const Option = { Select }

@Form.create()
class AllInwardList extends Component {
  delete = async (data) => {
    this.setState({ loading: true })
    let x = await Request.deleteInwardOutward(data)
    this.setState({ loading: false })

    this.table.current.reload()
    if (!x.error) {
      notification.success({
        message: x.message,
        duration: 20,
        key: `${data._id}-close`,
      })
    } else {
      notification.error({
        message: x.message,
        duration: 20,
        key: `${data._id}-close`,
      })
    }
  }
  handleDelete = (val) => {
    if (this.state._id !== null && val._id) {
      const { error } = Request.deleteInwardOutwardListItem(val)
      if (!error) {
        notification.success({ message: 'Deleted SuccessFully' })
      }
    }

    let tt = this.state.totalData
    tt = _.reject(tt, val)
    this.setState({ totalData: tt }, () => {})
  }
  apiRequest = (params) => {
    if (params.page) {
      this.state.currentPage = params.page
    }
    let currentUser = JSON.parse(window.localStorage.getItem('user'))

    if (currentUser && currentUser.userType === 'Dispatch') {
      params.filledBy = currentUser._id
    }
    if (!params.sortOrder) {
      params.sortField = 'createdAt'
      params.sortOrder = 'desc'
    }

    this.setState({ filters: { ...params } })

    return new Promise(async (resolve) => {
      let data = await Request.inwardOutwardList({
        ...params,
        regExFilters: ['product'],
      })
      this.setState({ count: data.total })

      resolve(data)
    })
  }
  showDrawer = () => {
    this.setState({
      visible: true,
      key: Date.now(),
    })
  }

  constructor(props) {
    super(props)
    this.table = React.createRef()
    this.state = {
      currentPage: 1,
      visible: false,
      userType: ['Inward', 'Outward'],
      productType: [
        'Finish Goods',
        'Packing Material',
        'Electronic Components',
        'Raw Material',
      ],
      purpose: ['Sale', 'Repair', 'Purchase', 'Replacement', 'Receiving'],
      carriersData: ['Fedex', 'DHL', 'UPS', 'USPS', 'BlueDart', 'DTDC'],
      productShow: [],
      fileDownloadLoader: [],
    }
  }

  exportData = async () => {

    this.setState({ startLoading: true })
    let data = await Request.inwardOutwardList({
      ...this.state.filters,
      export: true,
      regExFilters: [
        'mobile',
        'name',
        'email',
        'challanNumber',
        'carrierDetails',
        'packingDoneBy',
        'consignee',
        'invoiceNumber',
      ],
    })
    this.setState({ startLoading: false })
    if (!data.error) {
      window.open(data.url)
    } else {
      return notification.error({ message: 'Error in Export File' })
    }
  }

  setLoaderForFile = (key, value) => {
    let { fileDownloadLoader } = this.state
    let newLoading = _.clone(fileDownloadLoader)
    newLoading[key] = value
    this.setState({ fileDownloadLoader: newLoading })
  }
  downloadDispatch = async (item, key) => {
    this.setLoaderForFile(key, true)

    await Request.downloadDispatch({ _id: item })
    this.setLoaderForFile(key, false)
  }

  componentDidMount() {
    Request.loadUser({
      userType: ['RO', 'Admin', 'Dispatch', 'Technician'],
      results: 200,
    }).then(({ data: userList }) => {
      this.setState({ userList: userList || [] })
    })

    Request.inwardOutwardProducts().then(({ data, productIds }) => {
      this.setState({ productsData: data || [], productIds: productIds || [] })
    })
  }

  render() {
    const { dispatch } = this.props

    let {
      singleRecord,
      userType,
      currentPage,
      userList,
      productsData,
      purpose,
      carriersData,
      productShow,
      productIds,
    } = this.state
    const RofilterArray =
      userList &&
      userList.map((user) => {
        return {
          text: user.name,
          value: user._id,
        }
      })

    const columns = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        width: 50,
        key: 'sno',
        fixed: 'left',
        render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
      },

      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        // fixed: 'left',
        filters: userType && userType.map((x) => ({ value: x, text: x })),
        render: (val) => {
          return <Tag color={val === 'Inward' ? 'blue' : 'red'}>{val}</Tag>
        },
      },
      {
        title: 'Products',
        dataIndex: 'product',
        key: 'product',
        searchTextName: 'product',
        width: 200,
      },
      {
        title: 'Quantity',
        key: 'quantity',
        dataIndex: 'quantity',
      },
      {
        title: 'Description',
        key: 'description',
        dataIndex: 'description',
      },

      {
        title: 'Date',
        dataIndex: 'recordDate',
        key: 'recordDate',
        searchDateName: 'recordDate',
        render: (val, record) => {
          return <p>{moment(val).format('lll')}</p>
        },
      },
      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        searchDateName: 'createdAt',
        render: (text, record) => {
          return <p>{text ? moment(text).format('lll') : ''}</p>
        },
        sorter: true,
      },
      {
        key: 'actions',
        title: 'Actions',
        fixed: 'right',
        width: 100,
        render: (val, record, key) => {
          return (
            <React.Fragment>
              <Tooltip title="Edit Dispatch">
                <Button
                  shape="circle"
                  style={{ marginRight: 6 }}
                  size="small"
                  onClick={() => {
                    if (record && record.type === 'Inward') {
                      dispatch(
                        getUrlPushWrapper('inward.edit', {
                          id: record.InwardOutwardId,
                        })
                      )
                    } else {
                      dispatch(
                        getUrlPushWrapper('inward.outwardEdit', {
                          id: record.InwardOutwardId,
                        })
                      )
                    }
                  }}
                  icon="edit"
                />
              </Tooltip>
              {/* <Tooltip title="Delete Product">
                  <Popconfirm
                    title="Are you sure delete this Product?"
                    onConfirm={() => {
                    
                      this.handleDelete(record)
                    }}
                    onCancel={() => {
                      console.log()
                    }}
                    okText="Yes"
                    cancelText="No">
                    <Button

                      shape="circle"
                      size="small"
                      type={'danger'}
                      icon="delete"
                    />
                  </Popconfirm>
                </Tooltip> */}
            </React.Fragment>
          )
        },
      },
    ]
    return (
      <PageHeaderWrapper
        title={
          this.state.count
            ? `All Inward/Outward List: ${this.state.count}`
            : `All Inward/Outward List: 0`
        }>
        <Card bordered={true}>
          <div style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              icon="plus"
              type="warning"
              style={{ marginTop: '6px', marginRight: '10px' }}
              
              onClick={() => {
                dispatch(getUrlPushWrapper('inward.add'))
              }}>
              Add Inward
            </Button>{' '}
            <Button
              type="warning"
              icon="plus"
              style={{ marginTop: '6px' }}
              onClick={() => {
                dispatch(getUrlPushWrapper('inward.outward'))
              }}>
              Add Outward
            </Button>{' '}
          </div>
          <TableComp
            ref={this.table}
            columns={columns}
            apiRequest={this.apiRequest}
            //    extraProps={{scroll: {x: 1600}}}
            pagination={{
              ...this.state.pagination,
              defaultPageSize: 10,
              pageSizeOptions: ['10', '25', '50', '100'],
              showSizeChanger: true,
              ...this.props.pagination,
            }}
          />
        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  loading: global.buttonLoading,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AllInwardList)
