import React, { Component } from 'react'
import Board from 'react-trello'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils'

import moment from 'moment'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  Popconfirm,
  notification,
  Modal,
  Switch,
  Avatar,
  Row,
  Col,
  Popover,
  AutoComplete,
  Spin,
  Input,
  Button,
  Icon,
  Select,
  Drawer,
  Form,
  Checkbox,
  Tag,
} from 'antd'
import Request, {
  getLeadStageColor,
  getStatusStyle,
  getLeadTypeColor,
} from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import { connect } from 'react-redux'
import UpdateLead from '../add/editLead'
import { Typography } from 'antd'
import { stateFilter } from '../../../states'
import AddNote from '../../note/index'
import { addLeadActivity } from './helpers'

const { Paragraph } = Typography

const { Option } = Select

class RoAllLeads extends Component {
  constructor(props) {
    super(props)
    this.table = React.createRef()
    const { dispatch } = props
    this.state = {
      leadStatuseArr: ['Open', 'won', 'Unqualified', 'Lost'],
      allContacts: [],
      currentPage: 1,
      showBoard: true,
      showTable: false,
      leadStage: ['New (Untouched)', 'Contacted', 'Today Followup'],
      boardData: { lanes: [] },
      key: Math.random(),
      RoList: [],
      selectedRowKeys: [],
      selectedRO: undefined,

      visibleNoteModal: false,
      idNoteModal: false,
    }
    this.searchContact = _.debounce(this.handleContactSearch, 1000, true)
    
    this.paramsDone =  false
  }

  apiRequest = (params) => {
    if (params.page) {
      this.state.currentPage = params.page
    }

    let { contactNameId } = this.state

    if (!params.sortOrder) {
      params.sortField = 'createdAt'
      params.sortOrder = 'desc'
    }

    let user = { ...JSON.parse(localStorage.getItem('user')) }

    if (params.done && params.done.length) {
      this.paramsDone = true
    }

    return new Promise(async (resolve) => {
      let customQuery = this.paramsDone === true ? {
        officer: user._id,
        $and: [
          {
            $or: [
              { leadApprovedByAdmin: { $in: [true] } },
              { leadApprovedByAdmin: { $exists: false } }
            ]
          },
          {
            $or: [
              { stage: { $ne: 'Contacted' } },
              { $and: [
                  { stage: 'Contacted' },
                  { tags: { $ne: 'No Future FollowUp'} }
              ]},
            ]
          }
        ]
      } : {
        officer: user._id,
        $or: [
          { leadApprovedByAdmin: { $in: [true] } },
          { leadApprovedByAdmin: { $exists: false } },
        ],
      }
      if (contactNameId) {
        customQuery.contact = contactNameId
      }
      params.isChild = false
      params.isVault = false
      params.status = 'Open'
      params.isTransferred = false
      
      let data = await Request.getAllLeads({
        ...params,
        customQuery: customQuery,
        regExFilters: ['title', 'company', 'contact'],
      })

      this.paramsDone = false;

      resolve(data)
      this.setState({ leadData: data, count: data.total })
    })
  }

  showModal = () => {
    this.setState({
      visible: true,
      btnLoader: false,
    })
  }

  async componentDidMount() {
    let { data: allContacts } = await Request.getAllContacts({ results: 10 })
    this.setState({ allContacts, sale: 'Sales' })
    let { data: userList } = await Request.getAllROList({
      customQuery: { userType: 'RO' },
    })
    this.setState({ RoList: userList })
  }

  handleContactSearch = async (test) => {
    this.setState({ selectedContact: test })
    if (test == '') {
      this.setState(
        {
          allContacts: this.state.allContacts,
          selectedContact: test,
          contactNameId: null,
        },
        () => this.table.current.reload()
      )
      //   , () => {
      //   this.setState({
      //     showSpin: false,
      //   })
      // })
      return false
    }
    let { data, error, message } = await Request.contactSearch({
      text: test,
      type: 'list',
    })
    if (!error) {
      this.setState({ allContacts: data }, () => {
        this.setState({ showSpin: false })
      })
    }
  }

  onContactSelect = (val) => {
    let selectedContact = this.state.allContacts.find((obj) => obj._id === val)
    if (selectedContact) {
      this.setState(
        {
          contactNameId: selectedContact._id,
          selectedContact: `${selectedContact.firstName} (${selectedContact.mobile})`,
        },
        () => {
          this.table.current.reload()
        }
      )
    }
  }

  render() {
    const { submitting, currentUser, dispatch } = this.props
    const { leadStatuseArr, allContacts, currentPage } = this.state

    let user = JSON.parse(localStorage.getItem('user'))

    const columns = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        width: 50,
        key: 'sno',
        fixed: 'left',
        render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
      },

      {
        key: 'actions',
        title: 'View',
        fixed: 'left',
        width: 80,
        render: (text, record) => {
          return (
            <React.Fragment>
              <Tooltip title="View">
                <a
                  href={`/lead/detail?id=${record && record._id}`}
                  target={'_blank'}>
                  <Button
                    style={{ marginLeft: '5px' }}
                    size="small"
                    icon={'eye'}
                    shape={'circle'}
                  />
                </a>
              </Tooltip>
            </React.Fragment>
          )
        },
      },
      {
        title: 'Lead Id',
        dataIndex: 'id',
        key: 'id',
        width: 100,
        searchTextName: 'id',
        fixed: 'left',
      },
      {
        title: 'Product',
        dataIndex: 'productName',
        key: 'productName',
      },
      {
        title: 'State',
        dataIndex: 'state',
        key: 'state',
        filters: stateFilter,
      },
      {
        title: 'Contact',
        dataIndex: 'contact.firstName',
        key: 'contact.firstName',
        render: (text, record) => (
          <span>
            <span>{record.contact ? record.contact.firstName : ''}</span>
            <br />
            <span>{record.contact ? record.contact.mobile : ''}</span>
          </span>
        ),
      },
      {
        title: 'Stage',
        key: 'stage',
        render: (text, record) => {
          return (
            <div>
              {record && record.stage ? (
                <div
                  className={styles.stageColor}
                  style={{
                    backgroundColor: getLeadStageColor(record.stage),
                  }}>
                  {record.stage}
                </div>
              ) : (
                ''
              )}
            </div>
          )
        },
        filters: _.map(this.state.leadStage, (ii) => ({ text: ii, value: ii })),
      },
      {
        key: 'Add Note',
        title: 'Add Note',
        width: 120,
        render: (text, record) => {
          return (
            <React.Fragment>
              <Button onClick={() => this.setState({ visibleNoteModal: true, idNoteModal: record && record._id })}>Add Note</Button>
            </React.Fragment>
          )
        },
      },
      {
        title: 'Total Call Logs',
        dataIndex: 'callLogs',
        key: 'callLogs',
        width: 150,
        render: (val, record) => {
          return (
            <div>
              <small>
                Outgoing:{' '}
                {(val && val.outgoingCalls || 0) + (val && val.outgoingRejectCalls || 0)} ({(val && val.todayOutgoingCalls || 0) + (val && val.todayOutgoingRejectCalls || 0)})
              </small>
              <div>
                <small>Incoming: {(val && val.incomingCalls) || 0} ({(val && val.todayIncomingCalls) || 0})</small>
              </div>
              <div>
                <small>Missed: {(val && val.missedCalls) || 0} ({(val && val.todayMissedCalls) || 0})</small>
              </div>
              <div>
                <small>Attended: {(val && val.totalAttended) || 0} ({(val && val.todayTotalAttended) || 0})</small>
              </div>
            </div>
          )
        },
      },
      {
        title: 'Done',
        key: 'done',
        width: 80,
        render: (text, record) => {
          if (
            record &&
            !record.isVault &&
            record.status === 'Open' &&
            record.officer &&
            record.officer.name &&
            (record.stage !== 'Contacted' ||
              (record.stage === 'Contacted' &&
                !(record.tags && record.tags.includes('No Future FollowUp')) &&
                (!record.followUpDate ||
                  (record.followUpDate &&
                    moment(record.followUpDate).isSame(moment(), 'day')))))
          ) {
            if (record.done === true) {
              return (
                <Tag color="green" style={{ marginBottom: '2px' }}>
                  Yes
                </Tag>
              )
            } else {
              return (
                <Tag color="red" style={{ marginBottom: '2px' }}>
                  No
                </Tag>
              )
            }
          } else {
            return <Tag style={{ marginBottom: '2px' }}>N/A</Tag>
          }

        },
        filters: [{ text: 'Yes', value: 'true' }, { text: 'No', value: 'false' }],
      },
      {
        title: 'Tags',
        key: 'tags',
        width: 140,
        render: (text, record) => {
          if (record.tags && record.tags.length) {
            return _.map(record.tags, (ii) => {
              return <Tag style={{ marginBottom: '2px' }}>{ii}</Tag>
            })
          }
        },
        filters: [{ text: 'No Future FollowUp', value: 'No Future FollowUp' }],
      },
      {
        title: 'Followup On',
        dataIndex: 'latestFollowup',
        key: 'latestFollowup',
        render: (val, record) => {
          return (
            <div>
              <div>
                {record && record.description ? record.description : ''}
              </div>
              <div>
                {record && record.followUpDate
                  ? moment(record.followUpDate).format('ll')
                  : ''}
              </div>
            </div>
          )
        },
      },
      {
        title: 'Lead Received Date',
        dataIndex: 'leadReceivedDate',
        key: 'leadReceivedDate',
        searchDateName: 'leadReceivedDate',
        sorter: true,
        fixed: 'right',
        width: 150,
        render: (val, record) => {
          return (
            <div>
              <div>
                {' '}
                {record && record.leadReceivedDate
                  ? moment(record.leadReceivedDate).format('lll')
                  : ''}{' '}
              </div>
            </div>
          )
        },
      },
    ]
    const LostLeadsFilterSet = (e) => {
      if (e.target.checked) {
        this.setState({ LostLeadsFilterSet: true }, () => {
          this.table.current.reload()
        })
      } else {
        this.state.LostLeadsFilterSet = false
        this.table.current.reload()
      }
    }

    return (
      <PageHeaderWrapper
        title={
          this.state.count ? `All Leads: ${this.state.count}` : `All Leads: 0`
        }>
        <div style={{ margin: '-24px', padding: '0px' }}>
          <Card>
            <Row>
              <Col span={8}>
                <AutoComplete
                  value={this.state.selectedContact}
                  notFoundContent={this.state.showSpin && <Spin />}
                  autoComplete={'none'}
                  onSelect={this.onContactSelect}
                  onSearch={this.searchContact}
                  placeholder={'Search Contact'}
                  style={{ width: 300 }}
                  onChange={(val) => {
                    this.setState({ selectedContact: val })
                  }}>
                  {allContacts &&
                    allContacts.map((val, item) => (
                      <Option value={val._id}>
                        <>
                          <div>
                            <b>{`${val.firstName} (${val.mobile})`}</b>
                          </div>
                        </>
                      </Option>
                    ))}
                </AutoComplete>
              </Col>
              {/*<Col span={4}>*/}
              {/*<Checkbox onChange={LostLeadsFilterSet}>Lost Leads</Checkbox>*/}

              {/*</Col>*/}
            </Row>
          </Card>

          <Card className={styles.greyCard}>
            <TableComp
              ref={this.table}
              columns={columns}
              apiRequest={this.apiRequest}
              extraProps={{
                scroll: { x: 1700 },
                rowClassName: (record, index) => {
                  if (record && record.status == 'won') {
                    return styles.wonColor
                  } else if (record && record.status == 'Lost') {
                    return styles.lostColor
                  } else if (record && record.status == 'Unqualified') {
                    return styles.unqualifiedColor
                  } else {
                    return styles.openColor
                  }
                },
              }}
              // scroll={{ x: 1500, y: 300 }}
              id="leadList"
              pagination={{
                ...this.state.pagination,
                defaultPageSize: 10,
                pageSizeOptions: ['10', '25', '50', '100'],
                showSizeChanger: true,
                ...this.props.pagination,
              }}
            />
          </Card>
        </div>

        <Drawer
          title=""
          placement="right"
          closable={true}
          onClose={async () => {
            this.setState({
              ...this.state,
              updatelead: false,
              cardId: '',
            })
          }}
          visible={this.state.updatelead}
          width={800}>
          {/* cardId={cardId} */}
          <UpdateLead
            cardId={this.state.cardId}
            allContacts={allContacts}
            leadOwner={currentUser}
            key={this.state.drawerKey ? this.state.drawerKey : Date.now()}
            handleUpdateLead={() => {
              this.setState({ updatelead: false, cardId: '' })
              this.table.current.reload()
            }}
          />
        </Drawer>

        <Modal
          width={1000}
          visible={this.state.visibleNoteModal}
          onCancel={() => this.setState({ visibleNoteModal: false })}
          maskClosable={true}
          centered={true}
          footer={null}
          keyboard={true}
          title={'Add Note'}
        >
          <AddNote
            hideTitle={true}
            // hideSubmit={true}
            leadId={this.state.idNoteModal}
            handleAddNote={async () => {
              await addLeadActivity({
                values: {
                  message: `Note Added`,
                  leadId: this.state.idNoteModal,
                  user: user && user.name,
                  type: 'notes',
                },
              })

              this.setState({ visibleNoteModal: false })
            }}
          />
        </Modal>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  categories: global.categories,
  currentUser: global.currentUser,
  search: router.location.search,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(RoAllLeads)
