import React, {Component, PureComponent} from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import moment from 'moment'

import {
    Form,
    Button,
    Card,
    Icon,
    Tabs,
    Row,
    Input,
    Mentions,
    List,
    Badge,
    notification,
    Avatar,
    Col,
    Tooltip,
    Select,
    Drawer,
    Table,
    DatePicker
} from 'antd'
import _ from 'lodash'

import {hideLoader, showLoader} from '../../modules/actions'
import Request from '../../request'
import {connect} from 'react-redux'
import styles from '../../containers/taskManagement/style.less'
import {getUrlParams, getUrlPushWrapper} from '../../routes'
import MentionAddTask from './mentionAddTask'
import {getColorClass} from '../../colors'

const {Option} = Select

class TaskList extends Component {

    render() {

        const {item, allowForward} = this.props
        return <List
            className={styles['demo-loadmore-list']}
            size="small"
            itemLayout="horizontal"
            loading={this.props.loading}
            dataSource={item}
            renderItem={item => (
                <List.Item key={item._id} className={styles.list}>


                    <div className={styles.taskLeftDiv}>

                        {item && item.status !== 'Completed' && <Tooltip title="Complete Your Task">
                            <Icon
                                className={styles.icon}
                                onClick={() => {
                                    this.props.openEdit(item)
                                }}
                                type={'check-circle'}/>
                        </Tooltip>}

                        <div className={styles.taskTitle}>
                            {item.taskName}
                        </div>


                    </div>
                    {/*<div style={{display: 'flex', alignItems: 'center'}}>*/}
                    {/*<div style={{fontSize: 13, textAlign: 'left'}}>*/}
                    {/*{item.description}*/}
                    {/*</div>*/}
                    {/*</div>*/}

                    <div style={{display: 'flex', alignItems: 'center'}}>

                        <div style={{fontSize: 13, textAlign: 'right'}}>
                            {moment(item.dueDate).format('ll')}
                        </div>

                        <div
                            className={[styles[`label-${item.priority.toLowerCase()}`], styles.label].join(' ')}>{item.priority}</div>

                        <Tooltip title={item.assignToId ? item.assignToId.name : ''}>
                            <Avatar style={{
                                backgroundColor: getColorClass(item.initials)
                            }}>{item.initials}</Avatar>
                        </Tooltip>

                    </div>


                </List.Item>
            )}
        />


    }
}

@Form.create()
class AddTask extends PureComponent {
    state = {
        allUsers: [],
        allContacts: [],
        visible1: false,
        drawerItem: {},
        drawerData: {},
        descrip: '',
        visibleCompleteTask: false
    }


    addTask = async (taskData) => {
        if (taskData) {
            let respp = await Request.addTask(taskData)
            if (respp) {
                if (!respp.error) {
                    notification.success({
                        message: respp.message
                    })
                    this.getAllTasks()
                }
                else {
                    notification.error({
                        message: 'Error Saving'
                    })
                }
            }
            return respp
        } else {
            notification.error({
                message: 'Please enter all fields.'
            })
            return null
        }
    }
    showCompleteTask = () => {
        this.setState({
            visibleCompleteTask: true
        })
    }
    disableCompleteTask = () => {
        this.setState({
            visibleCompleteTask: false
        })
    }
    onClose1 = () => {
        this.setState({
            visible1: false
        })
    }
    handleContactSearch = async (test) => {
        let {data, error, message} = await Request.contactSearch({text: test, type: 'list'})
        if (!error) {
            this.setState({allContacts: data}, () => {

            })
        }

    }

    constructor(props) {
        super(props)
        this.List = React.createRef()
        let taskUrl = getUrlParams('taskUrl', window.location.pathname)


        if (taskUrl) {
            let {value, gte, lte} = taskUrl
            let key = ''
            if (value === 'Pending' || value === 'Completed') {
                this.state.status = value
                this.state.gte = gte
                this.state.lte = lte
            }
        }
    }

    async componentDidMount() {
        let {data: allUsers} = await Request.loadUser({results: 10000})
        let {data: allContacts} = await Request.getAllContacts({results: 20})

        this.setState({allUsers, allContacts})
        this.getAllTasks()
        this.getAllCompleteTasks()

    }

    async getAllTasks() {
        const {currentUser} = this.props
        let params = {status: ['Pending']}
        params.results = 200
        if (currentUser && currentUser.userType && currentUser.userType !== 'Admin') {
            params.assignToId = currentUser._id
        }

        if (this.state.status === 'Pending') {
            params.status = "Pending"
            params.updatedAt = {
                $gte: this.state.gte,
                $lte: this.state.lte
            }
        }

        let {data} = await Request.getAllTasksAssignByUser(params)

        if (data) {
            _.each(data.data, (item) => {
                let initials = item.assignToId ? item.assignToId.name.match(/\b\w/g) || [] : []
                item.initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
            })

            let pending = _.filter(data.data, (item) => {
                return !item.taskDue
            })

            let dueData = _.filter(data.data, (item) => {
                return item.taskDue
            })
            this.setState({pendingData: pending, dueTask: dueData, allowForward: data.allowForward})
        }
    }

    async getAllCompleteTasks() {
        const {currentUser} = this.props
        let params = {status: ['Completed']}
        params.results = 20
        if (currentUser && currentUser.userType && currentUser.userType !== 'Admin') {
            params.assignToId = currentUser._id
        }
        if (this.state.status === 'Completed') {
            params.status = "Completed"
            params.updatedAt = {
                $gte: this.state.gte,
                $lte: this.state.lte
            }
        }
        let {data} = await Request.getAllCompleteTasks(params)
        if (data) {
            _.each(data.data, (item) => {
                let initials = item.assignToId ? item.assignToId.name.match(/\b\w/g) || [] : []
                item.initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase()
            })
            this.setState({completeTask: data.data})
        }
    }

    render() {
        const {hideTitle, currentUser, dispatch} = this.props
        const {allUsers, drawerItem, drawerData, pendingData, dueTask, allowForward, allContacts} = this.state

        const columns = [
            {
                title: 'Status',
                key: 'status',
                dataIndex: 'status',
                render: val => {
                    if (val == 'Pending') {
                        return <div style={{color: 'red'}}>{val}</div>
                    }
                    if (val == 'Completed') {
                        return <div style={{color: 'green'}}>{val}</div>
                    }
                    if (val == 'Forward') {
                        return <div style={{color: 'blue'}}>{val}</div>
                    }
                }
            },
            {
                title: 'Task Name',
                key: 'taskName',
                dataIndex: 'taskName',
                searchTextName: 'taskTitle',
                filterRegex: true,
                render: (val, record) => {
                    return (
                        <div>
                            {val}
                            {record.taskDue ? (
                                <div>
                                    <small style={{color: 'red'}}>(Overdue Task)</small>
                                </div>
                            ) : (
                                ''
                            )}
                        </div>
                    )
                }
            },
            {
                title: 'Priority',
                key: 'priority',
                dataIndex: 'priority',
                searchTextName: 'priority',
                filterRegex: true,
                render: val => {
                    if (val == 'High') {
                        return <Badge count={val}/>
                    }
                    if (val == 'Low') {
                        return <Badge count={val} style={{backgroundColor: '#0000FF'}}/>
                    }
                    if (val == 'Normal') {
                        return <Badge count={val} style={{backgroundColor: '#52c41a'}}/>
                    }
                }
            },
            {
                title: 'DueDate ',
                key: 'dueDate',
                render: (val, record) => {
                    return (
                        <div>
                            {record.dueDate
                                ? moment(record.dueDate).format('DD-MMM-YYYY')
                                : ''}
                        </div>
                    )
                }
            },
            {
                title: 'Description ',
                key: 'description',
                dataIndex: 'description'
            },
            {
                title: 'Assigned By',
                key: 'assignById',
                dataIndex: 'assignById',
                filterRegex: true,
                render: (val, record) => {
                    return val ? val.name : ''
                }
            }
        ]

        let form = <div>{<React.Fragment>
            <Card bordered={true}>
                {currentUser && currentUser.userType && currentUser.userType === 'Admin' && <Row>
                    <Col span={23}>
                        <MentionAddTask currentUser={this.props.currentUser} allUsers={allUsers} onChange={(data) => {
                            this.handleContactSearch(data)
                        }}
                                        allContacts={allContacts}
                                        onsave={(data) => {
                                            return this.addTask(data)
                                        }}/>
                    </Col>
                    <Col span={1}>
                        <Tooltip title="View Completed Task">
                            <Button
                                className={styles.btnInfo}
                                shape="circle" icon="eye"
                                size={'small'}
                                onClick={() => this.showCompleteTask()}>
                            </Button>
                        </Tooltip>
                    </Col>
                </Row>}

            </Card>

            <Card bordered={true}>
                <h1 className={styles.heading}>Pending:</h1>
                {this.state && this.state.pendingData && (<TaskList

                    openEdit={(item) => {
                        this.setState({drawerItem: item, visible1: true})
                    }}
                    openForward={(item) => {
                        this.setState({
                            drawerData: item,
                            taskTitle: item.taskName,
                            description: item.description,
                            dueDate: item.dueDate,
                            assignId: item.assignToId ? item.assignToId.name : ''
                        })
                    }}
                    item={pendingData}/>)}


                <h1 className={styles.heading}>OverDue:</h1>
                {this.state && this.state.dueTask && <TaskList item={dueTask} allowForward={allowForward}
                                                               openEdit={(item) => {
                                                                   this.setState({drawerItem: item, visible1: true})
                                                               }} openForward={(item) => {
                    this.setState({
                        drawerData: item,
                        taskTitle: item.taskTitle,
                        description: item.description,
                        dueDate: item.dueDate,
                        assignId: item.assignId ? item.assignId.name : '',
                    })
                }}/>}

                {currentUser && currentUser.userType && currentUser.userType !== 'Admin' &&
                <>
                    <h1 className={styles.heading}>Completed:</h1>
                    <TaskList item={this.state.completeTask}
                              openEdit={(item) => {
                                  this.setState({
                                      drawerItem: item,
                                      visible1: true
                                  })
                              }} openForward={(item) => {
                        this.setState({
                            drawerData: item,
                            taskTitle: item.taskTitle,
                            description: item.description,
                            dueDate: item.dueDate,
                            assignId: item.assignId ? item.assignId.name : '',
                        })
                    }}/> </>}

            </Card>
            <Drawer
                title="COMPLETE YOUR TASK"
                width={650}
                closable={true}
                onClose={this.onClose1}
                visible={this.state.visible1}>
                <div style={{marginLeft: 20, marginTop: 25}}>
                    <h3 style={{textAlign: 'justify', marginRight: 20}}> {drawerItem && drawerItem.taskName}</h3>
                    <br/>
                    <p> {drawerItem && moment(drawerItem.dueDate).format('DD/MM/YYYY')}
                    </p>

                    <form>
                        <Input.TextArea
                            placeholder="Description Box"
                            value={this.state.descrip}
                            style={{width: 350, marginTop: 10, marginBottom: 20}}
                            rows={4}
                            onChange={record => {
                                record = record.target.value
                                this.setState({descrip: record && record.toString()})
                            }}
                        />
                    </form>

                    <Button
                        type="primary"
                        htmlType="submit"
                        loading={this.props.loading}
                        onClick={async () => {
                            dispatch(showLoader())
                            let x = await Request.changeTaskStatus({
                                _id: drawerItem._id,
                                status: 'Completed',
                                description: this.state.descrip

                            })
                            dispatch(hideLoader())
                            if (!x.error) {
                                notification.success({
                                    message: 'Task Completed Successfully.'
                                })
                                this.getAllTasks()
                                this.getAllCompleteTasks()
                            }

                            this.setState({visible1: false, descrip: ''})

                        }}>
                        Ok
                    </Button>

                    <Button
                        type="default"
                        htmlType="submit"
                        // loading={this.props.loading}
                        style={{marginLeft: 10}}
                        onClick={this.onClose1}>
                        CLOSE
                    </Button>
                </div>
            </Drawer>
            <Drawer
                title="Completed Task"
                width={1000}
                closable={true}
                onClose={this.disableCompleteTask}
                visible={this.state.visibleCompleteTask}>
                <div style={{marginLeft: 20, marginTop: 25}}>
                    <Table columns={columns} dataSource={this.state.completeTask} pagination={false} indentSize={50}/>
                </div>
            </Drawer>
        </React.Fragment>}</div>

        return (<React.Fragment>{!hideTitle ?
            <PageHeaderWrapper title={'Task Management'}>{form}</PageHeaderWrapper> : form}</React.Fragment>)
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    currentUser: global.currentUser,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}
export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddTask)
