import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils'
import styles from '../../lead/all/styles.less'
import moment from 'moment'
import {
  Modal,
  Card,
  Button,
  Tooltip,
  Input,
  Tag,
  Form,
  Select,
  notification,
  Drawer,
  Upload,
  Icon,
  Timeline,
  List,
  Avatar,
  Popconfirm,
} from 'antd'
import _ from 'lodash'
import Request, {
  getComplaintColor,
  complaintStatusForAdmin,
  warrantyStatusList,
  API_URL,
} from '../../../request'
import { connect } from 'react-redux'
import ViewComments from './viewComments'
import ViewImage from './viewImages'
import AddCommentOrStatus from '../add/addCommentOrStatus'
import AddImage from '../add/addImage'

class AllThirtyDaysOldVideos extends Component {
  constructor(props) {
    super(props)
    this.table = React.createRef()
    this.state = {
      visible: false,
      loading: false,
      key: Date.now(),
      isCommentModal: false,
      commentData: [],
      statusLogsData: [],
      selectedRowKeys: [],
      currentPage: 1,
      setFiles: [],
      imageArray: [],
      showTable: true,
      apiData:[],
      API_URL: 'http://localhost:8343',
    }
  }

  deleteVideo = async (data) => {
    this.setState({ loading: true })
    let x = await Request.deleteUpload(data)
    this.setState({ loading: false })

    this.table.current.reload()
    if (!x.error) {
      notification.success({
        message: x.message,
        duration: 20,
        key: `${data._id}-close`,
      })
    } else {
      notification.error({
        message: x.message,
        duration: 20,
        key: `${data._id}-close`,
      })
    }
  }
  deleteVideoDispatch = async (data) => {
    this.setState({ loading: true })
    let x = await Request.deleteUploadDispatch(data)
    this.setState({ loading: false })

    this.table.current.reload()
    if (!x.error) {
      notification.success({
        message: x.message,
        duration: 20,
        key: `${data._id}-close`,
      })
    } else {
      notification.error({
        message: x.message,
        duration: 20,
        key: `${data._id}-close`,
      })
    }
  }


  apiRequest = (params) => {
    if (params.page) {
      this.state.currentPage = params.page
    }
    return new Promise(async (resolve) => {
      if (!params.status) {
        let searchParams = new URLSearchParams(window.location.search)
        let statusType = searchParams.get('type')
        if (statusType) {
          params.status = statusType
        }
      }

      if (!params.sortField) {
        params.sortField = 'isReopened'
        params.sortOrder = 'descend'
      }

      let data = await Request.getThirtyDaysOldVideos({
        ...params,
        regExFilters: ['contactName','leadName'],
      })
      this.setState({apiData:data.data});



      this.setState({ count: data.total })
      resolve(data)
    })
  }

  async componentDidMount() {}

  onCancel = () => {
    this.setState({
      modalVisible: false,
      imageModalVisible: false,
    })
  }

  openVideoInNewTab = (videoUrl) => {
    window.open(videoUrl, '_blank')
  }

  handleDelete = async () => {
    const { selectedRowKeys , apiData } = this.state

    

    let reqArr = selectedRowKeys.map(id => {
      return apiData.find((item) => item._id.toString() == id.toString());
    });

    


   
    let data = await Request.deleteEveryRow(reqArr);
    
    // this.table.current.reload()
    if (!data.error) {
      notification.success({ message: 'Video Deleted succesfully' })
      setTimeout(()=>{
        window.location.reload();
      },2000)
      
    } else {
      notification.error({ message: 'Error occurred' })
    }
  }

  render() {
    const {} = this.props
    const {
    
      currentPage,
      selectedRowKeys,
      showTable,
    } = this.state
    let user = { ...JSON.parse(localStorage.getItem('user')) }

    const columns = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        width: 50,
        key: 'sno',
        render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
      },
      {
        title: 'ID',
        key: 'uniqueId',
        width: 30,
        searchTextName: 'uniqueId',
        render: (text,record)=>{
          return <div>{record.uniqueId}</div>
        }
       
      },
 
      {
        title: 'Type',
        key: 'type',
        width: 100,
        dataIndex: 'type',
        filters: [
          { text: 'Complaint', value: 'Complaint' },
          { text: 'Dispatch', value: 'Dispatch' }
        ],
        render: (text,record)=>{
          if (record.type==="Complaint") {
            return <div>{record.type}</div>
          } else {
            return <div>{record.type}</div>
          }
        }

      },

      {
        title: 'Lead Name',
        key: 'leadName',
        width: 100,
        searchTextName: 'leadName',
        render: (text,record)=>{
          return <div>{record.leadName}</div>
        }

      },
      {
        title: 'Contact Details',
        key: 'contactName',
        searchTextName: 'contactName',
        render: (text, record) => (
          <span>
            <span>{record.contactName || ''}</span>
            <br />
            <span>{record.mobile || ''}</span>
            <br />
            <span>{record.email || ''}</span>
            <br />
            <span>{record.state || ''}</span>
            <br />
          </span>
        ),
        width: 100,
      },
      {
        title: 'Product Name',
        key: 'productName',
        width: 100,
        dataIndex: 'productName',
        render: (text,record)=>{
          return <div>{record.productName}</div>
        }
      },

      {
        key: 'actions',
        title: 'Actions',
        width: 100,
        render: (text, record) => {
          return (
            <React.Fragment>
              <Tooltip title="Delete Video">
                <Popconfirm
                  title="Are you sure to Delete this video Link?"
                  onConfirm={async () => {
                    //Delete video logic

                    if(record.type === 'Complaint'){

                    this.deleteVideo({ _id: record.images._id })
                    }else{
                      this.deleteVideoDispatch({ _id: record.videos._id })
                    }
                  }}
                  onCancel={() => {
                  }}
                  okText="Yes"
                  cancelText="No">
                  <Button
                    hidden={record.status == 'Close'}
                    shape="circle"
                    size="small"
                    icon="close"
                  />
                </Popconfirm>
              </Tooltip>

              <Tooltip title="View">
                <a
                  
                  href={record.type==='Complaint'?`/complaint/closeComplaintForAdmin?id=${record && record.id}`:`/orderDispatch/orderDetails?id=${record && record.id}`}
                  target={'_blank'}>
                  <Button
                    style={{ marginLeft: '5px' }}
                    size="small"
                    icon={'eye'}
                    shape={'circle'}
                  />
                </a>
              </Tooltip>

              <Tooltip title="Open Video">
                
                  <Button
                    style={{ marginLeft: '5px' }}
                    size="small"
                    shape={'circle'}
                    onClick={() => {
                      if(record.type === "Complaint"){
                        window.open(`${API_URL}/${record.images.url}`)
                      }else{
                        window.open(`${API_URL}/${record.videos.url}`)
                      }
                    }}
                    target={'_blank'}
                  ><Icon type="video-camera" /></Button>
                
              </Tooltip>
            </React.Fragment>
          )
        },
      },
    ]

    const rowSelection = {
      selectedRowKeys: selectedRowKeys,
      onChange: (selectedRowKeys, selectedRows) => {
        
        this.setState({ selectedRowKeys })
      },
      getCheckboxProps: (record) => ({
        _id: record._id,
      }),
    }

    return (
      <PageHeaderWrapper
        title={`Thirty Days Old Videos : ${this.state.count || 0}`}>
        <Card className={styles.outerLeadCard}>
          {showTable && (
            <>
              <div className={styles.exportDiv}>
                <div className={styles.assignBtn}>
                  <Popconfirm
                    title={`Are You Sure You Want To Delete These ${selectedRowKeys.length} Videos`}
                    disabled={selectedRowKeys.length === 0}
                    onConfirm={(e) => {
                      if(selectedRowKeys.length!==0){
                          this.handleDelete()
                      }
                      
                    }}
                    onCancel={(e) => {
                    }}
                    okText="Yes"
                    cancelText="No">
                    <Button
                      style={{ marginRight: '10px' }}
                      disabled={selectedRowKeys.length === 0}
                      onClick={() =>
                        this.setState({ statusChangePicker: true })
                      }>
                      DELETE <Icon type="right" />
                    </Button>
                  </Popconfirm>
                </div>
              </div>
              <div style={{ backgroundColor: 'white' }}>
                <TableComp
                  ref={this.table}
                  columns={columns}
                  apiRequest={this.apiRequest}
                  extraProps={{
                    scroll: { x: 1000 },
                    rowSelection: { ...rowSelection },
                  }}
                  pagination={{
                    ...this.state.pagination,
                    defaultPageSize: 10,
                    pageSizeOptions: ['10', '25', '50', '100'],
                    showSizeChanger: true,
                    ...this.props.pagination,
                  }}
                />
              </div>
            </>
          )}
        </Card>

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  currentUser: global.currentUser,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllThirtyDaysOldVideos)
