import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Input,
  notification,
  Anchor,
  Spin,
  Select,
  Tag,
  Button,
  Table,
  Card,
  Typography,
  Row,
  Col,
  Tooltip,
  Popconfirm,
  Checkbox,
  AutoComplete,
  Radio,
  Timeline,
  Drawer,
  DatePicker,
  InputNumber,
} from 'antd'
import _ from 'lodash'
import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils'
import moment from 'moment'

import { API_URL } from '../../../request'

import 'react-quill/dist/quill.snow.css'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import styles from '../all/styles.less'
import { connect } from 'react-redux'
import { createMatchSelector, goBack } from 'connected-react-router'
import { getUrlPushWrapper } from '../../../routes'
import { Label } from 'recharts'
import { throttleSetter } from 'lodash-decorators'

const FormItem = Form.Item
const { Option } = Select
const { Text, Paragraph } = Typography
const { TextArea } = Input

@Form.create()
class AddInward extends PureComponent {
  pageSubmit = async (e) => {
    let { recordDate, totalData, product } = this.state
    e.preventDefault()
    this.setState({ buttonReload: true })
    let params = { recordDate, totalData }
    params.type = 'Inward'
    if (recordDate === null) {
      notification.error({ message: 'Please Select a Date' })
    } else {
      if (totalData.length > 0) {
        if (this.state._id === null) {
          const { error } = await Request.addInward(params)
          if (!error) {
            notification.success({ message: 'Inward Added Successfully ' })
            this.props.form.setFieldsValue({
              recordDate: moment(),
            })
            this.setState({ recordDate: moment(), totalData: [] })
          } else {
            notification.error({ message: 'Error Occured' })
          }
        } else {
          params.inwardId = this.state._id
          const { error } = await Request.updateInward(params)
          if (!error) {
            this.setState({ buttonReload: true })

            notification.success({ message: 'Inward Updated Successfully ' })
          } else {
            notification.error({ message: 'Error Occured' })
          }
        }
      } else {
        notification.error({ message: 'Please Add Product' })
      }
    }
    this.setState({ buttonReload: false })
  }
  handleDelete = (val) => {
    if (this.state._id !== null && val._id) {
      const { error } = Request.deleteInwardOutwardItem(val)
    }

    let tt = this.state.totalData
    tt = _.reject(tt, val)
    this.setState({ totalData: tt }, () => {})
  }

  handleSubmit = (e) => {
    let { quantity, description, product, productId } = this.state
    e.preventDefault()
    let data = {
      quantity: quantity,
      description: description,
      product: product,
      productId,
    }

    if (product == null) {
      notification.error({ message: 'Please Select A Product' })
    } else {
      if (this.state.totalData && this.state.totalData.length > 0) {
        this.setState({ totalData: [...this.state.totalData, data] })
      }
      if (this.state.totalData && this.state.totalData.length < 1) {
        this.setState({ totalData: [data] })
      }
      this.setState({ product: null, quantity: '', description: '' })
    }
  }

  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      loader: true,
      description: '',
      recordDate: null,
      totalData: [],
      productId: null,
      _id: null,
      buttonReload: false,
      totalProductList: [],
      product: null,
      quantity: '',
      currentPage: 1,
    }
    // this.AddData = _.debounce(this.addData, 1000, true)
  }
  async componentDidMount() {
    let searchParams = new URLSearchParams(this.props.search)
    let dispatchId = searchParams.get('id')

    if (dispatchId) {
      Request.getInwardOutwardById({ _id: dispatchId }).then(
        ({ data: dispatchData, error, message, totalData: totalTableData }) => {
          if (!error) {
            this.setState({
              _id: dispatchData && dispatchData._id,
              loader: false,
              totalData: totalTableData,
              recordDate: dispatchData.recordDate,
            })
            this.props.form.setFieldsValue({
              recordDate: dispatchData && moment(dispatchData.recordDate),
            })
          } else {
            this.setState({
              loader: false,
            })
            notification.error({
              message: 'Error Getting Data',
            })
          }
        }
      )
    } else {
      Request.getNextInwardOutwardFormNo({ type: 'Inward' }).then((data) => {
        if (!data.error) {
          this.setState({ recordDate: moment() })
          this.props.form.setFieldsValue({
            serialNumber: data.data,
            recordDate: moment(),
          })
        }
      })

      this.setState({ loader: false })
      // this.state.totalData.push({ isLast: true })
    }
    Request.getAllStockProducts({
      results: 2000,
    }).then(({ data: stockProductList }) => {
      this.setState({ totalProductList: stockProductList || [] }, () => {})
    })
  }

  render() {
    const {
      form,
      form: { getFieldDecorator, getFieldValue },
    } = this.props
    const {
      totalProductList,
      _id,
      userList,
      isDispatch,
      newUserList,
      carriersData,
      purposeValue,
      replacementType,
      selectedRowKeys,
      inwardPendingList,
      singleRecord,
    } = this.state
    const inputTypes = {
      fields: [
        {
          label: 'Record Date',
          key: 'recordDate',
          type: 'date',

          required: true,
          customProps: {
            onChange: (e) => {
              this.setState({ recordDate: e })
            },
          },
        },
      ],
    }
    const refColumns = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        key: 'sno',
        width: 70,
        render: (value, item, index) => {
          if (item && item.isLast != true) {
            return index + 1
          }
        },
      },
      {
        title: 'Product',
        key: 'product',
        dataIndex: 'product',
      },
      {
        title: 'Quantity',
        key: 'quantity',
        dataIndex: 'quantity',
      },
      {
        title: 'Description',
        key: 'description',
        dataIndex: 'description',
      },

      {
        title: 'Action',
        key: 'operation',
        width: 80,
        render: (item, record) => {
          if (record && record.isLast != true) {
            return (
              <React.Fragment>
                <Tooltip title="Delete Product">
                  <Popconfirm
                    title="Are you sure delete this Product?"
                    onConfirm={() => {
                      this.handleDelete(record)
                    }}
                    onCancel={() => {
                    }}
                    okText="Yes"
                    cancelText="No">
                    <Button
                      shape="circle"
                      size="small"
                      type={'danger'}
                      icon="delete"
                    />
                  </Popconfirm>
                </Tooltip>
              </React.Fragment>
            )
          }
        },
      },
    ]
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 },
      },
    }
    return (
      <PageHeaderWrapper
        title={this.state._id ? 'Update Inward Form' : 'Add Inward Form'}>
        <Card>
          <Row>
            <Col span={6}>
              <GetAllFormFields
                apiUrl={API_URL}
                inputSchema={inputTypes}
                getFieldDecorator={getFieldDecorator}
                // formItemLayout={...formItemLayout}
              />
            </Col>
          </Row>

          <Row>
            <Card>
              <Form layout="inline" onSubmit={this.handleSubmit}>
                <Row type="flex" justify="space-between" gutter={4}>
                  <Col>
                    <Form.Item
                      label="Product"
                      name="productId"
                      {...formItemLayout}>
                      <Select
                        value={this.state.product}
                        showSearch
                        optionFilterProp="children"
                        style={{
                          width: 200,
                        }}
                        onSelect={(e) => {
                          let productObj = this.state.totalProductList.find(
                            (obj) => obj._id === e
                          )
                          if (productObj) {
                            this.setState({
                              product: productObj.name,
                              productId: e,
                            })
                          }
                        }}>
                        {totalProductList.map((item) => {
                          return <Option value={item._id}>{item.name}</Option>
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item label="Quantity" name="quantity">
                      <InputNumber
                        value={this.state.quantity}
                        style={{
                          width: 200,
                        }}
                        onChange={(e) => this.setState({ quantity: e })}
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item
                      label="Description"
                      name="description"
                      {...formItemLayout}>
                      <TextArea
                        value={this.state.description}
                        style={{
                          width: 250,
                        }}
                        onChange={(e) =>
                          this.setState({ description: e.target.value })
                        }
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    <Form.Item>
                      <Button
                        style={{
                          width: 80,
                        }}
                        htmlType="submit"
                        type="primary">
                        Add
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </Card>
          </Row>

          <div style={{ marginTop: '30px' }}>
            <Table
              bordered={true}
              rowKey={(x) => x.productName}
              columns={refColumns}
              dataSource={this.state.totalData || []}
              pagination={false}
            />
          </div>
          <Row type="flex" justify="center" style={{ marginTop: '30px' }}>
            <Button
              htmlType="submit"
              onClick={this.pageSubmit}
              type="primary"
              loading={this.state.buttonReload}
              style={{
                width: 120,
              }}>
              {this.state._id !== null ? 'Update Inward' : 'Save Inward '}
            </Button>
          </Row>
        </Card>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  currentUser: global.currentUser,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddInward)
