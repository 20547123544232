import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Input,
  notification,
  Anchor,
  Spin,
  Select,
  Tag,
  Button,
  Table,
  Card,
  Typography,
  Row,
  Col,
} from 'antd'
import _ from 'lodash'
import { FormUtils as GetAllFormFields } from 'sz-react-utils'
import moment from 'moment'

import { API_URL } from '../../../request'

import 'react-quill/dist/quill.snow.css'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
//import styles from './styles.less'
import { connect } from 'react-redux'
import { goBack } from 'connected-react-router'

const FormItem = Form.Item
const { Option } = Select
const { Text, Paragraph } = Typography
const { TextArea } = Input

let number = 100000000

@Form.create()
class VoucherForm extends PureComponent {
  handleSubmit = (e) => {
    const { dispatch, form, currentUser } = this.props
    let { tableData } = this.state

    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let values = _.clone(valData)

        let foundFlag = false

        let tempArr = []
        let errorIds = []
        let smallerArr = []

        {
          this.state.generateSerialNumber &&
            _.each(this.state.tableData, (item) => {
              if (
                !(item.productId.generateSerialNumber == undefined
                  ? true
                  : item.productId.generateSerialNumber)
              )
                return
              if (!item.serialNumbers) {
                return (foundFlag = true)
              }
              if (
                item.serialNumbers &&
                item.serialNumbers.length !== item.qty
              ) {
                if (item.generateSerialNumber) foundFlag = true
              }
              _.each(item.serialNumbers, (val) => {
                if (!tempArr.includes(val)) {
                  tempArr.push(val)
                } else {
                  errorIds.push(val)
                }

                if (val < 99999) {
                  smallerArr.push(val)
                }
              })
            })
        }

        if (errorIds.length) {
          return notification.error({
            message: `Duplicate Product Ids ${errorIds}.`,
          })
        }
        if (smallerArr.length) {
          return notification.error({
            message: `Serial number should be greater than 6 ${smallerArr}.`,
          })
        }
        if (foundFlag) {
          return notification.error({
            message: 'Product Ids must be equivalent to quantity.',
          })
        }

        let voucherData = {}
        voucherData.voucherNotes = values.voucherNotes
        voucherData.type = this.state.type
        voucherData.createdByUserId = JSON.parse(
          window.localStorage.getItem('user')
        )._id
        voucherData.createdForUserId = values.createdForUserId
        voucherData.products = [
          {
            productId: valData.productId,
            qty: valData.qty,
          },
        ]
        voucherData.bom = tableData
        voucherData.voucherDate = values.voucherDate
        voucherData.voucherNumber = values.vID.split('-')[1]
        voucherData.voucherId = values.vID
        voucherData.generateSerialNumber = this.state.generateSerialNumber
        dispatch(showLoader())
        let x = null
        if (this.state.id) {
          voucherData._id = this.state.id

          x = await Request.updateVoucher(voucherData)
        } else {
          x = await Request.createVoucher(voucherData)
        }

        dispatch(hideLoader())

        if (!x.error) {
          notification.success({
            message: this.state.id ? x.message : x.message,
          })

          if (this.state.id) {
            dispatch(goBack())
          }

          this.props.form.resetFields()
          this.props.form.setFieldsValue({ scheduledDate: moment() })
          this.getOptionsData()

          this.setState({ tableData: [] })
        } else {
          notification.error({
            message: 'Error Saving',
            description: x.message,
          })
        }
      }
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      visible: false,
      loader: true,
      productList: [],
      tableData: [],
      selectedProduct: '',
      type: 'Manufacture',
      autoIncrementNumber: number,
    }
  }

  onProductSelect = (val) => {
    let selectedQuote = this.state.productList.find((obj) => obj._id === val)
    if (selectedQuote) {
      this.setState(
        {
          tableData: (selectedQuote && selectedQuote.bom) || [],
          generateSerialNumber:
            selectedQuote && selectedQuote.generateSerialNumber ? true : false,
        },
        () => {
          this.calculateQuantity()
        }
      )
    }
  }

  getOptionsData = async () => {
    let currentUser = JSON.parse(window.localStorage.getItem('user'))

    let filter = {
      userType: { $in: ['Technician'] },
      results: 1000,
    }
    this.props.form.setFieldsValue({
      userId: currentUser._id,
    })

    Request.loadUser(filter).then(({ data: userList }) => {
      this.setState({ userList: userList || [] })
    })

    Request.getAllProducts({ results: 200 }).then(({ data: productList }) => {
      this.setState({ productList: productList || [] }, () => {})
    })
    this.props.form.setFieldsValue({ voucherDate: moment() })

    let x = await Request.nextVoucherId({ type: this.state.type })
    this.props.form.setFieldsValue({ vID: 'MFT-' + x.message })
  }

  async componentDidMount() {
    let searchParams = new URLSearchParams(this.props.search)
    let manufactureId = searchParams.get('id')

    this.getOptionsData()

    if (manufactureId) {
      this.setState({ loader: true })

      Request.getVoucherById({ _id: manufactureId }).then(
        ({ data: voucherData, error, message }) => {
          if (!error) {
            this.setState({
              id: manufactureId,
              loader: false,
              userId: [],
              tableData: voucherData && voucherData.bom,
              generateSerialNumber:
                voucherData && voucherData.generateSerialNumber,
            })
            this.props.form.setFieldsValue({
              invoiceNo: voucherData && voucherData.invoiceNo,
              productId:
                voucherData &&
                voucherData.products &&
                voucherData.products[0].productId &&
                voucherData.products[0].productId._id,
              qty:
                voucherData &&
                voucherData.products &&
                voucherData.products[0].qty,
              scheduledDate:
                voucherData && voucherData.voucherDate
                  ? moment(voucherData.voucherDate)
                  : '',
              voucherNotes: voucherData && voucherData.voucherNotes,
              createdForUserId: voucherData && voucherData.createdForUserId,
              vID: voucherData.voucherId,
            })
          } else {
            notification.error({
              message: 'Error Getting Data',
            })
          }
        }
      )
    } else {
      this.props.form.setFieldsValue({ scheduledDate: moment() })
    }

    this.setState({ loader: false })
  }

  calculateQuantity = async () => {
    let tt = _.cloneDeep(this.state.tableData)
    _.each(tt, (item) => {
      if (!item.previousQuantity) {
        item.previousQuantity = item.qty
      }
      item.qty =
        item.previousQuantity * this.props.form.getFieldValue('qty') || item.qty
    })
    this.setState({
      tableData: tt,
      tableKey: Date.now(),
    })
  }

  render() {
    const {
      form,
      form: { getFieldDecorator },
    } = this.props

    const { productList, _id, userList } = this.state

    const inputTypes = {
      fields: [
        {
          label: 'Voucher Id',
          key: 'vID',
          type: 'text',
          customProps: {
            disabled: true,
          },
        },
        {
          label: 'Product',
          key: 'productId',
          showSearch: true,
          required: true,
          keyAccessor: (x) => x._id,
          valueAccessor: (x) => x.name,
          type: 'select',
          disabled: this.state.id ? true : false,
          options: productList || [],
          onChange: (filledBy) => {
            this.setState({ selectedProduct: filledBy })
            this.props.form.setFieldsValue({
              productId: filledBy,
            })
            this.onProductSelect(filledBy)
          },
        },
        {
          label: 'Quantity',
          key: 'qty',
          required: true,
          type: 'number',
          onChange: (val) => {
            this.props.form.setFieldsValue({ qty: val })
            this.calculateQuantity()
          },
        },
        {
          label: 'Scheduled date',
          key: 'scheduledDate',
          required: true,
          type: 'date',
          customProps: {
            disabledDate: (d) => !d || d.isBefore(moment().add(-1, 'day')),
          },
        },

        {
          label: 'Responsible',
          key: 'createdForUserId',
          showSearch: true,
          required: true,
          keyAccessor: (x) => x._id,
          valueAccessor: (x) => `${x.name} ${x.mobile} (${x.userType})`,
          type: 'select',
          options: userList || [],
          onChange: (filledBy) => {
            this.props.form.setFieldsValue({
              createdForUserId: filledBy,
            })
          },
        },
      ],
    }

    const refColumns = [
      {
        title: 'Product',
        dataIndex: 'productName',
        key: 'productName',
        width: 100,
        render: (x, item, index) => {
          return (
            <div style={{ marginBottom: '5px' }}> {item.productId.name}</div>
          )
        },
      },
      {
        title: 'Quantity purchased',
        key: 'qty',
        width: 40,
        dataIndex: 'qty',
        render: (y, item, index) => {
          return (
            <div>
              <Text>{y}</Text>
            </div>
          )
        },
      },
      {
        title: 'Product Serial No.',
        key: 'serialNumbers',
        dataIndex: 'serialNumbers',
        width: 100,
        render: (x, item, key) => {
          return (
            <React.Fragment>
              <Select
                defaultValue={item && item.serialNumbers}
                style={{ width: 200 }}
                placeholder={'Enter Product Id'}
                mode={'tags'}
                disabled={
                  !(item.productId.generateSerialNumber == undefined
                    ? true
                    : item.productId.generateSerialNumber)
                }
                onChange={(e) => {
                  let array = []
                  _.each(e, (i) => {
                    array.push(i)
                  })
                  item.serialNumbers = array
                }}
              />
            </React.Fragment>
          )
        },
      },
    ]

    if (!this.state.generateSerialNumber) {
      refColumns.splice(3, 1)
      refColumns.splice(2, 1)
    }
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 },
      },
    }

    return (
      <PageHeaderWrapper title={'Manufacture Voucher Create'}>
        <Spin spinning={this.state.loader}>
          <Form
            onSubmit={this.handleSubmit}
            hideRequiredMark={false}
            style={{ marginTop: 8 }}>
            <Card bordered={true}>
              <Row>
                <Col span={14}>
                  <GetAllFormFields
                    apiurl={API_URL}
                    inputSchema={inputTypes}
                    formItemLayout={formItemLayout}
                    getFieldDecorator={getFieldDecorator}
                  />
                </Col>
                <Col span={10}>
                  <Form.Item label={'Notes/Description'} {...formItemLayout}>
                    {getFieldDecorator('voucherNotes')(
                      <TextArea
                        rows={4}
                        onChange={(ei) => {
                          this.setState({ notes: ei.target.value })
                        }}
                      />
                    )}
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  {this.state.tableData && this.state.tableData.length ? (
                    <Table
                      key={this.state.tableKey}
                      bordered={true}
                      rowKey={(x) => x.productName}
                      columns={refColumns}
                      dataSource={this.state.tableData}
                      pagination={false}
                    />
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
              <Form.Item style={{ marginTop: 32, textAlign: 'center' }}>
                <Button
                  size="large"
                  type="primary"
                  loading={this.props.loading}
                  htmlType="submit"
                  style={{ width: '15%', marginLeft: '1em' }}>
                  {_id ? `Update` : `Save`}
                </Button>
              </Form.Item>
            </Card>
          </Form>
        </Spin>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  currentUser: global.currentUser,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VoucherForm)
