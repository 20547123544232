import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils'
import moment from 'moment'
import {
    Modal,
    Card,
    Button, Tooltip,
    Select, Row, Col,
    Drawer, Table, Tag,
    Popconfirm,notification

} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
//import styles from './styles.less'
import {connect} from 'react-redux'
import {apiUrl} from "../../../settings";
import {getUrlPushWrapper} from "../../../routes";

const {Option} = Select

class AllStockProducts extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {visible: false, key: Date.now(), currentPage: 1}

    }


    apiRequest = (params) => {
        if (params.page) {
            this.state.currentPage = params.page
        }

        return new Promise(async (resolve) => {
            if (!params.sortField) {
                params.sortField = 'name'
                params.sortOrder = 'ascend'
            }

            let data = await Request.getAllStockProducts({
                ...params, regExFilters: ['name']
            })

            this.setState({count: data.total})
            resolve(data);
        })


    }

    async componentDidMount() {
        let {data: categoryList} = await Request.loadCategories();

        this.setState({categoryList: categoryList})


    }
    deleteStockProduct = async data => {
        this.setState({loading: true})
        let x = await Request.deleteStockProduct(data)
        this.setState({loading: false})

        this.table.current.reload()
        if (!x.error) {
            notification.success({
                message: "Product deleted successfully",
                duration: 20,
                key: `${data._id}-close`
            })

        }
        else {
            notification.error({
                message:"Product can't be deleted (record already exist)",
                duration: 20,
                key: `${data._id}-close`
            })
        }


    }
    render() {
        const {dispatch} = this.props
        const {currentPage, stockData} = this.state;

        const categoriesArray = this.state.categoryList && this.state.categoryList.map((item) => {
            return {
                text: item.category,
                value: item._id
            }
        });
        const columns = [
            {
                title: 'S.No',
                dataIndex: 'sno',
                width: 50,
                key: 'sno',
                render: (value, item, index) => (currentPage - 1) * 10 + index + 1
            },
            {
                title: 'Product Name',
                key: 'name',
                width: 150,
               
                dataIndex: 'name',
                searchTextName: 'name'


            },
            {
                title: 'Category',
                width: 100,
                key: 'category',
                filters: categoriesArray,

                render: (val, record) => {
                    return <div>
                        {record && record.category && record.category.category}
                    </div>
                }

            },


            // {
            //     title: 'Inward Total',
            //     dataIndex: 'inwardCount',
            //     width: 100,
            //     searchTextName: 'inwardCount',

            // }, {
            //     title: 'Outward Total',
            //     dataIndex: 'outwardCount',
            //     width: 100,
            //     searchTextName: 'outwardCount',

            // },
            {
                title: 'Net Stock',
                dataIndex: 'stock',
                width: 80,
                render: (val, record) => {
                    if ((record && record.stock) <= (record && record.criticalValue)) {
                        return <Tag color={'red'}>{record.stock}</Tag>
                    } else {
                        return <Tag>{record.stock}</Tag>
                    }
                }

            },
            {
                title: 'Critical Value One ',
                dataIndex: 'criticalValue',
                width: 120,
                searchTextName: 'criticalValue',

            },
            {
                title: 'Critical Value Two',
                dataIndex: 'criticalValueTwo',
                width: 120,
                searchTextName: 'criticalValueTwo',

            },

            {
                title: 'Created At',
                width: 120,
                key: 'createdAt',
                dataIndex: 'createdAt',
                sorter: true,
                render: (text, record) => {
                    return (
                        <div>
                            {text ? moment(text).format('lll') : ''}
                        </div>
                    )
                }
            },
            {
                key: 'actions',
                width: 40,
                title: 'Actions',
                render: (text, record) => {
                    return <React.Fragment>

                        <Tooltip title="Edit Product">
                            <Button
                                shape="circle"
                                style={{marginRight: 6}}
                                size="small"
                                onClick={() => {
                                    dispatch(
                                        getUrlPushWrapper('inward.stockProductEdit', {
                                            id: record._id
                                        })
                                    )
                                }}
                                icon="edit"
                            />
                        </Tooltip>
                        <Tooltip title="View Ledger">
                            <a href={`/inward/view/ledger?id=${record._id}`} target={'_blank'}>
                                <Button size="small" icon={'eye'} shape={'circle'}/>
                            </a>
                        </Tooltip>
                        <Tooltip title="Delete Product">
                            <Popconfirm
                                title="Are you sure to delete this Product?"
                                onConfirm={() => {
                                    this.deleteStockProduct({_id: record._id})
                                }}
                                onCancel={() => {
                                    console.log()
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button
                                    shape="circle"
                                    size="small"

                                    icon="delete"
                                />
                            </Popconfirm>

                        </Tooltip>
                    </React.Fragment>
                }
            }


        ]

        return (
            <PageHeaderWrapper
                title={this.state.count ? `All Stock Products: ${this.state.count}` : `All Stock Products: 0`}>

                <Card bordered={true}>

                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllStockProducts)
