import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import { Divider, Button, List, Card } from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import Request from '../../request'
import { connect } from 'react-redux'
import { createMatchSelector } from 'connected-react-router'
import S from 'string'
import moment from 'moment'

class Notes extends PureComponent {
  saveNote = async () => {
    let { textNote } = this.state
    let { handleAddNote, leadId } = this.props
    if (!textNote) {
      return notification.error({ message: 'Please enter a note to proceed!' })
    }
    this.setState({ loading: true })
    let x = await Request.saveTextNote({
      message: textNote,
      leadId,
      checkLead: true,
    })
    this.setState({ loading: false })
    if (!x.error) {
      notification.success({
        message: x.message,
      })
      this.setState({ textNote: '' })
      //quill.setContents([{ insert: '\n' }])

      if (handleAddNote) {
        handleAddNote()
      }
    } else {
      notification.error({
        message: 'Error Saving',
        description: x.message,
      })
    }
  }

  constructor(props) {
    super(props)
    this.state = { notesData: [], loading: false, textNote: '' }
  }

  async componentDidMount() {}

  render() {
    const { hideTitle, hideSubmit, notesData } = this.props

    let form = (
      <div>
        {hideTitle && (
          <React.Fragment>
            <div style={{ height: '110px' }}>
              <ReactQuill
                style={{ backgroundColor: 'white' }}
                placeholder={'Write something here...'}
                onChange={(text) => {
                  this.setState({ textNote: text })
                }}
              />
            </div>

            {!hideSubmit && (
              <Button
                type="primary"
                style={{ marginTop: '5px' }}
                loading={this.state.loading}
                onClick={() => {
                  this.saveNote()
                  var element = document.getElementsByClassName('ql-editor')
                  element[0].innerHTML = ''
                  this.setState({ textNote: '' })
                }}>
                Submit
              </Button>
            )}

            {/* {notesData && notesData.length > 0 && <List
          bordered
          itemLayout="horizontal"
          dataSource={notesData || []}
          // className={styles['demo-loadmore-list']}
          size="small"
          header={<div>Total : {notesData.length}</div>}
          renderItem={item => (
            <List.Item>
              <List.Item.Meta
                description={S(item.message).stripTags().s}
              />
              <div>{item.date ? moment(item.date).format('lll') : ''}</div>
            </List.Item>
          )} 
          />} */}
          </React.Fragment>
        )}
      </div>
    )

    return (
      <React.Fragment>
        {!hideTitle ? <PageHeaderWrapper>{form}</PageHeaderWrapper> : form}
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  pathname: router.location.pathname,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Notes)
