import React, {Component} from 'react'
import PageHeaderWrapper from '../../components/PageHeaderWrapper'
import moment from 'moment'
import {TableComp} from 'sz-react-utils'
import ReactPlayer from 'react-player/lazy'
import styles from './styles.less'
import {
    Card,
    Tooltip,
    Popover,
    Button,
    Select,
    Row,
    Col,
    Upload,
    Slider,
    notification

} from 'antd'
import Request from '../../request'
import _ from 'lodash'
// import styles from './styles.less'
import {connect} from 'react-redux'
import {apiUrl} from '../../settings'
import {getUrlParams} from "../../routes";
import {API_URL} from '../../request'


const {Option} = Select

class AllCallLogs extends Component {
    apiRequest = (params) => {
        if (params.page) {
            this.state.currentPage = params.page
        }
        return new Promise(async (resolve) => {
            const {RONumber, urlRONumber, startDurationRange, lastDurationRange, isOutgoingCall} = this.state
            if (!params.sortField) {
                params.sortField = 'callingTime'
                params.sortOrder = 'descend'
            }
            if (RONumber) {
                params.roNumber = RONumber
            }
            if (this.state.roId) {
                params.roNumber = this.state.roId
            }
            if (urlRONumber) {
                params.roNumber = urlRONumber

                params.callingTime = {
                    $gte: this.state.gte,
                    $lte: this.state.lte
                }
            }
            if (this.state.status) {
                params.callType = this.state.status
            }
            if (this.state.duration) {
                params.duration = {$gt: 0}
            }
            // if (this.state.time && this.state.time.from) {
            //   params.dateFilter = this.state.time
            // }
            if ((lastDurationRange && startDurationRange) || startDurationRange == 0) {
                params.duration = {$gte: startDurationRange, $lte: lastDurationRange}
                if (isOutgoingCall) {
                    params.duration = {$gt: startDurationRange, $lte: lastDurationRange}

                }
            }
            let data = await Request.getAllCallLogs({
                ...params, regExFilters: ['dialedNumber']
            })
            this.setState({count: data.total || 0})
            resolve(data)
        })
    }

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            count: 0,
            currentPage: 1,
            showPlayer: [],
            callType: ['outgoing', 'missed', 'incoming'],
            files: []
        }

        let searchParams = getUrlParams('callUrl', window.location.pathname)
        if (searchParams) {
            let {value, gte, lte, officerId} = searchParams
            if (value && value === 'attended') {
                this.state.duration = true
            }
            if (value && (value !== '0' && value !== 'attended')) {
                this.state.status = value
                if (value === 'outgoing') {
                    this.state.startDurationRange = 5
                    this.state.lastDurationRange = 2000
                    this.state.isOutgoingCall = true
                }
                if (value === 'rejectedOutgoing') {
                    this.state.startDurationRange = 0
                    this.state.lastDurationRange = 0
                    this.state.status = 'outgoing'

                }


            }

            if (officerId) {
                this.state.urlRONumber = officerId
                this.state.lte = lte
                this.state.gte = gte
            }
        }

    }

    componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search);

        Request.getAllROList({userType: 'RO', results: '10000000000000'}).then(({data: userList}) => {
            this.setState({userList: userList || []})
        })


        let RONumber = searchParams.get('RONumber')
        if (RONumber)
            this.setState({RONumber: atob(RONumber)})
    }

    saveMediaFiles() {
        let {files} = this.state;
        let x = Request.saveMediaFile({files})


    }

    handleVisibleChange = (key, value) => {
        let newData = _.clone(this.state.showPlayer)
        newData[key] = value
        this.setState({showPlayer: newData})
    }

    render() {

        let {count, RONumber, showPlayer, callType, userList, currentPage, startDurationRange, lastDurationRange} = this.state

        const getStatusStyle = (status, duration) => {
            if (status === 'outgoing' && duration == 0) {
                return {
                    backgroundColor: "#FBB917",
                    textAlign: "center",
                    color: "white",
                    padding: "2px 2px",
                    borderRadius: "2px",
                    minWidth: "30px"
                }
            }
            else if (status === 'outgoing' && duration > 0) {
                return {
                    backgroundColor: "#43BE31",
                    textAlign: "center",
                    color: "white",
                    padding: "2px 2px",
                    borderRadius: "2px",
                    minWidth: "30px"
                }
            }
            else if (status === 'incoming') {
                return {
                    backgroundColor: "#1287A5",
                    textAlign: "center",
                    color: "white",
                    padding: "2px 2px",
                    borderRadius: "2px",
                    minWidth: "30px"
                }
            }
            else {
                return {
                    backgroundColor: "red",
                    color: "white",
                    textAlign: "center",
                    padding: "2px 2px",
                    borderRadius: "2px",
                    minWidth: "30px"
                }
            }


        }

        const handleChangeDebut = (range) => {
            if (!range.length) {
                this.setState({
                    startDurationRange: undefined,
                    lastDurationRange: undefined,
                })
            }
            else {
                const valueOfInput1 = range[0]
                const valueOfInput2 = range[1]
                this.setState({
                    startDurationRange: valueOfInput1,
                    lastDurationRange: valueOfInput2,
                    isOutgoingCall: false
                });
            }
            setTimeout(() => {
                this.table.current.reload()

            }, 500);

        }


        const handleFileChange = async (v) => {
            checkStatus(v.file);
        }

        const checkStatus = (file) => {
            if (!file) return;
            if (file.status == 'done' || file.status == 'success') {
                notification.success({message: "Contacts uploaded"});
                this.setState({files: []})
            }
            if (file.status == 'error') {
                notification.danger({message: "Something went wrong"});
                this.setState({files: []})
            }
        }

        const checkMimeType = (file) => {
            if (!file) return;
            if (file.name.toLowerCase().includes('.csv')) {
                return true
            } else {
                notification.error({message: "Please upload .csv file."})
            }
            return false
        }

        const columns = [
            {
                title: 'S.No',
                dataIndex: 'sno',
                width: 50,
                key: 'sno',
                render: (value, item, index) => (currentPage - 1) * 10 + index + 1
            },
            {
                title: 'Dialed Number',
                dataIndex: 'dialedNumber',
                key: 'dialedNumber',
                searchTextName: 'Dialed Number',
                render: (val, record) => {
                    return (<div>
                        <div>{record && record.contactName ? record.contactName :
                            <span style={{color: 'red'}}>NO CONTACT</span>}</div>
                        <div>

                            {record && record.contactId ? <a href={`/contact/history?id=${record.contactId}`}
                                                             target={'_blank'}>{record && record.dialedNumber}</a> : record && record.dialedNumber}

                        </div>
                        <div>{record && record.contactEmail}</div>

                    </div>)
                }
            },
            {
                title: 'RO Number',
                key: 'roNumber',
                render: (val, record) => {
                    return (<div>
                        <div>{record && record.roName}</div>
                        <div>{record && record.roNumber}</div>
                        <div>{record && record.roEmail}</div>

                    </div>)
                }
            },
            {
                title: 'Duration',
                dataIndex: 'duration',
                key: 'duration',
                render: (val) => `${moment.utc(val * 1000).format('HH:mm:ss')}`
            },
            {
                title: 'Call Type',
                dataIndex: 'callType',
                key: 'callType',
                filters: callType && callType.map(x => ({value: x, text: x})),
                render: (val, record) => {
                    return (
                        <div>{val ? <div
                            style={getStatusStyle(val.toLowerCase(), record && record.duration)}>{_.startCase(val)}</div> : ''}</div>
                    )
                }
            },
            {
                title: 'Called At',
                dataIndex: 'callingTime',
                key: 'callingTime',
                searchDateName: 'callingTime',
                sorter: true,
                render: (item, record) => {
                    return (<div>{record && record.callingTime ? moment(record.callingTime).format('lll') : ''}</div>)
                }
            },
            {
                title: 'Synced At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                searchDateName: 'createdAt',

                sorter: true,
                render: (item) => {
                    return (<Tooltip title={moment(item).format('lll')}>
                        {moment(item).fromNow()}
                    </Tooltip>)
                }
            },
            {
                key: 'recording',
                title: 'Recordings',
                width: 130,
                render: (val, record, key) => <>
                    {
                        record && record.recording && <Popover
                            content={<ReactPlayer
                                url={`${apiUrl}/recordingStream?id=${record && record.recording}`}
                                controls
                                height={50}
                                className={styles.player}
                                width={400}
                            />}
                            placement={'bottomRight'}
                            trigger="click"
                            destroyTooltipOnHide
                            visible={showPlayer[key]}
                            onVisibleChange={(val) => this.handleVisibleChange(key, val)}
                        >
                            <Button icon='play-circle' shape='circle' style={{marginRight: '6px'}}/>
                        </Popover>
                    }
                    {record && record.recording && <Tooltip title='Download recording'>
                        <Button style={{marginRight: '6px'}} icon='download' shape='circle' onClick={() => {
                            Request.download(record)
                        }}/>
                    </Tooltip>}
                    {record && record.leadId && record.leadId._id && <Tooltip title='View Lead'>
                        <a href={`/lead/detail?id=${record.leadId._id}`} target={'_blank'}>

                            <Button icon='eye' shape='circle'/>
                        </a>


                    </Tooltip>}


                </>
            }
        ]
        return (
            <PageHeaderWrapper
                title={<div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div>{`All Calls: ${count}`}</div>
                    <div>{RONumber}</div>
                </div>}>

                <Card bordered={true}>
                    <Row>
                        <Col span={12}>
                            <h4 style={{display: 'inline-block', marginRight: '10px'}}>Select Relationship Officer</h4>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                style={{
                                    width: 225

                                }}
                                placeholder="Select Relationship Officer"
                                onChange={id => {
                                    this.setState({roId: id}, () => {
                                        this.table.current.reload()
                                    })
                                }}
                            >
                                {userList &&
                                userList.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val.mobile}>
                                            {val.name} ({val.mobile})
                                        </Option>
                                    )
                                })}
                            </Select>


                        </Col>
                        <Col span={4} offset={8}>
                            <Upload
                                name={'file'}
                                action={`${API_URL}/uploadContactCsv`}
                                multiple={false}
                                onChange={handleFileChange}
                                beforeUpload={checkMimeType}
                                showUploadList={false}
                                maxCount={1}
                            >
                                <Button icon="upload">
                                    Upload Contact
                                </Button>
                            </Upload>
                        </Col>

                    </Row>

                    <Row>
                        <Col>
                            <h4 style={{display: 'inline-block', marginRight: '10px', marginTop: '10px'}}>Duration
                                Range</h4>

                            <Slider range defaultValue={[0, 2000]} max={2000} min={0}
                                    onAfterChange={(value, range) => handleChangeDebut(value)}
                            />
                        </Col>
                    </Row>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global, router}) => ({
    categories: global.categories,
    loading: global.buttonLoading,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllCallLogs)
