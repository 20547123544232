import React, {PureComponent} from 'react'
import {
    Form,
    Input,
    Button,
    DatePicker,
    Radio,
    Row,
    Col,
    Select,
    InputNumber,
    AutoComplete,
    Icon,
    Spin,
    notification,
    Modal,
    Checkbox
} from 'antd'
import styles from './styles.less'
import {AiOutlineSwap, AiOutlineDelete, AiOutlinePlusCircle} from 'react-icons/ai'
import {RightOutlined} from '@ant-design/icons'
import Request from '../../../request'
import moment from 'moment/moment'
import async from 'async'
import _ from 'lodash'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'
import {API_URL} from '../../../request'
import Search from 'antd/lib/input/Search'

const Option = Select.Option

@Form.create()
class AddMultipleProduct extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            loading: false,
            productList: [],
            checkedOther: false,
            selectedProducts: [],
            otherProduct: '',
            ProductArrayState: [],
            loadingProductList: false
        }


    }

    state = {
        form: {}
    }


    handleSubmit = async (e) => {
        const {dispatch, form, handleSubmit} = this.props

        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)
                const {t, dispatch, formResponse} = this.props
                let {productList, selectedProducts, otherProduct} = this.state
                let array = []
                if (selectedProducts.includes('other')) {
                    if (!otherProduct) {
                        return notification.error({message: 'Required Product Name'})
                    }

                    let arr = _.reject(selectedProducts, (item) => {
                        return item === 'other'
                    })
                    values.productList = arr
                } else {
                    values.productList = selectedProducts
                }
                let obj = {_id: this.props.leadId, productList: values.productList}

                _.each(values.productList, (i) => {
                    let found = _.find(productList, (ii) => {
                        return i === ii._id
                    })
                    if (found) {
                        array.push(found.name)
                    }
                })
                if (array && array.length) {
                    if (otherProduct) {
                        array.push(otherProduct)
                    }
                    obj.title = array.join(', ')
                } else {
                    obj.title = otherProduct
                }

                obj.otherProduct = otherProduct ? otherProduct : ''
                this.setState({btnLoading: true})

                let x = await Request.updateLead(obj)
                this.setState({btnLoading: false})

                if (!x.error) {
                    if(this.props.setShowTabs) {
                        this.props.setShowTabs(true)
                    }

                    notification.success({
                        message: x.message
                    })
                    if (formResponse) {
                        formResponse()
                    }

                } else {
                    console.log('err is', x.error)
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }
            }
        })
    }

    
    searchProduct = (value) => {
        let { productList } = this.state

        let cloneList = _.clone(productList)

        if (cloneList) {
            let newProductArray = cloneList.filter((val) => {
                let pro = val && val.name && val.name.toLowerCase()
                let check = pro && pro.includes(value.toLowerCase())
                return check
            })

            let ProductArray = newProductArray && newProductArray.map((user) => {
                return {
                    label: user.name,
                    value: user._id
                }

            });
    
            this.setState({ ProductArrayState: ProductArray })

        }

    }


    async componentDidMount() {
        let {selectedProducts, otherProduct} = this.props
        if (this.props.selectedProducts && this.props.selectedProducts.length) {
            selectedProducts = _.map(this.props.selectedProducts, (ii) => {
                return ii._id
            })

            this.setState({selectedProducts: selectedProducts})

            this.props.form.setFieldsValue({productList: selectedProducts})

        }
        if (otherProduct) {
            this.setState({
                checkedOther: true,
                otherProduct: otherProduct,
                selectedProducts: [...selectedProducts, 'other']
            })
            this.props.form.setFieldsValue({productList: [...selectedProducts, 'other']})


        }

        this.setState({ loadingProductList: true })

        Request.getAllProducts({results: 1000000}).then(({data: productList}) => {
            this.setState({productList: [...productList, {name: 'Other', _id: 'other'}] || []})

            let abcd = [...productList, {name: 'Other', _id: 'other'}] || []

            let ProductArray = abcd && abcd.map((user) => {
                return {
                    label: user.name,
                    value: user._id
                }
            });
    
            this.setState({ ProductArrayState: ProductArray })
    
            this.setState({ loadingProductList: false })

        })

    }


    render() {

        const {} = this.props
        let {productList, btnLoading, checkedOther} = this.state
        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        return (
          <div className={'container'}>
            <Search
              placeholder="Search product here"
              allowClear
              onSearch={this.searchProduct}
              style={{ marginBottom: '16px' }}
            />

            {this.state.loadingProductList ? (
              <div style={{ display: 'flex', justifyContent: 'center' }}>
                <Spin size='large' loading={this.state.loadingProductList} />
              </div>
            ) : (
              <Form onSubmit={this.handleSubmit}>
                <Form.Item>
                  {getFieldDecorator('productList', {
                    rules: [
                      {
                        required: true,
                      },
                    ],
                  })(
                    <Checkbox.Group
                      className={styles.checkBox}
                      options={this.state.ProductArrayState}
                      onChange={(checkedValues) => {
                        if (checkedValues.includes('other')) {
                          this.setState({
                            checkedOther: true,
                            selectedProducts: checkedValues,
                          })
                        } else {
                          this.setState({
                            checkedOther: false,
                            selectedProducts: checkedValues,
                            otherProduct: '',
                          })
                        }
                      }}
                    />
                  )}
                </Form.Item>
                {checkedOther && (
                  <Input
                    value={this.state.otherProduct}
                    style={{ width: '50%', marginRight: '5px' }}
                    placeholder={'Enter Product'}
                    onChange={(v) => {
                      this.setState({ otherProduct: v.target.value })
                    }}
                  />
                )}
                <Button htmlType="submit" type="primary" loading={btnLoading}>
                  SAVE
                </Button>
              </Form>
            )}
          </div>
        )

    }
}

export default AddMultipleProduct
