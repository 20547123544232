import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    notification,
    Anchor,
    Spin,
    Select,
    Tag,
    Button,
    Table,
    Card,
    Typography,
    Row,
    Col, Tooltip, Popconfirm, Checkbox, AutoComplete, Radio
} from 'antd'
import _ from 'lodash'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'
import moment from 'moment'
import 'react-quill/dist/quill.snow.css'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import styles from '../all/styles.less'
import {connect} from 'react-redux'

@Form.create()
class InwardListComp extends PureComponent {

    handleSubmit = e => {
        const {dispatch, form, currentUser, handleInward, id, type} = this.props

        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)

                values.purpose = 'Replacement'

                if (values.purpose !== 'Replacement') {
                    values.replacementType = ''
                    values.replacementList = []
                }

                if (values.replacementType === 'Inward Pending' || values.replacementType === 'Outward Pending') {
                    values.replacementList = []
                }

                values.type = type
                dispatch(showLoader())

                let x = null
                values._id = id

                x = await Request.updateInwardOutwardForm(values)


                dispatch(hideLoader())


                if (!x.error) {

                    notification.success({
                        message: x.message
                    })
                    if (handleInward) {
                        handleInward()
                    }


                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }

    constructor(props) {
        super(props)
        this.state = {
            loader: true,
            inwardList: [],
            checked: true,
            array: []
        }

    }


    async componentDidMount() {
        let {type} = this.props

        // if (type == 'Outward') {
        //     this.setState({array: ['Inward Pending', 'Already Inward']})
        //     Request.inwardOutwardList({
        //         type: 'Inward',
        //         purpose: 'Replacement',
        //         results: 100000000000000000
        //     }).then(({data}) => {
        //         this.state.inwardList = data || []
        //     })
        // } else {
        //     this.setState({array: ['Outward Pending', 'Already Outward']})
        //
        //     Request.inwardOutwardList({
        //         type: 'Outward',
        //         purpose: 'Replacement',
        //         results: 100000000000000000
        //     }).then(({data}) => {
        //
        //         this.state.inwardList = data || []
        //     })
        // }

    }


    render() {
        const {
            form,
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        const {replacementType, array, inwardList} = this.state
        const InwardList = inwardList && inwardList.map((item) => {
            return {
                label: `${item.serialNumber} ${item.department} ${item.challanNumber}`,
                value: item._id
            }
        });


        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }

        return (
            <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{marginTop: 8}}>

                <Card bordered={false}>

                    <Row>
                        <Col span={24}>
                            <Form.Item label={'Replacement Type'} {...formItemLayout}>
                                {getFieldDecorator('replacementType', {
                                    rules: [{
                                        required: true,
                                    }],
                                })(
                                    <Radio.Group options={array}
                                                 className={styles.inwardRadioButton}
                                                 onChange={(checkedValues) => {
                                                     this.setState({replacementType: checkedValues.target.value})
                                                 }} value={this.state.replacementType}>


                                    </Radio.Group>
                                )}
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            {(replacementType === 'Already Inward' || replacementType === 'Already Outward') ?
                                <Form.Item label={'List'} {...formItemLayout}>

                                    {getFieldDecorator('replacementInwardList', {
                                        rules: [{
                                            required: true,
                                            message: 'This is a Mandatory Field',
                                        }]
                                    })(
                                        <Checkbox.Group
                                            className={styles.inwardCheckbox}
                                            options={InwardList}
                                            onChange={(checkedValues) => {

                                            }}/>
                                    )}
                                </Form.Item> : ''}
                        </Col>
                    </Row>
                    <Form.Item style={{marginTop: 32, textAlign: 'center'}}>

                        <Button size="large" type='primary'
                                loading={this.props.loading}
                                htmlType='submit'
                                style={{width: '15%', marginLeft: '1em'}}>Save
                        </Button>


                    </Form.Item>
                </Card>


            </Form>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    currentUser: global.currentUser

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(InwardListComp)
