import React, { PureComponent, useState } from 'react'
import {
  AutoComplete,
  Descriptions,
  Button,
  Card,
  Col,
  Drawer,
  Form, Icon,
  List,
  Modal,
  notification,
  Row,
  Select,
  Table,
  Tag,
  Timeline,
  Upload
} from 'antd'
import 'react-quill/dist/quill.snow.css'
import { API_URL } from '../../../request'
import moment from 'moment'
import _ from 'lodash'

export const ProductsLogs = (props) => {
  let { productsData } = props
  const refColumns = [

    {
      title: 'Product',
      dataIndex: 'productName',
      key: 'productName',
      width: 200


    },
    {
      title: 'Photo',
      dataIndex: 'photos',
      width: 100,
      key: 'photos',
      render: (val, item) => {
        if (item && item.newPhotosArray && item.newPhotosArray.length) {
          return _.map(item.newPhotosArray, (ii) => {
            return <div><img src={`${API_URL}${ii}`} style={{ width: '50%', height: '50px', marginBottom: '10px' }} />
            </div>

          })

        } else {
          return <div>
            {val && <img src={`${API_URL}${val}`} style={{ width: '50%', height: '50%' }} />}
          </div>
        }


      }
    },

    {
      title: 'ProductIds',
      key: 'productIds',
      width: 200,
      dataIndex: 'productIds',
      render: (x, item) => {
        return <div>{item && item.productIds.join(' , ')}</div>
      }


    },


    {
      title: 'Quantity',
      key: 'quantity',
      width: 100,
      dataIndex: 'quantity'


    }
  ]


  return (
    <div>
      <Table size={'small'} columns={refColumns} dataSource={productsData}
             pagination={false} />
    </div>
  )
}

export const FilesLogs = (props) => {


  const { videos, photos } = props
  const columns3 = [

    {
      title: 'Download Photos',
      key: 'url',
      render: (val, record) => {
        if (val) {
          return <div style={{ display: 'flex', 'justify-content': 'space-between' }}>
            <img src={`${API_URL}${record && record.url}`} style={{ width: '50px', height: '50px' }} />
            <a onClick={() => {
              window.open(`${API_URL}/${record && record.url}`, 'download')
            }}><Tag> <Icon type='download' /> Download</Tag></a>

          </div>

        } else {
          return
        }

      }
    }
  ]
  const videoColumn = [

    {
      title: 'Download Videos',
      key: 'url',
      render: (val, record) => {
        if (val) {
          return <div style={{ display: 'flex', 'justify-content': 'space-between' }}>
            <iframe src={`${API_URL}${record && record.url}`} style={{ width: '50px', height: '50px' }} />
            <a onClick={() => {
              window.open(`${API_URL}/${record && record.url}`, 'download')
            }}><Tag> <Icon type='download' /> Download</Tag></a>

          </div>

        } else {
          return
        }

      }
    }
  ]

  return (
    <React.Fragment>

      <div>
        <Row gutter={8}>


          {videos && videos.length > 0 && <Col span={12}>
            <Table size={'small'} columns={videoColumn} dataSource={videos}
                   pagination={false} />
          </Col>}

          <Col span={12}>
            {photos && photos.length > 0 &&
            <Table size={'small'} columns={columns3} dataSource={photos} pagination={false} />
            }
          </Col>
        </Row>
      </div>

    </React.Fragment>
  )


}
export const DateLogs = (props) => {


  const { orderDate } = props

  return (
    <React.Fragment>

      <div>
        <Row gutter={8}>
          <Col>
            <Descriptions size={'small'} column={1}>
              {orderDate && orderDate.orderAcceptedDate ? <Descriptions.Item label={<b>Order Accepted
                Date</b>}>{moment(orderDate.orderAcceptedDate).format('lll')}</Descriptions.Item> : ''}


              {orderDate && orderDate.orderPreparingDate ? <Descriptions.Item label={<b>Order Preparing
                Date</b>}>{moment(orderDate.orderPreparingDate).format('lll')}</Descriptions.Item> : ''}


              {orderDate && orderDate.orderPreparedDate ? <Descriptions.Item label={<b>Order Prepared
                Date</b>}>{moment(orderDate.orderPreparedDate).format('lll')}</Descriptions.Item> : ''}

              {orderDate && orderDate.qualityCheckDate ? <Descriptions.Item label={<b>Quality Check
                Date</b>}>{moment(orderDate.qualityCheckDate).format('lll')}</Descriptions.Item> : ''}

              {orderDate && orderDate.qualityApprovedDate ? <Descriptions.Item label={<b>Quality Approved
                Date</b>}>{moment(orderDate.qualityApprovedDate).format('lll')}</Descriptions.Item> : ''}

              {orderDate && orderDate.readyToDispatchedDate ? <Descriptions.Item label={<b>Ready To Dispatched
                Date</b>}>{moment(orderDate.readyToDispatchedDate).format('lll')}</Descriptions.Item> : ''}
              {orderDate && orderDate.dispatched ? <Descriptions.Item label={<b>Dispatched
                Date</b>}>{moment(orderDate.dispatched).format('lll')}</Descriptions.Item> : ''}
            </Descriptions>


          </Col>

        </Row>
      </div>

    </React.Fragment>
  )


}





