export const allcolors = [
  'red',
  'pink',
  'purple',
  'deeppurple',
  'indigo',
  'blue',
  'lightblue',
  'teal',
  'green',
  'gray',
  'orange',
  'amber',
  'deep-orange'
]
export const getColorClass = (initial) => {
  const alphabets = ['a',
    'b',
    'c',
    'd',
    'e',
    'f',
    'g',
    'h',
    'i',
    'j',
    'k',
    'l',
    'm',
    'n',
    'o',
    'p',
    'q',
    'r',
    's',
    't',
    'u',
    'v',
    'w',
    'x',
    'y',
    'z']

  if (!initial) {
    return 0
  }

  let first = initial.charAt(0)
  let ind = alphabets.indexOf(first.toLowerCase())

  ind = Math.ceil(ind / 2)

  return `${allcolors[ind]}`
}
