import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp} from 'sz-react-utils'
import moment from 'moment'
import {
    Modal,
    Card,
    Button, Tooltip,
    Select,
    Row, Col

} from 'antd'
import Request from '../../../request'
import _ from 'lodash'
//import styles from './styles.less'
import {connect} from 'react-redux'
import {getUrlPushWrapper} from '../../../routes'
import {apiUrl} from "../../../settings";

const {Option} = Select

class AllCity extends Component {

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {visible: false, key: Date.now()}

    }


    apiRequest = (params) => {

        return new Promise(async (resolve) => {

            if (this.state.countryId) {
                params.countryId = this.state.countryId
            }

            if (this.state.stateId) {
                params.stateId = this.state.stateId
            }

            let data = await Request.getAllCity({
                ...params, regExFilters: ['cityName']
            })

            this.setState({count: data.total})
            resolve(data);
        })


    }
    showModal = () => {
        this.setState({
            visible: true
        })
    }

    async componentDidMount() {
        let {data: countryList} = await Request.loadCountry()

        this.setState({countryList: countryList})
    }

    render() {
        const {submitting, dispatch} = this.props
        const {visible, singleMail, countryList, stateList} = this.state;


        const columns = [
            {
                title: 'Country Name',
                key: 'countryName',
                width: 150,
                render: (val, record) => {
                    return <div>{record && record.countryId && _.startCase(record.countryId.countryName)}</div>
                }


            },
            {
                title: 'State Name',
                key: 'stateName',
                width: 150,
                render: (val, record) => {
                    return <div>{record && record.stateId && _.startCase(record.stateId.stateName)}</div>
                }


            },
            {
                title: 'City Name',
                key: 'cityName',
                width: 150,
                dataIndex: 'cityName',
                searchTextName: 'cityName',
                render: (val) => {
                    return val && _.startCase(val)
                }


            }

        ]


        return (
            <PageHeaderWrapper
                title={this.state.count ? `All City: ${this.state.count}` : `All City: 0`}>

                <Card>
                    <Row>
                        <Col span={8}>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                style={{
                                    width: 225

                                }}
                                placeholder="Select Country"
                                onChange={async (id) => {
                                    this.setState({stateId: ''})
                                    if (id) {
                                        let {data: stateList} = await Request.loadState({countryId: id})
                                        this.setState({showStates: true, stateList: stateList})
                                    }

                                    this.setState({countryId: id}, () => {
                                        this.table.current.reload()

                                    })
                                }}
                            >
                                {countryList &&
                                countryList.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val._id}>
                                            {val && _.startCase(val.countryName)}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Col>

                        <Col span={8}>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                style={{
                                    width: 225

                                }}
                                placeholder="Select State"
                                value={this.state.stateId}
                                disabled={this.state.showStates ? false : true}
                                onChange={(id) => {

                                    this.setState({stateId: id}, () => {
                                        this.table.current.reload()

                                    })
                                }}
                            >
                                {stateList &&
                                stateList.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val._id}>
                                            {val && _.startCase(val.stateName)}
                                        </Option>
                                    )
                                })}
                            </Select>
                        </Col>

                    </Row>
                </Card>


                <Card bordered={true}>

                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>


            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllCity)
