import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp, FormUtils as GetAllFormFields} from 'sz-react-utils'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Switch,
    Input, Button, Icon,
    Form,
    Drawer, Row, Col, Tag, Select
} from 'antd'
import Request, {API_URL} from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import {connect} from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import {getUrlPushWrapper} from '../../../routes'
import {hideLoader, showLoader} from '../../../modules/actions'

const Option = {Select}

@Form.create()
class AllInwardOutwardList extends Component {

    delete = async data => {
        this.setState({loading: true})
        let x = await Request.deleteInwardOutward(data)
        this.setState({loading: false})

        this.table.current.reload()
        if (!x.error) {
            notification.success({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })

        } else {
            notification.error({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })
        }


    }


    apiRequest = (params) => {
        if (params.page) {
            this.state.currentPage = params.page
        }
        let currentUser = JSON.parse(window.localStorage.getItem("user"))

        if ((currentUser && currentUser.userType === "Dispatch")) {
            params.filledBy = currentUser._id
        }
        if (!params.sortOrder) {
            params.sortField = 'createdAt';
            params.sortOrder = 'desc';
        }

        if (this.state.productName) {
            params['products.productName'] = this.state.productName
        }

        if (this.state.selectedProductId) {
            params['products.productIds'] = this.state.selectedProductId

        }

        this.setState({filters: {...params}})

        return new Promise(async (resolve) => {
            let data = await Request.inwardOutwardListDispatch({
                ...params,
                regExFilters: ['mobile', 'name', 'email', 'challanNumber', 'carrierDetails', 'packingDoneBy', 'consignee', 'invoiceNumber']
            })
            this.setState({count: data.total})

            resolve(data)
        })
    }
    showDrawer = () => {
        this.setState({
            visible: true,
            key: Date.now()
        })
    }

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            currentPage: 1,
            visible: false,
            userType: ['Inward', 'Outward'],
            productType: ['Finish Goods', 'Packing Material', 'Electronic Components', 'Raw Material'],
            purpose: ['Sale', 'Repair', 'Purchase', 'Replacement', 'Receiving'],
            carriersData: ['Fedex', 'DHL', 'UPS', 'USPS', 'BlueDart', 'DTDC'],
            productShow: [],
            fileDownloadLoader: []
        }

    }

    exportData = async () => {

        this.setState({startLoading: true})
        let data = await Request.inwardOutwardList({
            ...this.state.filters,
            export: true,
            regExFilters: ['mobile', 'name', 'email', 'challanNumber', 'carrierDetails', 'packingDoneBy', 'consignee', 'invoiceNumber']
        })
        this.setState({startLoading: false})
        if (!data.error) {
            window.open(data.url)

        } else {
            return notification.error({message: 'Error in Export File'})
        }


    }

    setLoaderForFile = (key, value) => {
        let {fileDownloadLoader} = this.state
        let newLoading = _.clone(fileDownloadLoader)
        newLoading[key] = value
        this.setState({fileDownloadLoader: newLoading})
    }
    downloadDispatch = async (item, key) => {
        this.setLoaderForFile(key, )

        await Request.downloadDispatch({_id: item})
        this.setLoaderForFile(key, false)
    }

    componentDidMount() {
        Request.loadUser({
            userType: ['RO', 'Admin', 'Dispatch', 'Technician'],
            results: 200
        }).then(({data: userList}) => {
            this.setState({userList: userList || []})
        })

        Request.inwardOutwardProducts().then(({data, productIds}) => {
            this.setState({productsData: data || [], productIds: productIds || []})
        })
    }

    render() {
        const {dispatch} = this.props

        let {
            singleRecord,
            userType,
            currentPage,
            userList,
            productsData,
            purpose,
            carriersData,
            productShow, productIds
        } = this.state
        const RofilterArray = userList && userList.map((user) => {
            return {
                text: user.name,
                value: user._id
            }
        });

        const columns = [
            {
                title: 'S.No',
                dataIndex: 'sno',
                width: 50,
                key: 'sno',
                fixed: 'left',
                render: (value, item, index) => (currentPage - 1) * 10 + index + 1
            },
            {
                title: 'Id',
                dataIndex: 'serialNumber',
                width: 50,
                fixed: 'left',
                key: 'serialNumber',
                searchTextName: 'serialNumber'

            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                fixed: 'left',
                filters: userType && userType.map(x => ({value: x, text: x})),
                render: (val) => {
                    return <Tag color={val === 'Inward' ? 'blue' : 'red'}>{val}</Tag>
                }

            },
            {
                title: 'Products',
                dataIndex: 'products',
                key: 'products',
                width: 200,
                render: (val, record, index) => {
                    return <React.Fragment>
                        <div>
                            <ul style={{paddingLeft: '0px', listStyle: 'none'}}>
                                {val && val.length && val.map((item, key) => {

                                    return <li key={key}>{item.productName}</li>
                                })}

                            </ul>
                            {/*{val && val.length > 1 ?*/}
                            {/*<a style={{color: 'blue'}} onClick={() => {*/}

                            {/*let {productShow} = this.state*/}
                            {/*let newLoading = _.clone(productShow)*/}
                            {/*newLoading[index] = !productShow[index]*/}
                            {/*this.setState({productShow: newLoading})*/}


                            {/*}}>*/}
                            {/*{productShow[index] ? <p>View Less - </p> : <p>View More + </p>}*/}
                            {/*</a> : null}*/}

                        </div>
                    </React.Fragment>

                }

            },
            {
                title: 'Purpose',
                dataIndex: 'purpose',
                key: 'purpose',
                filters: purpose && purpose.map(x => ({value: x, text: x})),

            },
            {
                title: 'Challan Number',
                dataIndex: 'challanNumber',
                key: 'challanNumber',
                searchTextName: 'challanNumber',
            },
            {
                title: 'Invoice Number',
                dataIndex: 'invoiceNumber',
                key: 'invoiceNumber',
                searchTextName: 'invoiceNumber',
            },
            {
                title: 'Order Authority',
                dataIndex: 'orderAuthority',
                key: 'orderAuthority',
                filters: RofilterArray,
                render: (val, record) => {
                    return (<div>
                        <div>{record.orderAuthority ? record.orderAuthority.name : ''}</div>
                        {/*<div>{record.orderAuthority ? record.orderAuthority.email : ''}</div>*/}
                        {/*<div>{record.orderAuthority ? record.orderAuthority.mobile : ''}</div>*/}
                    </div>)
                }
            },


            {
                title: 'Consignee Name',
                dataIndex: 'consignee',
                width: 150,
                key: 'consignee',
                searchTextName: 'consignee'
            },
            {
                title: 'Packing Done By',
                dataIndex: 'packingDoneBy',
                key: 'packingDoneBy',
                searchTextName: 'packingDoneBy'

            },

            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                searchDateName: 'date',
                render: (text, record) => {
                    return (
                        <Tag>
                            {text ? moment(text).format('lll') : ''}
                        </Tag>
                    )
                },
                sorter: true
            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                searchDateName: 'createdAt',
                render: (text, record) => {
                    return (
                        <Tag>
                            {text ? moment(text).format('lll') : ''}
                        </Tag>
                    )
                },
                sorter: true
            },
            {
                key: 'actions',
                title: 'Actions',
                fixed: 'right',
                width: 100,
                render: (val, record, key) => {
                    return (<React.Fragment>
                        <Tooltip title="Edit Dispatch">
                            <Button
                                shape="circle"
                                style={{marginRight: 6}}
                                size="small"
                                onClick={() => {
                                    if (record && record.type === 'Inward') {
                                        dispatch(
                                            getUrlPushWrapper('dispatch.editInwardForm', {
                                                id: record._id
                                            })
                                        )
                                    } else {
                                        dispatch(
                                            getUrlPushWrapper('dispatch.editOutwardForm', {
                                                id: record._id
                                            })
                                        )
                                    }
                                }}
                                icon="edit"
                            />
                        </Tooltip>
                        <Tooltip title="Delete">
                            <Popconfirm
                                title="Are you sure to delete?"
                                onConfirm={() => {
                                    this.delete({_id: record._id})
                                }}
                                onCancel={() => {
                                }}
                                okText="Yes"
                                cancelText="No">
                                <Button
                                    style={{marginRight: '6px'}}

                                    shape="circle"
                                    size="small"
                                    icon="delete"
                                />
                            </Popconfirm>

                        </Tooltip>
                        <Tooltip title="View Products">
                            <Button
                                style={{marginRight: '6px', marginTop: '6px'}}
                                size="small"
                                shape="circle"
                                onClick={() => {
                                    this.setState({
                                        singleRecord: record
                                    }, () => {
                                        this.showDrawer()

                                    })

                                }}
                                icon="eye"
                            />
                        </Tooltip>
                        {record && record.type === 'Outward' && <Tooltip title="Download">
                            <Button
                                type="warning"
                                size="small"
                                shape="circle"
                                style={{marginTop: '6px'}}
                                loading={this.state.fileDownloadLoader[key]}
                                onClick={() => {
                                    this.downloadDispatch(record && record._id, key)
                                }}
                                icon="download"
                            />
                        </Tooltip>}
                    </React.Fragment>)
                }
            }

        ]
        return (
            <PageHeaderWrapper
                title={this.state.count ? `All Inward/Outward List: ${this.state.count}` : `All Inward/Outward List: 0`}>

                <Card bordered={true}>

                    <Row>
                        <Col span={6}>
                            <h4 style={{display: 'inline-block', marginRight: '10px'}}>Search Product</h4>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                style={{
                                    width: 225

                                }}
                                placeholder="Search Product"
                                onChange={productName => {
                                    this.setState({productName: productName}, () => {
                                        this.table.current.reload()
                                    })
                                }}
                            >
                                {productsData &&
                                productsData.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val}>
                                            {val}
                                        </Option>
                                    )
                                })}
                            </Select>


                        </Col>
                        <Col span={6}>
                            <h4 style={{display: 'inline-block', marginRight: '10px'}}>Search Product Id</h4>
                            <Select
                                showSearch
                                allowClear
                                optionFilterProp="children"
                                style={{
                                    width: 225

                                }}
                                placeholder="Search Product"
                                onChange={selectedProductId => {
                                    this.setState({selectedProductId: selectedProductId}, () => {
                                        this.table.current.reload()
                                    })
                                }}
                            >
                                {productIds &&
                                productIds.map((val, idn) => {
                                    return (
                                        <Option key={idn} value={val}>
                                            {val}
                                        </Option>
                                    )
                                })}
                            </Select>


                        </Col>

                        <Col span={12} style={{textAlign: 'right'}}>
                            <Button
                                style={{marginBottom: 10, marginLeft: 10}}
                                type="default"
                                onClick={() => {
                                    this.exportData()
                                }}
                                loading={this.state.startLoading}>
                                Export Excel
                            </Button>
                        </Col>

                    </Row>

                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               extraProps={{scroll: {x: 1600}}}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>
                <Drawer
                    title=""
                    placement="right"
                    closable={true}
                    onClose={() => {
                        this.setState({
                            visible: false,
                        })
                    }}
                    visible={this.state.visible}
                    width={600}>
                    <Card className={styles.drawerCard}>

                        <table style={{width: '100%'}} className={styles.ProcessTable}>
                            {singleRecord && singleRecord.type === 'Inward' ? <thead>
                            <tr>
                                <th>Product Name</th>
                                <th>Product Ids</th>
                                <th>Quantity Recieved</th>
                                <th>Quantity Accepted</th>
                                <th>Quantity Rejected</th>
                            </tr>
                            </thead> : <thead>
                            <tr>
                                <th>Product Name</th>
                                <th>Quantity</th>
                                <th>Product Ids</th>
                            </tr>
                            </thead>}

                            <tbody> {_.map(singleRecord && singleRecord.products, (item) => {
                                if (singleRecord && singleRecord.type === 'Inward') {
                                    return <tr>
                                        <td colSpan="1">{item && item.productName}</td>
                                        <td>{item && item.productIds.join(' , ')}</td>
                                        <td colSpan="1">{item && item.quantityRecieved}</td>
                                        <td colSpan="1"> {item && item.quantityAccepted}</td>
                                        <td colSpan="1">{item && item.quantityRejected}</td>

                                    </tr>
                                } else {
                                    return <tr>
                                        <td colSpan="1">{item && item.productName}</td>
                                        <td colSpan="1">{item && item.quantity}</td>
                                        <td>
                                            {item && item.productIds.join(' , ')}
                                        </td>

                                    </tr>


                                }


                            })}</tbody>


                        </table>

                    </Card>

                </Drawer>

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    loading: global.buttonLoading,

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllInwardOutwardList)
