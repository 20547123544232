import React, { Component } from 'react'
import Board from 'react-trello'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { TableComp } from 'sz-react-utils'

import moment from 'moment'
import {
  Table,
  Badge,
  Card,
  Tooltip,
  Popconfirm,
  notification,
  Modal,
  Switch,
  Avatar,
  Row,
  Col,
  Popover,
  Input,
  Button,
  Icon,
  Select,
  Drawer,
  Form,
  Spin,
  AutoComplete,
} from 'antd'
import Request, { getLeadStageColor, getLeadTypeColor } from '../../../request'
import Color from 'color'
import _ from 'lodash'
import styles from './styles.less'
import { connect } from 'react-redux'
import { getUrlPushWrapper, getUrlParams } from '../../../routes'
import UpdateLead from '../add/editLead'
import { Typography } from 'antd'
import { stateFilter } from '../../../states'

const { Paragraph } = Typography

const { Option } = Select
let lanes = [
  {
    id: 'New (Untouched)',
    title: 'New (Untouched)',
    style: { width: 300, backgroundColor: '#f4f5f7', color: '#5243aa' },
    cardStyle: { maxWidth: 290 },
    cards: [],
  },
  {
    id: 'Contacted',
    title: 'Contacted',
    style: { width: 300, backgroundColor: '#f4f5f7', color: '#2196f3' },
    cardStyle: { maxWidth: 290 },
    cards: [],
  },
]

class UnqualifiedLeads extends Component {
  constructor(props) {
    super(props)
    this.table = React.createRef()
    const { dispatch } = props
    this.state = {
      leadStatuseArr: ['Open', 'Lost', 'won', 'Closed'],
      allContacts: [],
      showBoard: false,
      showTable: true,
      leadStage: ['New (Untouched)', 'Contacted', 'Today Followup'],
      boardData: { lanes: [] },
      key: Math.random(),
      currentPage: 1,
      RoList: [],
      selectedRowKeys: [],
      selectedRO: undefined,
    }
    let searchParams = getUrlParams('countUrl', window.location.pathname)
    if (searchParams) {
      let { value, gte, lte, officerId } = searchParams
      if (value && value !== '0') {
        if (value === 'won' || value === 'Closed' || value === 'Lost') {
          this.state.status = value
        }
        if (
          value === 'Contacted'
        ) {
          this.state.stage = value
        }
      }

      if (officerId) {
        this.state.officerId = officerId
        this.state.gte = gte
        this.state.lte = lte
      }
    }
    this.searchContact = _.debounce(this.handleContactSearch, 1000, true)
  }

  apiRequest = (params) => {
    if (params.page) {
      this.state.currentPage = params.page
    }
    let { contactNameId } = this.state

    if (this.state.selectedLeadStage) {
      params.stage = this.state.selectedLeadStage
    }

    if (!params.sortOrder) {
      params.sortField = 'createdAt'
      params.sortOrder = 'desc'
    }

    return new Promise(async (resolve) => {
      if (this.state.officerId && !this.state.status && !this.state.stage) {
        params.updatedAt = {
          $gte: this.state.gte,
          $lte: this.state.lte,
        }
      }

      if (this.state.status && this.state.officerId) {
        params.status = this.state.status
        params.createdAt = {
          $gte: this.state.gte,
          $lte: this.state.lte,
        }
      }
      if (this.state.stage && this.state.officerId) {
        params.stage = this.state.stage
        params.createdAt = {
          $gte: moment().startOf('day').toDate(),
          $lte: moment().endOf('day').toDate(),
        }
      }

      params.isChild = false
      let data = await Request.getAllLeads({
        ...params,
        customQuery: {
          status: 'Unqualified',
          contact: contactNameId ? contactNameId : undefined,
          officer: this.state.officerId ? this.state.officerId : undefined,
        },
        regExFilters: ['title', 'company', 'contact'],
      })
      resolve(data)
      this.setState({ leadData: data, count: data.total })
    })
  }

  showModal = () => {
    this.setState({
      visible: true,
      btnLoader: false,
    })
  }

  handleContactSearch = async (test) => {
    // this.setState({​​​​​​​​showSpin: true}​​​​​​​​)
    this.setState({ selectedContact: test })
    if (test == '') {
      this.setState(
        {
          allContacts: this.state.allContacts,
          selectedContact: test,
          contactNameId: null,
        },
        () => this.table.current.reload()
      )

      return false
    }
    let { data, error, message } = await Request.contactSearch({
      text: test,
      type: 'list',
    })
    if (!error) {
      this.setState({ allContacts: data }, () => {
        this.setState({ showSpin: false })
      })
    }
  }

  onContactSelect = (val) => {
    let selectedContact = this.state.allContacts.find((obj) => obj._id === val)
    if (selectedContact) {
      this.setState(
        {
          contactNameId: selectedContact._id,
          selectedContact: `${selectedContact.firstName} (${selectedContact.mobile})`,
        },
        () => {
          this.table.current.reload()
        }
      )
    }
  }

  async componentDidMount() {
    this.props.dispatch({ type: 'TOGGLE_SIDEBAR_COLLAPSED' })
    let { data: allContacts } = await Request.getAllContacts({ results: 10 })
    this.setState({ allContacts, sale: 'Sales' })
    let { data: userList } = await Request.getAllROList({
      customQuery: { userType: 'RO' },
    })
    this.setState({ RoList: userList })
  }

  render() {
    const RofilterArray =
      this.state.RoList &&
      this.state.RoList.map((user) => {
        return {
          text: user.name,
          value: user._id,
        }
      })

    const { submitting, currentUser, dispatch } = this.props
    const {
      leadStatuseArr,
      allContacts,
      showTable,
      showBoard,
      leadStage,
      boardData,
      selectedRowKeys,
      currentPage,
    } = this.state

    const columns = [
      {
        title: 'S.No',
        dataIndex: 'sno',
        width: 50,
        key: 'sno',
        fixed: 'left',
        render: (value, item, index) => (currentPage - 1) * 10 + index + 1,
      },

      {
        key: 'actions',
        title: 'View',
        fixed: 'left',
        width: 50,
        render: (text, record) => {
          return (
            <React.Fragment>
              <Tooltip title="View">
                <a
                  href={`/lead/detail?id=${record && record._id}`}
                  target={'_blank'}>
                  <Button
                    style={{ marginLeft: '5px' }}
                    size="small"
                    icon={'eye'}
                    shape={'circle'}
                  />
                </a>
              </Tooltip>
            </React.Fragment>
          )
        },
      },
      {
        title: 'Id',
        dataIndex: 'id',
        key: 'id',
        fixed: 'left',
        searchTextName: 'id',
        width: 100,
      },

      {
        title: 'Lead',
        dataIndex: 'title',
        key: 'title',
        searchTextName: 'title',
        render: (text, record) => (
          <span>
            <a
              onClick={() => {
                this.setState({
                  ...this.state,
                  cardId: record._id,
                  updatelead: true,
                })
              }}>
              {record.title}
            </a>
          </span>
        ),
      },

      {
        title: 'Contact',
        dataIndex: 'contact.firstName',
        key: 'contact.firstName',
        render: (text, record) => (
          <span>
            <span>{record.contact ? record.contact.firstName : ''}</span>
            <br />
            <span>{record.contact ? record.contact.mobile : ''}</span>
          </span>
        ),
      },
      {
        title: 'Company',
        dataIndex: 'company',
        key: 'company',
        searchTextName: 'company',
      },
      {
        title: 'Relationship Officer',
        dataIndex: 'officer',
        key: 'officer',
        filters: RofilterArray,
        render: (val, record) => {
          return (
            <div>
              <div>{val && val.name}</div>
              <div>{val && val.email}</div>
              <div>{val && val.mobile}</div>
            </div>
          )
        },
      },

      {
        title: 'Stage',
        //  dataIndex: 'stage',
        key: 'stage',
        filters: _.map(this.state.leadStage, (ii) => ({ text: ii, value: ii })),
        render: (text, record) => {
          return (
            <div>
              {record && record.stage ? (
                <div
                  className={styles.stageColor}
                  style={{
                    backgroundColor: getLeadStageColor(record.stage),
                  }}>
                  {record.stage}
                </div>
              ) : (
                ''
              )}
            </div>
          )
        },
      },
      {
        title: 'Lead Type',
        dataIndex: 'leadType',
        key: 'leadType',
        filters: [
          { text: 'IndiaMART Api', value: 'IndiaMART Api' },
          { text: 'Inbound', value: 'Inbound' },
          { text: 'Aajjo', value: 'aajjo' },
          { text: 'Facebook Ads', value: 'Facebook Ads' },
        ],
        render: (val, record) => {
          return (
            <div>
              {record.leadType ? (
                <div style={getLeadTypeColor(record.leadType)}>
                  {record.leadType}
                </div>
              ) : (
                ''
              )}
            </div>
          )
        },
      },
      {
        title: 'Total Call Logs',
        dataIndex: 'callLogs',
        key: 'callLogs',
        render: (val, record) => {
          return (
            <div>
              <small>
                Outgoing:{' '}
                {val &&
                  (val.outgoingCalls || 0) + (val.outgoingRejectCalls || 0)}
              </small>
              <div>
                <small>Incoming: {(val && val.incomingCalls) || 0}</small>
              </div>
              <div>
                <small>Missed: {(val && val.missedCalls) || 0}</small>
              </div>
              <div>
                <small>Attended: {(val && val.totalAttended) || 0}</small>
              </div>
            </div>
          )
        },
        // width: 100
      },
      {
        title: 'State/Pipeline',
        dataIndex: 'state',
        key: 'state',
        filters: stateFilter,
        // width: 100
      },

      {
        title: 'Status',
        key: 'status',
        render: (text, record) => {
          return (
            <div
              style={{
                backgroundColor: '#F3B431',
                color: 'white',
                textAlign: 'center',
                padding: '2px 2px',
                borderRadius: '2px',
                minWidth: '30px',
              }}>
              {record && record.status}
            </div>
          )
        },
      },

      {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
        searchDateName: 'createdAt',
        sorter: true,
        render: (val, record) => {
          return (
            <div>
              <div>{moment(record.createdAt).format('lll')}</div>
            </div>
          )
        },
        // width:100
      },
      {
        title: 'Lead Received Date',
        dataIndex: 'leadReceivedDate',
        key: 'leadReceivedDate',
        searchDateName: 'leadReceivedDate',
        sorter: true,
        fixed: 'right',
        render: (val, record) => {
          return (
            <div>
              <div>
                {' '}
                {record && record.leadReceivedDate
                  ? moment(record.leadReceivedDate).format('lll')
                  : ''}{' '}
              </div>
            </div>
          )
        },
        width: 150,
      },
    ]

    return (
      <PageHeaderWrapper
        title={
          this.state.count
            ? `All Unqualified Leads : ${this.state.count}`
            : `All Unqualified Leads: 0`
        }>
        <div style={{ margin: '-24px', padding: '0px' }}>
          <Card>
            <Row>
              <Col span={5}>
                <Select
                  showSearch
                  allowClear
                  optionFilterProp="children"
                  style={{ width: 200 }}
                  value={this.state.selectedLeadStage}
                  onChange={(id) => {
                    if (id == undefined) {
                      this.setState({ selectedLeadStage: '' }, () => {
                        this.getTodos()
                        this.table.current
                          ? this.table.current.reload()
                          : console.log('')
                      })
                    } else {
                      this.setState({ selectedLeadStage: id }, () => {
                        this.getTodos([id])
                        this.table.current
                          ? this.table.current.reload()
                          : console.log('')
                      })
                    }
                  }}>
                  {leadStage &&
                    leadStage.map((val, idn) => {
                      return (
                        <Option key={idn} value={val}>
                          {val}
                        </Option>
                      )
                    })}
                </Select>
              </Col>

              <Col span={5}>
                <AutoComplete
                  value={this.state.selectedContact}
                  notFoundContent={this.state.showSpin && <Spin />}
                  autoComplete={'none'}
                  onSelect={this.onContactSelect}
                  onSearch={this.searchContact}
                  onChange={(val) => {
                    this.setState({ selectedContact: val })
                  }}
                  placeholder={'Search Contact'}
                  style={{ width: 300 }}>
                  {allContacts &&
                    allContacts.map((val, item) => (
                      <Option value={val._id}>
                        <>
                          <div>
                            <b>{`${val.firstName} (${val.mobile})`}</b>
                          </div>
                        </>
                      </Option>
                    ))}
                </AutoComplete>
              </Col>
            </Row>
          </Card>

          <Card className={styles.outerLeadCard}>
            {showTable && (
              <>
                <div style={{ backgroundColor: 'white' }}>
                  <TableComp
                    ref={this.table}
                    columns={columns}
                    apiRequest={this.apiRequest}
                    extraProps={{
                      scroll: { x: 1950 },
                      //                                           rowSelection: {...rowSelection}
                    }}
                    //scroll={{x: 1500, y: 300}}
                    id="leadList"
                    pagination={{
                      ...this.state.pagination,
                      defaultPageSize: 10,
                      pageSizeOptions: ['10', '25', '50', '100'],
                      showSizeChanger: true,
                      ...this.props.pagination,
                    }}
                  />
                </div>
              </>
            )}
          </Card>
        </div>
        <Drawer
          title=""
          placement="right"
          closable={true}
          onClose={async () => {
            this.setState({
              ...this.state,
              updatelead: false,
              cardId: '',
            })
          }}
          visible={this.state.updatelead}
          width={800}>
          {/* cardId={cardId} */}
          <UpdateLead
            cardId={this.state.cardId}
            allContacts={allContacts}
            leadOwner={currentUser}
            key={this.state.drawerKey ? this.state.drawerKey : Date.now()}
            handleUpdateLead={() => {
              this.setState({ updatelead: false, cardId: '' })
              this.getTodos()
            }}
          />
        </Drawer>
      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  categories: global.categories,
  currentUser: global.currentUser,
  search: router.location.search,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(UnqualifiedLeads)
