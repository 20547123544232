import React, { Component } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import { FormUtils as GetAllFormFields, TableComp } from 'sz-react-utils'
import moment from 'moment'
import {
  Modal,
  Card,
  Button, Tooltip, Input,
  Tag, Form, Select, notification, Drawer, Upload, Icon, Timeline, List, Avatar

} from 'antd'
import _ from 'lodash'
import Request, { getComplaintColor, complaintStatus, warrantyStatusList, API_URL } from '../../../request'
import { connect } from 'react-redux'
import ViewComments from './viewComments'
import ViewImage from './viewImages'
import AddCommentOrStatus from '../add/addCommentOrStatus'
import AddImage from '../add/addImage'
import OtpModal from './otpModal'



class AllTransferToASC extends Component {

  constructor(props) {
    super(props)
    this.table = React.createRef()
    this.state = {
      visible: false,
      key: Date.now(),
      isCommentModal: false,
      commentData: [],
      statusLogsData: [],
      setFiles: [],
      imageArray: [],
      compId:0
    }

  }


  apiRequest = (params) => {

    return new Promise(async (resolve) => {


      let user = { ...JSON.parse(localStorage.getItem('user')) }
      params.complaintRaisedById = user._id

      if (!params.sortField) {
        params.sortField = 'isReopened'
        params.sortOrder = 'descend'
      }
      params.status = 'Transfer To ASC'

      let data = await Request.getAllComplaints({
        ...params,
        regExFilters: ['productName', 'complaintRaisedByName', 'leadName', 'complaintAssigneeName', 'complaintDetails']
      })
      this.setState({ count: data.total })
      resolve(data)
    })


  }

  async componentDidMount() {
  }

  onCancel = () => {
    this.setState({
      modalVisible: false,
      imageModalVisible: false,
      closeModalVisible: false
    })
  }

  render() {
    const {} = this.props
    const { mobile,photoKey, isCommentDrawer, imageArray, viewImageVisible, imageModalVisible, modalVisible, isCommentModal, drawerVisible, commentData, statusLogsData, complaintId,closeModalVisible } = this.state
    let user = { ...JSON.parse(localStorage.getItem('user')) }

    const columns = [
      {
        title: 'Complaint ID',
        key: 'complaintId',
        width: 140,
        fixed: 'left',
        searchTextName: 'complaintId',      
        render: (text,record)=>{
          return <div>{record.complaintId}</div>
        }
      },
      {
        title: 'Lead Name',
        key: 'leadName',
        width: 200,
        fixed: 'left',
        searchTextName: 'lead',
        render: (val, record) => {
          return <div>{record && record.leadId && record.leadId.title}

          </div>
        }
      },
      {
        title: 'Contact Details',
        key: 'contactDetails',
        searchTextName: 'contactDetails',
        render: (text, record) => <span><span>{record.contactName || ''}</span><br />
          <span>{record.mobile || ''}</span><br />
          <span>{record.email || ''}</span><br />
          <span>{record.state || ''}</span><br />

        </span>,
        width: 160
      },
      {
        title: 'Product Name',
        key: 'productName',
        width: 150,
        dataIndex: 'productName',
        searchTextName: 'productName'
      },
      {
        title: 'Type',
        key: 'type',
        width: 150,
        dataIndex: 'type',
        filters: [
          { text: 'Complaint', value: 'Complaint' },
          { text: 'Installation', value: 'Installation' }
        ],
        render: (val) => {
          return <Tag>{val}</Tag>
        }
      },

      {
        title: 'Technician Name',
        key: 'complaintAssigneeName',
        width: 150,
        dataIndex: 'complaintAssigneeName',
        searchTextName: 'complaintAssigneeName'
      },
      {
        title: 'Complaint Details',
        key: 'complaintDetails',
        width: 150,
        dataIndex: 'complaintDetails',
        searchTextName: 'complaintDetails'
      },
      {
        title: 'Status',
        key: 'status',
        width: 150,
        dataIndex: 'status',
        render: (val,record) => {
          if (record.isReopened) {
            return <Tag color={'#B20000'} >{val}</Tag>
            
         }
         else{
          return <Tag color={getComplaintColor(val)}>{val}</Tag>
         }
        }

      },
      {
        title: 'Warranty',
        key: 'warranty',
        width: 150,
        dataIndex: 'warranty',
        filters: _.map(warrantyStatusList, (x) => {
          return { text: x, value: x }
        }),
        render: (val) => {
          return <Tag>{val}</Tag>
        }

      },


      {
        title: 'Latest Comment',
        dataIndex: 'comments',
        key: 'comments',
        width: 150,
        render: (val, record) => {
          if (val && val.length) {
            let latestComment = val[0]
            return <div>
              <div>{latestComment.message || ''}</div>
              <small>{latestComment && latestComment.time ? moment(latestComment.time).format('lll') : ''}</small>
            </div>
          } else {
            return
          }

        }
      },

      {
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        width: 150,
        searchDateName: 'date',
        sorter: true,
        render: (item) => {
          return moment(item).format('lll')
        }
      },
      {
        key: 'actions',
        title: 'Actions',
        fixed: 'right',
        width: 100,
        render: (text, record) => {
          return <React.Fragment>
            <Tooltip title="View Photos">
              <Button
                shape="circle"
                style={{ marginRight: 6, marginTop: 6 }}
                size="small"
                onClick={() => {
                  this.setState({ viewImageVisible: true, imageArray: record && record.imageArray })

                }}
                icon="idcard"
              />
            </Tooltip>

            <Tooltip title="View Comments">
              <Button
                shape="circle"
                style={{ marginRight: 6, marginTop: 6 }}
                size="small"
                onClick={() => {
                  this.setState({ drawerVisible: true, commentData: record && record.comments, isCommentDrawer: true })

                }}
                icon="history"
              />
            </Tooltip>
            <Tooltip title="Status Logs">
              <Button
                shape="circle"
                style={{ marginRight: 6, marginTop: 6 }}
                size="small"
                onClick={() => {
                  this.setState({
                    isCommentDrawer: false,
                    drawerVisible: true,
                    statusLogsData: record && record.statusLogs
                  })

                }}
                icon="profile"
              />
            </Tooltip>

            <Tooltip title="Add Comment">
                <Button
                  shape="circle"
                  style={{ marginRight: 6 }}
                  size="small"
                  onClick={() => {
                    this.setState({ complaintId: record._id }, () => {
                      this.setState({
                        modalVisible: true,
                        isCommentModal: true,
                        photoKey: Date.now(),
                      })
                    })
                  }}
                  icon="plus"
                />
              </Tooltip>



              <Tooltip title="Close Complaint">
                <Button
                  shape="circle"
                  style={{ marginRight: 6 }}
                  size="small"
                  onClick={() => {
                    this.setState(
                      { complaintId: record._id, mobile: record.mobile },
                      () => {
                        this.setState({
                          closeModalVisible: true,
                          photoKey: Date.now(),
                          compId:record.complaintId,
                        })
                      }
                    )
                  }}
                  icon="close"
                />
              </Tooltip>

            
          </React.Fragment>

        }
      }

    ]

    return (
      <PageHeaderWrapper
        title={`All Transfer To ASC: ${this.state.count || 0}`}>

        <Card bordered={true}>
          <TableComp ref={this.table}
                     columns={columns}
                     apiRequest={this.apiRequest}
                     extraProps={{ scroll: { x: 1000 } }}
                     pagination={{
                       ...this.state.pagination,
                       defaultPageSize: 10,
                       pageSizeOptions: ['10', '25', '50', '100'],
                       showSizeChanger: true,
                       ...this.props.pagination
                     }} />


          <Modal
            title={isCommentModal ? 'Add Comment' : 'Change Status'}
            visible={modalVisible}
            onCancel={this.onCancel}
            footer={false}
            key={photoKey}>
            <div>
              <AddCommentOrStatus
                onCancel={this.onCancel}
                isCommentModal={isCommentModal}
                complaintId={complaintId}
                handleComment={async () => {
                  this.setState(
                    {
                      modalVisible: false,
                    },
                    () => {
                      this.table.current.reload()
                    }
                  )
                }}
              />
            </div>
          </Modal>



          <Modal
            title={`Close Complaint      (Complaint Id:${this.state.compId})`}
            visible={closeModalVisible}
            onCancel={this.onCancel}
            footer={false}
            key={photoKey}>
            <div>
            {/* <div style={{ color: 'black' }}>{`Complaint Id : ${this.state.compId}`}</div> */}
              <OtpModal
                onCancel={this.onCancel}
                mobile={mobile}
                complaintId={complaintId}
                handleOtp={async () => {
                  this.setState(
                    {
                      closeModalVisible: false,
                    },
                    () => {
                      this.table.current.reload()
                    }
                  )
                }}
              />
            </div>
          </Modal>


          <Drawer
            title={isCommentDrawer ? 'Comments' : 'Status Logs'}
            placement="right"
            closable={true}
            onClose={() => {
              this.setState({
                drawerVisible: false,
                commentData: [],
                statusLogsData: []
              })
            }}
            visible={drawerVisible}
            width={800}>
            <Card>

              {isCommentDrawer ? <ViewComments commentData={commentData} isCommentDrawer={isCommentDrawer} /> :
                <ViewComments statusLogsData={statusLogsData} isCommentDrawer={isCommentDrawer} />}

            </Card>

          </Drawer>

          <Drawer
            title={'File Logs'}
            placement="right"
            closable={true}
            onClose={() => {
              this.setState({
                viewImageVisible: false,
                imageArray: []
              })
            }}
            visible={viewImageVisible}
            width={800}>
            <ViewImage imageArray={imageArray} />

          </Drawer>

        </Card>


      </PageHeaderWrapper>)

  }
}


const mapStateToProps = ({ global }) => ({
  categories: global.categories,
  currentUser: global.currentUser
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllTransferToASC)
