import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp, FormUtils as GetAllFormFields} from 'sz-react-utils'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Switch,
    Input, Button, Icon,
    Form,
    Drawer, Row, Col, Tag
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import {connect} from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import {getUrlPushWrapper} from '../../../routes'
import {hideLoader, showLoader} from '../../../modules/actions'


@Form.create()
class UserLoginHistory extends Component {

    apiRequest = (params) => {
        if (params.page) {
            this.state.currentPage = params.page
        }
        if (!params.sortOrder) {
            params.sortField = 'createdAt';
            params.sortOrder = 'desc';
        }

        return new Promise(async (resolve) => {
            let data = await Request.getAllLoginHistory({
                ...params, regExFilters: ['mobile', 'name', 'email']
            })
            resolve(data)
        })
    }

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            currentPage: 1,
            visible: false,
            userType: ['RO', 'Dispatch', 'Lead Executive', 'Caller', 'Manager'],
            type: ['Login', 'Logout']
        }

    }

    componentDidMount() {
    }

    render() {
        const {dispatch} = this.props

        let {changePasswordRecord, userType, currentPage, type} = this.state
        const columns = [
            {
                title: 'S.No',
                dataIndex: 'sno',
                width: 50,
                key: 'sno',
                fixed: 'left',
                render: (value, item, index) => (currentPage - 1) * 10 + index + 1
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                searchTextName: 'name'
            },

            {
                title: 'Mobile',
                dataIndex: 'mobile',
                key: 'mobile',
                searchTextName: 'mobile'
            },
            {
                title: 'Email',
                dataIndex: 'email',
                key: 'email',
                searchTextName: 'email'
            },
            {
                title: 'User Type',
                dataIndex: 'userType',
                key: 'userType',
                filters: userType && userType.map(x => ({value: x, text: x})),

            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                filters: type && type.map(x => ({value: x, text: x})),

            },
            {
                title: 'Login At',
                key: 'loginTime',
                dataIndex: 'loginTime',
                render: (text, record) => {
                    return (
                        <Tag>
                            {text ? moment(text).format('lll') : ''}
                        </Tag>
                    )
                },
                sorter: true
            },
            {
                title: 'Logout At',
                key: 'logoutTime',
                dataIndex: 'logoutTime',
                render: (text, record) => {
                    return (
                        <Tag>
                            {text ? moment(text).format('lll') : ''}
                        </Tag>
                    )
                },
                sorter: true
            },

        ]
        return (
            <PageHeaderWrapper
                title={'Login History'}>

                <Card bordered={true}>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>


            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    loading: global.buttonLoading,

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UserLoginHistory)
