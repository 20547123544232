import React, {PureComponent} from 'react'
import {
    Form,
    Input, Button,
    Card, Row, Col, Icon, Drawer
} from 'antd'
import _ from 'lodash'
import moment from 'moment'
import {FormUtils as GetAllFormFields} from 'sz-react-utils'
import {API_URL} from '../../../request'

import 'react-quill/dist/quill.snow.css'
import {notification} from 'antd/lib/index'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import {connect} from 'react-redux'
import {createMatchSelector} from 'connected-react-router'
// import WrappedAddContact from './addContact'
import AddContact from '../../contacts/add/index'

const {TextArea} = Input

@Form.create()
class LeadAdd extends PureComponent {

    handleSubmit = e => {
        const {dispatch, form, handleAddLead} = this.props
        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)

                dispatch(showLoader())

                let x = await Request.addLead(values)

                dispatch(hideLoader())

                if (!x.error) {
                    this.setState({stateList: []})
                    notification.success({
                        message: x.message

                    })
                    if (handleAddLead) {
                        handleAddLead()
                    }
                    form.resetFields()

                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }


    constructor(props) {
        super(props);

        this.state = {
            // source: ['Ads', 'Referrals', 'Website', 'Word of mouth'],
            source: ['Purchased', 'Call', 'Direct'],
            stage: ['New (Untouched)', 'Contacted'],
            priority: ['High', 'Low', 'Medium'],
            status: ['Open', 'Lost', 'won'],
            visible: false,
            stateList: [],
            stateDisabled: true,

        }
    }

    async componentDidMount() {
        const {leadOwner} = this.props;
        let {data: countryList} = await Request.loadCountry()

        this.setState({countryList: countryList})
        this.setState({ownerData: [leadOwner], allContacts: this.props.allContacts});
        setTimeout(() => {
            this.props.form.setFieldsValue({
                status: 'Open',
                priority: 'Low',
                stage: 'New (Untouched)',
                //currency: 'INR',
                closeDate: moment().endOf('month')
            })
        }, 500);

    }

    render() {
        const {submitting, allContacts} = this.props;
        const showContactDrawer = () => {
            this.setState({...this.state, visible: true});
        }
        const onClose = () => {
            this.setState({...this.state, visible: false});

        }

        const {
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        const {stage, source, priority, status, ownerData, stateList, countryList} = this.state

        const leftInputTypes = {
            fields: [
                {
                    label: 'Contact',
                    key: 'contact',
                    // required: true,
                    showSearch: true,
                    keyAccessor: x => x._id,
                    valueAccessor: x => (x.firstName && x.mobile) ? `${x.firstName} (${x.mobile})` : '',
                    type: 'select',
                    options: this.state.allContacts || [],
                    // optionFilterProp: "children",
                    onChange: v => {
                        this.props.form.setFieldsValue({contact: v})
                    },
                    rightComp: <Button style={{marginLeft: '3px', marginBottom: "13px"}} type="primary"
                                       onClick={() => {
                                           showContactDrawer()
                                       }}>{<Icon type="plus"/>}</Button>
                }, /*  {
            label: 'Win Probability',
            key: 'winProbability',
            disabled: true
          },*/
                {
                    label: 'Estimated Close Date',
                    key: 'closeDate',
                    type: 'date',

                },
                {
                    label: 'Lead Type',
                    key: 'leadType',
                    
                    required: true,
                    type: 'select',
                    options: ['Inbound', 'Facebook Ads','IndiaMART Api','aajjo'],
                    onChange: (v) => {
                      
                      this.props.form.setFieldsValue({ leadType: v })
                    },
                    
                    
                  },
                {
                    label: 'Stage',
                    key: 'stage',
                    defaultValue: 'Open',
                    type: 'select', options: stage || [],
                    // required: true,
                    onChange: v => {
                        this.props.form.setFieldsValue({stage: v})
                    }
                },
                // {
                //     label: 'State',
                //     key: 'state',
                //     type: 'select',
                //     required: true,
                //     showSearch: true,
                //     options: states,
                //     onChange: v => {
                //         this.props.form.setFieldsValue({state: v})
                //     },
                // },

                {
                    label: 'Country',
                    key: 'country',
                    type: 'select',
                    required: true,
                    showSearch: true,
                    keyAccessor: x => x._id,
                    valueAccessor: x => x.countryName ? _.startCase(x.countryName) : '',
                    options: countryList || [],
                    onChange: async (country) => {
                        let {data: stateList} = await Request.loadState({countryId: country})

                        this.setState({
                            stateList: stateList,
                            stateDisabled: false,
                        })
                        this.props.form.setFieldsValue({
                            country: country,
                            state: ''
                        })

                    }


                },

                {
                    label: 'State',
                    type: 'select',
                    key: 'state',
                    required: true,
                    showSearch: true,
                    disabled: this.state.stateDisabled,
                    keyAccessor: x => x.stateName,
                    valueAccessor: x => x.stateName ? _.startCase(x.stateName) : '',
                    options: stateList || [],
                    onChange: async (state) => {

                        this.props.form.setFieldsValue({
                            state: _.startCase(state)
                        })
                    }


                },

                {
                    label: 'Priority',
                    key: 'priority',
                    type: 'select', options: priority || [],
                    onChange: v => {
                        this.props.form.setFieldsValue({priority: v})
                    }
                }

            ]
        }

        const rightInputTypes = {
            fields: [
                {
                    label: 'Company',
                    key: 'company',

                },
                {
                    label: 'Currency',
                    key: 'currency',
                    type: 'select',
                    options: ['DOLLAR-$', 'INR-₹'],
                    onChange: v => {
                        this.props.form.setFieldsValue({currency: v})
                    }
                },
                /*  {
                    label: 'Lead Owner',
                    key: 'leadOwner',
                    required: true,
                    keyAccessor: x => x._id,
                    valueAccessor: x => x.name,
                    type: 'select',
                    options: ownerData || [],
                    onChange: v => {
                      this.props.form.setFieldsValue({ leadOwner: v })
                    }
                  },
                  {
                    label: 'Value',
                    key: 'value',
                    required: true
                  },
                  {
                    label: 'Pipelines',
                    key: 'pipelines',
                    type: 'select',
                    options: ['Sales']
                  },*/
                {
                    label: 'Value',
                    key: 'value',
                },
                {
                    label: 'Source', key: 'source',
                    type: 'select',
                    options: source || [],
                    onChange: v => {
                        this.props.form.setFieldsValue({source: v})
                    }

                },
                {
                    label: 'Status', key: 'status',
                    type: 'select',
                    options: status || [],
                    onChange: v => {
                        this.props.form.setFieldsValue({status: v})
                    }

                }
            ]
        }

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 12}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 24}
            }
        }
        const formItemLayout1 = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 4}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 24}
            }
        }
        const submitFormLayout = {
            wrapperCol: {
                xs: {span: 24, offset: 0},
                sm: {span: 10, offset: 7},
                md: {span: 12, offset: 8}
            }
        }


        return (
            <>
                <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{marginTop: 8}} layout={'vertical'}>
                    <Card bordered={true}>
                        <div>
                            <Row>
                                <Col span={24}>
                                    <Form.Item {...formItemLayout1} label={'Title'}>
                                        {getFieldDecorator('title', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `Please input title!`
                                                }
                                            ]
                                        })(<Input/>)}
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={11}>
                                    <GetAllFormFields apiurl={API_URL}
                                                      inputSchema={leftInputTypes} formItemLayout={formItemLayout}
                                                      getFieldDecorator={getFieldDecorator}/>
                                </Col>
                                <Col span={2}></Col>
                                <Col span={11}>
                                    <GetAllFormFields apiurl={API_URL}
                                                      inputSchema={rightInputTypes} formItemLayout={formItemLayout}
                                                      getFieldDecorator={getFieldDecorator}/>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <Form.Item {...formItemLayout1} label={'Description'}>
                                        {getFieldDecorator('description', {
                                            rules: [
                                                {
                                                    required: true,
                                                    message: `Please input description!`
                                                }
                                            ]
                                        })(<TextArea rows={5}/>)}
                                    </Form.Item>
                                </Col>
                            </Row>
                        </div>
                        <Form.Item  {...submitFormLayout} style={{marginTop: 20}}>
                            <Button type="primary" htmlType="submit" loading={this.props.loading}>
                                SAVE
                            </Button>
                        </Form.Item>
                    </Card>
                    <Drawer
                        width={780}
                        onCancel={() => this.setState({visible: false})}
                        onClose={() => this.setState({visible: false})}
                        visible={this.state.visible}
                        bodyStyle={{paddingBottom: 80}}>
                        <AddContact handleAfterAdd={async () => {
                            this.setState({visible: false});
                            let {data: allContacts} = await Request.loadContacts();
                            this.setState({allContacts: allContacts});
                            let len = allContacts.length;
                            this.props.form.setFieldsValue({
                                status: 'Lost',
                                priority: 'Low',
                                contact: this.state.allContacts[len - 1]._id,
                                stage: 'New (Untouched)',
                                closeDate: moment().endOf('month')
                            })

                        }}/>
                    </Drawer>
                </Form>
            </>

        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search
})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LeadAdd)
