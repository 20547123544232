import React, { Component } from 'react'
import {
  Card,
  Col,
  Row,
  Icon,
  Table,
  List,
  Radio,
  Descriptions,
  Tooltip,
  Select,
  Spin,
  DatePicker,
  Button,
} from 'antd'
import styles from './style.less'
import moment from 'moment'
import _ from 'lodash'
import {
  RadialBarChart,
  RadialBar,
  Legend,
  Tooltip as BarTooltip,
  CartesianGrid,
  XAxis,
  YAxis,
  ComposedChart,
  Line,
  Area,
  BarChart,
  Bar,
  LabelList,
} from 'recharts'

import Request from '../../request'
import { getUrlPushWrapper } from '../../routes'
import { connect } from 'react-redux'

const { Option } = Select

class Graph extends Component {
  loadApisRequest = () => {
    let user = { ...JSON.parse(localStorage.getItem('user')) }

    if (user && user.userType == 'Manager' && !this.state.ownerIds.length) {
      this.state.ownerIds = user.roList
    }

    this.setState({ startLoading: true })
    Request.activityCountDataByRO({
      data: this.state.ownerIds,
      leadDate: { $gte: this.state.startRange, $lte: this.state.lastRange },
    }).then(({ data }) => {
      _.each(data, (item, key) => {
        let sum = 0

        _.each(Object.keys(item), (ii) => {
          if (ii !== 'open') {
            if (typeof item[ii] == 'number') {
              sum = sum + item[ii]
            }
          }
        })

        item.sum = sum
      })

      data = _.reject(data, (ii) => {
        return (ii && ii._id && ii._id.officer === null) || ii.sum === 0
      })

      this.setState({
        activitiesCountDataByRO: data,
        lastCallDetails: data && data.lastCallLogDetails,
        startLoading: false,
      })
    })
  }

  constructor(props) {
    super(props)
    this.state = {
      ownerIds: [],
      startRange: moment().startOf('day').toDate(),
      lastRange: moment().endOf('day').toDate(),
    }
  }

  async componentDidMount() {
    this.loadApisRequest()
    let user = { ...JSON.parse(localStorage.getItem('user')) }

    if (user && user.userType == 'Manager') {
      Request.loadUser(
        { _id: { $in: user.roList } },
        { results: 1000000000000 }
      ).then(({ data: userList }) => {
        this.setState({ userList: userList })
      })
    } else {
      let { data: userList } = await Request.getAllROList({
        customQuery: { userType: { $eq: 'RO' } },
      })
      this.setState({ userList: userList })
    }
  }

  render() {
    let { userList, startLoading } = this.state
    const { dispatch } = this.props
    const handleChangeDebut = (range) => {
      if (!range.length) {
        this.setState({
          startRange: moment().startOf('month').toDate(),
          lastRange: moment().endOf('month').toDate(),
        })
      } else {
        const valueOfInput1 = moment(range[0]).startOf('day').format()
        const valueOfInput2 = moment(range[1]).endOf('day').format()
        this.setState({
          ...this.state,
          startRange: valueOfInput1,
          lastRange: valueOfInput2,
        })
      }
      setTimeout(() => {
        this.loadApisRequest()
      }, 500)
    }

    // const getIntroOfPage = (label) => {
    //     console.log(label, "label--------------------------")
    //     if (label === 'Page A') {
    //         return "Page A is about men's clothing";
    //     }
    // };
    //
    // const CustomTooltip = ({active, payload, label}) => {
    //     console.log(active, payload, label,"----")
    //     if (active) {
    //         return (
    //             <div className="custom-tooltip">
    //                 <p className="label">{`${label} : ${payload[0].value}`}</p>
    //                 <p className="intro">{getIntroOfPage(label)}</p>
    //                 <p className="desc">Anything you want can be displayed here.</p>
    //             </div>
    //         );
    //     }
    //
    //     return null;
    // };

    return (
      <div>
        <Card>
          <Row gutter={16}>
            <Col span={12}>
              <h4 style={{ marginBottom: '2px' }}>
                Select Relationship Officer
              </h4>
              <Select
                showSearch
                allowClear
                mode={'multiple'}
                optionFilterProp="children"
                style={{ width: '25vw' }}
                placeholder="Select Relationship Officer"
                onChange={(ids) => {
                  this.setState({ ownerIds: ids }, () => {
                    this.loadApisRequest()
                  })
                }}>
                {userList &&
                  userList.map((val, idn) => {
                    return (
                      <Option key={idn} value={val._id}>
                        {val.name} ({val.mobile})
                      </Option>
                    )
                  })}
              </Select>
            </Col>

            <Col span={12}>
              <div style={{ position: 'absolute', right: '10px' }}>
                <h4 style={{ marginBottom: '2px' }}>Select Date Range</h4>
                <DatePicker.RangePicker
                  style={{ width: '30vw' }}
                  format="DD-MM-YYYY"
                  value={[
                    moment(this.state.startRange),
                    moment(this.state.lastRange),
                  ]}
                  getPopupContainer={(trigger) => trigger.parentNode}
                  onChange={(value, dateString) => handleChangeDebut(value)}
                />
              </div>
            </Col>
          </Row>
        </Card>

        <Spin spinning={startLoading} style={{ marginTop: '30px' }}>
          <div className="site-card-wrapper">
            {_.map(this.state.activitiesCountDataByRO, (ii) => {
              return (
                <Row>
                  <Card
                    style={{ marginBottom: '10px' }}
                    // title={
                    //   <div>
                    //     <div>Total Leads {ii.handleLeadsCount}</div>
                    //   </div>
                    // }
                  >
                    {/* <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                      }}>
                      <div>
                        <div style={{ fontSize: '20px' }}>
                          <div>
                            <b>{ii.userName}</b>
                          </div>
                          <div>{ii.userMobile}</div>
                          <div>{ii.userEmail}</div>
                        </div>
                        <div style={{ marginTop: '15px' }}>
                          <div
                            style={{
                              fontWeight: '500',
                              fontSize: 15,
                              marginBottom: '5px',
                            }}>
                            Total Calls :{' '}
                            {ii && ii.totalCalls > 0 ? (
                              <a
                                target={'_blank'}
                                href={`/callCountUrl/${
                                  ii && ii.userMobile
                                }/${moment(this.state.lastRange).format(
                                  'YYYY-MM-DD HH:mm'
                                )}/${moment(this.state.startRange).format(
                                  'YYYY-MM-DD HH:mm'
                                )}/0`}>
                                {' '}
                                {ii.totalCalls || 0}
                              </a>
                            ) : (
                              0
                            )}
                          </div>

                          <div
                            style={{
                              fontWeight: '500',
                              fontSize: 15,
                              marginBottom: '5px',
                            }}>
                            {' '}
                            Total Recordings :{' '}
                            {ii && ii.totalRecordings > 0 ? (
                              <a
                                target={'_blank'}
                                href={`/recordingCountUrl/${
                                  ii && ii.userMobile
                                }/${moment(this.state.lastRange).format(
                                  'YYYY-MM-DD HH:mm'
                                )}/${moment(this.state.startRange).format(
                                  'YYYY-MM-DD HH:mm'
                                )}`}>
                                {' '}
                                {ii.totalRecordings || 0}
                              </a>
                            ) : (
                              0
                            )}
                          </div>

                          <div
                            style={{
                              fontWeight: '500',
                              fontSize: 15,
                            }}>
                            {' '}
                            Last Call :{' '}
                            {ii &&
                            ii.lastCallLogDetails &&
                            ii.lastCallLogDetails.callingTime
                              ? moment(
                                  ii.lastCallLogDetails.callingTime
                                ).format('lll')
                              : ''}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div style={{ fontSize: '30px', fontWeight: '500' }}>
                          <div className={styles.totalScore}>
                            {(ii.totalActivities || 0) + (ii.totalQuotes || 0)}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div>
                          <ComposedChart
                            layout="vertical"
                            width={500}
                            height={200}
                            data={[ii]}
                            margin={{
                              top: 10,
                              right: 20,
                              bottom: 20,
                              left: 20,
                            }}>
                            <CartesianGrid stroke="#f5f5f5" />
                            <XAxis type="number" />
                            <YAxis dataKey="userName" type="category" />
                            <BarTooltip />
                            <Legend
                              formatter={(value, entry, index) => {
                                return _.startCase(value)
                              }}
                            />

                            <Bar
                              dataKey="handleLeadsCount"
                              barSize={10}
                              fill="#ff7300"
                              onClick={() => {
                                window.open(
                                  `/graphCountUrl/${
                                    ii && ii._id.officer
                                  }/${moment(this.state.lastRange).format(
                                    'YYYY-MM-DD HH:mm'
                                  )}/${moment(this.state.startRange).format(
                                    'YYYY-MM-DD HH:mm'
                                  )}/0`
                                )
                                dispatch(
                                  getUrlPushWrapper('lead.adminAllLeads', {
                                    officerId: ii._id.officer,
                                    lte: moment(this.state.lastRange).format(
                                      'YYYY-MM-DD HH:mm'
                                    ),
                                    gte: moment(this.state.startRange).format(
                                      'YYYY-MM-DD HH:mm'
                                    ),
                                  })
                                )
                              }}>
                              <LabelList
                                dataKey="handleLeadsCount"
                                position="right"
                              />
                            </Bar>

                            <Bar
                              dataKey="totalActivities"
                              barSize={10}
                              fill="#FFA500">
                              <LabelList
                                dataKey="totalActivities"
                                position="right"
                              />
                            </Bar>

                            <Bar
                              dataKey="totalQuotes"
                              barSize={10}
                              fill="#0000A0">
                              <LabelList
                                dataKey="totalQuotes"
                                position="right"
                              />
                            </Bar>
                          </ComposedChart>
                        </div>
                      </div>
                    </div> */}

                    <h1
                      className={styles.headingRO}
                      style={{ marginTop: '10px' }}>
                      <img
                        src="/test/RO/performance-stats.svg"
                        alt="Icon"
                        className={styles.headingROIcon}
                        style={{ height: '18px' }}
                      />
                      Performance Stats
                    </h1>

                    <Row gutter={16}>
                      <Col span={12}>
                        <Row>
                          <Col span={13} className={styles.cardPerformance}>
                            <div
                              style={{
                                marginBottom: '15px',
                              }}>
                              <b
                                style={{
                                  fontSize: '17px',
                                }}>
                                {ii.userName}
                              </b>
                              <p>RO Officer</p>
                            </div>
                            <h4>
                              <img
                                src="/test/RO/email.svg"
                                alt="Icon"
                                className={styles.headingROIconEmail}
                              />
                              {ii.userEmail}
                            </h4>
                            <h4>
                              <img
                                src="/test/RO/phone.svg"
                                alt="Icon"
                                className={styles.headingROIcon}
                                style={{ height: '15px' }}
                              />
                              {ii.userMobile}
                            </h4>
                            <Button
                              type="primary"
                              ghost
                              style={{ marginTop: '20px' }}>
                              View Profile
                            </Button>
                          </Col>
                          <Col span={6} className={styles.cardPerformanceSub}>
                            <div
                              className={styles.cardPerformanceSubInside}
                              style={{
                                borderBottom: '0.5px solid #f8fbf4',
                              }}>
                              <p
                                style={{
                                  marginBottom: '-1px',
                                }}>
                                Total Calls
                              </p>
                              <div className={styles.cardPerformanceSubDetails}>
                                {ii && ii.totalCalls > 0 ? (
                                  <a
                                    target={'_blank'}
                                    href={`/callCountUrl/${
                                      ii && ii.userMobile
                                    }/${moment(this.state.lastRange).format(
                                      'YYYY-MM-DD HH:mm'
                                    )}/${moment(this.state.startRange).format(
                                      'YYYY-MM-DD HH:mm'
                                    )}/0`}
                                    className={
                                      styles.cardPerformanceSubDetailsHover
                                    }>
                                    {ii.totalCalls || 0}
                                  </a>
                                ) : (
                                  0
                                )}
                              </div>
                            </div>
                            <div
                              className={styles.cardPerformanceSubInside}
                              style={{
                                borderBottom: '0.5px solid #f8fbf4',
                              }}>
                              <p
                                style={{
                                  marginBottom: '-1px',
                                }}>
                                Total Recordings
                              </p>
                              <div className={styles.cardPerformanceSubDetails}>
                                {ii && ii.totalRecordings > 0 ? (
                                  <a
                                    target={'_blank'}
                                    href={`/recordingCountUrl/${
                                      ii && ii.userMobile
                                    }/${moment(this.state.lastRange).format(
                                      'YYYY-MM-DD HH:mm'
                                    )}/${moment(this.state.startRange).format(
                                      'YYYY-MM-DD HH:mm'
                                    )}`}
                                    className={
                                      styles.cardPerformanceSubDetailsHover
                                    }>
                                    {ii.totalRecordings || 0}
                                  </a>
                                ) : (
                                  0
                                )}
                              </div>
                            </div>
                            <div className={styles.cardPerformanceSubInside}>
                              <p
                                style={{
                                  marginBottom: '-2px',
                                }}>
                                Last Call
                              </p>
                              <div className={styles.cardPerformanceSubDetails}>
                                {ii &&
                                ii.lastCallLogDetails &&
                                ii.lastCallLogDetails.callingTime
                                  ? moment(
                                      ii.lastCallLogDetails.callingTime
                                    ).format('MMM DD, YYYY')
                                  : 'N/A'}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </Col>

                      {/* {(ii.totalActivities || 0) + (ii.totalQuotes || 0)} */}

                      <Col span={12}>
                        <ComposedChart
                          layout="vertical"
                          width={500}
                          height={200}
                          data={[ii]}
                          margin={{
                            top: 10,
                            right: 20,
                            bottom: 20,
                            left: 20,
                          }}>
                          <CartesianGrid stroke="#f5f5f5" />
                          <XAxis type="number" />
                          <YAxis dataKey="userName" type="category" />
                          <BarTooltip />
                          <Legend
                            formatter={(value, entry, index) => {
                              return _.startCase(value)
                            }}
                          />

                          <Bar
                            dataKey="totalActivities"
                            barSize={10}
                            fill="#FFA500">
                            <LabelList
                              dataKey="totalActivities"
                              position="right"
                            />
                          </Bar>

                          <Bar
                            dataKey="totalQuotes"
                            barSize={10}
                            fill="#0000A0">
                            <LabelList dataKey="totalQuotes" position="right" />
                          </Bar>
                        </ComposedChart>
                      </Col>
                    </Row>

                    <h1 className={styles.headingRO}>
                      <img
                        src="/test/leads-stats.svg"
                        alt="Icon"
                        className={styles.headingROIcon}
                      />
                      Leads Stats
                    </h1>

                    <Row gutter={[16, 16]}>
                      <Col span={6}>
                        <div className={styles.cardRO}>
                          <div>
                            <h6 className={styles.cardHeadingRO}>Won Leads</h6>

                            <div className={styles.cardNumberRO}>
                              {ii && ii.won > 0 ? (
                                <a
                                  target={'_blank'}
                                  href={`/graphCountUrl/${
                                    ii && ii._id.officer
                                  }/${moment(this.state.lastRange).format(
                                    'YYYY-MM-DD HH:mm'
                                  )}/${moment(this.state.startRange).format(
                                    'YYYY-MM-DD HH:mm'
                                  )}/won`}
                                  className={styles.cardNumberLinkRO}>
                                  {(ii && ii.won) || 0}
                                </a>
                              ) : (
                                0
                              )}
                            </div>
                          </div>

                          <img
                            src="/test/RO/leads/won-leads.svg"
                            alt="Icon"
                            className={styles.iconLeads}
                          />
                        </div>
                      </Col>

                      <Col span={6}>
                        <div className={styles.cardRO}>
                          <div>
                            <h6 className={styles.cardHeadingRO}>Lost Leads</h6>

                            <div className={styles.cardNumberRO}>
                              {ii && ii.lost > 0 ? (
                                <a
                                  target={'_blank'}
                                  href={`/graphCountUrl/${
                                    ii && ii._id.officer
                                  }/${moment(this.state.lastRange).format(
                                    'YYYY-MM-DD HH:mm'
                                  )}/${moment(this.state.startRange).format(
                                    'YYYY-MM-DD HH:mm'
                                  )}/Lost`}
                                  className={styles.cardNumberLinkRO}>
                                  {(ii && ii.lost) || 0}
                                </a>
                              ) : (
                                0
                              )}
                            </div>
                          </div>

                          <img
                            src="/test/RO/leads/lost-leads.svg"
                            alt="Icon"
                            className={styles.iconLeads}
                          />
                        </div>
                      </Col>

                      <Col span={6}>
                        <div className={styles.cardRO}>
                          <div>
                            <h6 className={styles.cardHeadingRO}>
                              Contacted Leads
                            </h6>

                            <div className={styles.cardNumberRO}>
                              {ii && ii.contacted > 0 ? (
                                <a
                                  target={'_blank'}
                                  href={`/graphCountUrl/${
                                    ii && ii._id.officer
                                  }/${moment(this.state.lastRange).format(
                                    'YYYY-MM-DD HH:mm'
                                  )}/${moment(this.state.startRange).format(
                                    'YYYY-MM-DD HH:mm'
                                  )}/Contacted`}
                                  className={styles.cardNumberLinkRO}>
                                  {(ii && ii.contacted) || 0}
                                </a>
                              ) : (
                                0
                              )}
                            </div>
                          </div>

                          <img
                            src="/test/RO/leads/contacted-leads.svg"
                            alt="Icon"
                            className={styles.iconLeads}
                          />
                        </div>
                      </Col>

                      {/* <Col span={6}>
                        <div className={styles.cardRO}>
                          <div>
                            <h6 className={styles.cardHeadingRO}>
                              Proposal Leads
                            </h6>

                            <div className={styles.cardNumberRO}>
                              {ii && ii.proposalPresented > 0 ? (
                                // <a
                                //   target={'_blank'}
                                //   href={`/graphCountUrl/${
                                //     ii && ii._id.officer
                                //   }/${moment(this.state.lastRange).format(
                                //     'YYYY-MM-DD HH:mm'
                                //   )}/${moment(this.state.startRange).format(
                                //     'YYYY-MM-DD HH:mm'
                                //   )}/Proposal Presented`}
                                //   className={styles.cardNumberLinkRO}>
                                  (ii && ii.proposalPresented) || 0
                                // </a>
                              ) : (
                                0
                              )}
                            </div>
                          </div>

                          <img
                            src="/test/RO/leads/proposal-leads.svg"
                            alt="Icon"
                            className={styles.iconLeads}
                          />
                        </div>
                      </Col> */}

                      {/* <Col span={6}>
                        <div className={styles.cardRO}>
                          <div>
                            <h6 className={styles.cardHeadingRO}>
                              In Negotiations
                            </h6>

                            <div className={styles.cardNumberRO}>
                              {ii && ii.inNegotiation > 0 ? (
                                <a
                                  target={'_blank'}
                                  href={`/graphCountUrl/${
                                    ii && ii._id.officer
                                  }/${moment(this.state.lastRange).format(
                                    'YYYY-MM-DD HH:mm'
                                  )}/${moment(this.state.startRange).format(
                                    'YYYY-MM-DD HH:mm'
                                  )}/In Negotiation`}
                                  className={styles.cardNumberLinkRO}>
                                  {(ii && ii.inNegotiation) || 0}
                                </a>
                              ) : (
                                0
                              )}
                            </div>
                          </div>

                          <img
                            src="/test/RO/leads/in-negotiations.svg"
                            alt="Icon"
                            className={styles.iconLeads}
                          />
                        </div>
                      </Col> */}

                      <Col span={6}>
                        <div className={styles.cardRO}>
                          <div>
                            <h6 className={styles.cardHeadingRO}>
                              Won Leads Average
                            </h6>

                            <div className={styles.cardNumberRO}>
                              {(ii && ii.wonAverageOfLeads) || 0}
                            </div>
                          </div>

                          <img
                            src="/test/RO/leads/average-won-leads.svg"
                            alt="Icon"
                            className={styles.iconLeads}
                          />
                        </div>
                      </Col>
                    </Row>

                    {/* <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}>
                      <div className={styles.performanceDiv}>
                        <div style={{ color: '#095d51' }}>Won Leads</div>
                        <div style={{ color: '#095d51' }}>
                          {ii && ii.won > 0 ? (
                            <a
                              target={'_blank'}
                              href={`/graphCountUrl/${
                                ii && ii._id.officer
                              }/${moment(this.state.lastRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/${moment(this.state.startRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/won`}
                              onClick={() => {
                                // dispatch(
                                //     getUrlPushWrapper('lead.adminAllLeads', {
                                //         officerId: ii._id.officer,
                                //         lte: moment(this.state.lastRange).format("YYYY-MM-DD HH:mm"),
                                //         gte: moment(this.state.startRange).format("YYYY-MM-DD HH:mm"),
                                //         status: 'won'
                                //     })
                                // )
                              }}>
                              {' '}
                              {(ii && ii.won) || 0}
                            </a>
                          ) : (
                            0
                          )}
                        </div>
                      </div>
                      <div className={styles.performanceDiv}>
                        <div>Lost Leads</div>
                        <div>
                          {ii && ii.lost > 0 ? (
                            <a
                              target={'_blank'}
                              href={`/graphCountUrl/${
                                ii && ii._id.officer
                              }/${moment(this.state.lastRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/${moment(this.state.startRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/Lost`}>
                              {' '}
                              {(ii && ii.lost) || 0}
                            </a>
                          ) : (
                            0
                          )}
                        </div>
                      </div>
                      <div className={styles.performanceDiv}>
                        <div>Contaced Leads</div>
                        <div>
                          {ii && ii.contacted > 0 ? (
                            <a
                              target={'_blank'}
                              href={`/graphCountUrl/${
                                ii && ii._id.officer
                              }/${moment(this.state.lastRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/${moment(this.state.startRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/Contacted`}

                              //    onClick={() => {
                              //     dispatch(
                              //         getUrlPushWrapper('lead.adminAllLeads', {
                              //             officerId: ii._id.officer,
                              //             lte: moment(this.state.lastRange).format("YYYY-MM-DD HH:mm"),
                              //             gte: moment(this.state.startRange).format("YYYY-MM-DD HH:mm"),
                              //             stage: 'Contacted'
                              //         })
                              //     )
                              // }}
                            >
                              {' '}
                              {(ii && ii.contacted) || 0}
                            </a>
                          ) : (
                            0
                          )}
                        </div>
                      </div>
                      <div className={styles.performanceDiv}>
                        <div>Proposal Leads</div>
                        <div>
                          {ii && ii.proposalPresented > 0 ? (
                            <a
                              target={'_blank'}
                              href={`/graphCountUrl/${
                                ii && ii._id.officer
                              }/${moment(this.state.lastRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/${moment(this.state.startRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/Proposal Presented`}

                              //    onClick={() => {
                              //     dispatch(
                              //         getUrlPushWrapper('lead.adminAllLeads', {
                              //             officerId: ii._id.officer,
                              //             lte: moment(this.state.lastRange).format("YYYY-MM-DD HH:mm"),
                              //             gte: moment(this.state.startRange).format("YYYY-MM-DD HH:mm"),
                              //             stage: 'Contacted'
                              //         })
                              //     )
                              // }}
                            >
                              {' '}
                              {(ii && ii.proposalPresented) || 0}
                            </a>
                          ) : (
                            0
                          )}
                        </div>
                      </div>
                      <div className={styles.performanceDiv}>
                        <div>In Negotitions</div>
                        <div>
                          {ii && ii.inNegotiation > 0 ? (
                            <a
                              target={'_blank'}
                              href={`/graphCountUrl/${
                                ii && ii._id.officer
                              }/${moment(this.state.lastRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/${moment(this.state.startRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/In Negotiation`}

                              //    onClick={() => {
                              //     dispatch(
                              //         getUrlPushWrapper('lead.adminAllLeads', {
                              //             officerId: ii._id.officer,
                              //             lte: moment(this.state.lastRange).format("YYYY-MM-DD HH:mm"),
                              //             gte: moment(this.state.startRange).format("YYYY-MM-DD HH:mm"),
                              //             stage: 'Contacted'
                              //         })
                              //     )
                              // }}
                            >
                              {' '}
                              {(ii && ii.inNegotiation) || 0}
                            </a>
                          ) : (
                            0
                          )}
                        </div>
                      </div>

                      <div className={styles.performanceDiv}>
                        <div>Won Leads Average</div>
                        <div>{(ii && ii.wonAverageOfLeads) || 0}</div>
                      </div>
                    </div> */}

                    <h1 className={styles.headingRO}>
                      <img
                        src="/test/RO/call-stats.svg"
                        alt="Icon"
                        className={styles.headingROIcon}
                        style={{ height: '18px' }}
                      />
                      Call Stats
                    </h1>

                    <Row gutter={[16, 16]}>
                      <Col span={4}>
                        <div className={styles.cardROCalls}>
                          <img
                            src="/test/RO/calls/incoming-calls.svg"
                            alt="Icon"
                            className={styles.iconCalls}
                          />
                          <div className={styles.cardNumberRO}>
                            {ii && ii.incomingCalls > 0 ? (
                              <a
                                target={'_blank'}
                                href={`/callCountUrl/${
                                  ii && ii.userMobile
                                }/${moment(this.state.lastRange).format(
                                  'YYYY-MM-DD HH:mm'
                                )}/${moment(this.state.startRange).format(
                                  'YYYY-MM-DD HH:mm'
                                )}/incoming`}
                                className={styles.cardNumberLinkRO}>
                                {(ii && ii.incomingCalls) || 0}
                              </a>
                            ) : (
                              0
                            )}
                          </div>
                          <h6 className={styles.cardHeadingRO}>
                            Incoming Calls
                          </h6>
                        </div>
                      </Col>

                      <Col span={4}>
                        <div className={styles.cardROCalls}>
                          <img
                            src="/test/RO/calls/missed-calls.svg"
                            alt="Icon"
                            className={styles.iconCalls}
                          />
                          <div className={styles.cardNumberRO}>
                            {ii && ii.missedCalls > 0 ? (
                              <a
                                target={'_blank'}
                                href={`/callCountUrl/${
                                  ii && ii.userMobile
                                }/${moment(this.state.lastRange).format(
                                  'YYYY-MM-DD HH:mm'
                                )}/${moment(this.state.startRange).format(
                                  'YYYY-MM-DD HH:mm'
                                )}/missed`}
                                className={styles.cardNumberLinkRO}>
                                {(ii && ii.missedCalls) || 0}
                              </a>
                            ) : (
                              0
                            )}
                          </div>
                          <h6 className={styles.cardHeadingRO}>Missed Calls</h6>
                        </div>
                      </Col>

                      <Col span={4}>
                        <div className={styles.cardROCalls}>
                          <img
                            src="/test/RO/calls/outgoing-calls.svg"
                            alt="Icon"
                            className={styles.iconCalls}
                          />
                          <div className={styles.cardNumberRO}>
                            {ii && ii.outgoingCalls > 0 ? (
                              <a
                                target={'_blank'}
                                href={`/callCountUrl/${
                                  ii && ii.userMobile
                                }/${moment(this.state.lastRange).format(
                                  'YYYY-MM-DD HH:mm'
                                )}/${moment(this.state.startRange).format(
                                  'YYYY-MM-DD HH:mm'
                                )}/outgoing`}
                                className={styles.cardNumberLinkRO}>
                                {(ii && ii.outgoingCalls) || 0}
                              </a>
                            ) : (
                              0
                            )}
                          </div>
                          <h6 className={styles.cardHeadingRO}>
                            Outgoing Calls
                          </h6>
                        </div>
                      </Col>

                      <Col span={4}>
                        <div className={styles.cardROCalls}>
                          <img
                            src="/test/RO/calls/rejected-outgoing-calls.svg"
                            alt="Icon"
                            className={styles.iconCalls}
                          />
                          <div className={styles.cardNumberRO}>
                            {ii && ii.outgoingRejectCalls > 0 ? (
                              <a
                                target={'_blank'}
                                href={`/callCountUrl/${
                                  ii && ii.userMobile
                                }/${moment(this.state.lastRange).format(
                                  'YYYY-MM-DD HH:mm'
                                )}/${moment(this.state.startRange).format(
                                  'YYYY-MM-DD HH:mm'
                                )}/rejectedOutgoing`}
                                className={styles.cardNumberLinkRO}>
                                {(ii && ii.outgoingRejectCalls) || 0}
                              </a>
                            ) : (
                              0
                            )}
                          </div>
                          <h6 className={styles.cardHeadingRO}>
                            Outgoing Rejected Calls
                          </h6>
                        </div>
                      </Col>

                      <Col span={4}>
                        <div className={styles.cardROCalls}>
                          <img
                            src="/test/RO/calls/rejected-calls.svg"
                            alt="Icon"
                            className={styles.iconCalls}
                          />
                          <div className={styles.cardNumberRO}>
                            {ii && ii.rejectedCalls > 0 ? (
                              <a
                                target={'_blank'}
                                href={`/callCountUrl/${
                                  ii && ii.userMobile
                                }/${moment(this.state.lastRange).format(
                                  'YYYY-MM-DD HH:mm'
                                )}/${moment(this.state.startRange).format(
                                  'YYYY-MM-DD HH:mm'
                                )}/rejected`}
                                className={styles.cardNumberLinkRO}>
                                {(ii && ii.rejectedCalls) || 0}
                              </a>
                            ) : (
                              0
                            )}
                          </div>
                          <h6 className={styles.cardHeadingRO}>
                            Rejected Calls
                          </h6>
                        </div>
                      </Col>

                      <Col span={4}>
                        <div className={styles.cardROCalls}>
                          <img
                            src="/test/RO/calls/calls-in-minutes.svg"
                            alt="Icon"
                            className={styles.iconCalls}
                          />
                          <div className={styles.cardNumberRO}>
                            {(ii && ii.durationInMinutes) || 0}
                          </div>
                          <h6 className={styles.cardHeadingRO}>
                            Calls in Minutes
                          </h6>
                        </div>
                      </Col>

                      <Col span={4}>
                        <div className={styles.cardROCalls}>
                          <img
                            src="/test/RO/calls/attended-calls.svg"
                            alt="Icon"
                            className={styles.iconCalls}
                          />
                          <div className={styles.cardNumberRO}>
                            {ii && ii.totalAttended > 0 ? (
                              <a
                                target={'_blank'}
                                href={`/callCountUrl/${
                                  ii && ii.userMobile
                                }/${moment(this.state.lastRange).format(
                                  'YYYY-MM-DD HH:mm'
                                )}/${moment(this.state.startRange).format(
                                  'YYYY-MM-DD HH:mm'
                                )}/attended`}
                                className={styles.cardNumberLinkRO}>
                                {(ii && ii.totalAttended) || 0}
                              </a>
                            ) : (
                              0
                            )}
                          </div>
                          <h6 className={styles.cardHeadingRO}>
                            Attended Calls
                          </h6>
                        </div>
                      </Col>
                    </Row>

                    {/* <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        marginTop: '20px',
                      }}>
                      <div className={styles.performanceDiv}>
                        <div>Incoming Calls</div>
                        <div>
                          {ii && ii.incomingCalls > 0 ? (
                            <a
                              target={'_blank'}
                              href={`/callCountUrl/${
                                ii && ii.userMobile
                              }/${moment(this.state.lastRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/${moment(this.state.startRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/incoming`}>
                              {' '}
                              {(ii && ii.incomingCalls) || 0}
                            </a>
                          ) : (
                            0
                          )}
                        </div>
                      </div>
                      <div className={styles.performanceDiv}>
                        <div>Missed Calls</div>
                        <div>
                          {ii && ii.missedCalls > 0 ? (
                            <a
                              target={'_blank'}
                              href={`/callCountUrl/${
                                ii && ii.userMobile
                              }/${moment(this.state.lastRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/${moment(this.state.startRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/missed`}>
                              {' '}
                              {(ii && ii.missedCalls) || 0}
                            </a>
                          ) : (
                            0
                          )}
                        </div>
                      </div>
                      <div className={styles.performanceDiv}>
                        <div>Outgoing Calls</div>
                        <div>
                          {ii && ii.outgoingCalls > 0 ? (
                            <a
                              target={'_blank'}
                              href={`/callCountUrl/${
                                ii && ii.userMobile
                              }/${moment(this.state.lastRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/${moment(this.state.startRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/outgoing`}>
                              {' '}
                              {(ii && ii.outgoingCalls) || 0}
                            </a>
                          ) : (
                            0
                          )}
                        </div>
                      </div>
                      <div className={styles.performanceDiv}>
                        <div>Outgoing Rejected Calls</div>
                        <div>
                          {ii && ii.outgoingRejectCalls > 0 ? (
                            <a
                              target={'_blank'}
                              href={`/callCountUrl/${
                                ii && ii.userMobile
                              }/${moment(this.state.lastRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/${moment(this.state.startRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/rejectedOutgoing`}>
                              {' '}
                              {(ii && ii.outgoingRejectCalls) || 0}
                            </a>
                          ) : (
                            0
                          )}
                        </div>
                      </div>

                      <div className={styles.performanceDiv}>
                        <div>Rejected Calls</div>
                        <div>
                          {ii && ii.rejectedCalls > 0 ? (
                            <a
                              target={'_blank'}
                              href={`/callCountUrl/${
                                ii && ii.userMobile
                              }/${moment(this.state.lastRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/${moment(this.state.startRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/rejected`}>
                              {' '}
                              {(ii && ii.rejectedCalls) || 0}
                            </a>
                          ) : (
                            0
                          )}
                        </div>
                      </div>
                      <div className={styles.performanceDiv}>
                        <div>Calls in minutes</div>
                        <div>{(ii && ii.durationInMinutes) || 0}</div>
                      </div>

                      <div className={styles.performanceDiv}>
                        <div>Attended Calls</div>
                        <div>
                          {ii && ii.totalAttended > 0 ? (
                            <a
                              target={'_blank'}
                              href={`/callCountUrl/${
                                ii && ii.userMobile
                              }/${moment(this.state.lastRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/${moment(this.state.startRange).format(
                                'YYYY-MM-DD HH:mm'
                              )}/attended`}>
                              {' '}
                              {(ii && ii.totalAttended) || 0}
                            </a>
                          ) : (
                            0
                          )}
                        </div>
                      </div>
                    </div> */}
                  </Card>
                </Row>
              )
            })}
          </div>
        </Spin>
      </div>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}
export default connect(mapDispatchToProps)(Graph)
