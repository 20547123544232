import React, { PureComponent } from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
  Form,
  Input,
  DatePicker,
  Collapse,
  Spin,
  Select,
  Transfer,
  Button,
  Table,
  Card,
  Row,
  Col,
  InputNumber,
  Icon, Tooltip, Popconfirm, Avatar, Checkbox
} from 'antd' // eslint-disable-next-line

import { ChromePicker } from 'react-color'
import _ from 'lodash'
import moment from 'moment'
import { FormUtils as GetAllFormFields } from 'sz-react-utils'
// import GetAllFormFields from '../../../components/_utils/formUtils'

import { API_URL } from '../../../request'

import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import { hideLoader, showLoader } from '../../../modules/actions'
import Request from '../../../request'
import styles from './styles.less'
import { connect } from 'react-redux'
import { createMatchSelector, goBack } from 'connected-react-router'
import StateData from '../../../states'
import { getUrlPushWrapper } from '../../../routes'

const FormItem = Form.Item
const { Option } = Select
const { TextArea } = Input
const State = [
  'Andaman and Nicobar Islands',
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chandigarh',
  'Chhattisgarh',
  'Dadra and Nagar Haveli',
  'Daman and Diu',
  'Delhi',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu',
  'Jharkhand',
  'Karnataka',
  'Kashmir',
  'Kerala',
  'Ladakh',
  'Lakshadweep',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Puducherry',
  'Punjab',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttarakhand',
  'Uttar Pradesh',
  'West Bengal',
  'Lumbini'
]

@Form.create()
class AddContact extends PureComponent {

  handleSubmit = e => {
    const { dispatch, form, handleSubmit, handleAfterAdd, handleEditContact } = this.props
    const {
      billingStateName,
      billingCityName,
      billingCountryName,
      shippingCityName,
      shippingStateName,
      shippingCountryName
    } = this.state
    e.preventDefault()
    form.validateFieldsAndScroll(async (err, valData) => {
      if (!err) {
        let values = _.clone(valData)
        dispatch(showLoader())
        let x = null
        let billingAddress = {
          cityId: values.billingCity,
          city: billingCityName,
          countryId: values.billingCountry,
          country: billingCountryName,
          state: billingStateName,
          stateId: values.billingState,
          zipcode: values.billingZipcode,
          addressLine1: values.billingAddressLine1,
          addressLine2: values.billingAddressLine2

        }
        let shippingAddress = {
          zipcode: values.shippingZipcode,
          cityId: values.shippingCity,
          city: shippingCityName,
          countryId: values.shippingCountry,
          country: shippingCountryName,
          stateId: values.shippingState,
          state: shippingStateName,
          addressLine1: values.shippingAddressLine1,
          addressLine2: values.shippingAddressLine2
        }

        values.billingAddress = billingAddress
        values.shippingAddress = shippingAddress
        let user = { ...JSON.parse(localStorage.getItem('user')) }  // eslint-disable-next-line
        if (user.userType == 'RO') {
          values.contactOwner = user._id
        }

        if (this.state.id) {
          values._id = this.state.id
          x = await Request.updateContact(values)
        } else {
          x = await Request.addContact(values)
        }
        dispatch(hideLoader())

        if (!x.error) {
          if (!this.state.id) {
            this.setState({
              cityList: [],
              billingStateName: '',
              billingCityName: '',
              billingCountryName: '',
              shippingCityName: '',
              shippingStateName: '',
              shippingCountryName: '',
              shippingStateList: [],
              stateList: [],
              shippingCityList: []
            })
            this.props.form.resetFields()
          }
          if (handleSubmit) {
            handleSubmit(x.data)
          }

          notification.success({
            message: this.state.id
                     ? x.message
                     : x.message
          })
          // when editing contact via lead
          if (this.props.contactId) {
            if (handleEditContact) {
              handleEditContact()
            }
            return
          }
          //otherwise
          if (this.state.id && !this.props.editContactId) {
            dispatch(goBack())
          }
          if (handleAfterAdd) {
            handleAfterAdd()
          }

        } else {
          notification.error({
            message: 'Error Saving',
            description: x.message
          })
        }

      }
    })
  }

  constructor (props) {
    super(props)
    this.state = {
      loader: true,
      userList: [],
      countryList: [],
      stateList: [],
      shippingStateList: [],
      shippingCityList: []
    }

  }

  getOptionsData = async (contactData) => {

    let { billingAddress, shippingAddress } = contactData

    Request.loadState({ countryId: billingAddress && billingAddress.countryId }).then(({ data: billingStateList }) => {
      this.setState({
        stateList: billingStateList || [],
        billingStateName: billingAddress && billingAddress.state

      })
    })
    Request.loadState({ countryId: shippingAddress && shippingAddress.countryId })
      .then(({ data: shippingStateList }) => {
        this.setState({
          shippingStateList: shippingStateList || [],
          shippingStateName: shippingAddress && shippingAddress.state
        })
      })
    Request.loadCity({ stateId: billingAddress && billingAddress.stateId }).then(({ data: billingCityList }) => {
      this.setState({
        cityList: billingCityList || [], billingCityName: billingAddress && billingAddress.city
      })
    })
    Request.loadCity({ stateId: shippingAddress && shippingAddress.stateId }).then(({ data: shippingCityList }) => {
      this.setState({
        shippingCityList: shippingCityList || [],
        shippingCityName: shippingAddress && shippingAddress.city
      })
    })

  }

  async componentDidMount () {
    let searchParams = new URLSearchParams(this.props.search)
    let currentUser = JSON.parse(window.localStorage.getItem('user'))
    let { data: allContacts } = await Request.loadContacts()
    let { data: allCountry } = await Request.loadCountry()

    this.setState({ allContacts: allContacts, countryList: allCountry })
    let contactId = searchParams.get('id')
    if (!contactId)
      contactId = this.props.contactId
    if (this.props && this.props.editContactId) {
      contactId = this.props.editContactId
    }
    if (currentUser && currentUser.userType !== 'RO') {
      let { data: userList } = await Request.loadUser({ userType: 'RO' }, { results: 10000000000000000 })
      this.setState({ userList: userList, showUserList: true })
    }

    let { data: contactData } = await Request.getContactById({ _id: contactId })
    if (contactId) {
      Request.getContactById({ _id: contactId }).then(async ({ data, error, message }) => {
        if (!error) {
          this.setState({
            id: contactId,
            loader: false
          })

          this.getOptionsData(contactData)

          this.setState({
            billingCountryName: contactData && contactData.billingAddress && contactData.billingAddress.country,
            shippingCountryName: contactData && contactData.shippingAddress && contactData.shippingAddress.country
          })

          this.props.form.setFieldsValue({
            note: contactData && contactData.note,
            // contactOwner: contactData && contactData.contactOwner,
            firstName: contactData && contactData.firstName,
            email: contactData && contactData.email,
            phone: contactData && contactData.phone,
            shippingPhone: contactData && contactData.shippingPhone,
            companyName: contactData && contactData.companyName,
            mobile: contactData && contactData.mobile,
            leadSource: contactData && contactData.leadSource,
            lastName: contactData && contactData.lastName,
            title: contactData && contactData.title,
            department: contactData && contactData.department,
            remarks: contactData && contactData.remarks,
            homePhone: contactData && contactData.homePhone,
            dob: contactData && contactData.dob ? moment(contactData.dob) : '',
            billingCity: contactData && contactData.billingAddress && contactData.billingAddress.cityId,
            billingCountry: contactData && contactData.billingAddress && contactData.billingAddress.countryId,
            billingZipcode: contactData && contactData.billingAddress && contactData.billingAddress.zipcode,
            billingState: contactData && contactData.billingAddress && contactData.billingAddress.stateId,
            billingAddressLine1: contactData && contactData.billingAddress && contactData.billingAddress.addressLine1,
            billingAddressLine2: contactData && contactData.billingAddress && contactData.billingAddress.addressLine2,
            shippingZipcode: contactData && contactData.shippingAddress && contactData.shippingAddress.zipcode,
            shippingCity: contactData && contactData.shippingAddress && contactData.shippingAddress.cityId,
            shippingCountry: contactData && contactData.shippingAddress && contactData.shippingAddress.countryId,
            shippingState: contactData && contactData.shippingAddress && contactData.shippingAddress.stateId,
            shippingAddressLine1: contactData && contactData.shippingAddress &&
              contactData.shippingAddress.addressLine1,
            shippingAddressLine2: contactData && contactData.shippingAddress &&
              contactData.shippingAddress.addressLine2,
            contactOwner: contactData && contactData.contactOwner

          })
        } else {
          notification.error({
            message: 'Error Getting Data'
          })
        }
      })
    } else {
      this.setState({

        loader: false

      })
    }

  }

  render () {

    const { submitting } = this.props
    const {
      form: { getFieldDecorator, getFieldValue }
    } = this.props

    const { userList, showUserList, countryList, stateList, shippingStateList } = this.state

    const contactDetails = {
      fields: [

        {
          label: 'First Name',
          key: 'firstName',
          required: true
        },
        {
          label: 'Mobile No',
          key: 'mobile',
          required: true

        },
        {
          label: 'Contact Owner',
          key: 'contactOwner',
          required: true,
          showSearch: true,
          keyAccessor: x => x._id,
          valueAccessor: x => `${x.name} (${x && x.id}) (${x.mobile}) `,
          type: 'select',
          options: userList || [],
          hidden: showUserList ? false : true,
          onChange: contactOwner => {
            this.props.form.setFieldsValue({
              contactOwner: contactOwner
            })

          }

        },

        {
          label: 'Email',
          key: 'email'

        },
        {
          label: 'Phone',
          key: 'phone'

        },

        {
          label: 'Date of Birth',
          key: 'dob',
          type: 'date'

        },
        {
          label: 'GST Number',
          key: 'gst',

        }

      ]
    }

    const contactDetails1 = {
      fields: [
        // {
        //     label: 'Lead Source',
        //     key: 'leadSource',
        //     type: 'select',
        //     options: ['a', 'b'],
        //     onChange: leadSource => {
        //         this.props.form.setFieldsValue({
        //             leadSource: leadSource
        //         })

        //     }

        // },
        {
          label: 'Remarks',
          key: 'remarks',
          type: 'textArea'

        },
        {
          label: 'Last Name',
          key: 'lastName'

        },
        {
          label: 'Company Name',
          key: 'companyName'

        },
        {
          label: 'Title/Designation',
          key: 'title'

        },
        {
          label: 'Department',
          key: 'department'

        },
        {
          label: 'Home Phone',
          key: 'homePhone'

        },
        {
          label: 'Shipping Phone',
          key: 'shippingPhone'

        }
      ]
    }

    const addressDetails = {
      fields: [
        {
          label: 'Address Line 1',
          key: 'billingAddressLine1'
        },
        {
          label: 'Address Line 2',
          key: 'billingAddressLine2'
        },
        {
          label: 'Country',
          key: 'billingCountry',
          type: 'select',
          showSearch: true,
          keyAccessor: x => x._id,
          valueAccessor: x => x.countryName ? _.startCase(x.countryName) : '',
          options: countryList || [],
          onChange: async (billingCountry) => {
            let { data: stateList } = await Request.loadState({ countryId: billingCountry })
            let { data: countryDetails } = await Request.getCountryById({ _id: billingCountry })

            this.setState({
              stateList: stateList,
              billingCountryName: countryDetails && countryDetails.countryName,
              billingStateName: '',
              billingCityName: ''
            })
            this.props.form.setFieldsValue({
              billingCountry: billingCountry,
              billingState: '',
              billingCity: ''
            })

          }

        },
        {
          label: 'State',
          type: 'select',
          key: 'billingState',
          showSearch: true,
          keyAccessor: x => x._id,
          valueAccessor: x => x.stateName ? _.startCase(x.stateName) : '',
          disabled: this.state.stateList && this.state.stateList.length ? false : true,
          options: stateList || [],
          onChange: async (billingState) => {
            let { data: cityList } = await Request.loadCity({ stateId: billingState })
            let { data: stateDetails } = await Request.getStateById({ _id: billingState })

            this.setState({
              cityList: cityList,
              billingStateName: stateDetails && stateDetails.stateName,
              billingCityName: ''
            })
            this.props.form.setFieldsValue({
              billingState: billingState,
              billingCity: ''
            })
          }

        },
        {
          label: 'City',
          type: 'select',
          key: 'billingCity',
          showSearch: true,
          disabled: this.state.cityList && this.state.cityList.length ? false : true,
          keyAccessor: x => x._id,
          valueAccessor: x => x.cityName ? _.startCase(x.cityName) : '',
          options: this.state.cityList || [],
          onChange: async (billingCity) => {
            let { data: cityDetails } = await Request.getCityById({ _id: billingCity })
            this.setState({ billingCityName: cityDetails && cityDetails.cityName })

            this.props.form.setFieldsValue({
              billingCity: billingCity
            })

          }
        },
        {
          label: 'Zipcode',
          key: 'billingZipcode'

        }

      ]
    }
    const addressDetails1 = {
      fields: [

        {
          label: 'Address Line1',
          key: 'shippingAddressLine1'
        },
        {
          label: 'Address Line2',
          key: 'shippingAddressLine2'
        },
        {
          label: 'Country',
          key: 'shippingCountry',
          type: 'select',
          showSearch: true,
          keyAccessor: x => x._id,
          valueAccessor: x => x.countryName ? _.startCase(x.countryName) : '',
          options: countryList || [],
          onChange: async (shippingCountry) => {
            let { data: stateList } = await Request.loadState({ countryId: shippingCountry })
            let { data: countryDetails } = await Request.getCountryById({ _id: shippingCountry })

            this.setState({
              shippingStateList: stateList,
              shippingCountryName: countryDetails && countryDetails.countryName,
              shippingStateName: '',
              shippingCityName: ''
            })
            this.props.form.setFieldsValue({
              shippingCountry: shippingCountry,
              shippingState: '',
              shippingCity: ''
            })

          }

        },

        {
          label: 'State',
          type: 'select',
          key: 'shippingState',
          showSearch: true,
          disabled: this.state.shippingStateList && this.state.shippingStateList.length ? false : true,
          keyAccessor: x => x._id,
          valueAccessor: x => x.stateName ? _.startCase(x.stateName) : '',
          options: shippingStateList || [],
          onChange: async (shippingState) => {
            let { data: cityList } = await Request.loadCity({ stateId: shippingState })
            let { data: stateDetails } = await Request.getStateById({ _id: shippingState })

            this.setState({
              shippingCityList: cityList,
              shippingStateName: stateDetails && stateDetails.stateName,
              shippingCityName: ''
            })
            this.props.form.setFieldsValue({
              shippingState: shippingState,
              shippingCity: ''
            })
          }

        },
        {
          label: 'City',
          type: 'select',
          key: 'shippingCity',
          showSearch: true,
          disabled: this.state.shippingCityList && this.state.shippingCityList.length ? false : true,
          keyAccessor: x => x._id,
          valueAccessor: x => x.cityName ? _.startCase(x.cityName) : '',
          options: this.state.shippingCityList || [],
          onChange: async (shippingCity) => {

            let { data: cityDetails } = await Request.getCityById({ _id: shippingCity })
            this.setState({
              shippingCityName: cityDetails && cityDetails.cityName
            })
            this.props.form.setFieldsValue({
              shippingCity: shippingCity
            })

          }
        },
        {
          label: 'Zipcode',
          key: 'shippingZipcode'

        }

      ]
    }

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 8 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 }
      }
    }
    const formItemLayout1 = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
        md: { span: 4 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
        md: { span: 12 }
      }
    }

    const submitFormLayout = {
      wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 10, offset: 7 },
        md: { span: 12, offset: 8 }
      }
    }

    return (
      <PageHeaderWrapper
        title={this.state.id ? 'Update Contact' : 'Add Contact'}
      >

        <Spin spinning={this.state.loader}>
          <Card bordered={true}>
            <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{ marginTop: 8 }}>

              <Card bordered={true} style={{ marginBottom: '15px' }}>
                <div className={styles.heading}><h3>Contact Information</h3>
                </div>

                <Row>
                  <Col span={12}>
                    <GetAllFormFields apiurl={API_URL}
                                      inputSchema={contactDetails} formItemLayout={formItemLayout}
                                      getFieldDecorator={getFieldDecorator}/>
                  </Col>
                  <Col span={12}>

                    <GetAllFormFields apiurl={API_URL}
                                      inputSchema={contactDetails1} formItemLayout={formItemLayout}
                                      getFieldDecorator={getFieldDecorator}/>

                  </Col>
                </Row>
              </Card>


              <Card bordered={true} style={{ marginBottom: '15px' }}>
                <div className={styles.heading}><h3>Billing Address</h3>
                  {/*<Button onClick={() => {*/}

                  {/*let values = this.props.form.getFieldsValue()*/}
                  {/*this.setState({*/}
                  {/*shippingStateName: this.state.billingStateName,*/}
                  {/*shippingCityName: this.state.billingCityName,*/}
                  {/*shippingCountryName: this.state.billingCountryName,*/}

                  {/*})*/}
                  {/*this.props.form.setFieldsValue({*/}
                  {/*shippingZipcode: values.billingZipcode,*/}
                  {/*// shippingCity: values.billingCity,*/}
                  {/*// shippingCountry: values.billingCountry,*/}
                  {/*// shippingState: values.billingState,*/}
                  {/*shippingAddressLine1: values.billingAddressLine1,*/}
                  {/*shippingAddressLine2: values.billingAddressLine2,*/}

                  {/*})*/}


                  {/*}}>Copy Address</Button>*/}
                </div>

                <Row>
                  <Col span={12}>
                    <GetAllFormFields apiurl={API_URL}
                                      inputSchema={addressDetails} formItemLayout={formItemLayout}
                                      getFieldDecorator={getFieldDecorator}/>
                  </Col>

                </Row>


                {/*<div className={styles.heading}><h3>Shipping Address</h3>*/}
                {/*</div>*/}

                {/*<Row>*/}
                {/*<Col span={12}>*/}
                {/*<GetAllFormFields apiurl={API_URL}*/}
                {/*inputSchema={addressDetails1} formItemLayout={formItemLayout}*/}
                {/*getFieldDecorator={getFieldDecorator}/>*/}
                {/*</Col>*/}

                {/*</Row>*/}


                {/*<Row>*/}
                {/*<Col span={22}>*/}
                {/*<Form.Item label={'Note'} {...formItemLayout1}>*/}
                {/*{getFieldDecorator('note')(<TextArea rows={4}*/}
                {/*onChange={ei => {*/}
                {/*this.setState({description: ei.target.value})*/}
                {/*}}/>)}*/}
                {/*</Form.Item>*/}
                {/*</Col>*/}

                {/*</Row>*/}

              </Card>

              <Card bordered={true} style={{ marginBottom: '15px' }}>
                <div className={styles.heading}><h3>Shipping Address</h3>
                </div>

                <Row>
                  <Col span={12}>
                    <GetAllFormFields apiurl={API_URL}
                                      inputSchema={addressDetails1} formItemLayout={formItemLayout}
                                      getFieldDecorator={getFieldDecorator}/>
                  </Col>

                </Row>


                {/*<Row>*/}
                {/*<Col span={22}>*/}
                {/*<Form.Item label={'Note'} {...formItemLayout1}>*/}
                {/*{getFieldDecorator('note')(<TextArea rows={4}*/}
                {/*onChange={ei => {*/}
                {/*this.setState({description: ei.target.value})*/}
                {/*}}/>)}*/}
                {/*</Form.Item>*/}
                {/*</Col>*/}

                {/*</Row>*/}
              </Card>


              <Card bordered={true} style={{ marginBottom: '15px' }}>

                <Row>
                  <Col span={22}>
                    <Form.Item label={'Note'} {...formItemLayout1}>
                      {getFieldDecorator('note')(<TextArea rows={4}
                                                           onChange={ei => {
                                                             this.setState({ description: ei.target.value })
                                                           }}/>)}
                    </Form.Item>
                  </Col>

                </Row>

              </Card>


              <Form.Item style={{ marginTop: 32, textAlign: 'center' }}>
                <Button size="large" type="primary" htmlType="submit" loading={this.props.loading}
                        style={{ width: '15%' }}>
                  {this.state.id ? `UPDATE` : `SAVE`}

                </Button>
              </Form.Item>


            </Form>
          </Card>
        </Spin>

      </PageHeaderWrapper>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search
})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddContact)
