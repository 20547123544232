import React, { PureComponent } from 'react'
import {
  Steps,
  Card,
  Input,
  Select,
  DatePicker,
  Modal,
  Icon,
  Spin,
  Row,
  Col,
  Tabs,
  Collapse,
  Avatar,
  Tooltip,
  Divider,
  Popover,
  Button,
  Form,
  Tag,
  Alert,
  Badge,
  Drawer,
  Popconfirm,
} from 'antd'
import { Timeline } from 'antd'
import 'react-quill/dist/quill.snow.css'
import _ from 'lodash'
import moment from 'moment'
import { Link } from 'react-router-dom'
import S from 'string'
import { LikeTwoTone, LikeOutlined, UserOutlined, CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import styles from './styles.less'
import { notification } from 'antd/lib/index'
import Request, { setCountryName, getCallColor } from '../../../request'
import { connect } from 'react-redux'
import { createMatchSelector } from 'connected-react-router'
import { getColorClass } from '../../../colors'
import AddFollowup from '../../followup/add/index'
import AddSmartFollowup from '../../followup/add/smartFollowup'
import AddActivity from '../../activity/add/index'
import AddNote from '../../note/index'
import AttachFile from '../../emails/attachFiles'
import AddEmail from '../../emails/index'
import { FormUtils as GetAllFormFields } from 'sz-react-utils'
import { MdSnooze } from 'react-icons/md'
import UpdateLead from '../add/editLead'

import LeadsProduct from '../../product/add/addProductByRO'

import {
  WinModal,
  LostModal,
  addLeadActivity,
  NoteActivityModal,
  EmailActivityModal,
  DealActivityModal,
  FollowUpActivityModal,
  WhatsAppActivityModal,
  SmartFollowUpModal,
  TextActivityModal,
} from './helpers'
import { apiUrl } from '../../../settings'
import ReactPlayer from 'react-player/lazy'
import TabsComp from './tabsComp'
import { getUrlPushWrapper } from '../../../routes'

const { TabPane } = Tabs
const { Step } = Steps
const { Panel } = Collapse
const { Option } = Select

@Form.create()
class ViewLead extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      leadStage: [
        {
          value: 0,
          text: 'New (Untouched)',
        },
        { value: 1, text: 'Contacted' },
        { value: 2, text: 'Today Followup' },
      ],

      activeKey: '0',
      activeKey2: '0',
      currentStage: 0,
      win: false,
      lost: false,
      popOver: false,
      noteModal: false,
      emailModal: false,
      followUpModal: false,
      textActivityModal: false,
      whatsAppModal: false,
      activities: [],
      loader: true,
      showPlayer: [],
      smartLoader: false,
      callBtnLoader: false,
      alert: false,
      parentLead: null,
      smartFollowupModal: false,
      showContactButton: false,
      showTabs: false,
      loaderValueForm: false,
      updatelead:false,
      cardId:'',
      isLeadUpdate:false,
      checkListData:{},
      productCheck:false,
      noteCheck:false,
      followUpCheck:false,
      whatsAppCheck:false,
      callAttempt:false

    }
  }

  apiRequest = async () => {
    let searchParams = new URLSearchParams(this.props.search)
    let leadId = searchParams.get('id') || this.props.leadId
    let user = { ...JSON.parse(localStorage.getItem('user')) }
    if (this.state.leadId === leadId && this.state.isLeadUpdate!=true) {
      return false
    } else if(this.state.isLeadUpdate===true) {
      this.state.leadId = leadId
      this.setState({
        isLeadUpdate: false,
      })
    }
    else {
      this.state.leadId = leadId
    }
    if (leadId) {
      await Request.getLeadById({ _id: leadId }).then(
        async ({ data, error, message, parentData }) => {
          if (!error) {
            // let gh = _.map(data.activities, (v) => {
            //   if (v.type == 'callLog' && v.callDuration.toNumber() > 30) {
            //     this.setState({ showContactButton: true })
            //   }
            // })

            if (data.isChild) {
              this.setState({
                parentLead: parentData,
              })
            }
            let { data: ROData } = await Request.getUserById({
              _id: data.officer,
            })

            if (data && data.existingRO) {
              let { data: existingROData } = await Request.getUserById({
                _id: data.existingRO,
              })
              this.setState({ existingROData: existingROData })
            }

            this.setState({
              leadId: leadId,
              leadData: data,
              loader: false,
              ROData: ROData,
            })


            let found = _.find(this.state.leadStage, (item) => {
              return item.text == data.stage
            })
            if (found) {
              this.setState({ currentStage: found.value })
            } else {
              this.setState({ currentStage: 0 })
            }

            this.props.form.setFieldsValue({
              value: data.value ? data.value : '',
              currency: data.currency ? data.currency : '',
              closeDate: data.closeDate ? moment(data.closeDate) : '',
            })
          } else {
            this.setState({
              loader: false,
            })
            notification.error({
              message: 'Error Getting Data',
            })
          }
        }
      )
      let { data } = await Request.getLeadByActivityType({
        leadId: leadId,
        type: 'all',
      })
      this.setState({
        activities: data,
      })
      let { data: followupData } = await Request.getAllFollowupsByLeadId({
        // ...params,
        customQuery: { leadId: { $eq: leadId }, active: true },
        regExFilters: ['description'],
      })
      this.setState({ followupData: followupData })
       const abcd={
        _id:leadId
      }
      let newData =await Request.getCheckList(abcd)
      this.setState({
        checkListData:newData.data,
        productCheck:newData.data.product,
        noteCheck:newData.data.note,
        followUpCheck:newData.data.followUp,
        whatsAppCheck:newData.data.whatsApp,
        callAttempt:newData.data.call
      })
    }
   
 
  }

  componentDidMount() {}

  handleVisibleChange = (key, value) => {
    let newData = _.clone(this.state.showPlayer)
    newData[key] = value
    this.setState({ showPlayer: newData })
  }
  showDrawer = () => {
    this.setState({
      visible: true,
      key: Date.now(),
    })
  }

  handleCancel = (e) => {
    this.setState({
      visible: false,
      smartFollowupModal: false,
    })
  }


  render() {
    this.apiRequest()
    const styles1 = {
      mainDiv: {
        position: 'relative',
      },
      loadingBox: {
        // position: 'absolute',
        top: 10,
        left: '40%',
        height: '100%',
        width: '100%',
        backgroundColor: 'rgba(255, 255,255, 0.5)',
        textAlign: 'center',
        paddingTop: '10%',
      },
    }
    const { getTodos, currentUser } = this.props
    const {
      leadStage,
      leadData,
      currentStage,
      activities,
      ROData,
      showPlayer,
      newTitle,
      existingROData,
    } = this.state

    const setActivity = (activity, newKey, key) => {
      if (
        activity.type == 'value' ||
        activity.type == 'status' ||
        activity.type == 'stage' ||
        activity.type == 'closeDate'
      )
        return (
          <Timeline.Item key={newKey}>
            {activity.message} by <b>{activity.user}</b> on{' '}
            <b>{moment(activity.createdAt).format('lll')}</b>
          </Timeline.Item>
        )
      else if (activity.type == 'notes')
        return (
          <Timeline.Item key={newKey}>
            Note : "{S(activity.noteMessage).stripTags().s}" added at{' '}
            <b>{moment(activity.createdAt).format('lll')}</b>
          </Timeline.Item>
        )
      else if (activity.type == 'email')
        return (
          <Timeline.Item key={newKey}>
            <a
              onClick={() => {
                this.setState({ emailModal: true, emailActivity: activity })
              }}>
              {activity.message} by <b>{activity.user}</b> on{' '}
              <b>{moment(activity.createdAt).format('lll')}</b>
            </a>
          </Timeline.Item>
        )
      // else if (activity.type == "file")
      else if (activity.type == 'whatsApp')
        return (
          <Timeline.Item key={newKey}>
            <a
              onClick={() => {
                this.setState({
                  whatsAppModal: true,
                  whatsAppActivity: activity,
                })
              }}>
              {activity.message} by <b>{activity.user}</b> on{' '}
              <b>{moment(activity.createdAt).format('lll')}</b>
            </a>
          </Timeline.Item>
        )
      else if (activity.type == 'file')
        return (
          <Timeline.Item key={newKey}>
            {activity.message} by <b>{activity.user}</b> on{' '}
            <b>{moment(activity.createdAt).format('lll')}</b>
            <a
              style={{ marginLeft: '10px' }}
              href={apiUrl + `${activity.fileUrl}`}
              target="_blank">
              <mark>{activity.fileName}</mark>
            </a>
          </Timeline.Item>
        )
      else if (activity.type == 'followUp')
        return (
          <Timeline.Item key={newKey}>
            <a
              onClick={() => {
                this.setState({
                  followUpModal: true,
                  followupActivity: activity,
                })
              }}>
              {activity.message} by <b>{activity.user}</b> on{' '}
              <b>{moment(activity.createdAt).format('lll')}</b>
            </a>
          </Timeline.Item>
        )
      else if (activity.type == 'message')
        return (
          <Timeline.Item key={newKey}>
            <a
              onClick={() => {
                this.setState({
                  textActivityModal: true,
                  textActivity: activity,
                })
              }}>
              {activity.message} by <b>{activity.user}</b> on{' '}
              <b>{moment(activity.createdAt).format('lll')}</b>
            </a>
          </Timeline.Item>
        )
      else if (activity.type == 'activity')
        return (
          <Timeline.Item key={newKey}>
            <a
              onClick={() => {
                this.setState({
                  dealActivityModal: true,
                  dealActivity: activity,
                })
              }}>
              {activity.message} by <b>{activity.user}</b> on{' '}
              <b>{moment(activity.createdAt).format('lll')}</b>
            </a>
          </Timeline.Item>
        )
      else if (activity.type === 'recording')
        return (
          <Timeline.Item key={newKey}>
            <span>{activity.message}</span> by <b>{activity.user}</b> on{' '}
            <b>{moment(activity.createdAt).format('lll')}</b>
            <Popover
              content={
                <ReactPlayer
                  url={`${apiUrl}/recordingStream?path=${activity.filePath}&isPath=true`}
                  controls
                  height={50}
                  className={styles.player}
                  width={400}
                />
              }
              placement={'bottomRight'}
              trigger="click"
              destroyTooltipOnHide
              visible={showPlayer[key]}
              onVisibleChange={(val) => this.handleVisibleChange(key, val)}>
              <Icon
                className={styles.playBtn}
                size="small"
                type="play-circle"
              />
            </Popover>
            <Icon
              className={styles.playBtn}
              size="small"
              type="download"
              onClick={() => {
                Request.downloadWithPath(activity.filePath)
              }}
            />
          </Timeline.Item>
        )
      else if (activity.type === 'callLog' && leadData) {
        // let currentActivity =
        //   _.find(
        //     leadData.activities,
        //     (e) => e && e._id && e._id === activity._id
        //   ) || {}
        let recordId =
          activity &&
          activity.callLogId &&
          activity.callLogId.recording
        
        return (
          <Timeline.Item key={newKey}>
            <span>
              {activity && activity.callLogId ? (
                <Tag
                  style={getCallColor(
                    activity.callLogId.callType,
                    activity.callDuration
                  )}>
                  {_.startCase(activity.callLogId.callType)}
                </Tag>
              ) : (
                ''
              )}
            </span>{' '}
            Call of duration{' '}
            <span>
              {moment.utc(activity.callDuration * 1000).format('HH:mm:ss')}{' '}
            </span>{' '}
            of <b>{activity.user}</b> on{' '}
            <b>{moment(activity.followUpDate).format('lll')}</b>
            {recordId && (
              <>
                <Popover
                  content={
                    <ReactPlayer
                      url={`${apiUrl}/recordingStream?id=${recordId}`}
                      controls
                      height={50}
                      className={styles.player}
                      width={400}
                    />
                  }
                  placement={'bottomRight'}
                  trigger="click"
                  destroyTooltipOnHide
                  visible={showPlayer[key]}
                  onVisibleChange={(val) => this.handleVisibleChange(key, val)}>
                  <Icon
                    className={styles.playBtn}
                    size="small"
                    type="play-circle"
                  />
                </Popover>
                <Icon
                  className={styles.playBtn}
                  size="small"
                  type="download"
                  onClick={() => {
                    Request.downloadWithPath(`recordings/${recordId}.mp3`)
                  }}
                />
              </>
            )}
          </Timeline.Item>
        )
      } else if (activity.type === 'info')
        return (
          <Timeline.Item key={newKey}>
            <a>
              {activity.message} by <b>{activity.user}</b> on{' '}
              <b>{moment(activity.createdAt).format('lll')}</b>
            </a>
          </Timeline.Item>
        )
    }

    const RenderActivities = (props) => {
      let { renderKey, data } = props

      let newData = _.groupBy(data, (x) =>
        moment(x.createdAt).format('YYYY-MM-DD')
      )

      let findAArr = []
      _.each(newData, (val, k) => {
        findAArr.push({ date: k, activities: val })
      })
      return _.map(findAArr, (val, key) => {
        return (
          <div className={styles.timeLineDiv}>
            <p style={{ fontWeight: '500' }}>
              {moment(val.date).format('MMM DD, YYYY')}
              {/* <Tag color={'lightgreen'}>{val.date}</Tag> */}
            </p>
            <Timeline mode={'left'}>
              {_.map(val.activities, (ii, k) => {
                let newKey = `${renderKey} ${ii._id}`
                if (
                  leadData &&
                  leadData.leadAssignedDate &&
                  currentUser &&
                  currentUser.userType === 'RO'
                ) {
                  if (moment(ii.createdAt).isAfter(leadData.leadAssignedDate)) {
                    return setActivity(ii, newKey, k)
                  }
                } else {
                  return setActivity(ii, newKey, k)
                }
              })}
            </Timeline>
          </div>
        )
      })
    }

    let user = JSON.parse(localStorage.getItem('user'))
    const setNewData = async (leadId) => {
      this.setState({
        loader:true
      })
      await Request.getLeadById({ _id: leadId }).then(
        ({ data, error, message }) => {
          if (!error) {
            this.setState({
              leadData: data,
            })

            let found = _.find(this.state.leadStage, (item) => {
              return item.text == data.stage
            })
            if (found) {
              this.setState({ currentStage: found.value })
            } else {
              this.setState({ currentStage: 0 })
            }
            this.props.form.setFieldsValue({
              value: data && data.value ? data.value : '',
              currency: data && data.currency ? data.currency : '',
              closeDate: data && data.closeDate ? moment(data.closeDate) : '',
            })
          } else {
            notification.error({
              message: 'Error Getting Data',
            })
          }
          if (this.props.getTodos) {
            getTodos(
              undefined,
              this.props.paginate,
              undefined,
              this.state.activeKey == 3
            )
          }
          if (this.props.handleAfterUpdate) {
            this.props.handleAfterUpdate()
          }
          
        }
      )
      let { data: followupData } = await Request.getAllFollowupsByLeadId({
        // ...params,
        customQuery: { leadId: { $eq: leadId }, active: true },
        regExFilters: ['description'],
      })
      this.setState({ followupData: followupData })
      const abcd={
        _id:leadId
      }
      let newData =await Request.getCheckList(abcd)
      this.setState({
        productCheck:newData.data.product,
        noteCheck:newData.data.note,
        followUpCheck:newData.data.followUp,
        whatsAppCheck:newData.data.whatsApp,
        callAttempt:newData.data.call,
        loader:false
      })

    }
    const setDataNewFunction = async (leadId) => {
      this.setState({
        loader:true
      })
      await Request.getLeadById({ _id: leadId }).then(
        ({ data, error, message }) => {
          if (!error) {
            this.setState({
              leadData: data,
            })

            let found = _.find(this.state.leadStage, (item) => {
              return item.text == data.stage
            })
            if (found) {
              this.setState({ currentStage: found.value })
            } else {
              this.setState({ currentStage: 0 })
            }
            this.props.form.setFieldsValue({
              value: data && data.value ? data.value : '',
              currency: data && data.currency ? data.currency : '',
              closeDate: data && data.closeDate ? moment(data.closeDate) : '',
            })
          } else {
            notification.error({
              message: 'Error Getting Data',
            })
          }
        //   if (this.props.getTodos) {
        //     getTodos(
        //       undefined,
        //       this.props.paginate,
        //       undefined,
        //       this.state.activeKey == 3
        //     )
        //   }
        //   if (this.props.handleAfterUpdate) {
        //     this.props.handleAfterUpdate()
        //   }
          
        }
      )
      let { data: followupData } = await Request.getAllFollowupsByLeadId({
        // ...params,
        customQuery: { leadId: { $eq: leadId }, active: true },
        regExFilters: ['description'],
      })
      this.setState({ followupData: followupData })
      const abcd={
        _id:leadId
      }
      let newData =await Request.getCheckList(abcd)
      this.setState({
        productCheck:newData.data.product,
        noteCheck:newData.data.note,
        followUpCheck:newData.data.followUp,
        whatsAppCheck:newData.data.whatsApp,
        callAttempt:newData.data.call,
        loader:false
      })
      let { data } = await Request.getLeadByActivityType({
        leadId: this.state.leadData && this.state.leadData._id,
        type: 'all',
      })
      this.setState({
        activities: data,
      })

    }
    const getOldLeadByTypes = async (type) => {
      try {
        let oldData = await Request.getOldLeadByActivityType({
          leadId: leadData._id,
          type: type,
        })

        if (user.userType === 'Admin') {
          this.setState({
            activities: oldData.data,
          })
        } else {
          this.setState({
            activities: activities,
          })
        }
      } catch (err) {
        console.log('error', err)
      }
    }
    const getLeadByTypes = async (type, type1, type2) => {
      let selectType

      if (type === 'all' || type === 'updates') {
        selectType = type
      } else {
        if (type && type1 && !type2) {
          selectType = [type, type1]
        } else if (type && type1 && type2) {
          selectType = [type, type1, type2]
        } else {
          selectType = [type]
        }
      }

      try {
        let { data } = await Request.getLeadByActivityType({
          leadId: leadData._id,
          type: selectType,
        })

        this.setState({
          activities: data,
        })
      } catch (err) {
        console.log('error', err)
      }
    }

    const handleCallUnattended = async () => {
      try {
        //check lead Call

        let xx = await Request.checkLeadsCall({
          leadId: this.state.leadData._id,
          isAllow: true,
        })

        if (xx.error) {
          return notification.error({
            message: 'Error Saving',
            description: xx.message,
          })
        }

        //add note
        let note = await Request.saveTextNote({
          message: 'Call not picked',
          leadId: this.state.leadData._id,
        })
        addLeadActivity({
          values: {
            message: `Note Added `,
            leadId: this.state.leadData._id,
            user: user && user.name,
            type: 'notes',
          },
        })

        let body = { _id: this.state.leadData._id }
        this.setState({ ...this.state, callBtnLoader: true })
        //set next followup
        let followup = await handleSmartFollowup(true)
        let x = await Request.callUnattended(body)
        this.setState({ ...this.state, callBtnLoader: false })
        if (!x.error) {
          let msg = `Hello ${
            this.state.leadData && this.state.leadData.contact.firstName
          }, ${
            this.props.currentUser.name
          } from Hindustan Thermostatics this side. \n\n I tried to call you but was unable to reach you. Please call me back when you are free or \n\n I will try to call you again some after sometime`
          setTimeout(async () => {
            await addLeadActivity({
              values: {
                message: `Whatsapp sent to 91${
                  this.state.leadData && this.state.leadData.contact.mobile
                }`,
                whatsAppMessage: msg,
                leadId: this.state.leadData._id,
                user: this.props.currentUser.name,
                type: 'whatsApp',
              },
            })
            window.open(
              `http://api.whatsapp.com/send?phone=91${
                this.state.leadData && this.state.leadData.contact.mobile
              }&text=${msg} `
            )
          }, 1000)
          notification.success({ message: 'Call record Updated' })
        } else {
          notification.error({ message: 'Something went wrong' })
        }
        await getLeadByTypes('all')
        setNewData(this.state.leadData._id)
      } catch (err) {
        notification.error('Something went wrong')
      }
    }

    const handleSmartFollowup = async (val) => {
      try {
        let fromCallBtn = false

        if (val == 'fromCallBtn') {
          fromCallBtn = true
        }

        let body = {
          leadId: this.state.leadData._id,
          userId: user && user._id,
          proposalPresented: true,
        }
        if (val == 'tomorrowFollowup') {
          this.setState({ ...this.state, smartLoader: true })
          fromCallBtn = true

          body.tomorrowFollowup = true
        }
        if (val == 'nextWeekFollowUp') {
          this.setState({ ...this.state, startSnoozeLoader: true })
          fromCallBtn = true

          body.nextWeekFollowup = true
        }
        if (fromCallBtn) {
          body.proposalPresented = false
        }

        let x = await Request.addSmartFollowup(body)
        console.log(x)
        this.setState({
          ...this.state,
          smartLoader: false,
          startSnoozeLoader: false,
        })
        if (!x.error) {
          if (!fromCallBtn) {
            notification.success({ message: 'Follow up Added' })
          }
          await addLeadActivity({
            values: {
              message: `Smart Follow up added`,
              leadId: this.state.leadData._id,
              user: user && user.name,
              type: 'followUpSmart',
            },
          })
        } else if (x.type == 'Booked') {
          this.setState({
            ...this.state,
            smartFollowupModal: true,
          })
        } else {
          return notification.error({
            message: x.message,
          })
        }
        await getLeadByTypes('all')
        setNewData(this.state.leadData._id)
      } catch (err) {
        notification.error('something went wrong')
      }
    }

    let closeDateValue = leadData ? leadData.closeDate : undefined
    const {
      form: { getFieldDecorator, getFieldValue },
    } = this.props

    const onDateChange = async (date, dateString) => {
      let user = JSON.parse(localStorage.getItem('user'))
      let x = await updateTheLead({ closeDate: date })
      if (!x.error) {
        addLeadActivity({
          values: {
            message: `Date changed from ${moment(leadData.closeDate).format(
              'YYYY-MM-DD'
            )} to ${moment(date).format('YYYY-MM-DD')}`,
            leadId: leadData._id,
            user: user && user.name,
            type: 'closeDate',
          },
        })
      }
    }

    const onFinishValueForm = async (e) => {
      e.preventDefault()
      this.props.form.validateFields(async (err, values) => {
        if (!err) {
          if (
            !(values.value || values.currency) ||
            values.value == '' ||
            values.currency == ''
          ) {
            notification.error({
              message: 'Please input both Value and Currency before submitting',
            })
            return
          }
          this.setState({ loaderValueForm: true })
          let x = await updateTheLead(values)
          this.setState({ loaderValueForm: false })
          if (!x.error) {
            if (leadData.value !== values.value)
              addLeadActivity({
                values: {
                  message: `Value changed from ${
                    leadData.value ? leadData.value : 'empty'
                  } to ${values.value}`,
                  leadId: leadData._id,
                  user: user && user.name,
                  type: 'value',
                },
              })
            else
              addLeadActivity({
                values: {
                  message: `Currency changed from ${leadData.currency} to ${values.currency}`,
                  leadId: leadData._id,
                  user: user && user.name,
                  type: 'currency',
                },
              })
          }
        }
      })
    }
    const handleDoneClick =async(id)=>{
      const data={
        _id:id
      }
      const response=await Request.markLeadAsDone(data);
      if(response.message){
        notification.success({
          message: response.message,
        })
        setNewData(id);
      }
    }
    const content = (
      <Form layout="vertical" onSubmit={onFinishValueForm}>
        <Form.Item name="value" label="Value">
          {getFieldDecorator('value', {
            // rules: [{ required: true }]
          })(<Input />)}
        </Form.Item>
        <Form.Item name="currency" label="Currency">
          {getFieldDecorator('currency', {
            // rules: [{ required: true }]
          })(
            <Select placeholder="Select Currency" allowClear>
              <Option value="DOLLAR-$">DOLLAR-$</Option>
              <Option value="INR-₹">INR-₹</Option>
            </Select>
          )}
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={this.state.loaderValueForm}>
            Submit
          </Button>
        </Form.Item>
      </Form>
    )
    const leftInputTypes = {
      fields: [
        {
          label: 'Value',
          key: 'value',
          required: true,
        },
        {
          label: 'Currency',
          key: 'currency',
          required: true,
          type: 'select',
          options: ['DOLLAR-$', 'INR-₹'],
          onChange: (v) => {
            this.props.form.setFieldsValue({ currency: v })
          },
        },
      ],
    }

    const showWonModal = () => {
      this.setState({
        ...this.state,
        win: true,
      })
    }
    const showLostModal = () => {
      this.setState({
        ...this.state,
        lost: true,
      })
    }
    const onCancel = () => {
      this.setState({
        ...this.state,
        lost: false,
        win: false,
      })
    }
    const updateTheLead = async (values) => {
      values._id = this.state.leadData._id
      values.new = false
      let x = await Request.updateLead(values)
      if (!x.error) {
        notification.success({
          message: x.message,
        })
        this.props.form.resetFields()
        this.setState({ ...this.state, popOver: false })
        setNewData(this.state.leadData._id)
        return x
        // this.setState({ activeKey2: "0" });
      } else {
        console.log('err is', x.error)
        notification.error({
          message: 'Error Saving',
          description: x.message,
        })
        return x
      }
    }
    let rightSide

    if (leadData && !leadData.isChild) {
      if (
        (leadData && leadData.status == 'Open') ||
        (leadData && !leadData.status)
        //  &&
        //  (leadData && !leadData.isChild)
      ) {
        rightSide = (
          <div className={styles.headerRight}>
            <Button
              onClick={() => {
                showWonModal()
              }}
              style={{ margin: '4px', background: 'white', color: 'green' }}
              icon="like"
              type="primary"
              size="large">
              Won
            </Button>
            <Button
              hidden={leadData && leadData.isTransferred}
              onClick={() => {
                showLostModal()
              }}
              style={{ margin: '4px', background: 'white', color: 'red' }}
              icon="dislike"
              type="danger"
              size="large">
              Lost
            </Button>
          </div>
        )
      } else {
        rightSide = <div className={styles.headerRight}></div>
      }
    }
    const stageHandler = async (step) => {
      switch (step) {
        case 0:
          let { error } = await updateTheLead({ stage: 'New (Untouched)' })
          if (!error) {
            await addLeadActivity({
              values: {
                message: `Lead staged as "New (Untouched)"`,
                leadId: leadData._id,
                user: user && user.name,
                type: 'stage',
              },
            })
          }
          break
        case 1:
          let x = await updateTheLead({ stage: 'Contacted' })
          if (!x.error) {
            await addLeadActivity({
              values: {
                message: `Lead staged as "Contacted"`,
                leadId: leadData._id,
                user: user && user.name,
                type: 'stage',
              },
            })
          }
          break
        
      }
      getLeadByTypes('all')
    }

    const cancelActivityModal = () => {
      this.setState({
        noteModal: false,
        emailModal: false,
        followUpModal: false,
        dealActivityModal: false,
        whatsAppModal: false,
        textActivityModal: false,
      })
    }

    function truncate(str, n) {
      if (str && str.length) {
        return str.length > n ? str.substr(0, n - 1) + '...' : str
      }
      return ''
    }

    const formattedValue = (value) => {
      let formatted = value ? value.replace(/(\d)(?=(\d\d)+\d$)/g, '$1,') : ''
      return formatted
    }

    const setShowTabs = (value) => {
      this.setState({ showTabs: value })
    }
    const removeHtmlTags = (str)=> {
      return str.replace(/<[^>]+>/g, ' ');
    }

 
    
    return (
      <Spin size="large" spinning={this.state.loader}>
        <div style={{ background: 'white' }}>
          <Row gutter={16} style={{ margin: '0 0 25px 0' }}>
            <Col span={16} className={styles.descriptionTop}>
              <Row>
                <h3
                  style={{
                    fontSize: '22px',
                    fontWeight: '800',
                  }}>
                  {leadData && leadData.title ? (
                    <>
                      <span>
                        <b
                          style={{
                            color: '#686B75',
                            fontWeight: 400,
                          }}>
                          #{leadData.id}
                          {' - '}
                        </b>
                        {leadData.title}{' '}
                        {currentUser && currentUser.userType === 'Admin'
                          ? ROData && ROData.name
                            ? `( ${ROData.name} )`
                            : ''
                          : ''}{' '}
                          <Tooltip title='Edit'>
                            <Icon type="edit" className={styles.editIcon} 
                            onClick={() => {
                              this.setState({
                                ...this.state,
                                cardId: leadData._id,
                                updatelead: true,
                              })
                            }}
                            />
                          </Tooltip>
                          <Tooltip title='Reload'>
                            <Icon
                              type="reload"
                              className={styles.reloadIcon}
                              onClick={async () => {
                                // setNewData(this.state.leadData && this.state.leadData._id)
                                setDataNewFunction(leadData && leadData._id)
                                let { data } = await Request.getLeadByActivityType({
                                  leadId: this.state.leadData && this.state.leadData._id,
                                  type: 'all',
                                })
                                this.setState({
                                  activities: data,
                                })
                              }}
                            />
                          </Tooltip>
                      </span>

                    </>
                  ) : (
                    ''
                  )}
                </h3>
              </Row>
              <Row className={styles.descriptionTopDesc}>
                {leadData && leadData.description ? (
                  <div className={styles.description}>
                    {leadData && leadData.description
                      ? removeHtmlTags(leadData.description)
                      : ''}
                  </div>
                ) : null}
              </Row>
              <Row style={{ padding: '10px 0px' }}>
                <Col span={12}>
                  {leadData &&
                  leadData.contact &&
                  leadData.contact.firstName ? (
                    <Row className={styles.descriptionTopDetails}>
                      <img
                        src={`/test/leads/user.svg`}
                        alt="Icon"
                        className={styles.descriptionTopIcon}
                      />
                      <Tooltip title={leadData.contact.firstName}>
                        {leadData.contact.firstName}
                      </Tooltip>
                    </Row>  
                  ) : null}

                  {leadData && leadData.contact && leadData.contact.email ? (
                    <Row className={styles.descriptionTopDetails}>
                      <img
                        src={`/test/leads/email.svg`}
                        alt="Icon"
                        className={styles.descriptionTopIcon}
                        style={{ margin: '0 12px 4px 0' }}
                      />
                      <Tooltip title={leadData.contact.email}>
                        {leadData.contact.email}
                      </Tooltip>
                    </Row>
                  ) : null}

                  {leadData && leadData.priority ? (
                    <Row className={styles.descriptionTopDetails}>
                      <img
                        src={`/test/leads/priority.svg`}
                        alt="Icon"
                        className={styles.descriptionTopIcon}
                      />
                      <Tooltip title={leadData.priority}>
                        {leadData.priority}
                      </Tooltip>
                    </Row>
                  ) : null}
                </Col>
                <Col span={12}>
                  {leadData && leadData.contact && leadData.contact.mobile ? (
                    <Row className={styles.descriptionTopDetails}>
                      <img
                        src={`/test/leads/phone.svg`}
                        alt="Icon"
                        className={styles.descriptionTopIcon}
                        style={{ height: '15px' }}
                      />
                      <Tooltip title={leadData.contact.mobile}>
                        {leadData.contact.mobile}
                      </Tooltip>
                    </Row>
                  ) : null}

                  {leadData &&
                  leadData.contact &&
                  leadData.contact.companyName ? (
                    <Row className={styles.descriptionTopDetails}>
                      <img
                        src={`/test/leads/people.svg`}
                        alt="Icon"
                        className={styles.descriptionTopIcon}
                        style={{ height: '16px' }}
                      />
                      <Tooltip title={leadData.contact.companyName}>
                        {leadData.contact.companyName}
                      </Tooltip>
                    </Row>
                  ) : null}

                  {leadData && leadData.state ? (
                    <Row className={styles.descriptionTopDetails}>
                      <img
                        src={`/test/leads/building.svg`}
                        alt="Icon"
                        className={styles.descriptionTopIcon}
                        style={{ height: '16px' }}
                      />
                      <Tooltip title={leadData.state}>{leadData.state}</Tooltip>
                    </Row>
                  ) : null}
                </Col>
              </Row>
            </Col>
            {/* <Col
              span={8}
              className={styles.descriptionTop}
              style={{ marginTop: '10px' }}>
              <div className={styles.descriptionTopCard}>
                <p className={styles.descriptionTopCardText}>Value</p>
                <Popover
                  content={content}
                  title="Update Value"
                  trigger="click"
                  className={styles.descriptionTopCardInput}>
                  <a
                    onClick={() => {
                      this.setState({ ...this.state, popOver: true })
                    }}>
                    {leadData && leadData.currency
                      ? `${
                          leadData.currency[leadData.currency.length - 1] +
                          formattedValue(leadData.value)
                        }`
                      : '____'}
                  </a>
                </Popover>
                <p
                  className={styles.descriptionTopCardText}
                  style={{ marginTop: '18px' }}>
                  Estimated Close Date
                </p>
                <DatePicker
                  key={closeDateValue ? 'abc' : 'xyz'}
                  defaultValue={
                    closeDateValue ? moment(closeDateValue) : moment()
                  }
                  onChange={onDateChange}
                />
              </div>
            </Col> */}
            <Col
              span={8}
              className={styles.descriptionTop}
              style={{ marginTop: '10px' }}>
              <div className={styles.wonLostCard}>
                <Button
                  onClick={() => {
                    showWonModal()
                  }}
                  style={{
                    margin: '0 10px 0 0',
                    background: 'white',
                    color: 'green',
                  }}
                  icon="like"
                  type="primary"
                  size="small">
                  Won
                </Button>
                <Button
                  hidden={leadData && leadData.isTransferred}
                  onClick={() => {
                    showLostModal()
                  }}
                  style={{ background: 'white', color: 'red' }}
                  icon="dislike"
                  type="danger"
                  size="small">
                  Lost
                </Button>
            </div>
              <div className={styles.descriptionTopCardList}>
                <div>
                  <div className={styles.bottomMargin}>
                    <span> 1. Product Added</span>
                    {this.state.productCheck ? (
                      // <Icon
                      //   type="check"
                      //   style={{ color: 'green', marginLeft: '8px' }}
                      // />
                      <img
                        src={`/test/leads/accept.png`}
                        alt="Icon"
                        style={{marginLeft: '8px'}}
                        className={styles.checklistIcon}
                      />
                      
                    ) : (
                      // <Icon
                      //   type="close"
                      //   style={{ color: 'black', marginLeft: '8px' }}
                      // />
                      <img
                        src={`/test/leads/delete.png`}
                        alt="Icon"
                        style={{marginLeft: '8px'}}
                        className={styles.checklistIcon}
                      />
                    )}
                  </div>
                  <div className={styles.bottomMargin}>
                    <span>2. Future FollowUp</span>
                    {this.state.followUpCheck  ? (
                     // <Icon
                      //   type="check"
                      //   style={{ color: 'green', marginLeft: '8px' }}
                      // /> 
                      <img
                        src={`/test/leads/accept.png`}
                        alt="Icon"
                        style={{marginLeft: '8px'}}
                        className={styles.checklistIcon }
                      />
                      
                    ) : (
                      // <Icon
                      //   type="close"
                      //   style={{ color: 'black', marginLeft: '8px' }}
                      // />
                      <img
                        src={`/test/leads/delete.png`}
                        alt="Icon"
                        style={{marginLeft: '8px'}}
                        className={styles.checklistIcon}
                      />
                    )}
                  </div>
                  <div className={styles.bottomMargin}>
                    <span>3. Note Added Today</span>
                    {this.state.noteCheck  ? (
                     // <Icon
                      //   type="check"
                      //   style={{ color: 'green', marginLeft: '8px' }}
                      // /> 
                      <img
                        src={`/test/leads/accept.png`}
                        alt="Icon"
                        style={{marginLeft: '8px'}}
                        className={styles.checklistIcon }
                      />
                      
                    ) : (
                      // <Icon
                      //   type="close"
                      //   style={{ color: 'black', marginLeft: '8px' }}
                      // />
                      <img
                        src={`/test/leads/delete.png`}
                        alt="Icon"
                        style={{marginLeft: '8px'}}
                        className={styles.checklistIcon}
                      />
                    )}
                  </div>
                  <div className={styles.bottomMargin}>
                    <span> 4. Call Attempted Today</span>
                    {this.state.callAttempt  ? (
                     // <Icon
                      //   type="check"
                      //   style={{ color: 'green', marginLeft: '8px' }}
                      // /> 
                      <img
                        src={`/test/leads/accept.png`}
                        alt="Icon"
                        style={{marginLeft: '8px'}}
                        className={styles.checklistIcon }
                      />
                      
                    ) : (
                      // <Icon
                      //   type="close"
                      //   style={{ color: 'black', marginLeft: '8px' }}
                      // />
                      <img
                        src={`/test/leads/delete.png`}
                        alt="Icon"
                        style={{marginLeft: '8px'}}
                        className={styles.checklistIcon}
                      />
                    )}
                  </div>
                  <div className={styles.bottomMargin}>
                    <span>5. Whatsapp Send Today</span>
                    {this.state.whatsAppCheck  ? (
                     // <Icon
                      //   type="check"
                      //   style={{ color: 'green', marginLeft: '8px' }}
                      // /> 
                      <img
                        src={`/test/leads/accept.png`}
                        alt="Icon"
                        style={{marginLeft: '8px'}}
                        className={styles.checklistIcon }
                      />
                      
                    ) : (
                      // <Icon
                      //   type="close"
                      //   style={{ color: 'black', marginLeft: '8px' }}
                      // />
                      <img
                        src={`/test/leads/delete.png`}
                        alt="Icon"
                        style={{marginLeft: '8px'}}
                        className={styles.checklistIcon}
                      />
                    )}
                  </div>
                </div>
                <div className={styles.doneButtonCss}>
                  {currentUser && currentUser.userType === 'Admin' ? (
                    <Button
                      style={{ background: '#4bae4f', color: 'white' }}
                      onClick={() => handleDoneClick(this.state.leadId)}>
                      Done
                    </Button>
                  ) : (
                    <Button
                      style={
                        !this.state.productCheck ||
                        !this.state.whatsAppCheck ||
                        !this.state.callAttempt ||
                        !this.state.followUpCheck ||
                        !this.state.noteCheck
                          ? { background: '#e2382f', color: 'white' }
                          : { background: '#4bae4f', color: 'white' }
                      }
                      disabled={
                        !this.state.productCheck ||
                        !this.state.whatsAppCheck ||
                        !this.state.callAttempt ||
                        !this.state.followUpCheck ||
                        !this.state.noteCheck
                      }
                      onClick={() => handleDoneClick(this.state.leadId)}>
                      Done
                    </Button>
                  )}
                </div>
              </div>
            </Col>
          </Row>

          <Row style={{ position: 'relative' }}>
            <div className={styles.stepsBox}></div>
            <div
              className={styles.stepsBoxDir}
              style={{ borderRadius: '5px 0px 0px 5px', left: '8%' }}>
              <Icon
                type={'left'}
                theme="outlined"
                style={{
                  fontSize: '15px',
                }}
              />
            </div>
            <div
              className={styles.stepsBoxDir}
              style={{ borderRadius: '0px 5px 5px 0px', right: '8%' }}>
              <Icon
                type={'right'}
                theme="outlined"
                style={{
                  fontSize: '15px',
                }}
              />
            </div>

            {leadData && !leadData.isChild && (
              <div style={{ margin: '10px 18% 30px 18%' }}>
                <Steps
                  size="small"
                  labelPlacement="vertical"
                  current={currentStage}
                  onChange={(e) => {
                    if (e == 1) {
                      stageHandler(e)
                    }
                    // if (
                    //   e !== 1 &&
                    //   e !== 0 &&
                    //   leadData &&
                    //   !leadData.productList.length
                    // ) {
                    //   this.setState({ visible: true })
                    // }
                  }}>
                  {leadStage &&
                    leadStage.map((item, cc) => {
                      if (cc == 1 && currentStage != 2) {
                        return (
                          <Step
                            title={item.text}
                            key={cc}
                            style={{ whiteSpace: 'nowrap' }}
                          />
                        )
                      } else {
                        return (
                          <Step
                            disabled={true}
                            title={item.text}
                            key={cc}
                            style={{ whiteSpace: 'nowrap' }}
                          />
                        )
                      }
                      // if (user && user.userType && user.userType !== 'Admin') {
                      // } else {
                      // }
                    })}
                </Steps>
              </div>
            )}
          </Row>

          <Col span={24} className="gutter-row" style={{ background: 'white' }}>
            <Row>
              <Col>
                <Card style={{ backgroundColor: '#F4F6FA', margin: '20px' }}>
                  {/* <div style={{ marginBottom: '10px' }}></div> */}
                  {leadData && leadData.isChild && (
                    <>
                      <h2>This is a Child Lead</h2>
                      <h2>Click here to view Parent Lead</h2>
                      <Button type="primary">
                        <Link
                          to={`/lead/detail?id=${this.state.parentLead._id}`}
                          target="_blank">
                          Click Here To view
                        </Link>
                      </Button>
                    </>
                  )}

                  {leadData && !leadData.isChild && (
                    <>
                      <Collapse
                        activeKey={this.state.productActiveKey}
                        accordion
                        onChange={() => {
                          this.setState({ productActiveKey: '1' })
                          if (this.state.productActiveKey === '1') {
                            this.setState({ productActiveKey: '2' })
                          }
                        }}>
                        <Panel key="1" header={'Add Product'}>
                          <LeadsProduct
                            leadId={this.state.leadId}
                            otherProduct={leadData && leadData.otherProduct}
                            selectedProducts={leadData && leadData.productList}
                            setShowTabs={setShowTabs}
                            formResponse={async () => {
                              this.setState(
                                {
                                  visible: false,
                                  productActiveKey: '2',
                                },
                                async () => {
                                  setDataNewFunction(leadData && leadData._id)
                                  const data={
                                    _id:this.state.leadId
                                  }
                                  let newData =await Request.getCheckList(data)
                                    this.setState({
                                      productCheck:newData.data.product,
                                      noteCheck:newData.data.note,
                                      followUpCheck:newData.data.followUp,
                                      whatsAppCheck:newData.data.whatsApp,
                                      callAttempt:newData.data.call
                                    })
                                    window.scrollTo(0,0);
                                }
                              )
                            }}
                          />
                        </Panel>
                      </Collapse>

                      {/* {this.state.showTabs ? ( */}
                        <TabsComp
                          leadData={leadData}
                          setNewData={setDataNewFunction}
                          setNewDataReloadPage={setNewData}
                          user={user}
                          getLeadByTypes={getLeadByTypes}
                          updateTheLead={updateTheLead}
                          handleTabSubmit={async () => {
                            const data={
                              _id:this.state.leadId
                            }
                            let newData =await Request.getCheckList(data)
                              this.setState({
                                productCheck:newData.data.product,
                                noteCheck:newData.data.note,
                                followUpCheck:newData.data.followUp,
                                whatsAppCheck:newData.data.whatsApp,
                                callAttempt:newData.data.call,
                                showTabs:false,
                              })
                          }}
                          
                        />
                      {/* ) : (
                        <p style={{ marginTop: '10px' }}>
                          <b>Please select a product to proceed further.</b>
                        </p>
                      )} */}

                      {/* {this.state.productActiveKey === '2' && (
                        <>
                          <Tabs
                            style={{ overflow: 'unset' }}
                            type="line"
                            activeKey={this.state.activeKey}
                            defaultActiveKey="0"
                            onTabClick={(key) => {
                              this.setState({ activeKey: key }, () => {
                                // if (key == '2') {
                                //   this.setState({ renderActivity: Date.now() })
                                // }
                                // if (key == '3') {
                                //   this.setState({ renderFollowup: Date.now() })
                                // }
                              })
                            }}>
                            <TabPane tab={'Note'} key={0}>
                              <AddNote
                                hideTitle={true}
                                hideSubmit={true}
                                leadId={leadData && leadData._id}
                                notesData={
                                  leadData && leadData.notes
                                    ? leadData.notes
                                    : []
                                }
                                handleAddNote={async () => {
                                  await addLeadActivity({
                                    values: {
                                      message: `Note Added`,
                                      leadId: leadData._id,
                                      user: user && user.name,
                                      type: 'notes',
                                    },
                                  })
                                  await getLeadByTypes('all')

                                  setNewData(leadData._id)
                                  this.setState({ activeKey2: '0' })
                                }}
                              />
                            </TabPane>
                            <TabPane tab={'Email'} key={1}>
                              <AddEmail
                                hideTitle={true}
                                hideSubmit={true}
                                leadId={leadData && leadData._id}
                                emailsData={
                                  leadData && leadData.emails
                                    ? leadData.emails
                                    : []
                                }
                                leadData={{ ...leadData, emails: null }}
                                handleAddEmail={async () => {
                                  await getLeadByTypes('all')
                                  setNewData(leadData._id)
                                  this.setState({ activeKey2: '0' })
                                }}
                              />
                            </TabPane>
                            <TabPane tab={'Activity'} key={2}>
                              <AddActivity
                                hideTitle={true}
                                hideSubmit={true}
                                leadId={leadData && leadData._id}
                                key={this.state.renderActivity}
                                handleAddActivity={async () => {
                                  await addLeadActivity({
                                    values: {
                                      message: `Activity Added`,
                                      leadId: leadData._id,
                                      user: user && user.name,
                                      type: 'activity',
                                    },
                                  })
                                  await getLeadByTypes('all')
                                  setNewData(leadData._id)
                                  this.setState({ activeKey2: '0' })
                                }}
                              />
                            </TabPane>
                            {leadData &&
                            leadData.stage == 'New (Untouched)' ? null : (
                              <TabPane tab={'FollowUp'} key={3}>
                                <AddFollowup
                                  hideTitle={true}
                                  hideSubmit={true}
                                  leadId={leadData && leadData._id}
                                  stage={leadData && leadData.stage}
                                  user={user}
                                  key={this.state.renderFollowup}
                                  handleAddFollowup={async () => {
                                    this.setState({ activeKey2: '0' })
                                    await getLeadByTypes('all')
                                    setNewData(leadData._id)
                                  }}
                                />
                              </TabPane>
                            )}
                            <TabPane tab={'File'} key={4}>
                              <AttachFile
                                hideTitle={true}
                                hideSubmit={true}
                                leadId={leadData && leadData._id}
                                filesData={
                                  leadData && leadData.files
                                    ? leadData.files
                                    : []
                                }
                                handleAttachFile={async () => {
                                  this.setState({ activeKey2: '0' })
                                  await addLeadActivity({
                                    values: {
                                      message: `File attached`,
                                      leadId: leadData._id,
                                      user: user && user.name,
                                      type: 'file',
                                    },
                                  })
                                  await getLeadByTypes('all')
                                  setNewData(leadData._id)
                                }}
                              />
                            </TabPane>
                          </Tabs>
                          <Button
                            type="primary"
                            style={{ marginTop: '5px' }}
                            onClick={() => {
                              // this.setState({
                              //   submitNote: true,
                              //   submitEmail: true,
                              //   submitActivity: true,
                              //   submitFollowUp: true,
                              //   submitAttachFile: true,
                              // })
                            }}>
                            Submit
                          </Button>
                        </>
                      )} */}
                    </>
                  )}
                </Card>
              </Col>
            </Row>

            <Row>
              <Col>
                <Card style={{ marginTop: '30px' }}>
                  <Tabs
                    type="line"
                    activeKey={this.state.activeKey2}
                    defaultActiveKey="0"
                    onTabClick={(key) => {
                      this.setState({ activeKey2: key }, async () => {
                        if (key == '7') await getOldLeadByTypes('old')
                        if (key == '0') await getLeadByTypes('all')
                        if (key == '1') {
                          await getLeadByTypes('notes')
                        }
                        if (key == '2') {
                          await getLeadByTypes('email', 'message', 'whatsApp')
                        }
                        if (key == '3') {
                          await getLeadByTypes('followUp')
                        }
                        if (key == '4') {
                          await getLeadByTypes('file')
                        }
                        if (key == '5') {
                          await getLeadByTypes('updates')
                        }
                        if (key == '6') {
                          await getLeadByTypes('callLog', 'recording')
                        }
                      })
                    }}>
                    {user.userType === 'Admin' && (
                      <TabPane tab={'Old Activities'} key={7}>
                        <RenderActivities renderKey="7" data={activities} />
                      </TabPane>
                    )}

                    <TabPane tab={'All Activities'} key={0}>
                      <RenderActivities renderKey="0" data={activities} />
                    </TabPane>
                    <TabPane tab={'Notes'} key={1}>
                      <RenderActivities renderKey="1" data={activities} />
                    </TabPane>
                    <TabPane tab={'Communication'} key={2}>
                      <RenderActivities renderKey="2" data={activities} />
                    </TabPane>
                    <TabPane tab={'FollowUp'} key={3}>
                      <RenderActivities renderKey="3" data={activities} />
                    </TabPane>
                    <TabPane tab={'File'} key={4}>
                      <RenderActivities renderKey="4" data={activities} />
                    </TabPane>
                    <TabPane tab={'Updates'} key={5}>
                      <RenderActivities renderKey="5" data={activities} />
                    </TabPane>
                    <TabPane tab={'Call'} key={6}>
                      <RenderActivities renderKey="6" data={activities} />
                    </TabPane>
                  </Tabs>
                </Card>
              </Col>
            </Row>
          </Col>
          <Drawer
          title=""
          placement="right"
          closable={true}
          onClose={async () => {
            this.setState({
              ...this.state,
              updatelead: false,
              cardId: '',
            })
          }}
          visible={this.state.updatelead}
          width={800}>
          <UpdateLead
            cardId={this.state.cardId}
            leadOwner={currentUser}
            key={this.state.drawerKey ? this.state.drawerKey : Date.now()}
            handleUpdateLead={() => {
              this.setState({ updatelead: false, cardId: '',isLeadUpdate:true })
              this.apiRequest()
            }}
          />
        </Drawer>

          {this.state.win &&
            WinModal({
              getFieldDecorator,
              onCancel,
              updateTheLead,
              state: { ...this.state },
              form: { ...this.props.form },
            })}
          {this.state.lost &&
            LostModal({
              getFieldDecorator,
              onCancel,
              updateTheLead,
              state: { ...this.state },
              form: { ...this.props.form },
            })}
          {this.state.noteModal &&
            NoteActivityModal({
              getFieldDecorator,
              cancelActivityModal,
              updateTheLead,
              state: { ...this.state },
            })}
          {this.state.emailModal &&
            EmailActivityModal({
              getFieldDecorator,
              cancelActivityModal,
              updateTheLead,
              state: { ...this.state },
            })}
          {this.state.followUpModal &&
            FollowUpActivityModal({
              getFieldDecorator,
              cancelActivityModal,
              updateTheLead,
              state: { ...this.state },
            })}
          {this.state.dealActivityModal &&
            DealActivityModal({
              getFieldDecorator,
              cancelActivityModal,
              updateTheLead,
              state: { ...this.state },
            })}
          {this.state.whatsAppModal &&
            WhatsAppActivityModal({
              getFieldDecorator,
              cancelActivityModal,
              updateTheLead,
              state: { ...this.state },
            })}
          {this.state.textActivityModal &&
            TextActivityModal({
              getFieldDecorator,
              cancelActivityModal,
              updateTheLead,
              state: { ...this.state },
            })}

          <Modal
            visible={this.state.smartFollowupModal}
            onCancel={this.handleCancel}
            // key={this.state.key}
            footer={false}>
            <div style={{ marginLeft: 20, marginRight: 20 }}>
              <Row>
                <Col>
                  <h3>
                    No Time slot available for the next day. Please choose
                    manually
                  </h3>
                  <AddSmartFollowup
                    hideTitle={true}
                    leadId={leadData && leadData._id}
                    stage={leadData && leadData.stage}
                    user={user}
                    key={this.state.renderFollowup}
                    handleAddSmartFollowup={async () => {
                      await addLeadActivity({
                        values: {
                          message: `Follow up added`,
                          leadId: this.state.leadData._id,
                          user: user && user.name,
                          type: 'followup',
                        },
                      })
                      await getLeadByTypes('all')
                      this.setState({
                        ...this.state,
                        smartFollowupModal: false,
                      })
                      setNewData(leadData._id)
                    }}
                  />
                </Col>
              </Row>
            </div>
          </Modal>
        </div>
      </Spin>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  search: router.location.search,
  currentUser: global.currentUser,
})
const mapDispatchToProps = (dispatch) => {
  return {
    dispatch,
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewLead)
