import React, {Component} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {TableComp, FormUtils as GetAllFormFields} from 'sz-react-utils'
import moment from 'moment'
import {
    Table,
    Badge,
    Card,
    Tooltip,
    Popconfirm,
    notification,
    Switch,
    Input, Button, Icon,
    Form,
    Drawer, Row, Col, Tag, AutoComplete, Spin
} from 'antd'
import Request from '../../../request'
import Color from 'color'
import _ from 'lodash'
import Highlighter from 'react-highlight-words'
import styles from './styles.less'
import {connect} from 'react-redux'
import memoizeOne from 'memoize-one'
import update from 'immutability-helper'
import {getUrlPushWrapper} from '../../../routes'
import {hideLoader, showLoader} from '../../../modules/actions'
import {Select} from "antd/lib/index";

const {Option} = Select

@Form.create()
class AllDispatchList extends Component {

    delete = async data => {
        this.setState({loading: true})
        let x = await Request.deleteDispatch(data)
        this.setState({loading: false})

        this.table.current.reload()
        if (!x.error) {
            notification.success({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })

        }
        else {
            notification.error({
                message: x.message,
                duration: 20,
                key: `${data._id}-close`
            })
        }


    }


    apiRequest = (params) => {
        let {contactNameId} = this.state
        if (params.page) {
            this.state.currentPage = params.page
        }

        return new Promise(async (resolve) => {
            params.sortField = 'createdAt'
            params.sortOrder = 'desc'
            if (contactNameId) {
                params.contact = contactNameId
            }

            let data = await Request.dispatchFormList({
                ...params, regExFilters: ['trackingNumber']
            })
            this.setState({count: data.total})

            resolve(data)
        })
    }
    showDrawer = () => {
        this.setState({
            visible: true,
            key: Date.now(),
        })
    }

    constructor(props) {
        super(props)
        this.table = React.createRef()
        this.state = {
            currentPage: 1,
            visible: false,
            userType: ['Inward', 'Outward'],
            allContacts: [],
            ROList: [],
            carrier: ['Fedex', 'DHL', 'UPS', 'USPS', 'BlueDart', 'DTDC']

        }
        this.searchContact = _.debounce(this.handleContactSearch, 1000, true)

    }

    componentDidMount() {
        Request.loadUser({userType: ['RO', 'Dispatch']}).then(({data: userList}) => {
            this.setState({RoList: userList || []})
        })
        Request.getAllContacts({results: 10}).then(({data: allContacts}) => {
            this.setState({allContacts: allContacts || []})
        })

    }

    handleContactSearch = async (test) => {
        // this.setState({​​​​​​​​showSpin: true}​​​​​​​​)
        this.setState({selectedContact: test})
        if (test == '') {
            this.setState({
                allContacts: this.state.allContacts,
                selectedContact: test,
                contactNameId: null
            }, () => this.table.current.reload())
            //   , () => {
            //   this.setState({
            //     showSpin: false,
            //   })
            // })
            return false
        }
        let {data, error, message} = await Request.contactSearch({text: test})
        if (!error) {
            this.setState({allContacts: data}, () => {
                this.setState({showSpin: false})
            })
        }
    }

    onContactSelect = (val) => {
        let selectedContact = this.state.allContacts.find((obj) => obj._id === val)
        if (selectedContact) {
            this.setState({
                contactNameId: selectedContact._id,
                selectedContact: `${selectedContact.firstName} (${selectedContact.mobile})`
            }, () => {
                this.table.current.reload()
            })
        }
    }

    render() {
        const {dispatch} = this.props

        let {singleRecord, userType, currentPage, RoList, allContacts, carrier} = this.state
        const RofilterArray = RoList && RoList.map((user) => {
            return {
                text: user.name,
                value: user._id
            }
        });

        const columns = [
            {
                title: 'S.No',
                dataIndex: 'sno',
                width: 50,
                key: 'sno',
                fixed: 'left',
                render: (value, item, index) => (currentPage - 1) * 10 + index + 1
            },
            {
                title: 'Contact Name',
                key: 'contact',
                render: (val, record) => {
                    return (<div>
                        {record.contact ? record.contact.firstName : ''} {record.contact && record.contact.lastName ? record.contact.lastName : ''}<br/>
                        {record.contact && record.contact.mobile ? record.contact.mobile : ''}
                    </div>)
                }

            },
            {
                title: 'Checked By',
                key: 'checkedBy',
                // width: 150,
                filters: RofilterArray,
                render: (val, record) => {
                    return (<div>
                        <div>{record.checkedBy ? record.checkedBy.name : ''}</div>
                        <div>{record.checkedBy ? record.checkedBy.email : ''}</div>
                        <div>{record.checkedBy ? record.checkedBy.mobile : ''}</div>
                    </div>)
                }

            },
            {
                title: 'Packed By',
                key: 'packedBy',
                // width: 150,
                filters: RofilterArray,
                render: (val, record) => {
                    return (<div>
                        <div>{record.packedBy ? record.packedBy.name : ''}</div>
                        <div>{record.packedBy ? record.packedBy.email : ''}</div>
                        <div>{record.packedBy ? record.packedBy.mobile : ''}</div>
                    </div>)
                }

            },


            {
                title: 'Dispatch Date',
                dataIndex: 'dispatchDate',
                key: 'dispatchDate',
                width: 80,
                searchDateName: true,
                render: (text, record) => {
                    return (
                        <Tag>
                            {text ? moment(text).format('lll') : ''}
                        </Tag>
                    )
                },
                sorter: true
            },
            {
                title: 'Shipping Address',
                dataIndex: 'shippingAddress',
                searchTextName: 'state/city/pincode/address',
                key: 'shippingAddress',
                render: (val, record) => {
                    let arr = []
                    if (record && record.shippingAddress) {
                        if (record.shippingAddress && record.shippingAddress.addressLine1) {
                            arr.push(record.shippingAddress.addressLine1)
                        }
                        if (record.shippingAddress && record.shippingAddress.addressLine2) {
                            arr.push(record.shippingAddress.addressLine2)
                        }
                        if (record.shippingAddress && record.shippingAddress.state) {
                            arr.push(record.shippingAddress.state)
                        }
                        if (record.shippingAddress && record.shippingAddress.city) {
                            arr.push(record.shippingAddress.city)
                        }
                        if (record.shippingAddress && record.shippingAddress.zipcode) {
                            arr.push(record.shippingAddress.zipcode)
                        }
                        if (record.shippingAddress && record.shippingAddress.country) {
                            arr.push(record.shippingAddress.country)
                        }
                    }

                    return (<div>
                        {arr.join(', ')}
                    </div>)

                }
            },

            {
                title: 'Tracking Number',
                key: 'trackingData',
                searchTextName: 'trackingData',
                width: 150,
                render: (item, record) => {
                    return _.map(item, (ii) => {
                        return <div>
                            <div>Courier Name {ii && ii.courierName}</div>
                            <div>Tracking Number {ii && ii.trackingNumber}</div>
                        </div>
                    })
                }
            },
            {
                title: 'No of Boxes',
                dataIndex: 'noOfBoxes',
                width: 100,
                key: 'noOfBoxes',
            },
            {
                title: 'Carrier',
                dataIndex: 'carrier',
                width: 100,
                key: 'carrier'
            },

            {
                key: 'actions',
                title: 'Actions',
                fixed: 'right',
                width: 80,
                render: (val, record) => {
                    return (<React.Fragment>
                        <Tooltip title="Edit Dispatch">
                            <Button
                                shape="circle"
                                style={{marginRight: 6}}
                                size="small"
                                onClick={() => {
                                    dispatch(
                                        getUrlPushWrapper('dispatch.editDispatchForm', {
                                            id: record._id
                                        })
                                    )

                                }}
                                icon="edit"
                            />
                        </Tooltip>
                    </React.Fragment>)
                }
            }

        ]
        return (
            <PageHeaderWrapper
                title={this.state.count ? `Dispatch List: ${this.state.count}` : `Dispatch List: 0`}>

                <Card bordered={true}>
                    <Row>
                        <Col>
                            <AutoComplete
                                value={this.state.selectedContact}
                                notFoundContent={this.state.showSpin && <Spin/>}
                                autoComplete={'none'}
                                onSelect={this.onContactSelect}
                                onSearch={this.searchContact}
                                onChange={(val) => {
                                    this.setState({selectedContact: val})
                                }}
                                placeholder={'Search Contact'}
                            >{allContacts && allContacts.map((val, item) => (
                                <Option value={val._id}>
                                    <>
                                        <div><b>{`${val.firstName} (${val.mobile})`}</b></div>
                                    </>
                                </Option>
                            ))}

                            </AutoComplete>
                        </Col>
                    </Row>
                    <TableComp ref={this.table}
                               columns={columns}
                               apiRequest={this.apiRequest}
                               extraProps={{scroll: {x: 1200}}}
                               pagination={{
                                   ...this.state.pagination,
                                   defaultPageSize: 10,
                                   pageSizeOptions: ['10', '25', '50', '100'],
                                   showSizeChanger: true,
                                   ...this.props.pagination
                               }}/>
                </Card>
                <Drawer
                    title=""
                    placement="right"
                    closable={true}
                    onClose={() => {
                        this.setState({
                            visible: false,
                        })
                    }}
                    visible={this.state.visible}
                    width={600}>
                    <Card className={styles.drawerCard}>


                    </Card>

                </Drawer>

            </PageHeaderWrapper>)

    }
}


const mapStateToProps = ({global}) => ({
    categories: global.categories,
    loading: global.buttonLoading,

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}


export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AllDispatchList)
