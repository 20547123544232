import React, { PureComponent } from 'react'
import {
  Divider,
  Button, Input,
  Card, Form, Select, Upload, Icon
} from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { notification } from 'antd/lib/index'
import Request, { API_URL } from '../../../request'
import { connect } from 'react-redux'
import { complaintStatus } from '../../../request'

const { Option } = Select
const { TextArea } = Input

class AddImage extends PureComponent {

  saveMedia = async () => {
    let { setFiles } = this.state
    let { handleImage, complaintId } = this.props

    if (!setFiles.length) {
      return notification.error({ message: 'Select 1 file.' })

    }

    this.setState({ statusLoader: true })

    let x = await Request.updateComplaint({ images: setFiles, _id: complaintId })
    this.setState({ statusLoader: false })

    if (!x.error) {
      notification.success({
        message: 'Upload Successfully'
      })
      if (handleImage) {
        handleImage()
      }
      this.setState({ setFiles: [] })

    } else {
      notification.error({
        message: 'Error Saving',
        description: x.message
      })
    }

  }


  constructor(props) {
    super(props)
    this.state = { statusLoader: false, setFiles: [] }
  }

  handleFileChange = v => {
    if (v.fileList) {
      this.setState({ setFiles: v.fileList })
    }
  }

  handlePreview = async (file) => {
    let url
    if (file && file.response && file.response.path) {
      file.response.path = file.response.path.replace(/^public/, '')
      url = `${API_URL}/${file.response.path}`
    } else {
      url = `${file.url}`
    }

    window.open(url, '_blank')
  }

  async componentDidMount() {
  }

  render() {

    const { isCommentModal, onCancel } = this.props
    let { setFiles, statusLoader } = this.state

    return (
      <React.Fragment>
        <Form layout='inline'>

          <Form.Item label={'Upload File'}>
            <Upload
              multiple={true}
              // name={'file'}
              action={`${API_URL}/filesUploader`}
              onChange={(v) => {
                if (v.fileList) {
                  this.setState({ setFiles: v.fileList })
                }
              }}
              onPreview={this.handlePreview}
             // fileList={setFiles}
            >

              <Button>
                <Icon type="upload" /> Upload
              </Button>


            </Upload>


          </Form.Item>

          <div style={{ textAlign: 'right', marginTop: '20px', width: '90%' }}>

            <Button onClick={() => {
              onCancel()
              this.setState({ setFiles: [] })
            }}>
              CANCEL
            </Button>
            <Button style={{ marginLeft: '10px' }} type="primary" onClick={() => {
              this.saveMedia()
            }} loading={statusLoader}>
              SAVE
            </Button>

          </div>
        </Form>


      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddImage)
