import React, { PureComponent } from 'react'
import {
  Divider,
  Button, List,
  Card, Drawer, Timeline
} from 'antd'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { connect } from 'react-redux'
import moment from 'moment'
import _ from 'lodash'
import { ComplaintActivityModal } from '../../lead/all/helpers'

function truncate(str, n) {
  if (str && str.length) {
    return (str.length > n) ? str.substr(0, n - 1) + '...' : str
  }
  return ''
}

class ViewComments extends PureComponent {

  constructor(props) {
    super(props)
    this.state = { complaintModal: false }
  }

  async componentDidMount() {
  }

  render() {
    const cancelActivityModal = () => {
      this.setState({
        complaintModal: false
      })
    }

    const { isCommentDrawer, statusLogsData, commentData } = this.props
    return (
      <React.Fragment>
        {this.state.complaintModal && ComplaintActivityModal(
          { cancelActivityModal, state: { ...this.state } })}
        <Card>

          {isCommentDrawer ? <Timeline mode={'left'}>
            {_.map(commentData, (val, key) => {
              return (
                <Timeline.Item key={key}><a onClick={() => {
                  this.setState({ complaintModal: true, complaintActivity: val })
                }}> {truncate(val.message, 24)} by <b>{val.user}</b> on <b>{moment(val.time).format('lll')}</b></a>
                </Timeline.Item>
              )
            })}
          </Timeline> : <Timeline mode={'left'}>
            {_.map(statusLogsData, (val, key) => {
              return (
                <Timeline.Item key={key}><a onClick={() => {
                }}> {val.message} by <b>{val.user}</b> on <b>{moment(val.time).format('lll')}</b></a>
                </Timeline.Item>
              )
            })}
          </Timeline>}


        </Card>
      </React.Fragment>
    )
  }
}

const mapStateToProps = ({ global, router }) => ({
  loading: global.buttonLoading,
  categories: global.categories,
  pathname: router.location.pathname

})
const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ViewComments)
