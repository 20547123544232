import React, {PureComponent} from 'react'
import PageHeaderWrapper from '../../../components/PageHeaderWrapper'
import {
    Form,
    Input,
    notification,
    Anchor,
    Spin,
    Select,
    Tag,
    Button,
    Table,
    Card,
    Typography,
    Row,
    Col, Tooltip, Popconfirm, Checkbox, AutoComplete, Radio, Timeline, Drawer
} from 'antd'
import _ from 'lodash'
import {FormUtils as GetAllFormFields, TableComp} from 'sz-react-utils'
import moment from 'moment'


import {API_URL} from '../../../request'

import 'react-quill/dist/quill.snow.css'
import {hideLoader, showLoader} from '../../../modules/actions'
import Request from '../../../request'
import styles from '../all/styles.less'
import {connect} from 'react-redux'
import {createMatchSelector, goBack} from 'connected-react-router'
import {getUrlPushWrapper} from "../../../routes";

const FormItem = Form.Item
const {Option} = Select
const {Text, Paragraph} = Typography;
const {TextArea} = Input

@Form.create()
class AddInwardForm extends PureComponent {

    handleSubmit = e => {
        const {dispatch, form, currentUser} = this.props
        let {totalData, selectedRowKeys} = this.state

        e.preventDefault()
        form.validateFieldsAndScroll(async (err, valData) => {
            if (!err) {
                let values = _.clone(valData)

                if (values.purpose !== 'Replacement') {
                    values.replacementType = ''
                    values.replacementList = []
                }

                if (values.replacementType === 'Outward Pending') {
                    values.replacementList = []
                }

                if (totalData && totalData.length == 1) {
                    this.setState({sendLoading: false})
                    return notification.error({
                        message: 'Atleast add one product'
                    })
                }


                let foundFlag = false

                let tempArr = []
                let errorIds = []

                _.each(this.state.totalData, (item) => {

                    if (item.productIds && item.productIds.length != item.quantityAccepted) {
                        foundFlag = true
                    }
                    _.each(item.productIds, (val) => {
                        if (!tempArr.includes(val)) {
                            tempArr.push(val)

                        } else {
                            errorIds.push(val)

                        }
                    })


                })


                if (errorIds.length) {
                    return notification.error({message: `Duplicate Product Ids ${errorIds}.`})

                }
              

                totalData = _.reject(this.state.totalData, (x) => x.isLast == true)
                values.products = totalData
                values.type = 'Inward'
                dispatch(showLoader())

                let x = null
                if (this.state._id) {
                    values._id = this.state._id

                    x = await Request.updateInwardOutwardForm(values)
                } else {
                    x = await Request.addInwardOutwardForm(values)
                }

                dispatch(hideLoader())


                if (!x.error) {

                    notification.success({
                        message: this.state._id
                            ? x.message
                            : x.message
                    })

                    if (!this.state._id) {

                        this.props.form.resetFields()
                        this.props.form.setFieldsValue({date: moment(), filledBy: currentUser && currentUser._id})

                        Request.getNextInwardOutwardFormNo({type: 'Inward'}).then((data) => {
                            if (!data.error) {
                                this.props.form.setFieldsValue({serialNumber: data.data})
                            }
                        })
                        this.setState({
                            totalData: [{isLast: true}],
                            replacementType: undefined,
                            purposeValue: undefined,
                            selectedRowKeys: []
                        })
                        this.loadInwardPendingList()
                    }

                    if (this.state._id) {
                        dispatch(goBack())

                    }


                } else {
                    notification.error({
                        message: 'Error Saving',
                        description: x.message
                    })
                }

            }
        })
    }

    constructor(props) {
        super(props)
        this.state = {
            visible: false,
            loader: true,
            productList: [],
            totalData: [],
            carriersData: ['Fedex', 'DHL', 'UPS', 'USPS', 'BlueDart', 'DTDC'],
            inwardPendingList: [],
            checked: true,
            newOption:null,
            selectedRowKeys: [],
            currentPage: 1,

        }
        this.AddData = _.debounce(this.addData, 1000, true)


    }


    handleCancel = e => {

        this.props.form.setFieldsValue({
            quantity: null,
            productName: undefined,
        })
        this.setState({
            modalVisible: false,
            productName: '',
            quantity: ''


        })
    }


    addData = () => {


        let tt = _.cloneDeep(this.state.totalData)
        let found = _.find(tt, (i) => {
            if (i && i.isLast != true) {
                return (i.productName == this.state.productName) || (i.productName._id == this.state.productName)

            }
        })

        if (found) {
            this.setState({showText: false})
            this.props.form.setFieldsValue({
                productName: undefined,
                quantity: null,

            })

            return notification.error({
                message: 'Product Already Exists.'
            })
        }
        let comp = tt.pop()
        tt.push({

            quantityRecieved: this.state.quantityRecieved,
            quantityAccepted: this.state.quantityAccepted,
            quantityRejected: this.state.quantityRejected,
            productName: this.state.productName,
            isLast: false,
            productIds: [],
            subjectedToStock: this.state.subjectedToStock ? this.state.subjectedToStock : false,
            productId: this.state.productId

        })
        tt.push(comp)
        this.props.form.setFieldsValue({
            quantityRejected: null,
            quantityAccepted: null,
            totalData: tt,
            productName: undefined,
            quantityRecieved: null,

        })
        this.setState({
            quantityRejected: null,
            quantityAccepted: null,
            totalData: tt,
            productName: undefined,
            quantityRecieved: null,
            subjectedToStock: undefined,
            productId: null

        }, () => {

        })

    }
    handleProductSearch = async (test) => {
        if (test == '' || test == undefined) {
            this.setState({productList: this.state.productList})

            return false
        } else {
            let tt = _.cloneDeep(this.state.totalData)

            this.setState({
                quantityRejected: 0,
                quantityAccepted: 1,
                totalData: tt,
                productName: test,
                quantityRecieved: 1,


            }, () => {
                this.addData()
            })
        }


    }

    onProductSelect = (val) => {
        let selectedQuote = this.state.productList.find((obj) => obj._id === val)
        if (selectedQuote) {
            this.props.form.setFieldsValue({
                productName: selectedQuote._id,

            })
            if (this.state.subjectedToStock) {
                this.setState({productId: selectedQuote && selectedQuote._id})
            }

            this.setState({
                quantityRejected: 0,
                quantityAccepted: 1,
                productName: selectedQuote._id,
                quantityRecieved: 1

            }, () => {
                this.addData()
            })
        }
        else{
            this.handleProductSearch(val)
        }

    }
    showDrawer = () => {
        this.setState({
            visible: true,
            key: Date.now()
        })
    }

    getOptionsData = async () => {
        let currentUser = JSON.parse(window.localStorage.getItem('user'))

        let filter = {
            userType: {$in: ['Admin', 'RO', 'Dispatch', 'Technician', 'Lead Executive', 'Caller']},
            results: 10000
        }
        this.props.form.setFieldsValue({
            filledBy: currentUser._id
        })

        Request.getAllHistoryProducts({
            results: 2000
        }).then((data) => {
            this.setState({productList: data || []}, () => {

            })
        })
        Request.loadUser(filter).then(({data: userList}) => {

            this.setState({userList: userList || []})
        })


    }
    loadInwardPendingList = async () => {

        Request.inwardOutwardListDispatch({
            replacementType: 'Inward Pending',
            purpose: 'Replacement',
            results: 100000000000000000
        }).then(({data}) => {

            this.setState({inwardPendingList: data || []})
        })

    }

    async componentDidMount() {
        let searchParams = new URLSearchParams(this.props.search);
        let dispatchId = searchParams.get('id')

        this.getOptionsData()

        if (dispatchId) {

            Request.getDispatchInwardOutwardById({_id: dispatchId}).then(({data: dispatchData, error, message}) => {
                if (!error) {
                    let selectedInwards = _.map(dispatchData.replacementList, (ii) => {
                        return ii._id
                    })

                    this.setState({
                        _id: dispatchData && dispatchData._id,
                        loader: false,
                        totalData: [...dispatchData.products, {isLast: true}],
                        replacementType: dispatchData.replacementType,
                        purposeValue: dispatchData && dispatchData.purpose,
                        selectedRowKeys: selectedInwards,
                        inwardPendingList: dispatchData.replacementList

                    })
                    this.props.form.setFieldsValue({

                        serialNumber: dispatchData && dispatchData.serialNumber,
                        department: dispatchData && dispatchData.department,
                        productType: dispatchData && dispatchData.productType,
                        challanNumber: dispatchData && dispatchData.challanNumber,
                        filledBy: dispatchData && dispatchData.filledBy,
                        checkedBy: dispatchData && dispatchData.checkedBy,
                        date: dispatchData && moment(dispatchData.date),
                        purpose: dispatchData && dispatchData.purpose,
                        carrier: dispatchData && dispatchData.carrier,
                        carrierDetails: dispatchData && dispatchData.carrierDetails,
                        replacementType: dispatchData.replacementType,
                        replacementList: dispatchData.replacementList

                    });
                } else {
                    this.setState({
                        loader: false
                    })
                    notification.error({
                        message: 'Error Getting Data'
                    })
                }
            })
        } else {
            Request.getNextInwardOutwardFormNo({type: 'Inward'}).then((data) => {
                if (!data.error) {
                    this.props.form.setFieldsValue({serialNumber: data.data, date: moment()})
                }
            })
            this.loadInwardPendingList()
            this.setState({loader: false})
            this.state.totalData.push({isLast: true})

        }


    }


    render() {
        const {
            form,
            form: {getFieldDecorator, getFieldValue}
        } = this.props

        const {
            productList,
            _id,
            userList,
            isDispatch,
            newUserList,
            carriersData,
            purposeValue,
            replacementType,
            selectedRowKeys,
            inwardPendingList,
            singleRecord
        } = this.state

        const inputTypes = {
            fields: [
                {
                    label: 'Serial No.',
                    key: 'serialNumber',
                    customProps: {disabled: true},
                    required: true,

                },
                {
                    label: 'Department',
                    key: 'department',
                    type: 'select',
                    required: true,
                    options: ['AMCU', 'Glassware'],
                    onChange: department => {
                        this.props.form.setFieldsValue({
                            department: department
                        })

                    }


                },
                {
                    label: 'Date',
                    key: 'date',
                    type: 'date',
                    required: true

                },
                {
                    label: 'Product Type',
                    key: 'productType',
                    type: 'select',
                    required: true,
                    options: ['Finish Goods', 'Packing Material', 'Electronic Components', 'Raw Material'],
                    onChange: productType => {
                        this.props.form.setFieldsValue({
                            productType: productType
                        })

                    }


                },

                {
                    label: 'Purpose',
                    key: 'purpose',
                    type: 'select',
                    required: true,
                    options: ['Sale', 'Repair', 'Purchase', 'Replacement', 'Receiving'],
                    onChange: async (purpose) => {
                        this.setState({purposeValue: purpose, replacementType: undefined})
                        this.props.form.setFieldsValue({
                            purpose: purpose
                        })
                    }


                },
                {
                    label: 'Consignee',
                    key: 'consignee',
                    required: true,


                },
            ]
        }
        const inputTypes1 = {
            fields: [
                {
                    label: 'Challan Number',
                    key: 'challanNumber',
                    required: true,

                },
                {
                    label: 'Filled By',
                    key: 'filledBy',
                    showSearch: true,
                    disabled: true,
                    required: true,
                    keyAccessor: x => x._id,
                    valueAccessor: x => `${x.name} ${x.mobile} (${x.userType})`,
                    type: 'select',
                    options: userList || [],
                    onChange: filledBy => {
                        this.props.form.setFieldsValue({
                            filledBy: filledBy
                        })

                    },

                },
                {
                    label: 'Checked By',
                    key: 'checkedBy',
                    showSearch: true,
                    required: true,
                    keyAccessor: x => x._id,
                    valueAccessor: x => `${x.name} ${x.mobile} (${x.userType})`,
                    type: 'select',
                    options: userList || [],
                    onChange: checkedBy => {
                        this.props.form.setFieldsValue({
                            checkedBy: checkedBy
                        })

                    },
                    customProps: {
                        dropdownMatchSelectWidth: false
                    }
                },

            ]
        }

        const refColumns = [

            {
                title: 'S.No',
                dataIndex: 'sno',
                key: 'sno',
                width: 70,
                render: (value, item, index) => {
                    if (item && item.isLast != true) {
                        return index + 1
                    }
                }
            }, {
                title: 'Subjected to Stock',
                dataIndex: 'subjectedToStock',
                key: 'subjectedToStock',
                width: 150,
                render: (value, item, index) => {
                    if (item && item.isLast == true) {
                        return <Checkbox onChange={(val) => {
                            this.setState({subjectedToStock: val.target.checked})
                        }}/>
                    } else {
                        return <Tag>{value ? 'Yes' : 'No'}</Tag>
                    }
                }
            },

            {
                title: 'Product',
                dataIndex: 'productName',
                key: 'productName',
                width: 200,
                render: (x, item, index) => {
                    if (item && item.isLast == true) {

                        return (<div>
                            {getFieldDecorator('productName', {})(
                                 <AutoComplete
                                    notFoundContent={this.state.showSpin && <Spin/>}
                                    autoComplete={'none'}
                                    dropdownMatchSelectWidth={false}
                                    onSelect={this.onProductSelect}
                                    placeholder={'Product Name'}
                                    // onBlur={(val) => {
                                    //     this.handleProductSearch(val)

                                    // }}
                                    onSearch={(val) => {
                                        this.setState({newOption: val})
                                    }}
                                >
                                {this.state.newOption &&
                                        <Option value={this.state.newOption || ''}>{this.state.newOption}</Option>}
                                {productList.map((val, item) => (

                                  
                                    <Option value={val._id}>
                                        <>
                                            <div><b>{val._id}</b></div>
                                        </>
                                    </Option>
                                ))}

                                </AutoComplete>)}

                        </div>)

                    } else {
                        return <div style={{marginBottom: '5px'}}> {x}</div>
                    }


                }


            },
            {
                title: 'ProductIds',
                key: 'productIds',
                dataIndex: 'productIds',
                render: (x, item, key) => {
                    if (item && item.isLast !== true) {
                        return <div>
                            <Select
                                defaultValue={item && item.productIds}
                                style={{width: 200}}
                                placeholder={'Enter Product Id'}
                                mode={'tags'}
                                onChange={(e) => {
                                    let array = []
                                    _.each(e, (i) => {
                                        array.push(i.toLowerCase())
                                    })
                                    item.productIds = array


                                }}
                            >
                            </Select>
                        </div>
                    }
                }


            },


            {
                title: 'Quantity Recieved',
                key: 'quantityRecieved',
                dataIndex: 'quantityRecieved',
                render: (y, item, index) => {
                    if (item && item.isLast != true) {
                        return <div><Text editable={{
                            onChange: (val) => {

                                if (!isNaN(parseInt(val))) {
                                    let tt = this.state.totalData
                                    item.quantityRecieved = val ? Math.abs(val) : tt[index].quantityRecieved
                                    item.quantityAccepted = tt[index].quantityRecieved
                                    item.quantityRejected = tt[index].quantityRecieved - tt[index].quantityAccepted
                                    this.setState({totalData: tt})
                                    this.props.form.setFieldsValue({
                                        quantityRecieved: val
                                    })
                                }


                            }
                        }}> {y}</Text>


                        </div>
                    }


                }

            },
            {
                title: 'Quantity Accepted',
                key: 'quantityAccepted',
                dataIndex: 'quantityAccepted',
                render: (y, item, index) => {
                    if (item && item.isLast != true) {
                        return <div><Text editable={{
                            onChange: (val) => {
                                if (!isNaN(parseInt(val))) {
                                    if ((val && Math.abs(val)) > item.quantityRecieved) {
                                        return val
                                    }
                                    let tt = this.state.totalData
                                    item.quantityAccepted = val ? Math.abs(val) : tt[index].quantityAccepted
                                    item.quantityRejected = tt[index].quantityRecieved - tt[index].quantityAccepted
                                    this.setState({totalData: tt})
                                    this.props.form.setFieldsValue({
                                        quantityAccepted: val
                                    })

                                }


                            }
                        }}> {y}</Text>


                        </div>
                    }


                }

            },
            {
                title: 'Quantity Rejected',
                key: 'quantityRejected',
                dataIndex: 'quantityRejected',
                render: (y, item, index) => {
                    if (item && item.isLast != true) {
                        return y

                    }


                }

            },


            {
                title: 'Action',
                key: 'operation',
                width: 80,
                render: (item, record) => {
                    if (record && record.isLast != true) {
                        return <React.Fragment>

                            <Tooltip title="Delete Product">
                                <Popconfirm
                                    title="Are you sure delete this Product?"
                                    onConfirm={() => {
                                        let tt = this.state.totalData
                                        tt = _.reject(tt, item)
                                        this.setState({totalData: tt}, () => {
                                        })


                                    }}
                                    onCancel={() => {
                                    }}
                                    okText="Yes"
                                    cancelText="No">
                                    <Button
                                        shape="circle"
                                        size="small"
                                        type={'danger'}
                                        icon="delete"
                                    />
                                </Popconfirm>

                            </Tooltip>

                        </React.Fragment>

                    }
                }
            }
        ]
        const column = [
            {
                title: 'Id',
                dataIndex: 'serialNumber',
                width: 80,
                fixed: 'left',
                key: 'serialNumber',
                searchTextName: 'serialNumber'

            },
            {
                title: 'Type',
                dataIndex: 'type',
                key: 'type',
                width: 110,
                fixed: 'left',
                render: (val) => {
                    return <Tag color={val === 'Inward' ? 'blue' : 'red'}>{val}</Tag>
                }

            },
            {
                title: 'Products',
                dataIndex: 'products',
                key: 'products',
                width: 200,
                render: (val, record, index) => {
                    return <React.Fragment>
                        <div>
                            <ul style={{paddingLeft: '0px', listStyle: 'none'}}>
                                {val && val.length && val.map((item, key) => {

                                    return <li key={key}>{item.productName}</li>
                                })}

                            </ul>

                        </div>
                    </React.Fragment>

                }

            },
            {
                title: 'Purpose',
                dataIndex: 'purpose',
                width: 120,
                key: 'purpose',

            },
            {
                title: 'Challan Number',
                dataIndex: 'challanNumber',
                width: 150,
                key: 'challanNumber',
                searchTextName: 'challanNumber',
            },
            {
                title: 'Invoice Number',
                dataIndex: 'invoiceNumber',
                width: 150,
                key: 'invoiceNumber',
                searchTextName: 'invoiceNumber',
            },
            {
                title: 'Order Authority',
                dataIndex: 'orderAuthority',
                width: 150,
                key: 'orderAuthority',
                render: (val, record) => {
                    return (<div>
                        <div>{record.orderAuthority ? record.orderAuthority.name : ''}</div>
                    </div>)
                }
            },


            {
                title: 'Consignee Name',
                dataIndex: 'consignee',
                width: 150,
                key: 'consignee',
                searchTextName: 'consignee'
            },
            {
                title: 'Packing Done By',
                width: 150,
                dataIndex: 'packingDoneBy',
                key: 'packingDoneBy',
                searchTextName: 'packingDoneBy'

            },

            {
                title: 'Date',
                dataIndex: 'date',
                key: 'date',
                width: 180,
                searchDateName: 'date',
                render: (text, record) => {
                    return (
                        <Tag>
                            {text ? moment(text).format('lll') : ''}
                        </Tag>
                    )
                },
            },
            {
                title: 'Created At',
                dataIndex: 'createdAt',
                key: 'createdAt',
                width: 180,
                searchDateName: 'createdAt',
                render: (text, record) => {
                    return (
                        <Tag>
                            {text ? moment(text).format('lll') : ''}
                        </Tag>
                    )
                },
            },
            {
                key: 'actions',
                title: 'Actions',
                fixed: 'right',
                width: 80,
                render: (val, record, key) => {
                    return (<React.Fragment>
                        <Tooltip title="View Products">
                            <Button
                                style={{marginRight: '6px', marginTop: '6px'}}
                                size="small"
                                shape="circle"
                                onClick={() => {
                                    this.setState({
                                        singleRecord: record
                                    }, () => {
                                        this.showDrawer()

                                    })

                                }}
                                icon="eye"
                            />
                        </Tooltip>
                    </React.Fragment>)
                }
            }

        ]


        const rowSelection = {
            selectedRowKeys: selectedRowKeys,
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({selectedRowKeys})
                this.props.form.setFieldsValue({replacementList: selectedRows})
            },
            getCheckboxProps: record => ({
                _id: record._id
            })
        }

        const formItemLayout = {
            labelCol: {
                xs: {span: 24},
                sm: {span: 8},
                md: {span: 8}
            },
            wrapperCol: {
                xs: {span: 24},
                sm: {span: 16},
                md: {span: 12}
            }
        }

        return (
            <PageHeaderWrapper title={this.state._id ? 'Update Inward Form' : 'Add Inward Form'}
            >

                <Spin spinning={this.state.loader}>
                    <Form onSubmit={this.handleSubmit} hideRequiredMark={false} style={{marginTop: 8}}>

                        <Card bordered={true}>

                            <Row>
                                <Col span={12}>
                                    <GetAllFormFields apiurl={API_URL}
                                                      inputSchema={inputTypes}
                                                      formItemLayout={formItemLayout}
                                                      getFieldDecorator={getFieldDecorator}/>


                                    {(purposeValue == 'Replacement') ?
                                        <Form.Item label={'Replacement Type'} {...formItemLayout}>
                                            {getFieldDecorator('replacementType', {
                                                rules: [{
                                                    required: true,
                                                }],
                                            })(
                                                <Radio.Group disabled={this.state._id ? true : false}
                                                             options={['Outward Pending', 'Already Outward']}
                                                             className={styles.inwardRadioButton}
                                                             onChange={(checkedValues) => {
                                                                 this.setState({replacementType: checkedValues.target.value})
                                                             }} value={this.state.replacementType}>


                                                </Radio.Group>
                                            )}
                                        </Form.Item> : ''}


                                </Col>
                                <Col span={12}>
                                    <GetAllFormFields apiurl={API_URL}
                                                      inputSchema={inputTypes1}
                                                      formItemLayout={formItemLayout}
                                                      getFieldDecorator={getFieldDecorator}/>
                                    <Form.Item
                                        label='Carrier'
                                        {...formItemLayout}
                                    >{getFieldDecorator('carrier', {
                                        rules: [{
                                            required: true,
                                            message: 'This is a Mandatory Field',
                                        }]
                                    })(
                                        <AutoComplete
                                            autoComplete={'none'}
                                            onSelect={this.onSelect}
                                            placeholder={'Delivery Carrier'}

                                        >
                                            {carriersData.map((val, item) => (
                                                <Option value={val}>
                                                    {val}
                                                </Option>
                                            ))}
                                        </AutoComplete>
                                    )}
                                    </Form.Item>


                                    <Form.Item
                                        label='Carrier Details'
                                        {...formItemLayout}
                                    >{getFieldDecorator('carrierDetails', {
                                        rules: [{
                                            required: true,
                                            message: 'This is a Mandatory Field',
                                        }]
                                    })(
                                        <TextArea></TextArea>
                                    )}
                                    </Form.Item>

                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    {(replacementType === 'Already Outward') ?
                                        <Form.Item label={'Inward Pending'}>

                                            {getFieldDecorator('replacementList', {
                                                rules: [{
                                                    required: true,
                                                    message: 'This is a Mandatory Field',
                                                }]
                                            })(<Table
                                                    bordered={true}
                                                    rowKey={x => x._id}
                                                    columns={column}
                                                    scroll={{y: 350, x: 'max-content'}}
                                                    dataSource={inwardPendingList || []}
                                                    pagination={false}
                                                    rowSelection={this.state._id ? '' : rowSelection}
                                                />
                                            )}
                                        </Form.Item> : ''}
                                </Col>
                            </Row>

                            <Row>
                                <Col span={24} style={{marginTop: '20px'}}>
                                    {<Table
                                        bordered={true}
                                        rowKey={x => x.productName}
                                        columns={refColumns}
                                        dataSource={this.state.totalData || []}
                                        pagination={false}
                                    />}

                                </Col>
                            </Row>
                            <Form.Item style={{marginTop: 32, textAlign: 'center'}}>

                                <Button size="large" type='primary'
                                        loading={this.props.loading}
                                        htmlType='submit'
                                        style={{width: '15%', marginLeft: '1em'}}>{_id ? `Update` : `Save`}
                                </Button>


                            </Form.Item>
                        </Card>


                    </Form>
                    <Drawer
                        title=""
                        placement="right"
                        closable={true}
                        onClose={() => {
                            this.setState({
                                visible: false,
                            })
                        }}
                        visible={this.state.visible}
                        width={600}>
                        <Card className={styles.drawerCard}>

                            <table style={{width: '100%'}} className={styles.ProcessTable}>
                                <thead>
                                <tr>
                                    <th>Product Name</th>
                                    <th>Quantity</th>
                                    <th>Product Ids</th>
                                </tr>
                                </thead>

                                <tbody> {_.map(singleRecord && singleRecord.products, (item) => {
                                    return <tr>
                                        <td colSpan="1">{item && item.productName}</td>
                                        <td colSpan="1">{item && item.quantity}</td>
                                        <td>
                                            {item && item.productIds.join(' , ')}
                                        </td>

                                    </tr>

                                })}</tbody>


                            </table>

                        </Card>

                    </Drawer>

                </Spin>


            </PageHeaderWrapper>
        )
    }
}

const mapStateToProps = ({global, router}) => ({
    loading: global.buttonLoading,
    categories: global.categories,
    search: router.location.search,
    currentUser: global.currentUser

})
const mapDispatchToProps = dispatch => {
    return {
        dispatch
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AddInwardForm)
